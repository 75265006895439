import { string, object } from "yup";

const schemaThreeForm = object({
  administration: string().required("Este campo es obligatorio"),
  nameCommissioner: string().required("Este campo es obligatorio"),
  RFCCommissioner: string()
    .required("Este campo es obligatorio")
    .min(13, "El RFC debe contener 13 digitos")
    .max(13, "El RFC debe contener 13 digitos"),
}).required();

export default schemaThreeForm;
