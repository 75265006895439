import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

export default makeStyles((theme) => ({
  container: {
    backgroundColor: colors.ligthGray,
    padding: "40px 10vh",
    fontFamily: 'poppinsmedium',
    '@media (max-width: 640px)': {
      padding: 10,
    }
  },
  title: {
    textAlign: "center",
    fontSize: 40,
    fontFamily: 'poppinsbold'
  },
  subtitle: {
    fontSize: 14,
    textAlign: "center",
    fontFamily: 'poppinsregular'
  }
}));
