import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 100px)",
    width: "90%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      marginBottom: "60px",
    },
  },
  chatContainer: {
    minHeight: "calc(100vh - 200px)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  centeredVisitorContainer: {
    display: "flex",
    justifyContent: "center",
  },
  wrapper: {
    marginTop: 30,
  },
  title: {
    color: colors.white,
    fontSize: 20,
    fontWeight: "bold",
    textAlign: "center",
    marginBottom: "35px",
    fontFamily: "poppinsbold",
  },
  titleTransaction: {
    // color: colors.blue,
    fontWeight: "bold",
    fontFamily: "poppinsbold",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  containerForm: {
    display: "flex",
    justifyContent: "center",
    minWidth: "350px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  form: {
    backgroundColor: colors.blue,
    borderRadius: 10,
    padding: 30,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "space-around",
    fontFamily: "poppinsbold",
    [theme.breakpoints.down("md")]: {
      marginBottom: 25,
    },
  },
  transactionContainer: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("md")]: {
      marginTop: "40px",
    },
  },
  buttons: {
    color: colors.white,
    fontFamily: "poppinsbold",
  },
  highlines: {
    [theme.breakpoints.down("md")]: {
      marginTop: 10,
      display: "none !important",
    },
  },
  lineUp: {
    backgroundColor: colors.lightskyblue,
    marginBottom: 30,
    height: 2,
  },
  lineDown: {
    backgroundColor: colors.white,
    marginBottom: 30,
  },
  chatWrapper: {
    marginTop: 24,
    display: "inline-block",
    width: "65%",
    top: 24,
    [theme.breakpoints.down("lg")]: {
      width: 600,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
}));

export default useStyles;
