import { useForm, FormProvider } from "react-hook-form";
import { OneNestedInput } from "./one-nested-input";
import useStyles from "../styles";
import { Button } from "@mui/material";
import { useStateValue } from "context/store";
import { nextStep } from "context/company/actions";
import {
  addDataProtocolization,
  getProtocolizationById,
  resetStepsProtocolization,
} from "context/protocolization/actions";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import { FirebaseContext } from "server";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaProtocolizationOne from "schema/ProtocolizationAssemblies/protocolization-step-one";
import ErrorForm from "components/Error-form";

const defaultValues = (protocolization) => ({
  companyName: protocolization?.companyName,
  typeReport: protocolization?.typeReport,
  assembleResolutions: protocolization?.assembleResolutions,
  actAssembly: protocolization?.actAssembly,
  companyBackgroundFile: protocolization?.companyBackgroundFile,
  ccShareholdersFile: protocolization?.ccShareholdersFile,
  delegateFile: protocolization?.delegateFile,
  actSignedFile: protocolization?.actSignedFile,
});

const StepOne = () => {
  // styles
  const classes = useStyles();

  // react hook form
  const methods = useForm({
    resolver: yupResolver(schemaProtocolizationOne),
  });

  // variables
  const [
    {
      protocolization: {
        protocolInput,
        protocolizationById,
        resetStepProtocolization,
      },
    },
    dispatch,
  ] = useStateValue();
  const currentUser = JSON.parse(localStorage.getItem("current-user"));

  // documentId
  const { state } = useLocation();
  let documentId = state?.documentId;

  // firebase
  const firebase = useContext(FirebaseContext);

  // functions
  const onSubmit = (data) => {
    nextStep(dispatch);
    saveDataState(data);
    resetStepsProtocolization(dispatch, {
      step_one: true,
    });
  };

  const saveDataState = (data) => {
    const withCurrentUser = {
      currentUser: currentUser.name,
      status: "pending",
      ...data,
    };
    addDataProtocolization(dispatch, withCurrentUser);
  };

  // useEffect
  useEffect(() => {
    const fetchProtocolizationById = async () => {
      if (documentId) {
        await getProtocolizationById(documentId, firebase, dispatch);
      }
    };
    fetchProtocolizationById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentId, dispatch, firebase]);

  useEffect(() => {
    if (!resetStepProtocolization.step_one) {
      methods.reset(defaultValues(protocolizationById));
    }
  }, [protocolizationById, methods, resetStepProtocolization.step_one]);

  const countErrors = Object.entries(methods.formState.errors).length;

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <div style={{ marginBottom: 30 }}>
          <OneNestedInput
            value={protocolInput}
            protocolizationById={protocolizationById}
          />
          <div className={classes.containerButtons}>
            <Button
              variant="contained"
              className={classes.btnNextStep}
              onClick={methods.handleSubmit(onSubmit)}
            >
              Siguiente
            </Button>
          </div>
          <ErrorForm countErrors={countErrors} />
        </div>
      </form>
    </FormProvider>
  );
};

export default StepOne;
