import { string, object, array } from "yup";

const formSchema = {
  name: string().required("Este campo es obligatorio"),
  paternalSurname: string().required("Este campo es obligatorio"),
  maternalSurname: string().required("Este campo es obligatorio"),
  nacionality: string().required("Este campo es obligatorio"),
  placeBirth: string().required("Este campo es obligatorio"),
  dateBirth: string().required("Este campo es obligatorio"),
  maritalStatus: string().required("Este campo es obligatorio"),
  ocupation: string().required("Este campo es obligatorio"),
  RFC: string()
    .required("Este campo es obligatorio")
    .min(13, "El RFC debe contener 13 digitos")
    .max(13, "El RFC debe contener 13 digitos"),
  CURP: string()
    .required("Este campo es obligatoriorequerido")
    .min(18, "El CURP debe contener 18 digitos")
    .max(18, "El CURP debe contener 18 digitos"),
  identification: string().required("Este campo es obligatorio"),
  streetNumber: string().required("Este campo es obligatorio"),
  colony: string().required("Este campo es obligatorio"),
  state: string().required("Este campo es obligatorio"),
  municipality: string().required("Este campo es obligatorio"),
  CP: string().required("Este campo es obligatorio"),
  phone: string().required("Este campo es obligatorio"),
  email: string()
    .required("Este campo es obligatorio")
    .email("Email no válido"),
  inability: string().required("Este campo es obligatorio"),
};

const schemaFourForm = object({
  shareholder: array().of(object().shape(formSchema)),
});

export default schemaFourForm;
