import { useFormContext } from "react-hook-form";
import { InputRadio } from "components/inputs/input-radio";
import { Button, Grid, Typography } from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { labelsMaritalStatus } from "./constants";
import { colors } from "constants/colors";
import { InputFile } from "components/inputs/input-file";
import { SimpleInputSelect } from "components/inputs/simple-input-select";
import StatesMunicipalities from "constants/bd";
import { InputDatePicker } from "components/inputs/input-date-picker";
import { InputNumberFormat } from "components/inputs/input-number-format";
import ShowFiles from "components/show-files/show-files";
import { useState } from "react";
import { onInputNumber } from "utils";
import { InputEmail } from "components/inputs/input-email";

const NestedInput = ({ donationById }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext(); // retrieve all hook methods

  const states = StatesMunicipalities.map((state) => state[0]);
  const watchState = watch(`stateHome`);

  const { maritalStatus } = watch();

  let municipalities = [];
  if (watchState) {
    municipalities = StatesMunicipalities.filter(
      (item) => watchState === item[0]
    )[0][1];
  }

  // useState Files
  const [viewHomeFile, setViewHomeFile] = useState([]);
  const [viewLegalRepresentative, setViewLegalRepresentative] = useState([]);
  const [viewPassportFile, setViewPassportFile] = useState([]);
  const [viewPassportFileMigrate, setViewpassportFileMigrate] = useState([]);
  const [viewConjugalPartnership, setViewConjugalPartnership] = useState([]);
  const [viewSeparateProperty, setViewSeparateProperty] = useState([]);

  const removeViewHomeFile = () => {
    setViewHomeFile([]);
  };
  const removeViewLegalRepresentative = () => {
    setViewLegalRepresentative([]);
  };
  const removeViewPassportFile = () => {
    setViewPassportFile([]);
  };
  const removeViewPassportFileMigrate = () => {
    setViewpassportFileMigrate([]);
  };
  const removeViewConjugalPartnership = () => {
    setViewConjugalPartnership([]);
  };
  const removeViewSeparateProperty = () => {
    setViewSeparateProperty([]);
  };

  const removeExtension = (filename) => {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  };

  return (
    <>
      <div className="divider">
        <Typography variant="subtitle1">
          Trámite legal que se realiza entre parientes de primer grado que
          desean realizar donaciones voluntarias, libres de impuestos, a sus
          hijos.{" "}
        </Typography>
      </div>

      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <Typography variant="subtitle2" color={colors.blue}>
          Los campos marcados con * son obligatorios
        </Typography>
      </div>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={8} md={4}>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            style={styles.title}
          >
            Cantidad que se está donando *
          </Typography>
          <InputNumberFormat
            name="amountPesos"
            value={""}
            placeholder="$0"
            control={control}
            errors={errors}
          />
          {errors.amountPesos && (
            <p style={styles.errors}>{errors.amountPesos.message}</p>
          )}
        </Grid>
      </Grid>

      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <Typography style={{ fontSize: 30 }} color={colors.blue}>
          <span style={styles.title}>Datos generales</span>{" "}
          <span>(Por compareciente)</span>
        </Typography>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              style={styles.title}
            >
              Nombre completo *
            </Typography>
            <InputText
              name={`fullName`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.fullName && (
              <p style={styles.errors}>{errors.fullName.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Lugar de nacimiento *
            </Typography>
            <SimpleInputSelect
              name="stateHome"
              value={""}
              control={control}
              errors={errors}
              labels={states}
              placeholder="--Estado--"
            />
            {errors.stateHome && (
              <p style={styles.errors}>{errors.stateHome.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Ciudad
            </Typography>
            <SimpleInputSelect
              name={`municipalityHome`}
              value={""}
              control={control}
              errors={errors}
              labels={municipalities}
              disabled={!municipalities.length}
              placeholder="--Ciudad--"
            />
            {errors.municipalityHome && (
              <p style={styles.errors}>{errors.municipalityHome.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Fecha de nacimiento *
            </Typography>
            <InputDatePicker
              name={`dateBirth`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.dateBirth && (
              <p style={styles.errors}>{errors.dateBirth.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              component="div"
              className="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Nombre participante adicional
            </Typography>
            <InputText name={`associate`} control={control} value={""} />
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              component="div"
              className="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Nombre participante adicional
            </Typography>
            <InputText name={`associate2`} control={control} value={""} />
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Estado civil *
            </Typography>
            <InputRadio
              name="maritalStatus"
              value={""}
              control={control}
              errors={errors}
              labels={labelsMaritalStatus}
            />
            {errors.maritalStatus && (
              <p style={styles.errors}>{errors.maritalStatus.message}</p>
            )}
          </Grid>
          {maritalStatus === "Casado(a)" && (
            <>
              <Grid item xs={4} sm={8} md={4}>
                <Typography
                  className="subtitle2"
                  variant="subtitle2"
                  gutterBottom
                  style={styles.title}
                >
                  Casado(a) sociedad conyugal
                </Typography>

                <Button
                  variant="contained"
                  component="label"
                  style={styles.fileButtonv2}
                >
                  <InputFile
                    name={`conjugalPartnership`}
                    setValue={setValue}
                    control={control}
                    hidden={true}
                    setFiles={setViewConjugalPartnership}
                  />
                  <span>Adjuntar Acta de Matrimonio</span>
                  <div style={{ marginLeft: 15 }}>+</div>
                </Button>

                <ShowFiles
                  valueStateGlobal={donationById?.conjugalPartnership}
                  valueStateLocal={viewConjugalPartnership}
                  removeFile={removeViewConjugalPartnership}
                />
              </Grid>
              <Grid item xs={4} sm={8} md={4}>
                <Typography
                  className="subtitle2"
                  variant="subtitle2"
                  gutterBottom
                  style={styles.title}
                >
                  Casado(a) separación de bienes
                </Typography>

                <Button
                  variant="contained"
                  component="label"
                  style={styles.fileButtonv2}
                >
                  <InputFile
                    name={`separateProperty`}
                    setValue={setValue}
                    control={control}
                    hidden={true}
                    setFiles={setViewSeparateProperty}
                  />
                  <span>Adjuntar Acta de Matrimonio</span>
                  <div style={{ marginLeft: 15 }}>+</div>
                </Button>
                <ShowFiles
                  valueStateGlobal={donationById?.separateProperty}
                  valueStateLocal={viewSeparateProperty}
                  removeFile={removeViewSeparateProperty}
                />
              </Grid>
            </>
          )}
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Ocupación *
            </Typography>
            <InputText
              name={`ocupation`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.ocupation && (
              <p style={styles.errors}>{errors.ocupation.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Actividad económica actual *
            </Typography>
            <InputText
              name={`economicActivity`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.economicActivity && (
              <p style={styles.errors}>{errors.economicActivity.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Registro federal de contribuyentes (con homoclave)
            </Typography>
            <InputText
              name={`federalRegister`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.federalRegister && (
              <p style={styles.errors}>{errors.federalRegister.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Typography
          className="subtitle2"
          variant="subtitle2"
          gutterBottom
          color={styles.title}
        >
          Identificación vigente
        </Typography>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.titlev2}
            >
              CURP *
            </Typography>
            <InputText
              name={`CURP`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.CURP && <p style={styles.errors}>{errors.CURP.message}</p>}
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.titlev2}
            >
              Identificación *
            </Typography>
            <Button
              variant="contained"
              component="label"
              style={styles.fileButtonv2}
            >
              <InputFile
                name={`passportFile`}
                setValue={setValue}
                control={control}
                hidden={true}
                setFiles={setViewPassportFile}
              />
              <span>Adjuntar copia</span>
              <div style={{ marginLeft: 15 }}>+</div>
            </Button>

            <ShowFiles
              valueStateGlobal={donationById?.passportFile}
              valueStateLocal={viewPassportFile}
              removeFile={removeViewPassportFile}
            />
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={{ opacity: 0 }}
            >
              @notaria 26
            </Typography>
            <Button
              variant="contained"
              component="label"
              style={styles.fileButtonv2}
            >
              <InputFile
                name={`passportFileMigrate`}
                setValue={setValue}
                control={control}
                hidden={true}
                setFiles={setViewpassportFileMigrate}
              />
              <span>Adjuntar Forma Migratoria</span>
              <div style={{ marginLeft: 15 }}>+</div>
            </Button>

            <ShowFiles
              valueStateGlobal={donationById?.passportFileMigrate}
              valueStateLocal={viewPassportFileMigrate}
              removeFile={removeViewPassportFileMigrate}
            />
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        *<strong>Nota:</strong> En caso de ser extranjero debe adjuntar copia
        del Pasaporte y Forma migratoria.
      </div>

      <div className="divider">
        <Typography
          className="subtitle2"
          variant="subtitle2"
          gutterBottom
          color={styles.title}
        >
          Domicilio
        </Typography>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Calle y número *
            </Typography>
            <InputText
              name={`street`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.street && (
              <p style={styles.errors}>{errors.street.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Colonia *
            </Typography>
            <InputText
              name={`colony`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.colony && (
              <p style={styles.errors}>{errors.colony.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <Typography variant="body1" gutterBottom>
              Estado
            </Typography>
            <SimpleInputSelect
              name={`state`}
              value={""}
              control={control}
              errors={errors}
              labels={states}
            />
            {errors.state && (
              <p style={styles.errors}>{errors.state.message}</p>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={3}>
            <Typography variant="body1" gutterBottom>
              Municipio
            </Typography>
            <SimpleInputSelect
              name={`municipality`}
              value={""}
              control={control}
              errors={errors}
              labels={municipalities}
              disabled={!municipalities.length}
            />
            {errors.municipality && (
              <p style={styles.errors}>{errors.municipality.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              C.P. *
            </Typography>
            <InputText
              name={`CP`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.CP && <p style={styles.errors}>{errors.CP.message}</p>}
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Teléfono *
            </Typography>
            <InputText
              name={`phone`}
              value={""}
              onInput={onInputNumber}
              control={control}
              errors={errors}
            />
            {errors.phone && (
              <p style={styles.errors}>{errors.phone.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Correo electrónico *
            </Typography>
            <InputEmail
              name={`email`}
              value={""}
              control={control}
              type="email"
              errors={errors}
              className="input-email"
            />
            {errors.email && (
              <p style={styles.errors}>{errors.email.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        **<strong>Nota:</strong> En caso de identificarse con pasaporte o si su
        domicilio no coincide con el que menciona la credencial para votar debe
        proporcionar el comprobante de domicilio actual.
      </div>

      <div className="divider">
        <Typography
          className="subtitle2"
          variant="subtitle2"
          gutterBottom
          style={styles.titlev2}
        >
          Comprobante de domicilio actual *
        </Typography>
        <Button variant="contained" component="label" style={styles.fileButton}>
          <InputFile
            name={`homeFile`}
            setValue={setValue}
            control={control}
            hidden={true}
            setFiles={setViewHomeFile}
          />
          <span>Adjuntar</span>
          <div style={{ marginLeft: 15 }}>+</div>
        </Button>

        <ShowFiles
          valueStateGlobal={donationById?.homeFile}
          valueStateLocal={viewHomeFile}
          removeFile={removeViewHomeFile}
        />
      </div>

      <div className="divider">
        <Typography
          className="subtitle2"
          variant="subtitle2"
          gutterBottom
          color={colors.blue}
        >
          <strong>En caso de ser representante legal de sociedad</strong>{" "}
          (adjuntar constitutiva, cambios realizados, poder y cédula de la
          empresa)
        </Typography>
        <Button variant="contained" component="label" style={styles.fileButton}>
          <InputFile
            name={`legalRepresentative`}
            setValue={setValue}
            control={control}
            hidden={true}
            setFiles={setViewLegalRepresentative}
          />
          <span>Adjuntar</span>
          <div style={{ marginLeft: 15 }}>+</div>
        </Button>

        <ShowFiles
          valueStateGlobal={donationById?.legalRepresentative}
          valueStateLocal={viewLegalRepresentative}
          removeFile={removeViewLegalRepresentative}
        />
      </div>
    </>
  );
};

export default NestedInput;

const styles = {
  title: {
    color: colors.blue,
    fontWeight: "bold",
  },
  titlev2: {
    color: colors.black,
    fontWeight: "bold",
  },
  fileButton: {
    background: colors.blue,
    color: colors.white,
    borderRadius: 20,
    fontWeight: "bold",
    width: 160,
    marginRight: 20,
    alignItems: "center",
  },
  fileButtonv2: {
    background: colors.blue,
    color: colors.white,
    borderRadius: 20,
    fontWeight: "bold",
    width: 300,
    marginRight: 20,
    alignItems: "center",
  },
  errors: {
    padding: "5px 15px",
    fontSize: 14,
    borderRadius: 15,
    marginTop: -1,
    borderTopLeftRadius: 2,
    backgroundColor: colors.dangerLight,
    display: "inline-block",
    border: `1px solid ${colors.danger}`,
    color: colors.danger,
  },
};
