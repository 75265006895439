export const colors = {
  lightskyblue: "#72D8F7",
  lightgrey: "#E2E2E2",
  blue: "#1759A5",
  black: "#000000",
  ligthGray: "#E2E2E2",
  darkblue: "#1E2E3F",
  white: "#ffffff",
  gray: "#707070",
  borderGray: "#EEEEEE",
  darkGray: "#81878D",
  warning: "#FFEB3B",
  darkGrayShade: "#777777",
  appearance: "#666666",
  danger: "#FD4C4C",
  dangerLight: "#FFEDED",
};