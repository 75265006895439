import { useState } from "react";
import { toast } from "react-toastify";

const ValidateFormDonation = (onValidate, asyncSubmitDonationHandler) => {

    const tokenStorage = localStorage.getItem("auth-token");
    const [loginState, setLoginState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [dataForm, setDataForm] = useState({
        appearing: "",
        colony: "",
        cp: "",
        curp: "",
        dateBirth: "",
        disability: "",
        disabilityRadioValue: {
          none: false,
          deaf: false,
          mute: false,
          blind: false,
          other: false,
        },
        email: "",
        lastName: "",
        lastName2: "",
        maritalStatus: "",
        maritalRadioValue: {
          soltero: false,
          casado: false,
          unionLibre: false,
          divorciado: false,
          viudo: false,
        },
        municipality: "",
        name: "",
        occupation: "",
        identification: "",
        identificationRadioValue: {
          credential: false,
          passport: false,
        },
        phone: "",
        placeBirth: "",
        rfc: "",
        streetNumber: "",
        country: "0",
        countryId: "0",
        state: "0",
        stateId: "0",
        liveState: "0",
        liveStateId: "0",
        liveMunicipality: "0",
        liveMunicipalityId: "0",
        addressFile: "",
        addressFileName: "",
        identificationFile: "",
        identificationFileName: "",
        });
    const [dataFormAppearing, setDataFormAppearing] = useState([{
        appearing: "",
        colony: "",
        cp: "",
        curp: "",
        dateBirth: "",
        disability: "",
        disabilityRadioValue: {
          none: false,
          deaf: false,
          mute: false,
          blind: false,
          other: false,
        },
        email: "",
        lastName: "",
        lastName2: "",
        maritalStatus: "",
        maritalRadioValue: {
          soltero: false,
          casado: false,
          unionLibre: false,
          divorciado: false,
          viudo: false,
        },
        municipality: "",
        name: "",
        occupation: "",
        identification: "",
        identificationRadioValue: {
          credential: false,
          passport: false,
        },
        phone: "",
        placeBirth: "",
        rfc: "",
        streetNumber: "",
        country: "0",
        countryId: "0",
        state: "0",
        stateId: "0",
        liveState: "0",
        liveStateId: "0",
        liveMunicipality: "0",
        liveMunicipalityId: "0",
        addressFile: "",
        addressFileName: "",
        identificationFile: "",
        identificationFileName: "",
        }]);
    const [addAppearing, setAddAppearing] = useState([0]);
    const [amount, setAmount] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (tokenStorage) {
          const err = onValidate(dataFormAppearing);
          setErrors(err);
          console.log(errors)
  
          if (Object.keys(err).length === 0) {
              asyncSubmitDonationHandler();
              console.log("Enviando...")
              setDataFormAppearing([
                {
                  appearing: "",
                  colony: "",
                  cp: "",
                  curp: "",
                  dateBirth: "",
                  disability: "",
                  disabilityRadioValue: {
                    none: false,
                    deaf: false,
                    mute: false,
                    blind: false,
                    other: false,
                  },
                  email: "",
                  lastName: "",
                  lastName2: "",
                  maritalStatus: "",
                  maritalRadioValue: {
                    soltero: false,
                    casado: false,
                    unionLibre: false,
                    divorciado: false,
                    viudo: false,
                  },
                  municipality: "",
                  name: "",
                  occupation: "",
                  identification: "",
                  identificationRadioValue: {
                    credential: false,
                    passport: false,
                  },
                  phone: "",
                  placeBirth: "",
                  rfc: "",
                  streetNumber: "",
                  country: "0",
                  countryId: "0",
                  state: "0",
                  stateId: "0",
                  liveState: "0",
                  liveStateId: "0",
                  liveMunicipality: "0",
                  liveMunicipalityId: "0",
                  addressFile: "",
                  addressFileName: "",
                  identificationFile: "",
                  identificationFileName: "",
                },
              ]);
              setDataForm({
                appearing: "",
                colony: "",
                cp: "",
                curp: "",
                dateBirth: "",
                disability: "",
                disabilityRadioValue: {
                  none: false,
                  deaf: false,
                  mute: false,
                  blind: false,
                  other: false,
                },
                email: "",
                lastName: "",
                lastName2: "",
                maritalStatus: "",
                maritalRadioValue: {
                  soltero: false,
                  casado: false,
                  unionLibre: false,
                  divorciado: false,
                  viudo: false,
                },
                municipality: "",
                name: "",
                occupation: "",
                identification: "",
                identificationRadioValue: {
                  credential: false,
                  passport: false,
                },
                phone: "",
                placeBirth: "",
                rfc: "",
                streetNumber: "",
                country: "0",
                countryId: "0",
                state: "0",
                stateId: "0",
                liveState: "0",
                liveStateId: "0",
                liveMunicipality: "0",
                liveMunicipalityId: "0",
                addressFile: "",
                addressFileName: "",
                identificationFile: "",
                identificationFileName: "",
              });
              setAddAppearing([0]);
              setAmount("");
              setLoading(true);
          }
        } else {
          toast.error("Debe iniciar sesión y enviar el formulario nuevamente para continuar");
          setLoginState(true);
        }
        
      };

    return {
        dataForm,
        setDataForm,
        dataFormAppearing,
        setDataFormAppearing,
        addAppearing,
        setAddAppearing,
        amount,
        setAmount,
        handleSubmit,
        loading,
        setLoading,
        errors,
        setErrors,
        loginState,
        setLoginState,
    }
}

export default ValidateFormDonation