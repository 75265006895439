import { Backdrop, Box, CardContent, Typography } from "@mui/material";
import { LoadingTransactions } from "widgets/icons";
import useStyles from "./styles";

export default function Loading() {
  const classes = useStyles();
  return (
    <Backdrop
      sx={{
        color: "#fff",
        zIndex: theme => theme.zIndex.drawer + 1,
        height: "100%",
        background: "#ffffffb8",
        opacity: 0.3,
      }}
      open={true}
    >
      <div sx={{ display: "flex" }} className={classes.card}>
        <div className={classes.container}>
          <div className={classes.containerCardMedia}>
            <LoadingTransactions />
          </div>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography
                component="div"
                variant="subtitle2"
                className={classes.title}
              >
                Un momento por favor, <br /> Estamos creando el trámite.
              </Typography>
            </CardContent>
          </Box>
        </div>
      </div>
    </Backdrop>
  );
}
