import { Stack } from "@mui/material";
import { StatusTable } from "components/data-table/status-table";
import { useNavigate } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { colors } from "constants/colors";

export const DonationColumnHook = () => {
  const navigate = useNavigate();

  // Function
  const goToDetail = (id, view) => {
    let newId = id ? id : view.id;
    navigate(`/app/donation-money-id/${newId}`);
  };

  const columns = [
    {
      name: "id",
      options: {
        filter: true,
        display: false,
      },
    },
    {
      label: "Nombre solicitante",
      name: "currentUser",
      options: {
        filter: true,
      },
    },
    {
      label: "Nombre completo",
      name: "fullName",
      options: {
        filter: true,
      },
    },
    {
      label: "Correo electronico",
      name: "email",
      options: {
        filter: true,
      },
    },
    {
      label: "CURP",
      name: "CURP",
      options: {
        filter: true,
      },
    },
    {
      label: "CP",
      name: "CP",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Estado",
      name: "status",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: value => {
          return <StatusTable type={value} />;
        },
      },
    },
    {
      label: "Ver detalle",
      name: "Edit",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
                width: 30,
                height: 30,
                cursor: "pointer",
                backgroundColor: colors.blue,
              }}
            >
              <ArrowRightAltIcon
                style={{ color: colors.white }}
                onClick={() =>
                  goToDetail(
                    tableMeta.tableData[tableMeta.rowIndex][0],
                    tableMeta.tableData[tableMeta.rowIndex]
                  )
                }
              >
                Show
              </ArrowRightAltIcon>
            </Stack>
          );
        },
      },
    },
  ];

  return { columns };
};
