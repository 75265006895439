import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Stack } from "@mui/material";
import { StatusTable } from "components/data-table/status-table";
import { colors } from "constants/colors";
import { useNavigate } from "react-router-dom";

export const CompanyFormationColumnHook = () => {
  // Graphql Query
  const navigate = useNavigate();

  // Function
  const goToDetail = id => {
    navigate(`/app/company-formation-id/${id}`);
  };

  const columns = [
    {
      name: "id",
      options: {
        filter: true,
        display: false,
      },
    },
    {
      label: "Nombre solicitante",
      name: "currentUser",
      options: {
        filter: true,
      },
    },
    {
      label: "Tipo de sociedad",
      name: "typeSociety",
      options: {
        filter: true,
      },
    },
    {
      label: "Administración",
      name: "administration",
      options: {
        filter: true,
      },
    },
    {
      label: "Domicilio Social",
      name: "registeredOffice",
      options: {
        filter: true,
      },
    },
    {
      label: "Acciones",
      name: "action",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      label: "Estado",
      name: "status",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: value => {
          return <StatusTable type={value} />;
        },
      },
    },
    {
      label: "Ver detalle",
      name: "Edit",
      options: {
        filter: true,
        sort: false,
        empty: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 50,
                width: 30,
                height: 30,
                cursor: "pointer",
                backgroundColor: colors.blue,
              }}
            >
              <ArrowRightAltIcon
                style={{ color: colors.white }}
                onClick={() =>
                  goToDetail(tableMeta.tableData[tableMeta.rowIndex][0])
                }
              >
                Show
              </ArrowRightAltIcon>
            </Stack>
          );
        },
      },
    },
  ];

  return { columns };
};
