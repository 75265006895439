import { Controller } from "react-hook-form";
import { Select, MenuItem } from '@mui/material';

export const InputSelect = ({ name, value, control, errors, placeholder, className, labels }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value ?? ""}
      render={({ field: { onChange, value } }) => (
        <>
          <Select
            className={className ? className : "input-select"}
            value={value}
            onChange={onChange}
            displayEmpty={true}
            renderValue={value => value?.length ? Array.isArray(value) ? value.join(', ') : value : placeholder}
          >
            {labels ? (
              labels.map(({label, id}) => (
                <MenuItem value={label} key={id}>{label}</MenuItem>
              ))
            ) : (
              <>
                <MenuItem value="cedula">C.C</MenuItem>
                <MenuItem value="pasaporte">Pasaporte</MenuItem>
              </>
            )}
          </Select>
        </>
      )}
    />
  )
};
