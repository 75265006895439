import { Controller } from "react-hook-form";
//import DatePicker from 'react-date-picker';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";

export const InputDatePicker = ({ name, value, control, errors }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} size="small">
      <Controller
        name={name}
        control={control}
        defaultValue={value ?? ""}
        render={({ field: { onChange, value } }) => (
          <DatePicker
            label=""
            value={value}
            views={["year", "month", "day"]}
            inputFormat="dd/MM/yyyy"
            onChange={onChange}
            maxDate={new Date()}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                fullWidth
                className="input-date-picker"
                variant="outlined"
                error={!!errors.name}
                helperText={errors.name?.message}
              />
            )}
          />
        )}
      />
    </LocalizationProvider>
  );
};
