import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Grid,
} from "@mui/material";
import useStyles from "./styles";

import { ReactComponent as ReactLogo } from "assets/svg/rigth-icon.svg";

const CardTransaction = ({ icon, title, description }) => {
  const classes = useStyles();

  return (
    <Grid item xs={6} sm={12} md={6} lg={3}>
      <Card className={classes.cardTransaction}>
        <CardContent style={{ marginTop: 30 }}>
          <figure>
            <CardMedia component="img" image={icon} alt="Paella dish" />
          </figure>
        </CardContent>

        <CardContent>
          <div className={classes.cardContent}>
            <Typography className={classes.title}>{title}</Typography>
            <Typography variant="body1" className={classes.subtitle}>
              {description}
            </Typography>
          </div>
        </CardContent>

        <CardContent>
          <div className={classes.cardContent}>
            <Button variant="contained" className={classes.btnStart}>
              Comenzar
              <ReactLogo />
            </Button>
            <Typography className={classes.textInfo}>
              Más información
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default CardTransaction;
