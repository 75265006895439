import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "grid",
    placeItems: "center"
  },
  content: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    minHeight: 'calc(100vh - 200px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
  },
  title: {
    color: colors.blue,
    textAlign: "center",
    marginBottom: 40,
    lineHeight: 1.50,
    fontFamily: 'poppinsmedium',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  contentDocuments: {
    backgroundColor: colors.ligthGray,
    padding: 18,
    borderRadius: 10,
    marginTop: 20,
    '& h6': {
      color: colors.blue,
      fontWeight: "bold",
    }
  },
  ul: {
    listStyle: "circle",
    color: colors.blue,
    paddingLeft: 18,
    margin: 0,
    '& li': {
      color: colors.black,
    }
  },
  btnNextStep: {
    background: colors.blue,
    borderRadius: 20,
    fontWeight: "bold",
    width: 150,
    display: "flex",
    justifyContent: "space-between"
  },
    btnBackStep: {
    background: colors.white,
    color: colors.blue,
    borderRadius: 20,
    border: `1px solid ${colors.blue}`,
    fontWeight: "bold",
    width: 150,
    display: "flex",
    justifyContent: "space-between"
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
    gap: 10,
    marginBottom: 50,
  },
  btnShareholder: {
    background: '#72D8F7',
    color: "#1759A5",
    borderRadius: 20,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "space-between"
  },
  containerItemShareholder: {
    textAlign: 'center',
    '& .Mui-selected': {
      background: '#1759a5',
      color: 'white',
      pointerEvents: 'none',
      '& svg': {
        color: 'white'
      }
    }
  },
  itemShareholder: {
    marginTop: '15px',
    color: 'black',
    minWidth: '230px',
    minHeight: '35px',
    border: '1px solid #1759A5',
    borderRadius: '8px',
    marginRight: '15px',
    background: 'transparent',
    lineHeight: '35px',
    position: 'relative',
    textAlign: 'left',
    padding: '0px 20px',
    cursor: 'pointer',
    '& span': {
      position: 'absolute',
      right: '5px',
      top: '6px'
    },
    '& svg': {
      color: '#1759A5'
    }
  },
  contentFormShareholder: {
    '& .MuiBox-root': {
      padding: '24px 0'
    }
  },
  nameItem: {
    fontSize: '0.84rem',
    maxWidth: '120px',
    overflow: 'hidden',
    display: 'inline-block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    verticalAlign: 'middle',
    paddingRight: '30px'
  }
}));
