import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 200px)",
    display: "grid",
    placeItems: "center",
    fontFamily: "poppinsregular",
    padding: "120px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      minHeight: "none",
      overflowX: "auto",
      marginTop: 40,
    },
  },
  content: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: colors.blue,
    textAlign: "center",
    marginBottom: 40,
    lineHeight: 1.5,
    fontFamily: "poppinsmedium",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(4, 1fr)",
    gridGap: 10,
    gridAutoRows: "minmax(100px, auto)",
    
  },
}));
