import React, { useState } from "react";
import useStyles from "./styles";
import { Typography, Divider, Grid } from "@mui/material";
// import Login from "components/auth-user/login";
import LoginNull from "components/auth-user-null/login"
// import Register from "components/auth-user/signup";
import RegisterNull from "components/auth-user-null/signup"
import { colors } from "constants/colors";
import { useLocation } from "react-router-dom";
// import  clientConfig  from '../../constants/clientConfig'
// 
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase'
import "firebase/firestore";
import { useEffect } from "react";

const FormLogin = ({ setFormLoginState }) => {
  const [ configData, setConfigData ] = useState();
  
  const classes = useStyles({ baseColor: configData?.baseColor });

  // let {
  //   state: { src },
  // } = useLocation();

  const [isLogin, setIsLogin] = useState(true);

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc?.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.containerSub1}>
          <Typography
            variant="subtitle1"
            gutterBottom
            component="div"
            className={classes.subtitle1}
          >
            Debe estar registrado para poder realizar un trámite, por favor
            ingrese o regístrese para finalizar el trámite.
          </Typography>
        </div>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <div style={{ width: "70%" }}>
            <div className={classes.containerButtons}>
              <Typography
                variant="body1"
                gutterBottom
                component="div"
                className={classes.buttons}
                onClick={() => setIsLogin(true)}
                style={{ cursor: "pointer" }}
              >
                Ingrese
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                component="div"
                className={classes.buttons}
                onClick={() => setIsLogin(false)}
                style={{ cursor: "pointer" }}
              >
                Registrese
              </Typography>
            </div>
            <div className={classes.highlines}>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  {isLogin ? (
                    <div
                      style={{
                        backgroundColor: configData?.baseColor,
                        marginBottom: 30,
                        marginTop: 10,
                        height: 2,
                      }}
                    ></div>
                  ) : (
                    <Divider
                      style={{
                        backgroundColor: colors.dark,
                        marginBottom: 30,
                        marginTop: 10,
                      }}
                    />
                  )}
                </Grid>
                <Grid item xs={6} md={6}>
                  {!isLogin ? (
                    <div
                      style={{
                        backgroundColor: configData?.baseColor,
                        marginBottom: 30,
                        marginTop: 10,
                        height: 2,
                      }}
                    ></div>
                  ) : (
                    <Divider
                      style={{
                        backgroundColor: colors.dark,
                        marginBottom: 30,
                        marginTop: 10,
                      }}
                    />
                  )}
                </Grid>
              </Grid>
            </div>
            {isLogin ? (
              <LoginNull 
                isFormLogin={true} 
                setFormLoginState={setFormLoginState}  
              />
            ) : (
              <RegisterNull 
                isFormRegister={true} 
                setFormLoginState={setFormLoginState} 
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormLogin;