import {
  CREATE_USER,
  LOGIN_USER,
  LOGOUT_USER,
  ALL_USERS,
  GET_USER_BY_ID,
  GET_DOCUMENT_BY_USER,
} from "./constants";
import {
  collection,
  query,
  where,
  getDocs,
  Timestamp,
  updateDoc,
  doc,
} from "firebase/firestore";

export const resetUserPassword = async (firebase, email) => {
  await firebase.auth.sendPasswordResetEmail(email);
};

export const createUser = async (firebase, dispatch, data) => {
  const res = await firebase.auth.createUserWithEmailAndPassword(
    data.email,
    data.password
  );
  localStorage.setItem("auth-token", JSON.stringify(res.user.uid));
  getUserInfov2(firebase, res.user.email);
  await firebase.db
    .collection("users")
    .doc(res.user.uid)
    .set({
      name: data.displayName,
      phone: data.phone,
      email: res.user.email,
      documentNumber: data.documentNumber || '0',
      typeDocument: data.typeDocument || 'C.C',
      userId: res.user.uid,
      id: `user_${res.user.uid}`,
      rol: "user",
      uid: res.user.uid,
      createdAt: Timestamp.fromDate(new Date()),
      isOnline: false,
    });

  await firebase.db.collection("channel").add({
    name: `${data.displayName}-notaria26`,
    description: `Chat directo con ${data.displayName}`,
    eTag: "AbOx6N+6vu3zoAE",
    id: `space.${res.user.uid}`,
  });

  getUserById(res.user.uid, firebase, dispatch);

  try {
    let users = [];
    firebase.db
      .collection("users")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          users.push(doc.data());
        });
      });
    dispatch({
      type: ALL_USERS,
      users: users,
    });
  } catch (error) {
    console.log("getUsers===>", error);
  }

  dispatch({
    type: CREATE_USER,
    token: res.user.uid,
  });
};

export const loginUser = async (firebase, dispatch, data) => {
  const res = await firebase.auth.signInWithEmailAndPassword(
    data.email,
    data.password
  );
  localStorage.setItem("auth-token", JSON.stringify(res.user.uid));
  // getUserInfov2(firebase, res.user.email);

  getUserById(res.user.uid, firebase, dispatch);

  await updateDoc(doc(firebase.db, "users", res.user.uid), {
    isOnline: true,
  });

  dispatch({
    type: LOGIN_USER,
    token: res.user.uid,
  });

  try {
    let users = [];
    firebase.db
      .collection("users")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          users.push(doc.data());
        });
      });
    dispatch({
      type: ALL_USERS,
      users: users,
    });
  } catch (error) {
    console.log("getUsers===>", error);
  }
  return res;
};

export const signOutUser = async (firebase, dispatch) => {
  // localStorage.removeItem("pubnub-uuid");
  localStorage.removeItem("auth-token");
  localStorage.removeItem("current-user");
  localStorage.removeItem("info_user");
  await updateDoc(doc(firebase.db, "users", firebase.auth.currentUser.uid), {
    isOnline: false,
  });
  try {
    await firebase.auth.signOut();
  } catch (error) {
    console.log(error);
  }
  dispatch({
    type: LOGOUT_USER,
    token: null,
  });
};

export const getUserInfov2 = async (firebase, email) => {
  const q = query(
    collection(firebase.db, "users"),
    where("email", "==", email)
  );
  const querySnapshot = await getDocs(q);
  let data = {};
  querySnapshot.forEach((doc) => {
    data = doc.data();
  });

  // localStorage.setItem("pubnub-uuid", JSON.stringify(data.name));
  localStorage.setItem("current-user", JSON.stringify(data));

  return data;
};

export const getUserById = (userId, firebase, dispatch) => {
  try {
    firebase.db
      .collection("users")
      .doc(userId)
      .get()
      .then((docs) => {
        dispatch({
          type: GET_USER_BY_ID,
          user: docs.data(),
        });
        localStorage.setItem("current-user", JSON.stringify(docs.data()));
      });
  } catch (error) {
    console.error("Error-getCompanyById===>", error);
  }
};

export const getDocumentsByUser = (userId, firebase, dispatch) => {
  try {
    firebase.db
      .collection("users")
      .doc(userId)
      .collection("transactions")
      .orderBy("createdAt", "desc")
      .get()
      .then((v2docs) => {
        v2docs.forEach((docs) => {
          getDocuments(firebase, dispatch, docs.data());
        });
      });
  } catch (error) {
    console.error("Error-getCompanyById===>", error);
  }
};

export const getDocuments = (firebase, dispatch, document) => {
  const { documentName, documentId } = document;
  try {
    switch (documentName) {
      case "donation":
        firebase.db
          .collection("donation")
          .doc(documentId)
          .get()
          .then((docs) => {
            dispatch({
              type: GET_DOCUMENT_BY_USER,
              document: { id: docs.id, documentName, ...docs.data() },
            });
          });
        break;
      case "protocolization":
        firebase.db
          .collection("protocolization")
          .doc(documentId)
          .get()
          .then((docs) => {
            dispatch({
              type: GET_DOCUMENT_BY_USER,
              document: { id: docs.id, documentName, ...docs.data() },
            });
          });
        break;
      case "companyFormation":
        firebase.db
          .collection("companyFormation")
          .doc(documentId)
          .get()
          .then((docs) => {
            dispatch({
              type: GET_DOCUMENT_BY_USER,
              document: { id: docs.id, documentName, ...docs.data() },
            });
          });
        break;
      case "marriage":
        firebase.db
          .collection("marriage")
          .doc(documentId)
          .get()
          .then((docs) => {
            dispatch({
              type: GET_DOCUMENT_BY_USER,
              document: { id: docs.id, documentName, ...docs.data() },
            });
          });
        break;

      default:
        break;
    }
  } catch (error) {
    console.error("Error-getCompanyById===>", error);
  }
};
