import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  divInput: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
}));
