import { useEffect, useState, useContext } from "react";
import { db, auth, storage } from "../../firebase";
import {
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  Timestamp,
  orderBy,
  setDoc,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import User from "../../components/chat/User";
import MessageForm from "../../components/chat/MessageForm.jsx";
import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import Message from "../../components/chat/Message";
import "./chatpage.scss";
import profile_image from "../../img/profile_image.png";
import { ChatControllerContext } from "context/chat/ChatControllerContext";
import { StatusTable } from "components/data-table/status-table";
import { Typography, Box } from "@mui/material";

function SimpleChat() {
  const [users, setUsers] = useState([]);
  const [chat, setChat] = useState("");
  const [text, setText] = useState("");
  const [img, setImg] = useState("");
  const [msgs, setMsgs] = useState([]);
  const [userLogged, setUserLogged] = useState();
  const [changeEmail, setChangeEmail] = useState(false);
  const [usersTable, setUsersTable] = useState([]);
  const [search, setSearch] = useState("");
  const { selectedDocument, isChatEnabled, setIsChatEnabled } = useContext(
    ChatControllerContext
  );

  const user1 = auth?.currentUser?.uid;

  useEffect(() => {
    getDoc(doc(db, "users", auth?.currentUser?.uid)).then((docSnap) => {
      if (docSnap.exists) {
        setUserLogged(docSnap.data());
      }
    });
  }, []);

  useEffect(() => {
    const usersRef = collection(db, "users");
    // Create query object
    const q = query(usersRef, where("uid", "not-in", [user1]));
    // Execute query
    const unsub = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      if (userLogged?.rol !== "notario") {
        const notary = users.filter((user) => user?.rol === "notario");
        setUsers(notary);
        setUsersTable(notary);
      } else {
        setUsers(users);
        setUsersTable(users);
      }
    });
    return () => unsub();
  }, [userLogged]);

  useEffect(() => {
    if (users && selectedDocument) {
      const defaultUser = users?.find(
        (user) => user?.id === "user_nn8aQ3LapteYAzOyVPoUGgnSdhk2"
      );
      if (defaultUser?.name) {
        selectUser(defaultUser);
      }
    }
  }, [selectedDocument, users]);

  const iconDocument = {
    donation:
      "/static/media/donatingMoney.30789baa9220a7cc6ff146d6884b244d.svg",
    companyFormation:
      "/static/media/companyFormation.8a95b4b0ed3c6e07aa58f579b4c528c4.svg",
    marriage:
      "/static/media/marriage-contract.66cdbad03fda4dd7055e1372f4e415c1.sv",
    protocolization:
      "/static/media/audits.70914a9e509304a153d6518eab63d5b6.svg",
  };

  const nameDocument = {
    donation: "Donación de dinero",
    protocolization: "Protocolización de asambleas",
    companyFormation: "Constitución de sociedades",
    marriage: "Capitulaciones Matrimoniales",
  };

  const selectUser = async (user) => {
    setChat(user);

    const user2 = user.uid;
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;

    const msgsRef = collection(db, "messages", id, "chat");
    const q = query(msgsRef, orderBy("createdAt", "asc"));

    onSnapshot(q, (querySnapshot) => {
      let msgs = [];
      querySnapshot.forEach((doc) => {
        msgs.push(doc.data());
      });
      setMsgs(msgs);
    });

    //Petición del último mensaje entre el usuario logueado y el usuario seleccionado.
    const docSnap = await getDoc(doc(db, "lastMsg", id));
    //Si el ultimo mensaje existe y el mensaje está en el usuario seleccionado.
    if (docSnap.data() && docSnap.data().from !== user1) {
      //Actualiza el ultimo mensaje en doc, sete el unread por false.
      await updateDoc(doc(db, "lastMsg", id), {
        unread: false,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const user2 = chat.uid;
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
    let url;
    if (img) {
      const imgRef = ref(
        storage,
        `images/${new Date().getTime()} - ${img.name}`
      );
      const snap = await uploadBytes(imgRef, img);
      const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
      url = dlUrl;
    }

    await addDoc(collection(db, "messages", id, "chat"), {
      text,
      from: user1,
      to: user2,
      createdAt: Timestamp.fromDate(new Date()),
      media: url || "",
    });

    await setDoc(doc(db, "lastMsg", id), {
      text,
      from: user1,
      to: user2,
      createdAt: Timestamp.fromDate(new Date()),
      media: url || "",
      unread: true,
    });

    setText("");
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    filterer(e.target.value);
    e.preventDefault();
  };

  const filterer = (searchTerm) => {
    let searchResult = usersTable?.filter((element) => {
      if (
        element.name.toString().toLowerCase().includes(searchTerm.toLowerCase())
      ) {
        return element;
      }
    });
    setUsers(searchResult);
  };

  return (
    <div className={`app-simple1 ${"light"}`}>
      {!isChatEnabled && (
        <div className="search_container">
          <input
            type="text"
            placeholder="Buscar usuario"
            value={search}
            onChange={handleSearch}
            className="search_input"
          />
          <div className="search_btn">
            <i className="fa fa-search"></i>
          </div>
        </div>
      )}
      {isChatEnabled && (
        <div className="exit_chat_button">
          <button onClick={() => setIsChatEnabled(false)}>X</button>
        </div>
      )}
      <div
        className={` ${isChatEnabled ? "home_compressed" : "home_container1"}`}
      >
        {!isChatEnabled && (
          <div className="users_container1">
            {users
              ?.sort((a, b) => (a?.isOnline ? -1 : 1))
              .map((user) => (
                <User
                  key={user.id}
                  user={user}
                  selectUser={selectUser}
                  user1={user1}
                  chat={chat}
                  search={search}
                  handleSearch={handleSearch}
                />
              ))}
          </div>
        )}
        <div className="messages_container1">
          {selectedDocument && (
            <div className="document_description">
              <div className="document_image">
                <img
                  src={iconDocument[selectedDocument?.documentName]}
                  alt=""
                  color="white"
                  width="45"
                  height="45"
                />
              </div>
              <div className="document_description_text">
                <span className="document_title">
                  {nameDocument[selectedDocument?.documentName]}
                </span>
                <p className="document_type">
                  Trámite #
                  <span className="document_id">
                    {selectedDocument?.createdDateTime}
                  </span>
                </p>
                <Box className={"card_status"}>
                  <Typography fontSize={12} color="white">
                    <StatusTable
                      type={document?.status ? document.status : "pending"}
                    />
                  </Typography>
                </Box>
              </div>
            </div>
          )}
          {chat ? (
            <>
              <div className="messages_user1 user_selected1">
                <img
                  src={chat.avatar || profile_image}
                  alt="avatar"
                  className="avatar1"
                />
                <h3> {chat.name} </h3>
              </div>
              <div className="messages1">
                {msgs.length
                  ? msgs.map((msg, index) => (
                      <Message
                        key={index}
                        msg={msg}
                        user1={user1}
                        users={users}
                      />
                    ))
                  : null}
              </div>
              <MessageForm
                handleSubmit={handleSubmit}
                text={text}
                setText={setText}
                setImg={setImg}
                setChangeEmail={setChangeEmail}
                changeEmail={changeEmail}
                userLogged={userLogged}
                userChat={chat}
                shortMode={isChatEnabled}
              />
            </>
          ) : (
            <div>
              <h3 className="no_conv1">
                Selecciona un usuario para iniciar una conversación
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SimpleChat;
