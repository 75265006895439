import {
    NEXT_STEP,
    BACK_STEP,
    ADD_DATA_COMPANY,
    SHAREHOLDER_COMPANY,
    GENERATE_PDF,
    SHAREHOLDER_PDF,
    CREATE_COMPANY,
    PROXIES_COMPANY,
    CLEAR_DATA,
    PARTICIPATION_RATE_COMPANY,
    GET_COMPANIES,
    GET_COMPANY_BY_ID,
    GET_SHAREHOLDERS_BY_ID,
    RESET_STEP_COMPANY,
    CLEAR_COMPANY,
    STATUS_COMPANY,
} from "./constants";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";

import { httpsCallable } from "firebase/functions";
import { Timestamp } from "firebase/firestore";

export const nextStep = async (dispatch) => {
    dispatch({
        type: NEXT_STEP,
    });
};

export const backStep = async (dispatch) => {
    dispatch({
        type: BACK_STEP,
    });
};

export const addDataCompany = async (dispatch, data) => {
    dispatch({
        type: ADD_DATA_COMPANY,
        data: data,
    });
};

export const addShareholderCompany = async (dispatch, data) => {
    dispatch({
        type: SHAREHOLDER_COMPANY,
        data: data,
    });
};

export const addProxiesCompany = async (dispatch, data) => {
    dispatch({
        type: PROXIES_COMPANY,
        data: data,
    });
};

export const addParticipationRateCompany = async (dispatch, data) => {
    dispatch({
        type: PARTICIPATION_RATE_COMPANY,
        data: data,
    });
};

export const getCompanies = async (firebase, dispatch) => {
    try {
        firebase.db
            .collection("companyFormation")
            .get()
            .then((docs) => {
                docs.forEach((doc) => {
                    dispatch({
                        type: GET_COMPANIES,
                        company: { id: doc.id, ...doc.data() },
                    });
                });
            });
    } catch (error) {
        console.error("Error-getCompanies===>", error);
    }
};

export const getCompanyById = async (companyId, firebase, dispatch) => {
    try {
        firebase.db
            .collection("companyFormation")
            .doc(companyId)
            .get()
            .then((docs) => {
                dispatch({
                    type: GET_COMPANY_BY_ID,
                    company: docs.data(),
                });
            });
    } catch (error) {
        console.error("Error-getCompanyById===>", error);
    }
};

export const getShareholdersById = async (companyId, firebase, dispatch) => {
    try {
        firebase.db
            .collection("companyFormation")
            .doc(companyId)
            .collection("shareholders")
            .get()
            .then((v2docs) => {
                v2docs.forEach((shareholder) => {
                    dispatch({
                        type: GET_SHAREHOLDERS_BY_ID,
                        shareholder: {
                            ...shareholder.data(),
                            idsh: shareholder.id,
                        },
                    });
                });
            });
    } catch (error) {
        console.error("Error-getShareholdersById===>", error);
    }
};

export const editCompany = async (
    firebase,
    dataCompany,
    clearDataShareholders,
    dataShareholders,
    documentId,
    namesClear,
    dispatch
) => {
    try {
        await firebase.db
            .collection("companyFormation")
            .doc(documentId)
            .update(dataCompany);

        for (let i = 0; i < dataShareholders.shareholder.length; i++) {
            const dataSH = dataShareholders.shareholder[i];
            const dataClearSH = clearDataShareholders.shareholder[i];
            const idShareholder = dataShareholders.shareholder[i].idsh;

            await firebase.db
                .collection("companyFormation")
                .doc(documentId)
                .collection("shareholders")
                .doc(idShareholder)
                .update(dataClearSH);

            for (let j = 0; j < namesClear.length; j++) {
                const nameKey = namesClear[j];

                if (
                    Array.isArray(dataSH[nameKey]) &&
                    dataClearSH[nameKey] === ""
                ) {
                    await updateFileShareholder(
                        firebase,
                        dataSH[nameKey],
                        idShareholder,
                        documentId,
                        nameKey
                    );
                }
            }
        }

        await getDataPdf(firebase, dispatch, documentId);

        return true;
    } catch (error) {
        console.error("Error-companyFormation ===> ", error);
        return false;
    }
};

export const createCompany = async (
    firebase,
    dispatch,
    data,
    dataShareholders,
    currentUser
) => {
    try {
        const response = await firebase.db
            .collection("companyFormation")
            .add(data);

        await firebase.db
            .collection("users")
            .doc(currentUser.userId)
            .collection("transactions")
            .add({
                documentName: "companyFormation",
                documentId: response.id,
                createdAt: Timestamp.fromDate(new Date()),
            });

        for (const shareholder of dataShareholders.shareholder) {
            let objectShareholder = {};
            if (
                shareholder.file &&
                shareholder.file.length > 0 &&
                shareholder.homeFile &&
                shareholder.homeFile.length > 0
            ) {
                objectShareholder = {
                    ...shareholder,
                };
                objectShareholder.file = "";
                objectShareholder.homeFile = "";
            } else if (shareholder.file && shareholder.file.length > 0) {
                objectShareholder = {
                    ...shareholder,
                };
                objectShareholder.file = "";
            } else if (
                shareholder.homeFile &&
                shareholder.homeFile.length > 0
            ) {
                objectShareholder = {
                    ...shareholder,
                };
                objectShareholder.homeFile = "";
            } else {
                objectShareholder = {
                    ...shareholder,
                };
            }

            const responseShareholder = await firebase.db
                .collection("companyFormation")
                .doc(response.id)
                .collection("shareholders")
                .add(objectShareholder);
            if (shareholder.file && shareholder.file.length > 0)
                await updateFile(
                    firebase,
                    dispatch,
                    shareholder.file,
                    responseShareholder.id,
                    response.id
                );
            if (shareholder.homeFile && shareholder.homeFile.length > 0)
                await updateFileHomeFile(
                    firebase,
                    dispatch,
                    shareholder.homeFile,
                    responseShareholder.id,
                    response.id
                );
        }

        dispatch({
            type: CREATE_COMPANY,
            companyId: response.id,
        });

        response?.id && (await getDataPdf(firebase, dispatch, response.id));

        return { companyId: response.id };
    } catch (error) {
        console.error("Error-Shareholders===>", error);
    }
};

export const updateFileShareholder = async (
    firebase,
    file,
    idShareholder,
    idCompany,
    nameKey
) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Documentos/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                console.error("Error Storage Firebase::", error);
                reject();
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("companyFormation")
                        .doc(idCompany)
                        .collection("shareholders")
                        .doc(idShareholder)
                        .update({
                            [nameKey]: downloadURL,
                        })
                        .then(() => {
                            console.log("Success Update");
                            resolve();
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const updateFile = async (
    firebase,
    dispatch,
    file,
    idShareholder,
    idCompany
) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Documentos/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                console.error("Error Storage Firebase::", error);
                reject();
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("companyFormation")
                        .doc(idCompany)
                        .collection("shareholders")
                        .doc(idShareholder)
                        .update({
                            file: downloadURL,
                        })
                        .then((docs) => {
                            console.log("Success Update");
                            resolve();
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const updateFileHomeFile = async (
    firebase,
    dispatch,
    file,
    idShareholder,
    idCompany
) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Domicilio/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                console.error("Error Storage Firebase::", error);
                reject();
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("companyFormation")
                        .doc(idCompany)
                        .collection("shareholders")
                        .doc(idShareholder)
                        .update({
                            homeFile: downloadURL,
                        })
                        .then((docs) => {
                            console.log("Success Update");
                            resolve();
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const getDataPdf = async (firebase, dispatch, companyId) => {
    await firebase.db
        .collection("companyFormation")
        .doc(companyId)
        .get()
        .then((docs) => {
            dispatch({
                type: GENERATE_PDF,
                pdf: docs.data(),
            });
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const getDataShareholders = async (firebase, dispatch, companyId) => {
    let arrayShareholders = [];
    await firebase.db
        .collection("companyFormation")
        .doc(companyId)
        .collection("shareholders")
        .get()
        .then((docs) => {
            docs.forEach((doc) => {
                arrayShareholders.push({ id: doc.id, ...doc.data() });
            });
            dispatch({
                type: SHAREHOLDER_PDF,
                shareholdersPdf: arrayShareholders,
            });
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const sendEmailFirebase = async (firebase, dispatch, email) => {
    const sendingEmails = httpsCallable("sendMail");
    sendingEmails({ email: email }).then((res) => {
        console.log(res);
    });
};

export const updateDocx = async (firebase, companyId, urlPdf) => {
    firebase.db
        .collection("companyFormation")
        .doc(companyId)
        .update({
            urlPdf: urlPdf,
        })
        .then((docs) => {
            console.log("Success Update docx");
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const clearAllData = async (dispatch) => {
    dispatch({
        type: CLEAR_DATA,
    });
};

export const updateCompanyById = async (
    companyId,
    firebase,
    dispatch,
    status
) => {
    firebase.db
        .collection("companyFormation")
        .doc(companyId)
        .update({
            status: status,
        })
        .then(() => {
            console.log(companyId)
            dispatch({
                type: CLEAR_DATA,
            })
            console.log("Success Update docxfsadfsd");
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const resetStepsCompany = async (dispatch, resetStep) => {
    dispatch({
        type: RESET_STEP_COMPANY,
        resetStep,
    });
};

export const clearCompany = async (dispatch) => {
    dispatch({
        type: CLEAR_COMPANY,
        company: {
            activeStep: 0,
            companyInput: {},
            companyId: null,
            shareholder: { shareholder: [{}] },
            proxies: {
                proxies: [{ nestedArray: [{}], v2nestedArray: [{}] }],
                nestedArray: [{}],
                v2nestedArray: [{}],
            },
            participationRate: { participationRate: [{}] },
            pdf: null,
            shareholdersPdf: null,
            dataDocx: null,
            dataDocument: null,
            companies: [],
            companyById: {},
            shareholdersById: [],
            resetStepCompany: {
                step_one: false,
                step_two: false,
                step_three: false,
                step_four: false,
            },
        },
    });
};
