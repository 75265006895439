import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

const useStyles = makeStyles({
  contentRound: {
    alignSelf: "center",
    justifySelf: "center",
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  round: {
    borderRadius: "50%",
    backgroundColor: colors.blue,
    width: 280,
    height: 280,
    '@media (min-width: 1099px)': {
      width: 200,
      height: 200,
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  title: {
    fontSize: 20,
    width: 150,
    '@media (min-width: 1199px)': {
      fontSize: 14,
    },
    fontWeight: "bold",
    color: colors.white,
    textAlign: "center",
  },
  subRound: {
    width: 50,
    height: 50,
    display: "flex",
    alignItems: "center",
    background: colors.white,
    justifyContent: "center",
    position: "absolute",
    left: "-20px",
    border: `1px solid ${colors.lightskyblue}`,
    borderRadius: "50%",
    color: colors.blue,
    fontWeight: "bold"
  }
});


export default function CircleInfo({ icon, title, id }) {

  const classes = useStyles();

  return (
    <div className={classes.contentRound}>
      <div className={classes.subRound}>{id}.</div>
      <div className={classes.round}>
        <img src={icon} alt={title} style={{ width: 44, height: 60, marginBottom: 15 }} />
        <Typography variant='subtitle2' gutterBottom className={classes.title}>{title}</Typography>
      </div>
    </div>
  );
}
