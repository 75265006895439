import React, { createContext, useState } from "react";

export const PartnersControllerContext = createContext();

export const PartnersControllerContextProvider = ({ children }) => {
  const [partners, setPartners] = useState();

  return (
    <PartnersControllerContext.Provider
      value={{
        partners,
        setPartners,
      }}
      displayName="Partner Controller context"
    >
      {children}
    </PartnersControllerContext.Provider>
  );
};
