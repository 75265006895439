import { Timestamp } from "firebase/firestore";

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";

import {
  ADD_DATA_PROTOCOLIZATION,
  GET_PROTOCOLIZATIONS,
  GET_PROTOCOLIZATION_BY_ID,
  CLEAR_PROTOCOLIZATION,
  RESET_STEP_PROTOCOLIZATION,
  GENERATE_PDF_PROTOCOLIZATION,
} from "./constants";

export const addDataProtocolization = async (dispatch, data) => {
  dispatch({
    type: ADD_DATA_PROTOCOLIZATION,
    data: data,
  });
};

export const resetStepsProtocolization = async (dispatch, resetStep) => {
  dispatch({
    type: RESET_STEP_PROTOCOLIZATION,
    resetStep,
  });
};

export const createProtocolization = async (
  firebase,
  data,
  currentUser,
  filterData,
  namesDataClear,
  dispatch
) => {
  try {
    const response = await firebase.db
      .collection("protocolization")
      .add(filterData);

    await firebase.db
      .collection("users")
      .doc(currentUser.userId)
      .collection("transactions")
      .add({
        documentName: "protocolization",
        documentId: response.id,
        createdAt: Timestamp.fromDate(new Date()),
      });

    for (const name of namesDataClear) {
      if (data[name]?.length > 0) {
        await updateFile(firebase, data[name], response.id, name);
      }
    }

    response.id && (await getDataPdf(firebase, dispatch, response.id));

    return { protocolizationId: response.id };
  } catch (error) {
    console.error("Error-create-protocolization===>", error);
  }
};

export const editProtocolization = async (
  firebase,
  copyData,
  documentId,
  dispatch,
  data,
  namesDataClear
) => {
  try {
    await firebase.db
      .collection("protocolization")
      .doc(documentId)
      .update(copyData);

    for (const name of namesDataClear) {
      if (copyData[name] === "" && Array.isArray(data[name])) {
        await updateFile(firebase, data[name], documentId, name);
      }
    }

    await getDataPdf(firebase, dispatch, documentId);

    return true;
  } catch (error) {
    console.error("Error-Protocolization===>", error);
    return false;
  }
};

export const updateFile = async (
  firebase,
  files,
  idProtocolization,
  nameKey
) => {
  const downloadUrls = [];
  for (let i = 0; i < files.length; i++) {
    const storage = getStorage();

    const metadata = {
      contentType: "image/*",
    };
    console.log(files)
    let storageRef
    let uploadTask
    if( files[i] instanceof Array ){
      console.log('es array')
      storageRef = ref(storage, "Documentos/" + files[i][0]?.name);
      uploadTask = uploadBytesResumable(storageRef, files[i][0], metadata);
    } else {
      console.log('NO es array')
      storageRef = ref(storage, "Documentos/" + files[i]?.name);
      uploadTask = uploadBytesResumable(storageRef, files[i], metadata);
    }

    await new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          console.error("Error Storage Firebase::", error);
          reject();
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            downloadUrls.push(downloadURL);
            firebase.db
              .collection("protocolization")
              .doc(idProtocolization)
              .update({
                [nameKey]: downloadUrls,
              })
              .then((docs) => {
                if( files[i] instanceof Array ) {
                  console.log("Success Update " + files[i][0]?.name);
                } else {
                  console.log("Success Update " + files[i]?.name);
                }
                resolve();
              })
              .catch(function (error) {
                console.error("Error getting documents: ", error);
              });
            console.log("File available at", downloadURL);
          });
        }
      );
    });
  }
};

export const getProtocolizations = async (firebase, dispatch) => {
  try {
    firebase.db
      .collection("protocolization")
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          dispatch({
            type: GET_PROTOCOLIZATIONS,
            protocolization: { id: doc.id, ...doc.data() },
          });
        });
      });
  } catch (error) {
    console.error("Error-getProtocolizations===>", error);
  }
};

export const updateProtocolizationById = async (
  protocolizationId,
  firebase,
  status
) => {
  firebase.db
    .collection("protocolization")
    .doc(protocolizationId)
    .update({
      status: status,
    })
    .then(() => {
      console.log("Success Update docx");
    })
    .catch(function (error) {
      console.error("Error getting documents: ", error);
    });
};

export const getProtocolizationById = async (
  protocolizationId,
  firebase,
  dispatch
) => {
  try {
    firebase.db
      .collection("protocolization")
      .doc(protocolizationId)
      .get()
      .then((docs) => {
        dispatch({
          type: GET_PROTOCOLIZATION_BY_ID,
          protocolization: docs.data(),
        });
      });
  } catch (error) {
    console.error("Error-getCompanyById===>", error);
  }
};

export const clearProtocolization = async (dispatch) => {
  dispatch({
    type: CLEAR_PROTOCOLIZATION,
    protocolization: {
      protocolInput: {},
      listProtocolization: [],
      protocolizationById: {},
      resetStepProtocolization: {
        step_one: false,
        step_two: false,
        step_three: false,
        step_four: false,
      },
    },
  });
};

export const getDataPdf = async (firebase, dispatch, protocolizationId) => {
  await firebase.db
    .collection("protocolization")
    .doc(protocolizationId)
    .get()
    .then((docs) => {
      dispatch({
        type: GENERATE_PDF_PROTOCOLIZATION,
        pdf: true,
        protocolizationById: docs.data(),
      });
    })
    .catch(function (error) {
      console.error("Error getting documents: ", error);
    });
};

export const updatePdfProtocolization = async (
  firebase,
  protocolizationId,
  urlPdf
) => {
  firebase.db
    .collection("protocolization")
    .doc(protocolizationId)
    .update({
      urlPdf: urlPdf,
    })
    .then((docs) => {
      console.log("Success Update docx");
    })
    .catch(function (error) {
      console.error("Error getting documents: ", error);
    });
};
