import { useCallback } from "react";
import useStyles from "./styles";
import { Typography, Box, Grid, Button, Container } from "@mui/material";
import YoutubeEmbed from "components/youtube-embed/youtube-embed";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase'
import "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";

const MarriageContract = () => {
  const navigate = useNavigate();

  const [ configData, setConfigData ] = useState();

  const classes = useStyles({ baseColor: configData?.baseColor });

  const handleOnClick = useCallback(
    () => navigate("/app/form-marriage-contract", { replace: true }),
    [navigate]
  );

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <div className={classes.container}>
      <Container>
        <Typography
          variant="h2"
          gutterBottom
          component="div"
          className={classes.title}
        >
          Capitulaciones Matrimoniales
        </Typography>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 8 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Las capitulaciones matrimoniales son el pacto que celebran dos
                esposos para constituir la sociedad conyugal o la separación de
                bienes, con el fin de fijar las reglas económicas que regirán el
                patrimonio de cada uno. <br />
                Estas capitulaciones pueden otorgarse antes de la celebración
                del matrimonio o ya estando casados, y puede comprender la
                administración de bienes ya adquiridos o de los bienes que
                obtengan en el futuro. <br />
                En la Notaría 26 le ayudamos a agilizar trámites notariales como
                constitución de sociedades, donación de dinero, protocolización
                de asambleas y capitulaciones matrimoniales.
              </Typography>

              <div className={classes.contentDocuments}>
                <Typography variant="subtitle1" gutterBottom>
                  Documentos necesarios
                </Typography>
                <ul className={classes.ul}>
                  <li>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      style={{ fontWeight: 500 }}
                    >
                      Documentos de identificación de ambas partes.
                    </Typography>
                  </li>
                </ul>
              </div>

              <div className={classes.containerButton}>
                <Button
                  variant="contained"
                  className={classes.btnNextStep}
                  onClick={handleOnClick}
                >
                  Comenzar
                  <RigthIcon />
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.video}>
                <YoutubeEmbed embedId="rokGy0huYEA" />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default MarriageContract;
