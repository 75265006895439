import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
  },
  card: {
    backgroundColor: colors.blue,
    borderRadius: 10,
    padding: 30,
    width: 300,
    minHeight: 300,
  },
  flexCard: {
    display: "flex",
    alignItems: "center",
  },
  contentText: {
    display: "block",
    marginLeft: 20,
  },
  greet: {
    color: colors.white,
    fontSize: 16,
  },
  textName: {
    color: colors.white,
    fontWeight: "bold",
    fontSize: 16,
  },
  textCurp: {
    color: colors.white,
    fontSize: 12,
  },
  divider: {
    marginTop: 30,
  },
  contentProceedings: {
    borderRadius: 5,
    padding: 10,
    backgroundColor: "#2F6AAE",
  },
  proceedings: {
    display: "grid",
    alignItems: "center",
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  cardStatus: {
    borderRadius: 30,
    // padding: "0px 10px",
    marginLeft: 7,
  },
}));
