import {
    NEXT_STEP,
    BACK_STEP,
    ADD_DATA_PROTOCOLIZATION,
    GET_PROTOCOLIZATIONS,
    GET_PROTOCOLIZATION_BY_ID,
    RESET_STEP_PROTOCOLIZATION,
    GENERATE_PDF_PROTOCOLIZATION,
} from "./constants";

const protocolizationReducer = (state, action) => {
    switch (action.type) {
        case NEXT_STEP:
            return {
                ...state,
                activeStep: state.activeStep + 1,
            };
        case BACK_STEP:
            return {
                ...state,
                activeStep: state.activeStep - 1,
            };
        case ADD_DATA_PROTOCOLIZATION:
            return {
                ...state,
                protocolInput: { ...state.protocolInput, ...action.data },
            };
        case GET_PROTOCOLIZATIONS:
            return {
                ...state,
                listProtocolization: [
                    ...state.listProtocolization,
                    action.protocolization,
                ],
            };
        case GET_PROTOCOLIZATION_BY_ID:
            return {
                ...state,
                protocolizationById: action.protocolization,
            };
        case RESET_STEP_PROTOCOLIZATION:
            return {
                ...state,
                resetStepProtocolization: Object.assign(
                    {},
                    state.resetStepProtocolization,
                    action.resetStep
                ),
            };
        case GENERATE_PDF_PROTOCOLIZATION:
            return {
                ...state,
                pdf: action.pdf,
                protocolizationById: action.protocolizationById,
            };
        default:
            return state;
    }
};

export default protocolizationReducer;
