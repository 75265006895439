import Firebase, { FirebaseContext } from "server";
import { ThemeProvider } from "@mui/material/styles";
// import { I18nextProvider } from 'react-i18next';
import { StateProvider } from "context/store";
import { indexReducer } from "context/reducers";
import initialState from "context/initialState";
// import { configTranslations } from 'translations';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import i18next from 'i18next';
import { theme } from "theme";
import Routes from "./routes";
import { PubNubProvider } from "pubnub-react";
import PubNub from "pubnub";
import { ChatControllerContextProvider } from "context/chat/ChatControllerContext";
import { PartnersControllerContextProvider } from "context/company/PartnersControllerContext";
import { VisitorsControllerContextProvider } from "context/visitor/VisitorsControllerContext";
import { ModuleContextProvider } from "context/modulesContext/ModuleContext";

const tokenStorage = JSON.parse(localStorage?.getItem("pubnub-uuid"));

const pubnub = new PubNub({
  publishKey: process.env.REACT_APP_PUB_KEY,
  subscribeKey: process.env.REACT_APP_SUB_KEY,
  uuid: tokenStorage,
});

function App() {
  return (
    <ModuleContextProvider>
      <ThemeProvider theme={theme}>
        <VisitorsControllerContextProvider>
          <ChatControllerContextProvider>
            <PartnersControllerContextProvider>
              <FirebaseContext.Provider value={new Firebase()}>
                <StateProvider reducer={indexReducer} initialState={initialState}>
                  <PubNubProvider client={pubnub}>
                    <Routes />
                  </PubNubProvider>
                </StateProvider>
              </FirebaseContext.Provider>
            </PartnersControllerContextProvider>
          </ChatControllerContextProvider>
        </VisitorsControllerContextProvider>
      </ThemeProvider>
    </ModuleContextProvider>
  );
}

export default App;
