import { string, object } from "yup";

const schemaLogin = object({
  email: string().required("Este campo es obligatorio").email("Email invalido"),
  password: string()
    .required("Contraseña requerida")
    .min(6, "La contraseña debe contener al menos 6 caracteres"),
}).required();

export default schemaLogin;
