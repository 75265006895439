import {
    GENERATE_PDF_MARRIAGE,
    GET_MARRIAGE_CONTRACTS,
    GET_MARRIAGE_CONTRACT_BY_ID,
} from "./constants";
import { Timestamp } from "@firebase/firestore";

export const editMarriage = async (firebase, data, documentId, dispatch) => {
    try {
        await firebase.db.collection("marriage").doc(documentId).update(data);

        await getDataPdfForMarrriage(firebase, dispatch, documentId);

        return { editMarriageContract: true };
    } catch (error) {
        console.error("Error-Donations===>", error);
        return false;
    }
};

export const createMarriage = async (firebase, data, currentUser, dispatch) => {
    try {
        const response = await firebase.db.collection("marriage").add(data);
        await firebase.db
            .collection("users")
            .doc(currentUser.userId)
            .collection("transactions")
            .add({
                documentName: "marriage",
                documentId: response.id,
                createdAt: Timestamp.fromDate(new Date()),
            });

        response?.id &&
            (await getDataPdfForMarrriage(firebase, dispatch, response.id));

        return { marriageContract: response.id };
    } catch (error) {
        console.error("Error-createMarriage===>", error);
    }
};

export const updatePdfMarriage = async (firebase, marriageId, urlPdf) => {
    firebase.db
        .collection("marriage")
        .doc(marriageId)
        .update({
            urlPdf: urlPdf,
        })
        .then((docs) => {
            console.log("Success Update docx");
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const getDataPdfForMarrriage = async (
    firebase,
    dispatch,
    marriageId
) => {
    await firebase.db
        .collection("marriage")
        .doc(marriageId)
        .get()
        .then((docs) => {
            dispatch({
                type: GENERATE_PDF_MARRIAGE,
                pdf: true,
                marriageContractById: docs.data(),
            });
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const getMarriageContracts = async (firebase, dispatch) => {
    try {
        firebase.db
            .collection("marriage")
            .get()
            .then((docs) => {
                docs.forEach((doc) => {
                    dispatch({
                        type: GET_MARRIAGE_CONTRACTS,
                        marriageContract: { id: doc.id, ...doc.data() },
                    });
                });
            });
    } catch (error) {
        console.error("Error-getMarriageContracts===>", error);
    }
};

export const getMarriageContractById = async (
    marriageId,
    firebase,
    dispatch
) => {
    try {
        firebase.db
            .collection("marriage")
            .doc(marriageId)
            .get()
            .then((docs) => {
                dispatch({
                    type: GET_MARRIAGE_CONTRACT_BY_ID,
                    marriage: docs.data(),
                });
            });
    } catch (error) {
        console.error("Error-getCompanyById===>", error);
    }
};

export const updateMarriageContractById = async (
    marriageId,
    firebase,
    status
) => {
    firebase.db
        .collection("marriage")
        .doc(marriageId)
        .update({
            status: status,
        })
        .then(() => {
            console.log("Success Update docx");
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};
