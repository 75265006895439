import { useContext, useEffect, useState, useRef } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStateValue } from "context/store";
import { FirebaseContext } from "server";
import { colors } from "constants/colors";
import { getDonationById, updateDonationById } from "context/donation/actions";
import moment from "moment";
import { SnackAlert } from "widgets/snackAlert";
import PopupStateUi from "components/popup-state-ui/popup-state-ui";
import { DonatingMoneyIconBlue } from "widgets/icons";
import ViewDocumentComponent from "components/donationComponent/ViewDocumentComponent";
import "../../DonationMoney/form-donation/styles.css"
import {
  doc,
  getDoc,
} from 'firebase/firestore';
import {db} from '../../../firebase';

const DonationMoneyDetail = () => {
  let { donationId } = useParams();
  const navigate = useNavigate();

  // console.log("donationId====>", donationId);
  const [localDonationData, setLocalDonationData] = useState([]);
  const [donationData, setDonationData] = useState([]);
  const [addAppearing, setAddAppearing] = useState([0]);
  const [ editingIndex, setEditingIndex ] = useState(0);

  // firebase
  const firebase = useContext(FirebaseContext);

  const typeStatus = {
    pending: "pendiente",
    cancelled: "cancelado",
    finished: "finalizado",
  };

  const [
    {
      user: { userById },
      donation: { donationById },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (currentUser?.rol !== "notario") {
      navigate("/app/form-login", {
        replace: true,
        state: {
          src: window.location.href,
        },
      });
    }
  }, [userById]);

  const { current: currentUser } = useRef(
    JSON.parse(localStorage.getItem("current-user"))
  );

  useEffect(() => {
    getDonationById(donationId, firebase, dispatch);
  }, [donationId, firebase, dispatch]);

  // console.log("donationById=====>", donationById);

  // useState
  const [status, setStatus] = useState(donationById?.status);
  const [snackBar, setSnackBar] = useState({
    message: "",
    open: false,
    severity: "",
  });

  const { message, open, severity } = snackBar;

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "# fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    border: `1px solid ${colors.blue}`,
    color: colors.black,
    minHeight: '20px',
  }));

  let dateBirth = new Date(donationById?.dateBirth);
  var dateMomentObject = moment(dateBirth).locale("es").format("L");

  const handleChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await updateDonationById(donationId, firebase, newStatus);
    setSnackBar({
      message: `Estado a cambiado a ${typeStatus[newStatus]}`,
      open: true,
      severity: "success",
    });
  };

  const handleClose = () => {
    setSnackBar({
      message: "",
      open: false,
      severity: "",
    });
  };

  useEffect(() => {
    if (donationId) {
      getDonationDocument(donationId);
    }
  }, [donationId]);

  async function getDonationDocument(donationId) {
    const docRef = doc( db, 'donation', donationId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      for( let key in data ) {
        if(!isNaN(key)) {
          setLocalDonationData((prevState) => [...prevState, data[key]]);
          // setAddAppearing([ ...addAppearing, Number(key) ]);
        }
      }
    } else {
      console.log('No such document!');
    }
  };

  useEffect(() => {
    if(localDonationData.length > 0){
      setDonationData(localDonationData);
    }
    donationData.map((item, index) => {
      if(item?.appearing){
        setAddAppearing((prevState) => [...prevState, index]);
      }
      return item;
    });
  }, [localDonationData])

  return (
    <>
      <Container>
        <Box sx={{ flexGrow: 1 }} style={{ marginTop: 50, maxWidth: 1080, margin: "40px auto 20px auto", }}>
          <div
            style={{ display: "flex", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            {/* <IconButton size="small"> */}
            <ArrowBackIcon />
            {/* </IconButton> */}
            <Typography
              variant="h6"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Regresar
            </Typography>
          </div>
          <div className="divider"></div>

          <PopupStateUi
            icon={DonatingMoneyIconBlue}
            title="Donación de Dinero"
            status={status || donationById?.status}
            handleChange={handleChange}
            donationById={donationById}
          />

          <Typography
            variant="h5"
            gutterBottom
            component="div"
            className="subtitle2"
          >
            Otros Documentos
          </Typography>
        </Box>
        <div className="divider"
          style={{
            maxWidth: 1080,
            margin: "0px auto 40px auto",
          }}
        >
          <Typography variant="subtitle1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </Typography>
        </div>
        <form 
          className="donation__container"
          style={{ marginBottom: 100 }}
          onSubmit={(e) => e.preventDefault()}
        >
          <ViewDocumentComponent
            donationById={donationById}
            donationData={donationData}
            addAppearing={addAppearing}
            editingIndex={editingIndex}
            setEditingIndex={setEditingIndex}
          />
        </form>
      </Container>
    </>
  );
};

export default DonationMoneyDetail;
