import {
  Stack,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Container,
} from "@mui/material";
import useStyles from "./styles";
import StepOne from "./step-one/step-one";
import StepTwo from "./step-two/step-two";
import SwitchComponent from "components/switch-component/switch-component";
import { ColorlibConnector, ColorlibStepIcon } from "./styled-steps";
import { useStateValue } from "context/store";
import StepThree from "./step-three.js/step-three";

const FormProtocolization = () => {
  // styles
  const classes = useStyles();

  // variables
  const [
    {
      company: { activeStep },
    },
  ] = useStateValue();

  return (
    <div className={classes.content}>
      <div style={{ marginTop: 60 }}>
        <Typography
          variant="h2"
          gutterBottom
          component="div"
          className={classes.title}
        >
          Protocolización de Asambleas
        </Typography>
      </div>

      <Container>
        <div style={{ marginBottom: 30 }}>
          <Stack sx={{ width: "100%" }} spacing={4}>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              connector={<ColorlibConnector />}
            >
              {Array.from(Array(3).keys()).map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon} />
                </Step>
              ))}
            </Stepper>
          </Stack>
        </div>

        <div>
          <SwitchComponent active={activeStep}>
            <StepOne step={0} />
            <StepTwo step={1} />
            <StepThree step={2} />
          </SwitchComponent>
        </div>
      </Container>
    </div>
  );
};

export default FormProtocolization;
