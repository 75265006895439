import React, { useState } from "react";
import "../donationComponent/DonationComponent.css";
import { State, City } from "country-state-city";
import countryList from "react-select-country-list";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ViewAppearingElementComponent from "./ViewAppearingElementComponent";
import { ref, getDownloadURL, uploadBytesResumable } from "@firebase/storage";
import firebase from "../../firebase";
import ViewerDonationPDF from "components/success-form/donation-viewer-pdf";
import ErrorComponent from "./ErrorComponent";
import { add } from "date-fns";

function ViewDocumentComponent({ 
    donationById, 
    donationData, 
    addAppearing, 
    editingIndex, 
    setEditingIndex 
}) {

    const selectedAppearing = (index) => {
        setEditingIndex(index);
    };

    // console.log(donationData)

  return (
    <div className="inputs__container" >
      <ToastContainer />
      <div className="inputs__elements__style-amount">
        <p>Cantidad que se está donando *</p>
        <input
          type="number"
          name="amount"
          placeholder="$0"
          disabled={true}
          value={donationById?.amount}
        />
      </div>
      <h3>
        <b>Datos generales</b> (Por compareciente)
      </h3>
      <div className="appearing__container">
        {addAppearing.map((_, index) => (
          <div
            key={index}
            className={`appearing__container-element ${
              editingIndex === index ? "selected" : ""
            }`}
          >
            <p title="click para editar" onClick={() => selectedAppearing(index)}>
              <b>Compareciente #{index + 1}</b>:{" "}
              {donationData[index]?.appearing}
            </p>
            
          </div>
        ))}
      </div>
        {
            addAppearing.map((_, index) => 
                index === editingIndex && (
                    <ViewAppearingElementComponent
                        key={index}
                        index={index}
                        addAppearing={addAppearing}
                        donationData={donationData}
                        editingIndex={editingIndex}
                    />    
                )
            )
        }
    </div>
  );
}

export default ViewDocumentComponent;
