import { Box, Grid} from '@mui/material';
import { Fragment } from 'react';
import useStyles from './styles';
//import departmentSection from 'components/departments-section/DepartmentSection';

export default function Departments() {

  //Styles
  const classes = useStyles();

  return (
    <Fragment>
      <h1 className={classes.title}>Departamentos</h1>
      <Box sx={{flexGrow: 1}}>
        <Grid className={classes.grid} container spacing={2}>
          <Grid style={{position: 'relative' }} item xs={12} sm={12} md={12} lg={5} xl={5}>
            <div className={classes.departmentContent}>
              <h3 style={{margin: '0 0 10px 0'}} >Corporativo</h3>
              <div className={classes.textBoxes}> &#9900; &nbsp;Constitución de sociedades</div>
              <div className={classes.textBoxes}>&#9900; &nbsp;Protocolización de asambleas</div>
            </div>
            <div style={{ marginLeft: 15 }}>
              <img className={classes.image} src={require('assets/png/departmentImgOne.png')} alt="Notaria26 grupo de personas"/>
            </div>
          </Grid>
          <Grid style={{position: 'relative'}} item xs={12} sm={12} md={12} lg={5} xl={5}>
            <div className={ classes.departmentContent }>
              <h3 style={{margin: '0 0 10px 0'}} >Trámites especiales </h3>
              <div className={classes.textBoxes}>&#9900; &nbsp;<span>Donación de dinero</span></div>
              <div className={classes.textBoxes}>&#9900; &nbsp;<span>Ratificación de contratos</span></div>
              <div className={classes.textBoxes}>&#9900; &nbsp;<span>Copia certificada</span></div>
            </div>
            <div style={{ marginLeft: 15 }}>
              <img className={classes.image} src={require('assets/png/departmentImgTwo.png')} alt="Notaria26"/>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  )
}