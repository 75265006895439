import React, { useState } from "react";
import "../donationComponent/DonationComponent.css";
import { State, City } from "country-state-city";
import countryList from "react-select-country-list";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppearingElement from "./AppearingElement";
import { ref, getDownloadURL, uploadBytesResumable } from "@firebase/storage";
import firebase from "../../firebase";
import ViewerDonationPDF from "components/success-form/donation-viewer-pdf";
import ErrorComponent from "./ErrorComponent";

function DonationFormComponent({
  dataForm,
  setDataForm,
  dataFormAppearing,
  setDataFormAppearing,
  addAppearing,
  setAddAppearing,
  amount,
  setAmount,
  pdfData,
  pdfAmount,
  errors,
  editingIndex,
  setEditingIndex,
}) {

  const countryOptions = countryList().getData();
  const stateOptions = State.getStatesOfCountry(
    dataFormAppearing[editingIndex]?.countryId
  ).map((state) => ({
    value: state.isoCode,
    label: state.name,
  }));
  const mexicanStates = State.getStatesOfCountry("MX").map((state) => ({
    value: state.isoCode,
    label: state.name,
  }));

  const citiesOptions = City.getCitiesOfState(
    "MX",
    dataFormAppearing[editingIndex]?.liveStateId
  ).map((city) => ({
    value: city.name,
    label: city.name,
  }));

  const generateRandomName = () => {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleMaritalStatusChange = (e) => {
    if(editingIndex !== null){
      const updatedAppearing = dataFormAppearing.map((item, index) => {
        if (index === editingIndex) {
          return {
            ...item,
            maritalStatus: e.target.value,
            maritalRadioValue: {
              soltero: false,
              casado: false,
              unionLibre: false,
              divorciado: false,
              viudo: false,
              [e.target.value]: true,
            },
          };
        }
        return item;
      });
      setDataFormAppearing(updatedAppearing);
    }
  };

  const handleDisabilityChange = (e) => {
    if(editingIndex !== null){
      const updatedAppearing = dataFormAppearing.map((item, index) => {
        if (index === editingIndex) {
          return {
            ...item,
            disability: e.target.value,
            disabilityRadioValue: {
              none: false,
              deaf: false,
              mute: false,
              blind: false,
              other: false,
              [e.target.value]: true,
            },
          };
        }
        return item;
      });
      setDataFormAppearing(updatedAppearing);
    }
  };

  const handleIdentificationChange = (e) => {
    if(editingIndex !== null){
      const updatedAppearing = dataFormAppearing.map((item, index) => {
        if (index === editingIndex) {
          return {
            ...item,
            identification: e.target.value,
            identificationRadioValue: {
              credential: false,
              passport: false,
              [e.target.value]: true,
            },
          };
        }
        return item;
      });
      setDataFormAppearing(updatedAppearing);
    }
  };

  const handleChange = (e) => {
    if (editingIndex !== null) {
      const updatedAppearing = dataFormAppearing.map((item, index) => {
        if (index === editingIndex) {
          return {
            ...item,
            [e.target.name]: e.target.type !== "radio" && e.target.value,
            
            country:
              e.target.name === "nacionality"
                ? e.target.options[e.target.selectedIndex].textContent
                : item.country,
            state:
              e.target.name === "placeBirth"
                ? e.target.options[e.target.selectedIndex].textContent
                : item.state,
            countryId:
              e.target.name === "nacionality" ? e.target.value : item.countryId,
            stateId:
              e.target.name === "placeBirth" ? e.target.value : item.stateId,
            liveState:
              e.target.name === "liveState"
                ? e.target.options[e.target.selectedIndex].textContent
                : item.liveState,
            liveStateId:
              e.target.name === "liveState" ? e.target.value : item.liveStateId,
            liveMunicipality:
              e.target.name === "liveMunicipality"
                ? e.target.options[e.target.selectedIndex].textContent
                : item.liveMunicipality,
            liveMunicipalityId:
              e.target.name === "liveMunicipality"
                ? e.target.value
                : item.liveMunicipalityId,
          };
        }
        return item;
      });
      setDataFormAppearing(updatedAppearing);
    } else {
      setDataForm({
        ...dataForm,
        [e.target.name]: e.target.value,
        country:
          e.target.name === "nacionality"
            ? e.target.options[e.target.selectedIndex].textContent
            : dataForm.country,
        state:
          e.target.name === "placeBirth"
            ? e.target.options[e.target.selectedIndex].textContent
            : dataForm.state,
        countryId:
          e.target.name === "nacionality" ? e.target.value : dataForm.countryId,
        stateId:
          e.target.name === "placeBirth" ? e.target.value : dataForm.stateId,
        liveState:
          e.target.name === "liveState"
            ? e.target.options[e.target.selectedIndex].textContent
            : dataForm.liveState,
        liveStateId:
          e.target.name === "liveState" ? e.target.value : dataForm.liveStateId,
        liveMunicipality:
          e.target.name === "liveMunicipality"
            ? e.target.options[e.target.selectedIndex].textContent
            : dataForm.liveMunicipality,
        liveMunicipalityId:
          e.target.name === "liveMunicipality"
            ? e.target.value
            : dataForm.liveMunicipalityId,
      });
    }
  };

  const handleAddressFileChange = (e) => {
    const randomName = generateRandomName();
    const file = e.target.files[0];
    const imageRef = ref(
      firebase.storage,
      "products/" + file.name + randomName
    );

    const uploadTask = uploadBytesResumable(imageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          const index = editingIndex !== null ? editingIndex : 0;

          const updatedDataFormAppearing = [...dataFormAppearing];
          updatedDataFormAppearing[index] = {
            ...updatedDataFormAppearing[index],
            addressFile: downloadURL,
            addressFileName: file.name,
          };

          setDataFormAppearing(updatedDataFormAppearing);
        });
      }
    );
  };

  const handleIdentificationFileChange = (e) => {
    const randomName = generateRandomName();
    const file = e.target.files[0];
    const imageRef = ref(
      firebase.storage,
      "products/" + file.name + randomName
    );

    const uploadTask = uploadBytesResumable(imageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => {
        console.log(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);
          const index = editingIndex !== null ? editingIndex : 0;

          const updatedDataFormAppearing = [...dataFormAppearing];
          updatedDataFormAppearing[index] = {
            ...updatedDataFormAppearing[index],
            identificationFile: downloadURL,
            identificationFileName: file.name,
          };

          setDataFormAppearing(updatedDataFormAppearing);
        });
      }
    );
  };

  const addNewAppearing = () => {
    const newAppearing = { ...dataForm };
    const updatedAppearing = [...dataFormAppearing, newAppearing];
    setAddAppearing([...addAppearing, addAppearing.length + 1]);
    setDataForm({
      appearing: "",
      colony: "",
      cp: "",
      curp: "",
      dateBirth: "",
      disability: "",
      disabilityRadioValue: {
        none: false,
        deaf: false,
        mute: false,
        blind: false,
        other: false,
      },
      email: "",
      lastName: "",
      lastName2: "",
      maritalStatus: "",
      maritalRadioValue: {
        soltero: false,
        casado: false,
        unionLibre: false,
        divorciado: false,
        viudo: false,
      },
      municipality: "",
      name: "",
      occupation: "",
      identification: "",
      identificationRadioValue: {
        credential: false,
        passport: false,
      },
      phone: "",
      placeBirth: "",
      rfc: "",
      streetNumber: "",
      country: "0",
      countryId: "0",
      state: "0",
      stateId: "0",
      liveState: "0",
      liveStateId: "0",
      liveMunicipality: "0",
      liveMunicipalityId: "0",
      addressFile: "",
      addressFileName: "",
      identificationFile: "",
      identificationFileName: "",
    });
    setDataFormAppearing(updatedAppearing);
    dataFormAppearing.push(dataForm);
  };
  const editAppearing = (index) => {
    setEditingIndex(index);
  };
  const deleteAppearing = (index) => {
    if (dataFormAppearing.length > 1) {
      const updatedDataFormAppearing = dataFormAppearing.filter(
        (_, i) => i !== index
      );
      const updatedAddAppearing = addAppearing.filter((_, i) => i !== index);

      setDataFormAppearing(updatedDataFormAppearing);
      setAddAppearing(updatedAddAppearing);

      toast.success("Compareciente eliminado");
    } else {
      toast.warn("Debe existir por lo menos un compareciente");
    }
  };

  // console.log(dataFormAppearing);

  return (
    <div className="inputs__container" >
      <div style={{ display: "none" }}>
        {
          <ViewerDonationPDF
            pdfData={pdfData}
            pdfAmount={pdfAmount}
          />
        }
      </div>
      <ToastContainer />
      <p className="inputs__container-note">
        Los campos marcados con * son obligatorios
      </p>
      <div className="inputs__elements__style-amount">
        <p>Cantidad que se está donando *</p>
        <input
          type="number"
          name="amount"
          placeholder="$0"
          onChange={handleAmountChange}
          value={amount}
        />
        {/* {errors.amount && <ErrorComponent error={errors?.amount} />} */}
      </div>
      <h3>
        <b>Datos generales</b> (Por compareciente)
      </h3>
      <button className="add-appearing" type="button" onClick={addNewAppearing}>
        Agregar compareciente
        <i className="fa-solid fa-plus"></i>
      </button>
      <div className="appearing__container">
        {addAppearing.map((_, index) => (
          <div
            key={index}
            className={`appearing__container-element ${
              editingIndex === index ? "selected" : ""
            }`}
          >
            <p title="click para editar" onClick={() => editAppearing(index)}>
              <b>Compareciente #{index + 1}</b>:{" "}
              {dataFormAppearing[index]?.appearing}
            </p>
            <i
              className="fa-solid fa-xmark"
              title="click para eliminar"
              onClick={() => deleteAppearing(index)}
            ></i>
          </div>
        ))}
      </div>
      {addAppearing.map(
        (_, index) =>
          index === editingIndex && (
            <AppearingElement
              key={index}
              dataForm={dataForm}
              handleChange={handleChange}
              countryOptions={countryOptions}
              stateOptions={stateOptions}
              dataFormAppearing={dataFormAppearing}
              editingIndex={editingIndex}
              mexicanStates={mexicanStates}
              citiesOptions={citiesOptions}
              handleAddressFileChange={handleAddressFileChange}
              handleIdentificationFileChange={handleIdentificationFileChange}
              handleMaritalStatusChange={handleMaritalStatusChange}
              handleDisabilityChange={handleDisabilityChange}
              handleIdentificationChange={handleIdentificationChange}
              errors={errors}
            />
          )
      )}
    </div>
  );
}

export default DonationFormComponent;
