import { TEST } from './constants';

export const getTestData = async (firebase, dispatch) => {
	const data = [];
	firebase.db
		.collection('prueba')
		.get()
		.then((docs) => {
			docs.forEach((doc) => {
				data.push({ id: doc.id, ...doc.data()});
			});
			dispatch({
				type: TEST,
				list: data
			});
		})
		.catch(function(error) {
      console.log('Error getting documents: ', error);
    });
} 
  