// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBEWgx5gi5ODBZalM88t8vITwEQJs0spNU",
  authDomain: "notaria-26-5a633.firebaseapp.com",
  projectId: "notaria-26-5a633",
  storageBucket: "notaria-26-5a633.appspot.com",
  messagingSenderId: "45175691777",
  appId: "1:45175691777:web:39c1ae249efbbe68997e00",
  measurementId: "G-HM4XVHE7HL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };

class Firebase {
  constructor() {
      const app = initializeApp(firebaseConfig);
      this.auth = getAuth();
      this.db = getFirestore(app);
      this.storage = getStorage(this.app);
  }
}

const firebase = new Firebase();
export default firebase;