import Login from 'components/auth-user/login';
import Register from 'components/auth-user/signup';
import Grid from '@mui/material/Grid';

export default function TestForm() {

	return (
		<Grid container spacing={2}>
			<Grid item xs={6}>
				<Login />
			</Grid>
			<Grid item xs={6}>
				<Register />
			</Grid>
		</Grid>
	)
}