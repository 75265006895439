import React, { useEffect, useState, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { InputRadio } from "components/inputs/input-radio";
import { Grid, Typography } from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { InputTextarea } from "components/inputs/input-textarea";
import { labels } from "./constants";
import { colors } from "constants/colors";
import { Box } from "@mui/system";

export const OneNestedInput = ({ value, partners }) => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const [isSaCV, setIsSaCV] = useState();
  const saCvVal = watch("typeSociety");
  const firstPartnerName = watch("sacvpartner1");
  const secondPartnerName = watch("sacvpartner2");

  useEffect(() => {
    setIsSaCV(saCvVal === "S.A. De C.V.");
  }, [saCvVal]);

  return (
    <>
      <div className="divider">
        <Typography variant="subtitle1" gutterBottom>
          Cuando de iniciar una nueva empresa se trata, legislativamente es
          necesario realizar notarialmente la constitución de la sociedad de la
          misma, es decir realizar el registro legal de las firmas de los
          socios, del sentido de la empresa y así mismo una aclaración legal del
          rubro en el que ésta se desempeña.
        </Typography>
      </div>

      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <Typography variant="subtitle2" color={colors.blue}>
          Los campos marcados con * son obligatorios
        </Typography>
      </div>

      <div className="divider">
        <Typography variant="subtitle2" style={styles.title}>
          1. Tipo de sociedad *
        </Typography>
        <InputRadio
          name="typeSociety"
          value={value?.typeSociety}
          control={control}
          errors={errors}
          labels={labels}
        />

        {isSaCV && (
          <div className="divider">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  component="div"
                  className="subtitle2"
                >
                  Socio #1
                </Typography>
                <InputText
                  name={`sacvpartner1`}
                  control={control}
                  errors={errors}
                  value={value?.sacvpartner1}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  component="div"
                  className="subtitle2"
                >
                  Socio #2
                </Typography>
                <InputText
                  name={`sacvpartner2`}
                  control={control}
                  errors={errors}
                  value={value?.sacvpartner2}
                />
              </Grid>
            </Grid>
          </div>
        )}

        {errors.typeSociety && (
          <p style={styles.errors}>{errors.typeSociety.message}</p>
        )}
      </div>

      <div className="divider">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          style={styles.title}
        >
          2. Denominación o razón social *
          <span>
            {" "}
            (proporciones 5 denominaciones, "en orden de preferencia")
          </span>
        </Typography>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="numberInputs">1.</p>
              <InputText
                name={`denomination1`}
                value={value?.denomination1}
                control={control}
                errors={errors}
              />
            </div>
            <Box ml="22px">
              {errors.denomination1 && (
                <p style={styles.errors}>{errors.denomination1.message}</p>
              )}
            </Box>
          </Grid>

          <Grid item xs={4} sm={8} md={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="numberInputs">2.</p>
              <InputText
                name={`denomination2`}
                value={value?.denomination2}
                control={control}
                errors={errors}
              />
            </div>
            <Box ml="22px">
              {errors.denomination2 && (
                <p style={styles.errors}>{errors.denomination2.message}</p>
              )}
            </Box>
          </Grid>

          <Grid item xs={4} sm={8} md={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="numberInputs">3.</p>
              <InputText
                name={`denomination3`}
                value={value?.denomination3}
                control={control}
                errors={errors}
              />
            </div>
            <Box ml="22px">
              {errors.denomination3 && (
                <p style={styles.errors}>{errors.denomination3.message}</p>
              )}
            </Box>
          </Grid>

          <Grid item xs={4} sm={8} md={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="numberInputs">4.</p>
              <InputText
                name={`denomination4`}
                value={value?.denomination4}
                control={control}
                errors={errors}
              />
            </div>
            <Box ml="22px">
              {errors.denomination4 && (
                <p style={styles.errors}>{errors.denomination4.message}</p>
              )}
            </Box>
          </Grid>

          <Grid item xs={4} sm={8} md={6}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <p className="numberInputs">5.</p>
              <InputText
                name={`denomination5`}
                value={value?.denomination5}
                control={control}
                errors={errors}
              />
            </div>
            <Box ml="22px">
              {errors.denomination5 && (
                <p style={styles.errors}>{errors.denomination5.message}</p>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          style={styles.title}
        >
          3. Objeto social *
          <span> (actividad preponderante de la sociedad a constituirse)</span>
        </Typography>
        <InputTextarea
          name="socialObject"
          value={value?.socialObject}
          control={control}
        />
        {errors.socialObject && (
          <p style={styles.errors}>{errors.socialObject.message}</p>
        )}
      </div>
    </>
  );
};

const styles = {
  title: {
    color: colors.blue,
    fontWeight: "bold",
  },
  errors: {
    padding: "5px 15px",
    fontSize: 14,
    borderRadius: 15,
    marginTop: -1,
    borderTopLeftRadius: 2,
    backgroundColor: colors.dangerLight,
    display: "inline-block",
    border: `1px solid ${colors.danger}`,
    color: colors.danger,
  },
};
