import { useStateValue } from "context/store";
import moment from "moment";
import { Typography } from "@mui/material";

const ProtocolizationViewerPdf = () => {
  const [
    {
      protocolization: { protocolizationById },
    },
  ] = useStateValue();

  return (
    <div style={{ width: "600px", margin: "auto", display: "none" }}>
      <div id="contentPdfProtocolization" style={{ width: "600px" }}>
        <div className="header-pdf">
          <h3 style={{ margin: 0 }}>
            Protocolización de Asambleas
            <hr />
          </h3>
        </div>
        <div className="body-pdf" style={{ padding: "0 20px" }}>
          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              Informacion empresa
            </Typography>
          </div>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nombre de la empresa: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.companyName
                  ? protocolizationById.companyName
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Tipo de acta: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.typeReport
                  ? protocolizationById.typeReport
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Puntos a protocolizar en el acta de asamblea: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.actAssembly
                  ? protocolizationById.actAssembly
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Puntos a tratar en acta de asamblea/resoluciones: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.assembleResolutions
                  ? protocolizationById.assembleResolutions
                  : "NA"}
              </div>
            </div>
          </div>

          <div
            style={{ fontSize: 12, fontFamily: "Arial", fontWeight: "bold" }}
          >
            Documentos anexados:
          </div>
          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <div
                style={{
                  fontSize: 10,
                  fontFamily: "Arial",
                  fontWeight: "bold",
                }}
              >
                Antecedentes de la empresa:
              </div>
              <div style={{ fontSize: 8 }}>
                {protocolizationById?.companyBackgroundFile
                  ? protocolizationById?.companyBackgroundFile
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <div
                style={{
                  fontSize: 10,
                  fontFamily: "Arial",
                  fontWeight: "bold",
                }}
              >
                Cédulas fiscales de los accionistas y/o socios de la misma:
              </div>
              <div style={{ fontSize: 8 }}>
                {protocolizationById?.ccShareholdersFile
                  ? protocolizationById.ccShareholdersFile
                  : "NA"}
              </div>
            </div>
            <div>
              <div
                style={{
                  fontSize: 10,
                  fontFamily: "Arial",
                  fontWeight: "bold",
                }}
              >
                Identificación oficial del delegado especial:
              </div>
              <div style={{ fontSize: 8 }}>
                {protocolizationById?.companyBackgroundFileNit
                  ? protocolizationById.companyBackgroundFileNit
                  : "NA"}
              </div>
            </div>
            <div>
              <div
                style={{
                  fontSize: 10,
                  fontFamily: "Arial",
                  fontWeight: "bold",
                }}
              >
                Acta firmada a protocolizar y su archivo en Word:
              </div>
              <div style={{ fontSize: 8 }}>
                {protocolizationById?.actSignedFile
                  ? protocolizationById.actSignedFile
                  : "NA"}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              Datos generales del delegado:
            </Typography>
          </div>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nombres: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.name ? protocolizationById.name : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Apellidos:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.lastname
                  ? protocolizationById.lastname
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nacionalidad:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.nacionality
                  ? protocolizationById.nacionality
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Lugar de nacimiento:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.state ? protocolizationById.state : "NA"}{" "}
                -{" "}
                {protocolizationById?.municipality
                  ? protocolizationById.municipality
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Estado civil:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.maritalStatus
                  ? protocolizationById.maritalStatus
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Fecha de nacimiento:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.dateBirth
                  ? moment(protocolizationById.dateBirth)
                      .locale("es")
                      .format("L")
                  : "NA"}{" "}
                -{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Ocupación:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.ocupation
                  ? protocolizationById.ocupation
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Registro Federal de Contribuyentes:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.federalRegister
                  ? protocolizationById.federalRegister
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>CURP:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.CURP ? protocolizationById.CURP : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Credencial de elector:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.voterCard
                  ? protocolizationById.voterCard
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Pasaporte:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.passport
                  ? protocolizationById.passport
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Teléfono:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.phone ? protocolizationById.phone : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Correo electrónico:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.email ? protocolizationById.email : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Tiene alguna incapacidad:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.inability
                  ? protocolizationById.inability
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Documento de Identificación:</b>
              </Typography>
              <div style={{ fontSize: 8 }}>
                {protocolizationById?.delegateFile
                  ? protocolizationById.delegateFile
                  : "NA"}{" "}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              Direccion:
            </Typography>
          </div>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Calle y numero: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.street
                  ? protocolizationById.street
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Colonia: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.colony
                  ? protocolizationById.colony
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>CP: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.CP ? protocolizationById.CP : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Estado: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.stateHome
                  ? protocolizationById.stateHome
                  : "NA"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Municipio: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {protocolizationById?.municipalityHome
                  ? protocolizationById.municipalityHome
                  : "NA"}{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProtocolizationViewerPdf;
