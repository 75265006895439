import { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { ThreeNestedInput } from "./three-nested-input";
import { backStep, resetStepsCompany } from "context/company/actions";
import { Button } from "@mui/material";
import { useStateValue } from "context/store";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { ReactComponent as LeftIcon } from "assets/svg/left-icon.svg";
import useStyles from "../styles";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import {
  addDataProtocolization,
  clearProtocolization,
  createProtocolization,
  editProtocolization,
  updatePdfProtocolization,
} from "context/protocolization/actions";
import { FirebaseContext } from "server";
import { EmailHook } from "hooks/send-email";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaProtocolizationThree from "schema/ProtocolizationAssemblies/protocolization-step-three";
import ErrorForm from "components/Error-form";
import { ValidateDataProtocolizationHook } from "hooks/validateData/protocolization";
import ProtocolizationViewerPdf from "components/success-form/protocolization-viewer-pdf";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import jsPDF from "jspdf";
import { getCreatedTimeValue } from "utils";

const defaultValues = (protocolization) => ({
  street: protocolization?.street,
  colony: protocolization?.colony,
  CP: protocolization?.CP,
  stateHome: protocolization?.stateHome,
  municipalityHome: protocolization?.municipalityHome,
  phone: protocolization?.phone,
  email: protocolization?.email,
  inability: protocolization?.inability,
  otherInability: protocolization?.otherInability,
});

const StepThree = () => {
  // styles
  const classes = useStyles();

  // useState
  const [loading, setLoading] = useState(false);

  // variables
  const [
    {
      protocolization: {
        protocolInput,
        resetStepProtocolization,
        protocolizationById,
        pdf,
      },
    },
    dispatch,
  ] = useStateValue();
  const navigate = useNavigate();
  const tokenStorage = localStorage.getItem("auth-token");
  const currentUser = JSON.parse(localStorage.getItem("current-user"));

  // generate pdf
  const doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
  });

  // documentId
  const { state } = useLocation();
  let documentId = state?.documentId;
  const typeProcedure = documentId
    ? "Protocolización de Asambleas editado con éxito"
    : "Protocolización de Asambleas";

  // react hook form
  const methods = useForm({
    resolver: yupResolver(schemaProtocolizationThree),
  });

  // firebase
  const firebase = useContext(FirebaseContext);

  // hooks
  const { sendEmail, sendUserEmail } = EmailHook();

  const { validateData } = ValidateDataProtocolizationHook();

  // functions
  const onSubmit = (data) => {
    addDataProtocolization(dispatch, data);
    resetStepsCompany(dispatch, {
      step_three: true,
    });
    clearProtocolization(dispatch);
    if (tokenStorage) {
      documentId
        ? submitEditProtocolization(data)
        : submitCreateProtocolization(data);
    } else {
      navigate("/app/form-login", {
        replace: true,
        state: {
          src: "form-protocolization",
        },
      });
    }
  };

  const onBackSubmit = (data) => {
    resetStepsCompany(dispatch, {
      step_three: true,
    });
    addDataProtocolization(dispatch, data);
    backStep(dispatch);
  };

  const submitEditProtocolization = async (data) => {
    setLoading(true);

    const currentData = {
      status: "pending",
      ...data,
      ...protocolInput,
      otherInability: protocolInput?.otherInability || null,
    };

    const namesDataClear = [
      "actSignedFile",
      "companyBackgroundFile",
      "ccShareholdersFile",
      "companyBackgroundFileNit",
      "delegateFile",
    ];

    const filterData = validateData(currentData, namesDataClear);

    const callback = await editProtocolization(
      firebase,
      filterData,
      documentId,
      dispatch,
      currentData,
      namesDataClear
    );

    if (callback) {
      await generatePdf(documentId);
    }
  };

  const submitCreateProtocolization = async (data) => {
    data.createdDateTime = getCreatedTimeValue();

    setLoading(true);

    const currentData = {
      status: "pending",
      ...data,
      ...protocolInput,
    };

    const namesDataClear = [
      "actSignedFile",
      "companyBackgroundFile",
      "ccShareholdersFile",
      "companyBackgroundFileNit",
      "delegateFile",
    ];

    const filterData = validateData(currentData, namesDataClear);

    const callback = await createProtocolization(
      firebase,
      currentData,
      currentUser,
      filterData,
      namesDataClear,
      dispatch
    );

    if (callback?.protocolizationId) {
      generatePdf(callback?.protocolizationId);
    }
  };

  const navigateSendEmail = (documentId) => {
    const emailWasSent = sendEmail(typeProcedure, documentId);
    const emailUserWasSent = sendUserEmail(typeProcedure);
    if (emailWasSent && emailUserWasSent) {
      navigate("/app/transaction-online-success", {
        replace: true,
        state: {
          src: typeProcedure,
        },
      });
    }
  };

  const generatePdf = async (protocolizationId) => {
    let pdfjs = document.querySelector("#contentPdfProtocolization");
    const storage = getStorage();
    console.log("pdfjs====>", pdfjs);
    doc.html(pdfjs, {
      margin: [20, 0, 20, 0],
      callback: function (doc) {
        const metadata = {
          contentType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        };
        let blob = doc.output("blob");
        const storageRef = ref(
          storage,
          `Documents/${protocolizationId}/output${Date.now()}.pdf`
        );
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              console.log("url===>", url);
              updatePdfProtocolization(firebase, protocolizationId, url).then(
                (res) => {
                  console.log("res====>", res);
                  navigateSendEmail(protocolizationId);
                }
              );
            })
            .catch((error) => {
              console.log("error generatePdf", error);
              setLoading(false);
            });
        });
      },
    });
  };

  // useEffect
  useEffect(() => {
    if (!resetStepProtocolization.step_three && documentId) {
      methods.reset(defaultValues(protocolizationById));
    }
  }, [
    documentId,
    methods,
    protocolizationById,
    resetStepProtocolization.step_three,
  ]);

  const countErrors = Object.entries(methods.formState.errors).length;

  return (
    <>
      <FormProvider {...methods}>
        {loading && <Loading />}
        <form autoComplete="off">
          <ThreeNestedInput value={protocolInput} />
          <div className={classes.containerButtons}>
            <Button
              variant="contained"
              className={classes.btnBackStep}
              onClick={methods.handleSubmit(onBackSubmit)}
            >
              <LeftIcon />
              Volver
            </Button>

            <Button
              variant="contained"
              className={classes.btnNextStep}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {documentId ? "Editar" : "Enviar"}
              <RigthIcon />
            </Button>
          </div>
          <ErrorForm countErrors={countErrors} />
        </form>
      </FormProvider>
      <div style={{ display: "none" }}>
        {pdf || documentId ? <ProtocolizationViewerPdf /> : null}
      </div>
    </>
  );
};

export default StepThree;
