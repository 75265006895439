import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  title: {
    textAlign: 'center',
    color: '#1E2E3F',
    '@media (max-width: 1199px)':{
        marginLeft: '20px',
    },
  },
  grid:{
    width: '100%',
    justifyContent: 'center',
    padding: '20px 0 60px 0',
  },
  image: {
    width: '100%',
    height: 'auto',
    '@media (max-width: 1199px)':{
        // padding: '0 1rem 0 1rem',
    }
  },
  departmentContent: {
    top: '43%',
    color: 'white',
    maxWidth: '500px',
    padding: '0 0 0 35px',
    position: 'absolute',
    margin: '0',
    '@media (max-width: 1199px)':{
      maxWidth: '100%',
      position: 'initial',
      padding: '10px auto',
      color: '#1E2E3F',
      textAlign: 'center',
      margin: '10px auto'
    },
    '@media (max-width: 1360px)':{
      top: '10%',
    },
  },
  textBoxes: {
    backgroundColor: 'rgba(0, 92, 155, .85)',
    padding: '10px 20px 12px 20px',
    borderRadius: '8px',
    display: 'inline-flex',
    margin: '5px 10px 5px 0',
    whiteSpace: 'nowrap',
    '@media (max-width: 1199px)':{
      color: 'white',
    }
  },
}));