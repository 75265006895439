import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Container,
  Grid,
  Avatar,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import { colors } from "constants/colors";
import { signOutUser } from "context/user/actions";
import { useNavigate } from "react-router-dom";
import { FirebaseContext } from "server";
import { useStateValue } from "context/store";
import { SnackAlert } from "widgets/snackAlert";
import { Box } from "@mui/system";
import { NotificationsNoneOutlinedIcon } from "widgets/icons";
import profile_image from "../../../../img/profile_image.png";
import { ChatControllerContext } from "context/chat/ChatControllerContext";
import { VisitorsControllerContext } from "context/visitor/VisitorsControllerContext";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  onSnapshot,
  getDocs,
} from "firebase/firestore";
import { db, auth } from "../../../../firebase";
import useStyles from "./styles";

export default function Menu() {
  const [snackBar, setSnackBar] = useState({
    message: "",
    open: false,
    severity: "",
  });
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [userLogged, setUserLogged] = useState();
  const [data, setData] = useState([]);
  const styles = useStyles();
  const [configData, setConfigData] = useState();

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 600px)").matches
  );
  const [isProcedureHovered, setIsProcedureHovered] = useState(false);
  const [isDocumentsHovered, setIsDocumentsHovered] = useState(false);
  const [isChatHovered, setIsChatHovered] = useState(false);
  const [isSignInHovered, setIsSignInHovered] = useState(false);

  const navigate = useNavigate();
  const { message, open, severity } = snackBar;

  // storage
  const tokenStorage = localStorage.getItem("auth-token");
  const infoUserStorage = localStorage.getItem("current-user");
  const infoUser = JSON.parse(infoUserStorage);

  const { setIsChatEnabled, setSelectedDocument } = useContext(
    ChatControllerContext
  );

  const { isVisitor } = useContext(VisitorsControllerContext);

  // firebase
  const firebase = useContext(FirebaseContext);
  const [
    {
      user: { userById },
    },
    dispatch,
  ] = useStateValue();

  const { current: currentUser } = useRef(
    JSON.parse(localStorage.getItem("current-user"))
  );

  const user1 = auth?.currentUser?.uid;

  useEffect(() => {
    if (auth?.currentUser) {
      getDoc(doc(db, "users", auth?.currentUser?.uid)).then((docSnap) => {
        if (docSnap.exists) {
          setUserLogged(docSnap.data());
        }
      });
    }
  }, [auth.currentUser]);

  useEffect(() => {
    setUser(currentUser ? currentUser : userById);
  }, [currentUser, userById]);

  useEffect(() => {
    if (auth.currentUser) {
      const usersRef = collection(db, "users");
      // Create query object
      const q = query(usersRef, where("uid", "not-in", [user1]));
      // Execute query
      const unsub = onSnapshot(q, (querySnapshot) => {
        let users = [];
        querySnapshot.forEach((doc) => {
          users.push(doc.data());
        });
        if (userLogged?.rol !== "notario") {
          const notary = users.filter((user) => user?.rol === "notario");
          setUsers(notary);
        } else {
          setUsers(users);
        }
      });
      return () => unsub();
    }
  }, [userLogged, auth]);

  useEffect(() => {
    users.forEach((user) => {
      const id =
        user1 > user?.uid ? `${user1 + user?.uid}` : `${user?.uid + user1}`;
      let unsub = onSnapshot(doc(db, "lastMsg", id), (doc) => {
        if (doc?.data() && doc.data()?.from !== user1 && doc.data()?.unread) {
          !data.some((record) => record?.from === doc.data()?.from) &&
            setData((prevState) => [...prevState, doc.data()]);
        }
      });
      return () => unsub();
    });
  }, [users]);

  const logout = () => {
    setIsChatEnabled(false);
    setSelectedDocument();
    navigate("/");
    signOutUser(firebase, dispatch).then(() => {
      setSnackBar({
        message: "Sesión cerrada con exito",
        open: true,
        severity: "success",
      });
    });
  };

  const handleClose = () => {
    setSnackBar({
      message: "",
      open: false,
      severity: "",
    });
  };

  useEffect(() => {
    window
      .matchMedia("(min-width: 600px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const clickProfile = () => navigate("/app/profile");

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  const handleProcedureMouseEnter = () => {
    setIsProcedureHovered(true);
  };
  const handleProcedureMouseLeave = () => {
    setIsProcedureHovered(false);
  };
  const handleDocumentsMouseEnter = () => {
    setIsDocumentsHovered(true);
  };
  const handleDocumentsMouseLeave = () => {
    setIsDocumentsHovered(false);
  };
  const handleChatMouseEnter = () => {
    setIsChatHovered(true);
  };
  const handleChatMouseLeave = () => {
    setIsChatHovered(false);
  };
  const handleSignInMouseEnter = () => {
    setIsSignInHovered(true);
  };
  const handleSignInMouseLeave = () => {
    setIsSignInHovered(false);
  };

  return (
    <>
      <nav className={styles.nav}>
        <Grid container spacing={3}>
          <Grid item xs={3} md={1}>
            <Container className={styles.container}>
              <img
                src={configData?.logo}
                alt="Navbar"
                onClick={() => navigate("/", { replace: true })}
              />
            </Container>
          </Grid>

          <Grid
            item
            md={10}
            className={tokenStorage ? styles.organizedItems : styles.items}
          >
            <div className={styles.bannerOptions}>
              {user?.rol !== "notario" && (
                <Button
                  className={styles.buttons}
                  style={{
                    color: configData?.baseColor,
                    fontWeight: "bold",
                    padding: "6px 18px",
                    margin: "0 10px",
                    borderRadius: "30px",
                    fontFamily: "poppinsregular",
                    height: "40px",
                    position: "relative",
                    ...(isProcedureHovered && {
                      background: configData?.hoverNavColor,
                    }),
                  }}
                  onMouseEnter={handleProcedureMouseEnter}
                  onMouseLeave={handleProcedureMouseLeave}
                  size="small"
                  onClick={() => {
                    navigate("/app/transaction-online", {
                      replace: true,
                      state: {
                        src: "",
                      },
                    });
                  }}
                >
                  Centro de Trámites
                </Button>
              )}

              {user && user.rol === "notario" && (
                <Button
                  style={{
                    color: configData?.baseColor,
                    fontWeight: "bold",
                    padding: "6px 18px",
                    margin: "0 10px",
                    borderRadius: "30px",
                    fontFamily: "poppinsregular",
                    height: "40px",
                    position: "relative",
                    ...(isDocumentsHovered && {
                      background: configData?.hoverNavColor,
                    }),
                  }}
                  onMouseEnter={handleDocumentsMouseEnter}
                  onMouseLeave={handleDocumentsMouseLeave}
                  size="small"
                  onClick={() =>
                    navigate("/app/welcome-companies-list", {
                      replace: true,
                    })
                  }
                >
                  Documentos
                </Button>
              )}

              {!isVisitor && tokenStorage && (
                <Button
                style={{
                  color: configData?.baseColor,
                  fontWeight: "bold",
                  padding: "6px 18px",
                  margin: "0 10px",
                  borderRadius: "30px",
                  fontFamily: "poppinsregular",
                  height: "40px",
                  position: "relative",
                  ...(isChatHovered && {
                    background: configData?.hoverNavColor,
                  }),
                }}
                onMouseEnter={handleChatMouseEnter}
                onMouseLeave={handleChatMouseLeave}
                size="small"
                onClick={() => {
                  setData([]);
                  navigate("/app/chat", { replace: true });
                }}
                >
                  Chat
                </Button>
              )}

              {!tokenStorage && (
                <Button
                  style={{
                    color: configData?.baseColor,
                    fontWeight: "bold",
                    padding: "6px 18px",
                    margin: "0 10px",
                    borderRadius: "30px",
                    fontFamily: "poppinsregular",
                    height: "40px",
                    position: "relative",
                    ...(isSignInHovered && {
                      background: configData?.hoverNavColor,
                    }),
                  }}
                  onMouseEnter={handleSignInMouseEnter}
                  onMouseLeave={handleSignInMouseLeave}
                  size="small"
                  onClick={() =>
                    navigate("/app/form-login", {
                      replace: true,
                      state: {
                        src: "",
                      },
                    })
                  }
                >
                  Ingresar
                </Button>
              )}
            </div>

            {tokenStorage && (
              <div className={styles.contentText}>
                <div onClick={clickProfile} className={styles.textName}>
                  {infoUser && infoUser.name}
                </div>
                <div className={styles.logOut} onClick={logout}>
                  Cerrar sesión
                </div>
              </div>
            )}
            {tokenStorage && matches && (
              <Avatar
                alt="Remy Sharp"
                src={infoUser?.avatar || profile_image}
                className={styles?.userAvatar}
              />
            )}
            {tokenStorage && (
              <Box
                ml="5px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  backgroundColor: colors.lightgrey,
                  width: 40,
                  height: 40,
                  borderRadius: 50,
                  position: "relative",
                }}
              >
                <NotificationsNoneOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setData([]);
                    navigate("/app/chat", { replace: true });
                  }}
                />
                {data?.length !== 0 && (
                  <Stack
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                      position: "absolute",
                      right: -8,
                      top: 0,
                      backgroundColor: colors.lightskyblue,
                      borderRadius: 50,
                      width: 20,
                      height: 20,
                    }}
                  >
                    <Typography fontSize="10px" fontWeight="bold">
                      {data.length}
                    </Typography>
                  </Stack>
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </nav>

      <SnackAlert
        message={message}
        open={open}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
}
