import { useContext, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Button } from "@mui/material";
import { useStateValue } from "context/store";
import { FirebaseContext } from "server";
import { createUser } from "context/user/actions";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "schema";
import { SnackAlert } from "widgets/snackAlert";
import { SignupNestedInput } from "./signup-nested-input";
import { ReactComponent as ReactLogo } from "assets/svg/rigth-icon-blue.svg";
import { colors } from "constants/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { navigateTypeForm } from "hooks/login-forms";
import { useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import "firebase/firestore";

export default function Register({ isFormRegister }) {

  const [configData, setConfigData] = useState();

  // react hook form
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  // firebase
  const firebase = useContext(FirebaseContext);
  let { pathname } = useLocation();

  // useState
  const [snackBar, setSnackBar] = useState({
    message: "",
    open: false,
    severity: "",
  });

  // variables
  const { message, open, severity } = snackBar;
  const [
    {
      user: { token },
    },
    dispatch,
  ] = useStateValue();
  const navigate = useNavigate();

  // functions
  const handleClose = () => {
    setSnackBar({
      message: "",
      open: false,
      severity: "",
    });
  };

  const onSubmit = async (data) => {
    try {
      await createUser(firebase, dispatch, data);
      setSnackBar({
        message: "Usuario creado con exito.",
        open: true,
        severity: "success",
      });
    } catch (error) {
      console.error("Signup =====>", error);
      if (error.code === "auth/email-already-in-use") {
        setSnackBar({
          message: "Usuario ya registrado...",
          open: true,
          severity: "error",
        });
        return;
      }
    }
  };

  const onSubmitForm = async (data) => {
    try {
      await createUser(firebase, dispatch, data);
      setSnackBar({
        message: "Usuario creado con exito.",
        open: true,
        severity: "success",
      });
      navigateTypeForm(pathname.split("/").at(-1), navigate);
    } catch (error) {
      console.error("Signup =====>", error);
      if (error.code === "auth/email-already-in-use") {
        setSnackBar({
          message: "Usuario ya registrado...",
          open: true,
          severity: "error",
        });
        return;
      }
    }
  };

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
          <SignupNestedInput />

          {isFormRegister ? (
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: configData?.baseColor,
                  color: colors.white,
                  borderRadius: 20,
                  fontWeight: "bold",
                  width: 150,
                  display: "flex",
                }}
                onClick={methods.handleSubmit(onSubmitForm)}
              >
                Enviar
              </Button>
            </div>
          ) : (
            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{
                  background: configData?.iconsColor,
                  color: configData?.baseColor,
                  borderRadius: 20,
                  fontWeight: "bold",
                  width: 150,
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                Comenzar
                <ReactLogo />
              </Button>
            </div>
          )}
        </form>
      </FormProvider>

      <SnackAlert
        message={message}
        open={open}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
}
