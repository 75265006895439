import { string, object } from "yup";

const schemaProtocolizationThree = object({
  street: string().required("Este campo es obligatorio"),
  colony: string().required("Este campo es obligatorio"),
  CP: string().required("Este campo es obligatorio"),
  stateHome: string().required("Este campo es obligatorio"),
  municipalityHome: string().required("Este campo es obligatorio"),
  phone: string().required("Este campo es obligatorio"),
  email: string()
    .required("Este campo es obligatorio")
    .email("Email no válido"),
  inability: string().required("Este campo es obligatorio"),
}).required();

export default schemaProtocolizationThree;
