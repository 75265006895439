import LayoutLanding from 'components/Layouts/Landing';
import Banner from 'components/Landing/Banners/Banner';
import Transaction from 'pages/Transaction/transaction';
// La idea seria traer estas secciones de la landing desde componentes
// Cada seccion seria un componente o mas si es el caso
// Guardar estos componentes dentro de components/Landing/CarpetaNombreComponente

const Landing = () => {

  return (
    <LayoutLanding>
      <Banner />
      <Transaction />
    </LayoutLanding>
  )
}

export default Landing;