import { useEffect, useState } from "react";
import { Typography, Grid, Container } from "@mui/material";
import useStyles from "./styles";
import { listOfDocuments } from "./constants";
import CardViewDocuments from "components/card-view-documents/card-view-documents";
import MarriageContractDoc from "../MarriageContractDoc/marriage-contract-doc";
import DonationMoneyDoc from "../DonationMoneyDoc/donation-money-doc";
import ProtocolizationAssembliesDoc from "../ProtocolizationAssembliesDoc/protocolization-assemblies-doc";
import CompanyFormationDoc from "../TransacitionOnlineDoc/company-formation-doc";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../firebase'
import "firebase/firestore";

const TableFragment = ({ type }) => {
  switch (type) {
    case "marriage-contract-doc":
      return <MarriageContractDoc />;

    case "donation-money-doc":
      return <DonationMoneyDoc />;

    case "protocolization-assemblies-doc":
      return <ProtocolizationAssembliesDoc />;

    default:
      return <CompanyFormationDoc />;
  }
};

const WelcomeCompaniesList = () => {
  const classes = useStyles();
  const [typeTable, setTypeTable] = useState(
    listOfDocuments?.find((doc) => doc?.defaultActive)?.url || ""
  );
  const [clickCard, setClickCard] = useState(
    listOfDocuments?.find((doc) => doc?.defaultActive)?.id || "0"
  );

  const [ configData, setConfigData ] = useState();

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);


  return (
    <Container>
      <div className={classes.container}>
        <Grid item xs={6} sm={8} md={8}>
          <Typography
            variant="h2"
            gutterBottom
            component="div"
            className={classes.title}
            style={{ color: configData?.baseColor }}
          >
            Documentos
          </Typography>
          <div className={classes.wrapper}>
            {/* {listOfDocuments.map((card) => (
              <CardViewDocuments
                card={card}
                setTypeTable={setTypeTable}
                key={card.id}
                clickCard={clickCard}
                setClickCard={setClickCard}
                selected={clickCard === card.id}
              />
            ))} */}
            {
              configData?.societies &&
                <CardViewDocuments
                  card={listOfDocuments[0]}
                  setTypeTable={setTypeTable}
                  clickCard={clickCard}
                  setClickCard={setClickCard}
                  selected={clickCard === listOfDocuments[0].id}
                />
            }
            <CardViewDocuments
              card={listOfDocuments[1]}
              setTypeTable={setTypeTable}
              clickCard={clickCard}
              setClickCard={setClickCard}
              selected={clickCard === listOfDocuments[1].id}
            />
            { // Descomentar cuando se tenga el formulario de donaciones.
              configData?.donation &&
                <CardViewDocuments
                  card={listOfDocuments[1]}
                  setTypeTable={setTypeTable}
                  clickCard={clickCard}
                  setClickCard={setClickCard}
                  selected={clickCard === listOfDocuments[1].id}
                />
            }
            {
              configData?.protocolization &&
                <CardViewDocuments
                  card={listOfDocuments[2]}
                  setTypeTable={setTypeTable}
                  clickCard={clickCard}
                  setClickCard={setClickCard}
                  selected={clickCard === listOfDocuments[2].id}
                />
            }
            {
              configData?.capitulations &&
                <CardViewDocuments
                  card={listOfDocuments[3]}
                  setTypeTable={setTypeTable}
                  clickCard={clickCard}
                  setClickCard={setClickCard}
                  selected={clickCard === listOfDocuments[3].id}
                />
            }
          </div>
        </Grid>
        <div className={classes.divider}>
          <TableFragment type={typeTable} />
        </div>
      </div>
    </Container>
  );
};

export default WelcomeCompaniesList;
