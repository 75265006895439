import { string, object } from "yup";

const schemaProtocolizationTwo = object({
  name: string().required("Este campo es obligatorio"),
  lastname: string().required("Este campo es obligatorio"),
  nacionality: string().required("Este campo es obligatorio"),
  state: string().required("Este campo es obligatorio"),
  municipality: string().required("Este campo es obligatorio"),
  maritalStatus: string().required("Este campo es obligatorio"),
  dateBirth: string().required("Este campo es obligatorio"),
  ocupation: string().required("Este campo es obligatorio"),
  federalRegister: string().required("Este campo es obligatorio"),
  CURP: string()
    .required("Este campo es obligatoriorequerido")
    .min(18, "El CURP debe contener 18 digitos")
    .max(18, "El CURP debe contener 18 digitos"),
}).required();

export default schemaProtocolizationTwo;
