import { useContext, useEffect, useState } from "react";
import { Container, Stack, Paper } from "@mui/material";
import { FirebaseContext } from "server";
import { useStateValue } from "context/store";
import { DataTable } from "components/data-table/data-table";
import { getDonations } from "context/donation/actions";
import { DonationColumnHook } from "hooks/donation-columns";
import { MutatingDots } from "react-loader-spinner";

const DonationMoneyDoc = () => {
  // firebase
  const firebase = useContext(FirebaseContext);
  const [loading, setLoading] = useState(true);
  // custom hooks
  const { columns } = DonationColumnHook();

  const [
    {
      donation: { listDonation },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    const fetchData = async () => {
      if (listDonation.length === 0) {
        await getDonations(firebase, dispatch);
      }
    };

    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, firebase]);
  
  useEffect(() => {
    if (listDonation.length > 0) {
      setLoading(false);
    }
  }, [listDonation]);


  return (
    <Stack minHeight={"calc(100vh - 200px)"} justifyContent="center">
      <Container>
        {loading ? (
          <MutatingDots
            height="100"
            width="100"
            color="#1759A5"
            secondaryColor="#1759A5"
            radius="15.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <Paper>
            <DataTable
              data={listDonation.sort((a, b) =>
                parseInt(a?.createdDateTime) < parseInt(b?.createdDateTime)
                  ? 1
                  : -1
              )}
              columns={columns}
              title="Donación de dinero"
            />
          </Paper>
        )}
      </Container>
    </Stack>
  );
};

export default DonationMoneyDoc;
