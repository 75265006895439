import { useEffect } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { ThreeNestedInput } from "./three-nested-input";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  addDataCompany,
  addProxiesCompany,
  backStep,
  nextStep,
  resetStepsCompany,
} from "context/company/actions";
import { Button } from "@mui/material";
import { useStateValue } from "context/store";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { ReactComponent as LeftIcon } from "assets/svg/left-icon.svg";
import useStyles from "../styles";
import schemaThreeForm from "schema/CompanyFormation/schema-three-form";
import { useLocation } from "react-router-dom";
import ErrorForm from "components/Error-form";

const defaultValues = (company) => ({
  administration: company?.administration,
  nameManager: company?.nameManager,
  president: company?.president || "",
  secretary: company?.secretary || "",
  treasurer: company?.treasurer || "",
  vocal: company?.vocal || "",
  proxies: company?.proxies,
  nameCommissioner: company?.nameCommissioner,
  RFCCommissioner: company?.RFCCommissioner,
  commentsCommissioner: company?.commentsCommissioner,
});

const StepThree = () => {
  const classes = useStyles();

  const [
    {
      company: { companyInput, proxies, companyById, resetStepCompany },
    },
    dispatch,
  ] = useStateValue();

  const { state } = useLocation();
  let documentId = state?.documentId;

  const methods = useForm({
    resolver: yupResolver(schemaThreeForm),
    defaultValues: {
      proxies: proxies.proxies,
    },
  });

  const { control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "proxies",
  });

  const onSubmit = (data) => {
    nextStep(dispatch);
    const dataProxies = {
      proxies: data.proxies,
    };
    resetStepsCompany(dispatch, {
      step_three: true,
    });
    saveDataState(data);
    addProxiesCompany(dispatch, dataProxies);
  };

  const onBackSubmit = (data) => {
    resetStepsCompany(dispatch, {
      step_three: true,
    });
    backStep(dispatch);
    saveDataState(data);
    const dataProxies = {
      proxies: data.proxies,
    };
    addProxiesCompany(dispatch, dataProxies);
  };

  const saveDataState = (data) => {
    addDataCompany(dispatch, data);
  };

  useEffect(() => {
    if (!resetStepCompany.step_three && documentId) {
      methods.reset(defaultValues(companyById));
    }
  }, [companyById, methods, resetStepCompany.step_three, documentId]);

  const countErrors = Object.entries(methods.formState.errors).length;

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <div style={{ marginBottom: 50 }}>
          <ThreeNestedInput
            value={companyInput}
            fields={fields}
            append={append}
            remove={remove}
            setValue={methods.setValue}
            getValues={methods.getValues}
          />

          <div className={classes.containerButtons}>
            <Button
              variant="contained"
              className={classes.btnBackStep}
              onClick={methods.handleSubmit(onBackSubmit)}
            >
              <LeftIcon />
              Volver
            </Button>

            <Button
              variant="contained"
              className={classes.btnNextStep}
              onClick={methods.handleSubmit(onSubmit)}
            >
              Siguiente
            </Button>
          </div>
          <ErrorForm countErrors={countErrors} />
        </div>
      </form>
    </FormProvider>
  );
};

export default StepThree;
