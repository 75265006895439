import { useState } from "react";
import { useFormContext } from "react-hook-form";
import {
    TableBody,
    TableCell,
    IconButton,
    TableHead,
    TableRow,
    Paper,
    Table,
    Grid,
    Typography,
    FormGroup,
    Button,
} from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { InputRadio } from "components/inputs/input-radio";
import { InputTextarea } from "components/inputs/input-textarea";
import { labels } from "./constants";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import QuestionMarket from "components/question-market/question-market";
import { marketCommissioner } from "components/question-market/questions";
import NestedArray from "./nested-array";
import V2NestedArray from "./v2-nested-array";
import { colors } from "constants/colors";

export const ThreeNestedInput = ({
    value,
    fields,
    append,
    remove,
    setValue,
    getValues,
}) => {
    const {
        control,
        formState: { errors },
        watch,
        register,
    } = useFormContext(); // retrieve all hook methods
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const watchAministration = watch("administration");

    let showName =
        watchAministration === "Administrador único o gerente" ||
        watchAministration === "Gerente general, director, etc.";

    let showCouncil =
        watchAministration === "Consejo de administración" ||
        watchAministration === "Consejo de gerentes";

    return (
        <>
            <div style={{ marginTop: 30, marginBottom: 30 }}>
                <Typography variant="subtitle2" color={colors.blue}>
                    Los campos marcados con * son obligatorios
                </Typography>
            </div>
            <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                className="subtitle2"
                style={styles.title}
            >
                7. Administración *
            </Typography>

            <div className="divider">
                <InputRadio
                    name="administration"
                    value={value.administration}
                    control={control}
                    errors={errors}
                    labels={labels}
                />
                {errors.administration && (
                    <p style={styles.errors}>{errors.administration.message}</p>
                )}
            </div>

            {showName && (
                <div className="divider">
                    <Typography variant="body1" gutterBottom>
                        Nombre de gerente, administrador único o director
                    </Typography>
                    <InputText
                        name="nameManager"
                        value={value.nameManager}
                        control={control}
                        errors={errors}
                    />
                </div>
            )}

            {showCouncil && (
                <>
                    <div className="divider">
                        <Typography
                            className="subtitle2"
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                        >
                            Consejo de administración o consejo de gerentes
                        </Typography>
                    </div>

                    <div className="divider">
                        <Grid
                            container
                            spacing={{ xs: 2, md: 3 }}
                            columns={{ xs: 4, sm: 8, md: 12 }}
                        >
                            <Grid item xs={2} sm={6} md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <p className="numberInputs separate">
                                        Presidente:
                                    </p>
                                    <InputText
                                        name="president"
                                        value={value.president}
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={2} sm={6} md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <p className="numberInputs separate">
                                        Secretario:
                                    </p>
                                    <InputText
                                        name="secretary"
                                        value={value.secretary}
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={2} sm={6} md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <p className="numberInputs separate">
                                        Tesorero:
                                    </p>
                                    <InputText
                                        name="treasurer"
                                        value={value.treasurer}
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={2} sm={6} md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <p className="numberInputs separate">
                                        Vocal:
                                    </p>
                                    <InputText
                                        name="vocal"
                                        value={value.vocal}
                                        control={control}
                                        errors={errors}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </>
            )}
            <div className="divider">
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                    style={styles.title}
                >
                    8. Facultades *
                </Typography>
            </div>

            <div className="types-powers">
                <Typography
                    className="types-powers_title"
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                >
                    <span className="indicative">A.</span> Pleitos y cobranzas
                </Typography>

                <Typography
                    className="types-powers_title"
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                >
                    <span className="indicative">B.</span> Actos de
                    administración
                </Typography>

                <Typography
                    className="types-powers_title"
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                >
                    <span className="indicative">C.</span> Cuentas bancarias
                </Typography>

                <Typography
                    className="types-powers_title"
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                >
                    <span className="indicative">D.</span> Actos de dominio
                </Typography>

                <Typography
                    className="types-powers_title"
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                >
                    <span className="indicative">E.</span> Cambiario
                </Typography>

                <Typography
                    className="types-powers_title"
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                >
                    <span className="indicative">F.</span> Facultad de
                    delegación
                </Typography>
            </div>

            {/* TABLE  */}

            <div className="divider">
                <Paper sx={{ width: "100%" }} className="container">
                    <Table aria-label="simple table" className="table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">Apoderado</TableCell>
                                <TableCell align="center">
                                    Para ejercer de forma individual
                                </TableCell>
                                <TableCell align="center">
                                    Para ejercer de forma mancomunada
                                </TableCell>
                                <TableCell align="center">Acciones</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fields.map((item, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell
                                            align="center"
                                            component="td"
                                            scope="row"
                                        >
                                            <InputText
                                                name={`proxies[${index}].takenOver`}
                                                control={control}
                                                errors={errors}
                                                defaultValue={item.takenOver}
                                            />
                                        </TableCell>
                                        {/* NestedArray */}
                                        <TableCell
                                            align="center"
                                            component="td"
                                            scope="row"
                                        >
                                            <FormGroup row>
                                                <NestedArray
                                                    nestIndex={index}
                                                    {...{ control, register }}
                                                />
                                            </FormGroup>
                                        </TableCell>
                                        {/* V2NestedArray */}
                                        <TableCell
                                            align="center"
                                            component="td"
                                            scope="row"
                                        >
                                            <FormGroup row>
                                                <V2NestedArray
                                                    nestIndex={index}
                                                    {...{ control, register }}
                                                />
                                            </FormGroup>
                                        </TableCell>
                                        {/* Actions */}
                                        <TableCell
                                            align="center"
                                            component="td"
                                            scope="row"
                                        >
                                            <FormGroup row>
                                                <Button
                                                    type="button"
                                                    onClick={() =>
                                                        remove(index)
                                                    }
                                                >
                                                    Eliminar
                                                </Button>
                                            </FormGroup>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>

                        <Button
                            type="button"
                            onClick={() => {
                                setValue("proxies", [
                                    ...getValues().proxies,
                                    {
                                        name: "append",
                                        nestedArray: [{}],
                                        v2nestedArray: [{}],
                                    },
                                ]);
                            }}
                        >
                            Agregar Apoderado
                        </Button>
                    </Table>
                </Paper>
            </div>

            {/* END TABLE */}

            <div className="divider">
                <div className="commissioner-icon">
                    <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className="subtitle2"
                        style={styles.title}
                    >
                        9. Vigilancia: comisario *
                    </Typography>

                    <div style={{ marginLeft: 5 }}>
                        <IconButton onClick={handleOpen} size="small">
                            <HelpOutlineIcon style={{ fontSize: 20 }} />
                        </IconButton>
                    </div>
                </div>

                <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={2} sm={6} md={6}>
                        <Typography variant="body1" gutterBottom>
                            Nombre
                        </Typography>
                        <InputText
                            name="nameCommissioner"
                            value={value.nameCommissioner}
                            control={control}
                            errors={errors}
                        />
                        {errors.nameCommissioner && (
                            <p style={styles.errors}>
                                {errors.nameCommissioner.message}
                            </p>
                        )}
                    </Grid>

                    <Grid item xs={2} sm={6} md={6}>
                        <Typography variant="body1" gutterBottom>
                            RFC
                        </Typography>
                        <InputText
                            name="RFCCommissioner"
                            value={value.RFCCommissioner}
                            control={control}
                            errors={errors}
                        />

                        {errors.RFCCommissioner && (
                            <p style={styles.errors}>
                                {errors.RFCCommissioner.message}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div className="divider">
                <Typography
                    variant="body1"
                    gutterBottom
                    className="subtitle2"
                    style={styles.title}
                >
                    Comentarios y observaciones
                </Typography>
                <InputTextarea
                    name="commentsCommissioner"
                    value={value.commentsCommissioner}
                    control={control}
                />
            </div>

            <QuestionMarket
                open={open}
                handleClose={handleClose}
                question={marketCommissioner.question}
                definition={marketCommissioner.definition}
            />
        </>
    );
};

const styles = {
    title: {
        color: colors.blue,
        fontWeight: "bold",
    },
    errors: {
        padding: "5px 15px",
        fontSize: 14,
        borderRadius: 15,
        marginTop: -1,
        borderTopLeftRadius: 2,
        backgroundColor: colors.dangerLight,
        display: "inline-block",
        border: `1px solid ${colors.danger}`,
        color: colors.danger,
    },
};
