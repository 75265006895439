import { useStateValue } from "context/store";
import { Grid, Typography, Button } from "@mui/material";
import { height } from "@mui/system";
import MapDonationAppearing from "./map-donation-appearing";

const ViewerDonationPDF = ({ dataFormAppearing, amount, pdfData, pdfAmount }) => {
  const [
    {
      donation: { donationById },
    },
  ] = useStateValue();

  return (
    <div
      style={{
        width: "600px",
        margin: "auto",
        display: "none",
        fontFamily: "Arial",
      }}
    >
      <div id="new-donation" style={{ width: "600px" }}>
        <div className="header-pdf">
          <h3 style={{ margin: 0 }}>
            Donación de Dinero
            <hr />
          </h3>
        </div>
        <div className="body-pdf" style={{ padding: "0 20px" }}>
          <div style={{ fontSize: 12 }}>
            <b>Cantidad que se está donando: </b>{" "}
            {pdfAmount ? `$${pdfAmount}` : "N/A"}
          </div>
          {/*  */}
          {
            pdfData?.map((data, index) => (
              <MapDonationAppearing key={index} data={data} />
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default ViewerDonationPDF;
