import CompanyFormation from "assets/svg/companyFormation.svg";
import DonatingMoney from "assets/svg/donatingMoney.svg";
import RatificationEngagement from "assets/svg/ratificationEngagement.svg";
import MarriageContract from "assets/svg/marriage-contract.svg";
import CertifiedCopy from "assets/svg/certifiedCopy.svg";
import Audits from "assets/svg/audits.svg";
import { v4 as uuidv4 } from "uuid";

export const listOfDocuments = [
  {
    id: uuidv4(),
    icon: CompanyFormation,
    title: "Constitución de sociedades",
    url: "company-formation-doc",
    defaultActive: true,
  },
  {
    id: uuidv4(),
    icon: DonatingMoney,
    title: "Donación de Dinero",
    url: "donation-money-doc",
    defaultActive: false,
  },
  // {
  //   id: uuidv4(),
  //   icon: RatificationEngagement,
  //   title: "Ratificación de Contratos",
  //   url: "company-formation",
  // },
  // {
  //   id: uuidv4(),
  //   icon: CertifiedCopy,
  //   title: "Copia Certificada",
  //   url: "company-formation",
  // },
  {
    id: uuidv4(),
    icon: Audits,
    title: "Protocolización de Asambleas",
    url: "protocolization-assemblies-doc",
    defaultActive: false,
  },
  {
    id: uuidv4(),
    icon: MarriageContract,
    title: "Capitulaciones Matrimoniales",
    url: "marriage-contract-doc",
    defaultActive: false,
  },
];
