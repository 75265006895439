import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { TwoNestedInput } from "./two-nested-input";
import {
  backStep,
  nextStep,
  addDataCompany,
  addParticipationRateCompany,
  resetStepsCompany,
} from "context/company/actions";
import { Button } from "@mui/material";
import { useStateValue } from "context/store";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { ReactComponent as LeftIcon } from "assets/svg/left-icon.svg";
import useStyles from "../styles";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaTwoForm from "schema/CompanyFormation/schema-two-form";
import ErrorForm from "components/Error-form";
import { uuidv4 } from "@firebase/util";

const defaultValues = (company) => ({
  registeredOffice: company?.registeredOffice,
  amountPesos: company?.amountPesos,
  action: company?.action,
  valueEachAction: company?.valueEachAction,
  participationRate: company?.participationRate,
  nacionality: company?.nacionality,
});

const StepTwo = () => {
  const classes = useStyles();

  const [
    {
      company: {
        companyInput,
        participationRate,
        companyById,
        resetStepCompany,
      },
    },
    dispatch,
  ] = useStateValue();

  const { state } = useLocation();
  let documentId = state?.documentId;

  const methods = useForm({
    resolver: yupResolver(schemaTwoForm),
    defaultValues: {
      participationRate: participationRate.participationRate,
    },
  });

  const { control } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "participationRate",
  });

  useEffect(() => {
    if (
      companyInput?.sacvpartner2 &&
      !fields.some(
        (field) => field.nameShareholder === companyInput?.sacvpartner2
      )
    ) {
      append({
        id: uuidv4(),
        nameShareholder: companyInput?.sacvpartner2,
        percentShareholder: "",
      });
    }
  }, [companyInput]);

  const [actionPercentShare, setActionPercentShare] = useState(0);

  function handleActionPercentShare(num) {
    setActionPercentShare(num);
  }

  const onSubmit = (data) => {
    if (actionPercentShare > 100) return;
    nextStep(dispatch);
    resetStepsCompany(dispatch, {
      step_two: true,
    });

    if (data.amountPesos === undefined) delete data.amountPesos;
    if (data.valueEachAction === undefined) delete data.valueEachAction;

    saveDataState(data);
  };

  const onBackSubmit = (data) => {
    if (actionPercentShare > 100) return;
    resetStepsCompany(dispatch, {
      step_two: true,
    });
    backStep(dispatch);
    saveDataState(data);
  };

  const saveDataState = (data) => {
    const dataParticipationRate = {
      participationRate: data.participationRate,
    };
    addParticipationRateCompany(dispatch, dataParticipationRate);
    addDataCompany(dispatch, data);
  };

  useEffect(() => {
    if (!resetStepCompany.step_two && documentId) {
      methods.reset(defaultValues(companyById));
    }
  }, [companyById, methods, resetStepCompany.step_two, documentId]);

  const countErrors = Object.entries(methods.formState.errors).length;

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <TwoNestedInput
          handleActionPercentShare={handleActionPercentShare}
          value={companyInput}
          fields={fields}
          append={append}
          remove={remove}
        />
        <div className={classes.containerButtons}>
          <Button
            variant="contained"
            className={classes.btnBackStep}
            onClick={methods.handleSubmit(onBackSubmit)}
          >
            <LeftIcon />
            Volver
          </Button>

          <Button
            variant="contained"
            className={classes.btnNextStep}
            onClick={methods.handleSubmit(onSubmit)}
          >
            Siguiente
          </Button>
        </div>
        <ErrorForm countErrors={countErrors} />
      </form>
    </FormProvider>
  );
};

export default StepTwo;
