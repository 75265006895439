import React, { useEffect, useState } from "react";
import profile_image from "../../img/profile_image.png";
import { onSnapshot, doc } from "firebase/firestore";
import { db } from "../../firebase";
import "./components.scss";
import useNotifications from "hooks/useNotifications";

const User = ({ user, selectUser, user1, chat }) => {
  const user2 = user?.uid;
  const [data, setData] = useState("");
  const { dataNew, userNew } = useNotifications(user, user1);

  useEffect(() => {
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
    let unsub = onSnapshot(doc(db, "lastMsg", id), (doc) => {
      setData(doc.data());
    });
    return () => unsub();
  }, []);

  if (data?.from !== user1 && data?.unread) {
    localStorage.setItem("bell", JSON.stringify(true));
  } else {
    localStorage.setItem("bell", JSON.stringify(false));
  }

  return (
    <>
      <div
        className={`user_wrapper1 ${
          chat.name === user.name && "selected_user1"
        } `}
        onClick={() => selectUser(user)}
      >
        <div className="user_info1">
          <div className="user_detail1">
            <img
              src={user.avatar || profile_image}
              alt="avatar1"
              className="avatar1"
            />
            <h4> {user.name} </h4>
            {data?.from !== user1 && data?.unread && (
              <small className="unread1">Nuevo!</small>
            )}
          </div>
          <div
            className={`user_status1 ${user.isOnline ? "online1" : "offline1"}`}
          ></div>
        </div>
        {data && (
          <p className="truncate1">
            <strong> {data.from === user1 ? "Yo:" : null} </strong>
            {data.text}
          </p>
        )}
      </div>

      <div
        onClick={() => selectUser(user)}
        className={`sm_container1 ${
          chat.name === user.name && "selected_user1"
        } `}
      >
        <img
          src={user.avatar || profile_image}
          alt="avatar"
          className="avatar1 sm_screen1"
        />
      </div>
    </>
  );
};

export default User;
