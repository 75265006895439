import React, { useContext, useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { ReactComponent as ReactLogo } from "assets/svg/rigth-icon-blue.svg";
import { colors } from "constants/colors";
import { useStateValue } from "context/store";
import { loginUser } from "context/user/actions";
import { navigateTypeForm } from "hooks/login-forms";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import schemaLogin from "schema/schema-login";
import { FirebaseContext } from "server";
import { SnackAlert } from "widgets/snackAlert";
import { LoginNestedInput } from "./login-nested-input";
import { MutatingDots } from "react-loader-spinner";
import { VisitorsControllerContext } from "context/visitor/VisitorsControllerContext";
import { v4 as uuidv4 } from "uuid";
import ForgotPasswordInput from "../forgotPassword/forgot-password";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import "firebase/firestore";

export default function Login({ isFormLogin }) {
  // useState
  const [snackBar, setSnackBar] = useState({
    message: "",
    open: false,
    severity: "",
  });
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [forgotPasswordFlow, setForgotPasswordFlow] = useState(false);
  const { isVisitor, setIsVisitor } = useContext(VisitorsControllerContext);
  const [configData, setConfigData] = useState();

  // react hook form
  const methods = useForm({
    resolver: yupResolver(schemaLogin),
  });

  // firebase
  const firebase = useContext(FirebaseContext);

  // variables
  const { message, open, severity } = snackBar;
  const [
    {
      user: { userById },
    },
    dispatch,
  ] = useStateValue();

  const { current: currentUser } = useRef(
    JSON.parse(localStorage?.getItem("current-user"))
  );

  useEffect(() => {
    setUser(userById ? userById : currentUser);
  }, [currentUser, userById]);

  // navigate
  const navigate = useNavigate();
  let { pathname } = useLocation();

  // functions
  const handleClose = () => {
    setSnackBar({
      message: "",
      open: false,
      severity: "",
    });
  };

  const onSubmit = async (data) => {
    try {
      const response = await loginUser(firebase, dispatch, data);
      if (response) {
        const user = JSON.parse(localStorage.getItem("current-user"));
        if (user?.rol === "notario") {
          // navigate("/app/welcome-companies-list", { replace: true });
        }
        setSnackBar({
          message: "Bienvenido(a) a la notaria 26",
          open: true,
          severity: "success",
        });
      }
    } catch (error) {
      console.error("Login ====> ", error);
      if (error.code === "auth/user-not-found") {
        setSnackBar({
          message: "Usuario no encontrado",
          open: true,
          severity: "error",
        });
      }
      if (error.code === "auth/wrong-password") {
        setSnackBar({
          message: "Contraseña incorrecta",
          open: true,
          severity: "error",
        });
      }
    }
  };

  const onSubmitForm = async (data) => {
    setIsVisitor(false);
    try {
      setLoading(true);
      const response = await loginUser(firebase, dispatch, data);
      setSnackBar({
        message: "Bienvenido(a) a la notaria 26",
        open: true,
        severity: "success",
      });
      navigateTypeForm(
        pathname.split("/").at(-1),
        navigate,
        null,
        JSON.parse(localStorage.getItem("current-user"))
      );
    } catch (error) {
      console.error("Login ====> ", error);
      if (error.code === "auth/user-not-found") {
        setSnackBar({
          message: "Usuario no encontrado",
          open: true,
          severity: "error",
        });
      }
      if (error.code === "auth/wrong-password") {
        setSnackBar({
          message: "Contraseña incorrecta",
          open: true,
          severity: "error",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPassword = (e) => {
    e.preventDefault();
    setForgotPasswordFlow && setForgotPasswordFlow(true);
    console.log("hola");
  };

  const handleVisitorLogin = (e) => {
    e.preventDefault();
    setIsVisitor(true);
    const newId = uuidv4();
    localStorage.setItem("pubnub-uuid", JSON.stringify("Invitado"));
    localStorage.setItem("auth-token", "newId");
    localStorage.setItem(
      "current-user",
      JSON.stringify({
        createdAt: {
          seconds: 0,
          nanoseconds: 0,
        },
        isOnline: true,
        uid: "GZscQaSNxyg4C8oiQrqHuK5TxZq2",
        rol: "user",
        id: "visitor_" + newId,
        typeDocument: "",
        name: "Invitado",
        userId: newId,
        phone: "undefined",
        email: "undefined",
        documentNumber: "undefined",
      })
    );
    navigate("/app/transaction-online");
    setSnackBar({
      message: "Bienvenido(a) a la notaria 26",
      open: true,
      severity: "success",
    });
  };

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <>
      <FormProvider {...methods}>
        <form>
          {forgotPasswordFlow ? (
            <ForgotPasswordInput
              setForgotPasswordFlow={setForgotPasswordFlow}
            />
          ) : (
            <>
              <LoginNestedInput />

              {isFormLogin ? (
                loading ? (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <MutatingDots
                      height="100"
                      width="100"
                      color={configData?.baseColor}
                      secondaryColor={configData?.baseColor}
                      radius="15.5"
                      ariaLabel="mutating-dots-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        background: configData?.baseColor,
                        color: colors.white,
                        borderRadius: 20,
                        fontWeight: "bold",
                        width: 150,
                        display: "flex",
                      }}
                      onClick={methods.handleSubmit(onSubmitForm)}
                    >
                      Iniciar sesión
                    </Button>

                    <Button
                      type="submit"
                      variant="contained"
                      style={{
                        background: configData?.baseColor,
                        color: colors.white,
                        borderRadius: 20,
                        fontWeight: "bold",
                        width: 200,
                        display: "flex",
                        marginLeft: 12,
                      }}
                      onClick={(e) => handleVisitorLogin(e)}
                    >
                      Continuar sin usuario
                    </Button>
                  </div>
                )
              ) : (
                <div
                  style={{
                    marginTop: 20,
                    marginBottom: 20,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      background: configData?.iconsColor,
                      color: configData?.baseColor,
                      borderRadius: 20,
                      fontWeight: "bold",
                      width: 150,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    onClick={methods.handleSubmit(onSubmit)}
                  >
                    Comenzar
                    <ReactLogo />
                  </Button>
                </div>
              )}

              <div style={{ marginTop: 30 }}>
                <p
                  style={{
                    color: "#fff",
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 14,
                    cursor: "pointer",
                  }}
                  onClick={(e) => {
                    handleForgotPassword(e);
                  }}
                >
                  Olvidé mi contraseña
                </p>
              </div>
            </>
          )}
        </form>
      </FormProvider>

      <SnackAlert
        message={message}
        open={open}
        severity={severity}
        handleClose={handleClose}
      />
    </>
  );
}
