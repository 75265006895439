import { useFieldArray } from "react-hook-form";
import useStyles from "./styles";

const NestedArray = ({ nestIndex, control, register }) => {
  const { fields } = useFieldArray({
    control,
    name: `proxies.${nestIndex}.nestedArray`,
  });

  const classes = useStyles();

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {fields.map((item, k) => {
        return (
          <div key={item.id} className={classes.container}>
            <div className={classes.divInput}>
              <label htmlFor="checkbox_field1" style={{ marginRight: 10 }}>
                A
              </label>
              <input
                className="nested-input_checkbox"
                id="checkbox_field1"
                type="checkbox"
                {...register(`proxies.${nestIndex}.nestedArray.${k}.field1`, {
                  required: false,
                })}
                defaultValue={item.field1}
                style={{ marginRight: "25px" }}
              />
            </div>

            <div className={classes.divInput}>
              <label htmlFor="checkbox_field2" style={{ marginRight: 10 }}>
                B
              </label>
              <input
                id="checkbox_field2"
                className="nested-input_checkbox"
                type="checkbox"
                {...register(`proxies.${nestIndex}.nestedArray.${k}.field2`, {
                  required: false,
                })}
                defaultValue={item.field2}
                style={{ marginRight: "25px" }}
              />
            </div>

            <div className={classes.divInput}>
              <label htmlFor="checkbox_field3" style={{ marginRight: 10 }}>
                C
              </label>
              <input
                id="checkbox_field3"
                className="nested-input_checkbox"
                type="checkbox"
                {...register(`proxies.${nestIndex}.nestedArray.${k}.field3`, {
                  required: false,
                })}
                defaultValue={item.field3}
                style={{ marginRight: "25px" }}
              />
            </div>

            <div className={classes.divInput}>
              <label htmlFor="checkbox_field4" style={{ marginRight: 10 }}>
                D
              </label>
              <input
                id="checkbox_field4"
                className="nested-input_checkbox"
                type="checkbox"
                {...register(`proxies.${nestIndex}.nestedArray.${k}.field4`, {
                  required: false,
                })}
                defaultValue={item.field4}
                style={{ marginRight: "25px" }}
              />
            </div>

            <div className={classes.divInput}>
              <label htmlFor="checkbox_field5" style={{ marginRight: 10 }}>
                E
              </label>
              <input
                id="checkbox_field5"
                className="nested-input_checkbox"
                type="checkbox"
                {...register(`proxies.${nestIndex}.nestedArray.${k}.field5`, {
                  required: false,
                })}
                defaultValue={item.field5}
                style={{ marginRight: "25px" }}
              />
            </div>

            <div className={classes.divInput}>
              <label htmlFor="checkbox_field6" style={{ marginRight: 10 }}>
                F
              </label>
              <input
                id="checkbox_field6"
                className="nested-input_checkbox"
                type="checkbox"
                {...register(`proxies.${nestIndex}.nestedArray.${k}.field6`, {
                  required: false,
                })}
                defaultValue={item.field6}
                style={{ marginRight: "25px" }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default NestedArray;
