import CircleInfo from "components/circle-info/Circleinfo";
import { makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { colors } from "constants/colors";
// icons
import DepartmentIcon from "assets/svg/icon-select.svg";
import FoundTransactionIcon from "assets/svg/found-transaction.svg";
import FillFormsIcon from "assets/svg/fill-forms.svg";
import ClickIcon from "assets/svg/click.svg";
import UploadIcon from "assets/svg/upload.svg";
import videoNotary from "../../../../src/video/NOTARIA 26 Genrico (1).mp4";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit, 340px)",
    "@media (min-width: 1199px)": {
      gridTemplateColumns: "repeat(auto-fit, 240px)",
    },
    placeContent: "center",
    marginBottom: 40,
    [theme.breakpoints.down("lg")]: {
      gap: 16,
    },
  },
  title: {
    fontSize: 60,
    marginBottom: 40,
    textAlign: "center",
    color: colors.blue,
    fontWeight: 500,
  },
  subtitle: {
    fontSize: 40,
    textAlign: "center",
  },
  subtitle1: {
    textAlign: "center",
    marginBottom: 40,
    padding: 20,
  },
  videoContainer: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 40,
  },
  videoNotary: {
    maxWidth: "100vh",
    "@media (max-width: 950px)": {
      maxWidth: "90%",
    },
  },
}));

export default function TransactionOnline() {
  const classes = useStyles();

  const infoCircle = [
    {
      id: 1,
      icon: DepartmentIcon,
      title: "Seleccione el departamento correspondiente",
    },
    { id: 2, icon: FoundTransactionIcon, title: "Encuentre su trámite online" },
    {
      id: 3,
      icon: FillFormsIcon,
      title: "Diligencie la información necesaria",
    },
    { id: 4, icon: ClickIcon, title: "De click en enviar" },
    { id: 5, icon: UploadIcon, title: "Cargue sus documentos" },
  ];

  return (
    <div>
      <Typography
        className={classes.title}
        variant="h4"
        gutterBottom
        component="div"
      >
        Centro de Trámites
      </Typography>
      <div className={classes.videoContainer}>
        <video
          className={classes.videoNotary}
          src={videoNotary}
          controls
        ></video>
      </div>
      <Typography
        className={classes.subtitle}
        variant="h6"
        gutterBottom
        component="div"
      >
        Trámites online
      </Typography>

      <Typography
        className={classes.subtitle1}
        variant="subtitle2"
        gutterBottom
        component="div"
      >
        En la Notaría 26 pensamos todo el tiempo en brindarle opciones que le
        permitan agilizar sus trámites notariales.
      </Typography>

      <div className={classes.container}>
        {infoCircle.map((info) => (
          <CircleInfo
            key={info.id}
            icon={info.icon}
            title={info.title}
            id={info.id}
          />
        ))}
      </div>
      <Typography
        className={classes.subtitle1}
        variant="subtitle2"
        gutterBottom
        component="div"
      >
        Usted recibirá la notificación del inicio de su trámite en línea a
        través del correo electrónico que usted nos indique.
      </Typography>
    </div>
  );
}
