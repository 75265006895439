export const onInputNumber = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '')
}

export const getCreatedTimeValue = () => {
    const today = new Date();
    const month = (today.getMonth() + 1) < 10 ? '0' + today.getMonth() + 1 : today.getMonth() + 1
    const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate()
    const hour = today.getHours() < 10 ? '0' + today.getHours() : today.getHours()
    const minute = today.getMinutes() < 10 ? '0' + today.getMinutes() : today.getMinutes()
    const second = today.getSeconds() < 10 ? '0' + today.getSeconds() : today.getSeconds()
    return `${today.getFullYear()}${month}${day}${hour}${minute}${second}`
}