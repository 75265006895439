import { Box, Grid, Typography, Divider } from "@mui/material";
import useStyles from "./style";
import clientConfig from "constants/clientConfig";
// 
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../../firebase'
import "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";

const Footer = () => {

  const [ configData, setConfigData ] = useState();

  const classes = useStyles();

  const linksFooter = [
    { id: 1, title: "Contáctenos" },
    { id: 2, title: "Blog" },
    { id: 3, title: "Mapa del sitio" },
  ];

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <footer className={classes.footer}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={6} sm={6} md={3}>
            <div className={classes.footerResposive}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="secondary"
                className={classes.title}
              >
                Nuestros horarios de atención:
              </Typography>
              <div style={{ display: "flex" }}>
                <img
                  src={require("assets/svg/icon-time.svg").default}
                  alt="time"
                />
                <div style={{ marginLeft: 10 }}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="p"
                    className={classes.paragraph1}
                  >
                    Lunes a viernes de 9:00 a.m. a 18:30 p.m.
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="p"
                    className={classes.paragraph2}
                  >
                    Sábados y domingos cerrados
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <div className={classes.footerResposive}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="secondary"
                className={classes.title}
              >
                Números de contacto:
              </Typography>
              <div style={{ display: "flex" }}>
                <img
                  src={require("assets/svg/icon-phone.svg").default}
                  alt="phone"
                />
                <div style={{ marginLeft: 10 }}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="p"
                    className={classes.paragraph1}
                  >
                    <strong>Teléfono:</strong> +52 (81) 8676 8790
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="p"
                    className={classes.paragraph2}
                  >
                    <strong>WhatsApp:</strong> +52 (81) 2106 4227
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <div className={classes.footerResposive}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                color="secondary"
                className={classes.title}
              >
                Dónde estamos ubicados:
              </Typography>
              <div style={{ display: "flex" }}>
                <img
                  src={require("assets/svg/icon-location.svg").default}
                  alt="location"
                />
                <div style={{ marginLeft: 10 }}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="p"
                    className={classes.paragraph1}
                  >
                    Dr. José Luna Ayala #204. Piso 2,
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="p"
                    className={classes.paragraph2}
                  >
                    Colonia San Jerónimo. Cómo llegar
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <div className={classes.socialMedia}>
              <img
                src={require("assets/svg/facebook.svg").default}
                alt="facebook"
              />
              <img
                src={require("assets/svg/instagram.svg").default}
                alt="instagram"
              />
              <img
                src={require("assets/svg/linkedin.svg").default}
                alt="linkedin"
              />
            </div>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex" }}>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 6, sm: 12, md: 12 }}
            >
              {linksFooter.map((link) => (
                <Grid item xs={6} sm={6} md={4} key={link.id}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    key={link.id}
                    className={classes.linksFooter}
                  >
                    {link.title}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </div>

          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            className={classes.rightsReserved}
          >
            Ⓒ {configData?.name}, Todos los Derechos Reservados
          </Typography>
        </div>
      </Box>
    </footer>
  );
};

export default Footer;
