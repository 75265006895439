import { string, object } from "yup";

const schemaProtocolizationOne = object({
  companyName: string().required("Este campo es obligatorio"),
  typeReport: string().required("Este campo es obligatorio"),
  assembleResolutions: string().required("Este campo es obligatorio"),
  actAssembly: string().required("Este campo es obligatorio"),
}).required();

export default schemaProtocolizationOne;
