import { string, object, ref } from "yup";

const schema = object({
  email: string().required("Este campo es obligatorio").email("Email invalido"),
  password: string()
    .required("Contraseña requerida")
    .min(6, "La contraseña debe contener al menos 6 caracteres"),
  passwordConfirm: string()
    .required("Confirmar contraseña")
    .oneOf([ref("password")], "Las contraseñas no coinciden"),
}).required();

export default schema;
