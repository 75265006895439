import { useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { InputRadio } from "components/inputs/input-radio";
import { colors } from "constants/colors";
import { SimpleInputSelect } from "components/inputs/simple-input-select";
import StatesMunicipalities from "constants/bd";
import { labelsInability } from "./constants";
import { onInputNumber } from "utils";
import { InputEmail } from "components/inputs/input-email";

export const ThreeNestedInput = ({ value }) => {
    const {
        control,
        formState: { errors },
        watch,
    } = useFormContext(); // retrieve all hook methods

    const watchState = watch(`stateHome`);

    const states = StatesMunicipalities.map((state) => state[0]);
    let municipalities = [];
    if (watchState) {
        municipalities = StatesMunicipalities.filter(
            (item) => watchState === item[0]
        )[0][1];
    }

    return (
        <>
            <div className="divider">
                <Typography
                    gutterBottom
                    color={colors.blue}
                    style={{ fontSize: 40, fontWeight: "bold" }}
                >
                    Datos generales del delegado
                </Typography>
            </div>

            <div style={{ marginTop: 30, marginBottom: 30 }}>
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    color={colors.blue}
                >
                    Los campos marcados con * son obligatorios
                </Typography>
            </div>

            <div className="divider">
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    color={colors.blue}
                >
                    Domicilio
                </Typography>
            </div>

            <div className="divider">
                <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography
                            className="subtitle2"
                            variant="subtitle2"
                            gutterBottom
                            style={styles.title}
                        >
                            Calle y número *
                        </Typography>
                        <InputText
                            name="street"
                            value={value.street}
                            control={control}
                            errors={errors}
                        />
                        {errors.street && (
                            <p style={styles.errors}>{errors.street.message}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography
                            className="subtitle2"
                            variant="subtitle2"
                            gutterBottom
                            style={styles.title}
                        >
                            Colonia *
                        </Typography>
                        <InputText
                            name="colony"
                            value={value.colony}
                            control={control}
                            errors={errors}
                        />
                        {errors.colony && (
                            <p style={styles.errors}>{errors.colony.message}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography
                            className="subtitle2"
                            variant="subtitle2"
                            gutterBottom
                            style={styles.title}
                        >
                            CP *
                        </Typography>
                        <InputText
                            name="CP"
                            value={value.CP}
                            control={control}
                            errors={errors}
                        />
                        {errors.CP && (
                            <p style={styles.errors}>{errors.CP.message}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography
                            className="subtitle2"
                            variant="subtitle2"
                            gutterBottom
                            style={styles.title}
                        >
                            Estado *
                        </Typography>
                        <SimpleInputSelect
                            name="stateHome"
                            value={value.stateHome}
                            control={control}
                            errors={errors}
                            labels={states}
                            placeholder="--Estado--"
                        />
                        {errors.stateHome && (
                            <p style={styles.errors}>
                                {errors.stateHome.message}
                            </p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                        <Typography
                            className="subtitle2"
                            variant="subtitle2"
                            gutterBottom
                            style={styles.title}
                        >
                            Municipio *
                        </Typography>
                        <SimpleInputSelect
                            name={`municipalityHome`}
                            value={value.municipalityHome}
                            control={control}
                            errors={errors}
                            labels={municipalities}
                            disabled={!municipalities.length}
                            placeholder="--Municipio--"
                        />
                        {errors.municipalityHome && (
                            <p style={styles.errors}>
                                {errors.municipalityHome.message}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div className="divider">
                <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography
                            className="subtitle2"
                            variant="subtitle2"
                            gutterBottom
                            style={styles.title}
                        >
                            Teléfono *
                        </Typography>
                        <InputText
                            name="phone"
                            onInput={onInputNumber}
                            value={value.phone}
                            control={control}
                            errors={errors}
                        />
                        {errors.phone && (
                            <p style={styles.errors}>{errors.phone.message}</p>
                        )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Typography
                            className="subtitle2"
                            variant="subtitle2"
                            gutterBottom
                            style={styles.title}
                        >
                            Correo electrónico *
                        </Typography>
                        <InputEmail
                            name={`email`}
                            value={value?.email}
                            control={control}
                            type="email"
                            errors={errors}
                            className="input-email"
                        />
                        {errors.email && (
                            <p style={styles.errors}>{errors.email.message}</p>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div className="divider">
                <Typography
                    className="subtitle2"
                    variant="subtitle2"
                    gutterBottom
                    style={styles.title}
                >
                    Incapacidad
                </Typography>
                <Grid
                    container
                    spacing={{ xs: 2, md: 3 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                >
                    <Grid item xs={12} sm={6} md={6}>
                        <InputRadio
                            name={`inability`}
                            value={value.inability}
                            control={control}
                            errors={errors}
                            labels={labelsInability}
                        />
                        {errors.inability && (
                            <p style={styles.errors}>
                                {errors.inability.message}
                            </p>
                        )}
                    </Grid>
                    {watch().inability === "Otra" && (
                        <Grid item xs={12} sm={6} md={3}>
                            <InputText
                                name="otherInability"
                                value={value.otherInability}
                                control={control}
                                errors={errors}
                            />
                        </Grid>
                    )}
                </Grid>
            </div>
        </>
    );
};

const styles = {
    title: {
        color: colors.blue,
        fontWeight: "bold",
    },
    errors: {
        padding: "5px 15px",
        fontSize: 14,
        borderRadius: 15,
        marginTop: -1,
        borderTopLeftRadius: 2,
        backgroundColor: colors.dangerLight,
        display: "inline-block",
        border: `1px solid ${colors.danger}`,
        color: colors.danger,
    },
};
