import { Controller } from "react-hook-form";
import DropzoneComponent from "components/dropzone-component/dropzone-component";

export const InputFile = ({
  name,
  setValue,
  control,
  hidden,
  setFiles,
  multiple,
  currentFiles,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={() => (
        <DropzoneComponent
          multiple={multiple || false}
          maxSize={20971520}
          currentFiles={currentFiles}
          acceptedFiles={[".xlsx", ".xls", ".pdf"]}
          setValue={setValue}
          name={name}
          setFiles={setFiles}
        />
      )}
    />
  );
};
