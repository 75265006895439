import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles((theme) => ({
  card: {
    marginBottom: 40,
    boxShadow: "0px 10px 30px #27476C29",
    borderRadius: "12px",
    padding: "0px 10px",
    cursor: "pointer",
    backgroundColor: (props) =>
      props.isSelected
        ? `${props.baseColor} !important`
        : `${colors.white} !important`,
    transition: ".3s all !important",
    "&:hover": {
      backgroundColor: (props) => `${props.hoverNavColor} !important`,
    },
  },
  cardLogo: {
    marginLeft: "5px",
    [theme.breakpoints.down("sm")]: {
      marginRight: "5px !important",
      width: "28px !important",
    },
  },
  container: {
    [theme.breakpoints.down("sm")]: {
      width: "initial",
    },
    display: "flex",
    alignItems: "center",
  },
  title: {
    color: (props) =>
      props.isSelected
        ? `${colors.white} !important`
        : `${props.baseColor} !important`,
    fontFamily: "poppinsmedium",
    fontWeight: "bold",
    marginLeft: "20px",
  },
  containerCardMedia: {
    width: 80,
    [theme.breakpoints.down("sm")]: {
      width: 60,
    },
    height: 80,
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "& img": {
      width: 44,
      height: 40,
      objectFit: "contain",
    },
  },
}));