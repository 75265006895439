import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

export default makeStyles(() => ({
  cardTransaction: {
    position: "relative",
    marginTop: 30,
    padding: 30,
    overflow: "initial",
    boxShadow: "2px 2px 8px 1px rgb(0 0 0 / 20%)",
    '& figure': {
      position: "absolute",
      left: "50%",
      width: 110,
      height: 110,
      top: -30,
      marginLeft: -55,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: colors.lightskyblue,
      borderRadius: "50%",
      '& img': {
        width: 60,
        height: 60,
        objectFit: "contain",
      },
    }
  },
  cardContent: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  title: {
    color: colors.blue,
    fontWeight: "bold",
    width: 140,
    textAlign: "center",
    fontSize: 18
  },
  subtitle: {
    fontSize: 14,
    color: colors.black,
    textAlign: "center",
    marginTop: 10,
    width: 270,
  },
  textInfo: {
    color: colors.blue,
    marginTop: 15,
    fontSize: 14,
    fontWeight: "bold"
  },
  btnStart: {
    background: colors.blue,
    borderRadius: 20,
    fontWeight: "bold",
    width: 150,
    display: "flex",
    justifyContent: "space-between"
  }
}));