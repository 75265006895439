import React from 'react'

function ErrorComponent({ error }) {

  return (
    <div 
        style={
            {
                backgroundColor: '#FFEDED',
                fontSize: '12px',
                marginTop: '-5px',
                marginBottom: '10px',
                borderRadius: '0px 16px 16px 16px',
                borderColor: '#FD4C4C',
                width: '220px',
                height: '20px',
                padding: '3px 0px',
                border: '1px solid #FD4C4C',
                position: 'relative',
                zIndex: '1',
            }
        }
    >
        <p
            style={
                {
                    margin: '0px',
                    padding: '0px',
                    color: '#FD4C4C',
                    textAlign: 'center',
                    fontWeight: 'regular',
                    fontSize: '12px',
                }
            }
        >
            {error}
        </p>
    </div>
  )
}

export default ErrorComponent