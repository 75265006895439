import { useCallback } from "react";
import useStyles from "./styles";
import { Typography, Box, Grid, Button, Container } from "@mui/material";
import YoutubeEmbed from "components/youtube-embed/youtube-embed";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase'
import "firebase/firestore";

const DonationMoney = () => {
  const navigate = useNavigate();

  const [ configData, setConfigData ] = useState();

  const classes = useStyles({ baseColor: configData?.baseColor });

  const handleOnClick = useCallback(
    () => navigate("/app/form-donation", { replace: true }),
    [navigate]
  );

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <div className={classes.container}>
      <Container>
        <Typography
          variant="h2"
          gutterBottom
          component="div"
          className={classes.title}
        >
          Donación de Dinero
        </Typography>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 8 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Trámite legal que se realiza entre parientes de primer grado que
                desean realizar donaciones voluntarias, libres de impuestos, a
                sus hijos.
              </Typography>

              <div className={classes.contentDocuments}>
                <Typography variant="subtitle1" gutterBottom>
                  Documentos necesarios
                </Typography>
                <ul className={classes.ul}>
                  <li>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      className={classes.textLi}
                    >
                      En caso de estar casado presentar acta de matrimonio.
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      className={classes.textLi}
                    >
                      En caso de ser extranjero: Pasaporte y Forma migratoria
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      className={classes.textLi}
                    >
                      En caso de identificarse con pasaporte o si su domicilio
                      no coincide con el que menciona la credencial para votar
                      debe proporcionar el comprobante de domicilio actual
                    </Typography>
                  </li>
                  <li>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      className={classes.textLi}
                    >
                      En caso de ser representante legal de sociedad, adjuntar
                      constitutiva, cambios realizados, poder y cédula de la
                      empresa.
                    </Typography>
                  </li>
                </ul>
              </div>

              <div className={classes.containerButton}>
                <Button
                  variant="contained"
                  className={classes.btnNextStep}
                  onClick={handleOnClick}
                >
                  Comenzar
                  <RigthIcon />
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <div className={classes.video}>
                <YoutubeEmbed embedId="rokGy0huYEA" />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default DonationMoney;
