import { useMemo, useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { InputRadio } from "components/inputs/input-radio";
import { Grid, Typography } from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { InputSelect } from "components/inputs/input-select";
import { InputEmail } from "components/inputs/input-email";
import {
  labelsInability,
  labelsIsMarried,
  labelsMaritalStatus,
  partnerIdentified,
} from "./constants";
import { InputDatePicker } from "components/inputs/input-date-picker";
import { Button } from "@mui/material";
import { colors } from "constants/colors";
import { InputFile } from "components/inputs/input-file";
import countryList from "react-select-country-list";
import StatesMunicipalities from "constants/bd";
import { SimpleInputSelect } from "components/inputs/simple-input-select";
import ShowFiles from "components/show-files/show-files";
import { onInputNumber } from "utils";
import { State } from "country-state-city";

export const FourNestedInput = ({ index, item, remove, reset }) => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext(); // retrieve all hook methods
  // useState Files
  const [file, setFile] = useState([]);
  const [homeFile, setHomeFile] = useState([]);
  const [statesByCountry, setStatesByCountry] = useState([]);

  const removeFile = () => {
    setFile([]);
  };

  const removeHomeFile = () => {
    setHomeFile([]);
  };

  const isMarried =
    watch(`shareholder[${index}].maritalStatus`) === "Casado(a)";
  const inability = watch(`shareholder[${index}].inability`) === "otra";
  const nationality = watch(`shareholder[${index}].nacionality`);

  useEffect(() => {
    if (nationality && nationality !== "") {
      setStatesByCountry(
        State?.getStatesOfCountry(countryList()?.getValue(nationality))?.map(
          (state) => {
            return {
              value: state?.isoCode,
              label: state?.name,
            };
          }
        )
      );
    }
  }, [nationality]);

  const watchState = watch(`shareholder[${index}].state`);
  const options = useMemo(() => countryList().getData(), []);

  const states = StatesMunicipalities.map((state) => state[0]);
  let municipalities = [];
  if (watchState) {
    municipalities = StatesMunicipalities.filter(
      (item) => watchState === item[0]
    )[0][1];
  }

  return (
    <div className="contain-shareholder">
      <div style={{ marginBottom: 20 }}>
        <Typography variant="subtitle2" color={colors.blue}>
          Los campos marcados con * son obligatorios
        </Typography>
      </div>
      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Nombres *
            </Typography>
            <InputText
              name={`shareholder[${index}].name`}
              value={item?.name || item?.nameShareholder}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.name && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.name?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Apellido paterno *
            </Typography>
            <InputText
              name={`shareholder[${index}].paternalSurname`}
              value={item.paternalSurname}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.paternalSurname && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.paternalSurname?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Apellido materno *
            </Typography>
            <InputText
              name={`shareholder[${index}].maternalSurname`}
              value={item.maternalSurname}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.maternalSurname && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.maternalSurname?.message}
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Nacionalidad *
            </Typography>
            <InputSelect
              name={`shareholder[${index}].nacionality`}
              value={item.nacionality}
              control={control}
              errors={errors}
              labels={options}
            />
            {errors?.shareholder?.[index]?.nacionality && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.nacionality?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Lugar de nacimiento *
            </Typography>
            <InputSelect
              name={`shareholder[${index}].placeBirth`}
              value={item.placeBirth}
              control={control}
              errors={errors}
              labels={statesByCountry}
            />
            {errors?.shareholder?.[index]?.placeBirth && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.placeBirth?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Fecha de nacimiento *
            </Typography>
            <InputDatePicker
              name={`shareholder[${index}].dateBirth`}
              value={item.dateBirth}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.dateBirth && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.dateBirth?.message}
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          style={styles.title}
        >
          Estado civil *
        </Typography>
        <InputRadio
          name={`shareholder[${index}].maritalStatus`}
          value={item.maritalStatus}
          control={control}
          errors={errors}
          labels={labelsMaritalStatus}
        />
        {errors?.shareholder?.[index]?.maritalStatus && (
          <div style={styles.errors}>
            {errors?.shareholder?.[index]?.maritalStatus?.message}
          </div>
        )}

        {isMarried && (
          <Grid item xs={2} sm={4} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Selecciona el tipo de regimen *
            </Typography>
            <InputSelect
              name={`shareholder[${index}].isMarried`}
              value={item.isMarried}
              control={control}
              errors={errors}
              labels={labelsIsMarried}
            />
            {errors?.shareholder?.[index]?.isMarried && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.isMarried?.message}
              </div>
            )}
          </Grid>
        )}
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Ocupación *
            </Typography>
            <InputText
              name={`shareholder[${index}].ocupation`}
              value={item.ocupation}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.ocupation && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.ocupation?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              RFC *
            </Typography>
            <InputText
              name={`shareholder[${index}].RFC`}
              value={item.RFC}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.RFC && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.RFC?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              CURP *
            </Typography>
            <InputText
              name={`shareholder[${index}].CURP`}
              value={item.CURP}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.CURP && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.CURP?.message}
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <div className="divider">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          style={styles.title}
        >
          Identificación *
        </Typography>
        <InputRadio
          name={`shareholder[${index}].identification`}
          control={control}
          errors={errors}
          labels={partnerIdentified}
        />
        {errors?.shareholder?.[index]?.identification && (
          <div style={styles.errors}>
            {errors?.shareholder?.[index]?.identification?.message}
          </div>
        )}
      </div>

      <div className="divider">
        <Grid item xs={12} sm={12} md={2}></Grid>
      </div>

      <div className="divider">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
          color={colors.blue}
        >
          Domicilio *
        </Typography>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Calle y número *
            </Typography>
            <InputText
              name={`shareholder[${index}].streetNumber`}
              value={item.streetNumber}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.streetNumber && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.streetNumber?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Colonia *
            </Typography>
            <InputText
              name={`shareholder[${index}].colony`}
              value={item.colony}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.colony && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.colony?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Estado *
            </Typography>
            <SimpleInputSelect
              name={`shareholder[${index}].state`}
              value={item.state}
              control={control}
              errors={errors}
              labels={states}
            />
            {errors?.shareholder?.[index]?.state && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.state?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Municipio *
            </Typography>
            <SimpleInputSelect
              name={`shareholder[${index}].municipality`}
              value={item.state}
              control={control}
              errors={errors}
              labels={municipalities}
              disabled={!municipalities.length}
            />
            {errors?.shareholder?.[index]?.municipality && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.municipality?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              C.P. *
            </Typography>
            <InputText
              name={`shareholder[${index}].CP`}
              value={item.CP}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.CP && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.CP?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            <div>
              <Typography
                variant="body1"
                gutterBottom
                style={{ visibility: "hidden" }}
              >
                Comprobante domicilio
              </Typography>
              <Button
                variant="contained"
                component="label"
                style={{
                  background: colors.blue,
                  color: colors.white,
                  borderRadius: 20,
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "space-between",
                  marginRight: 20,
                  alignItems: "center",
                }}
              >
                <InputFile
                  name={`shareholder[${index}].homeFile`}
                  setValue={setValue}
                  control={control}
                  hidden={true}
                  setFiles={setHomeFile}
                />

                <span>Comprobante de domicilio</span>
                <div style={{ marginLeft: 30 }}>+</div>
              </Button>
            </div>

            <ShowFiles
              valueStateGlobal={item?.homeFile}
              valueStateLocal={homeFile}
              removeFile={removeHomeFile}
            />
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Teléfono *
            </Typography>
            <InputText
              onInput={onInputNumber}
              name={`shareholder[${index}].phone`}
              value={item.phone}
              control={control}
              errors={errors}
            />
            {errors?.shareholder?.[index]?.phone && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.phone?.message}
              </div>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Correo electrónico *
            </Typography>
            <InputEmail
              name={`shareholder[${index}].email`}
              value={item?.email}
              control={control}
              type="email"
              errors={errors}
              className="input-email"
            />
            {errors?.shareholder?.[index]?.email && (
              <div style={styles.errors}>
                {errors?.shareholder?.[index]?.email?.message}
              </div>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          style={styles.title}
        >
          ¿Cuentas con algún tipo de incapacidad? *
        </Typography>
        <InputRadio
          name={`shareholder[${index}].inability`}
          value={item.inability}
          control={control}
          errors={errors}
          labels={labelsInability}
        />
        {inability && (
          <InputText
            name={`shareholder[${index}].inabilityDescription`}
            placeholder={"Describe tu incapacidad."}
            control={control}
            errors={errors}
          />
        )}
        {errors?.shareholder?.[index]?.inability && (
          <div style={styles.errors}>
            {errors?.shareholder?.[index]?.inability?.message}
          </div>
        )}
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={4} md={4}>
            <Button
              variant="contained"
              component="label"
              style={{
                background: colors.blue,
                color: colors.white,
                borderRadius: 20,
                fontWeight: "bold",
                display: "flex",
                justifyContent: "space-between",
                marginRight: 20,
              }}
            >
              <InputFile
                name={`shareholder[${index}].file`}
                setValue={setValue}
                control={control}
                hidden={true}
                setFiles={setFile}
              />
              Documento de identificación
              <span style={{ marginLeft: 30 }}>+</span>
            </Button>

            <ShowFiles
              valueStateGlobal={item?.file}
              valueStateLocal={file}
              removeFile={removeFile}
            />
          </Grid>

          <Grid item xs={4} sm={4} md={4}>
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                remove(index);
              }}
              style={{
                background: colors.white,
                color: colors.blue,
                border: `1px solid ${colors.blue}`,
                borderRadius: 20,
                fontWeight: "bold",
              }}
            >
              Eliminar Accionista/Socio
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

const styles = {
  title: {
    color: colors.darkblue,
    fontWeight: "bold",
  },
  errors: {
    padding: "5px 15px",
    fontSize: 14,
    borderRadius: 15,
    marginTop: -1,
    borderTopLeftRadius: 2,
    backgroundColor: colors.dangerLight,
    display: "inline-block",
    border: `1px solid ${colors.danger}`,
    color: colors.danger,
  },
};
