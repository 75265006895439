import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MUIDataTable, { TableFilterList } from "mui-datatables";
import Chip from "@mui/material/Chip";

export const DataTable = ({ data = [], columns = [], title }) => {

  const [ dataTableView, setDataTableView ] = useState([]);

  useEffect(() => {
    data.forEach((item) => {
      item.currentUser = item[0]?.name;
      item.fullName = `${item[0]?.name} ${item[0]?.lastName}`;
      item.email = item[0]?.email;
      item.CURP = item[0]?.curp;
      item.CP = item[0]?.cp;
    }
    );
    setDataTableView(data);
  }, [data]);

  // console.log(dataTableView)

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive: "standard",
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableBodyCell: {
          styleOverrides: {
            root: {
              backgroundColor: "#FFFFFF",
            },
            head: {
              backgroundColor: "red !important",
              color: "green",
            },
          },
        },
        MuiPaper: {
          styleOverrides: {
            root: {
              display: "initial",
            },
          },
        },
      },
    });

  const CustomChip = ({ label, onDelete }) => {
    return (
      <Chip
        variant="outlined"
        color="secondary"
        label={label}
        onDelete={onDelete}
      />
    );
  };

  const CustomFilterList = (props) => {
    return <TableFilterList {...props} ItemComponent={CustomChip} />;
  };

  return (
    <ThemeProvider theme={getMuiTheme}>
      <MUIDataTable
        data={dataTableView}
        columns={columns}
        options={options}
        components={{
          TableFilterList: CustomFilterList,
        }}
      />
    </ThemeProvider>
  );
};
