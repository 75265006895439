import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// import 'firebase/compat/storage';
// import 'firebase/functions';

const config = {
  apiKey: "AIzaSyBEWgx5gi5ODBZalM88t8vITwEQJs0spNU",
  authDomain: "notaria-26-5a633.firebaseapp.com",
  projectId: "notaria-26-5a633",
  storageBucket: "notaria-26-5a633.appspot.com",
  messagingSenderId: "45175691777",
  appId: "1:45175691777:web:39c1ae249efbbe68997e00",
  measurementId: "G-HM4XVHE7HL"
};

class Firebase {
  constructor() {
    firebase.initializeApp(config);
    this.firebase = firebase;
    this.db = firebase.firestore();
    // this.storage = firebase.storage();
    this.auth = firebase.auth();
    // this.functions = firebase.functions();
  }
}

export default Firebase;
