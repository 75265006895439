import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles((theme) => ({
  nav: {
    borderBottom: `1px solid ${colors.lightgrey}`,
    [theme.breakpoints.down("md")]: {
      paddingBottom: 15,
    },
  },
  items: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      paddingTop: 0,
      flexDirection: "column",
      marginTop: "-20px",
      width: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
  },
  organizedItems: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
      width: "100% !important",
      paddingRight: "20px !important",
      paddingTop: "10px !important",
    },
  },
  bannerOptions: {
    width: "auto",
    margin: "auto 0",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      width: "60%",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      padding: 0,
      marginLeft: 20,
    },
  },
  container: {
    margin: "13px auto",
    cursor: "pointer",
  },
  contentText: {
    display: "block",
    marginRight: 10,
    marginLeft: 20,
    [theme.breakpoints.down("md")]: {
      marginLeft: "0 !important",
    },
  },
  textName: {
    fontWeight: "bold",
    textAlign: "end",
    fontSize: 14,
    cursor: "pointer",
    marginBottom: 3,
  },
  logOut: {
    textAlign: "end",
    fontSize: 12,
    cursor: "pointer",
    marginTop: 7,
  },
  buttons: {
    fontWeight: "bold",
    padding: "6px 18px",
    margin: "0 10px",
    borderRadius: "30px",
    fontFamily: "poppinsregular",
    height: "40px",
    position: "relative",
    "&:hover": {
      backgroundColor: "#E2E2E2",
    },
    [theme.breakpoints.down("md")]: {
      height: "20px",
      textAlign: "left",
      marginLeft: "8px !important",
    },
  },
  chatButton: {
    color: "#1759A5",
    fontWeight: "bold",
    padding: "6px 18px",
    margin: "0 10px",
    borderRadius: "30px",
    fontFamily: "poppinsregular",
    height: "40px",
    position: "relative",
    "&:hover": {
      backgroundColor: "#E2E2E2",
    },
    [theme.breakpoints.down("md")]: {
      position: "absolute",
      height: "20px",
      textAlign: "left",
      left: 0,
      margin: 0,
    },
  },
  loginButton: {
    color: "#1759A5",
    border: "1px solid #1759A5",
    padding: "6px 35px",
    margin: "0 10px",
    fontWeight: "bold",
    borderRadius: "30px",
    height: "40px",
    fontFamily: "avenirnextbold",
    "&:hover": {
      backgroundColor: "#E2E2E2",
    },
    [theme.breakpoints.down("md")]: {
      height: "20px",
      textAlign: "left",
      marginLeft: "8px !important",
      border: "none",
      padding: "6px 18px",
    },
  },

  userAvatar: {
    width: 50,
    height: 49,
  },
  newMessages: {
    backgroundColor: "#72D8F7",
    color: "fff",
  },
}));
