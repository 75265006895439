import { useContext, useEffect, useState, useRef } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStateValue } from "context/store";
import { FirebaseContext } from "server";
import {
  getCompanyById,
  getShareholdersById,
  updateCompanyById,
} from "context/company/actions";
import { colors } from "constants/colors";
import { SnackAlert } from "widgets/snackAlert";
import PopupStateUi from "components/popup-state-ui/popup-state-ui";
import { CompanyFormationIconBlue } from "widgets/icons";

const TransactionOnlineDetail = () => {
  let { companyId } = useParams();
  const navigate = useNavigate();

  // firebase
  const firebase = useContext(FirebaseContext);

  const [
    {
      company: { companyById, shareholdersById },
      user: { userById },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (currentUser?.rol !== "notario") {
      navigate("/app/form-login", {
        replace: true,
        state: {
          src: window.location.href,
        },
      });
    }
  }, [userById]);

  const { current: currentUser } = useRef(
    JSON.parse(localStorage.getItem("current-user"))
  );

  const typeStatus = {
    pending: "pendiente",
    cancelled: "cancelado",
    finished: "finalizado",
  };

  // useState
  const [status, setStatus] = useState(companyById.status);
  const [snackBar, setSnackBar] = useState({
    message: "",
    open: false,
    severity: "",
  });

  const { message, open, severity } = snackBar;

  useEffect(() => {
    getCompanyById(companyId, firebase, dispatch);
  }, [companyId, firebase, dispatch]);

  useEffect(() => {
    getShareholdersById(companyId, firebase, dispatch);
  }, [companyId, firebase, dispatch]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    border: `1px solid ${colors.blue}`,
    color: colors.black,
    minHeight: '20px',
  }));

  const handleChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await updateCompanyById(companyId, firebase, dispatch, newStatus);
    setSnackBar({
      message: `El estado ha cambiado a ${typeStatus[newStatus]}`,
      open: true,
      severity: "success",
    });
  };

  const handleClose = () => {
    setSnackBar({
      message: "",
      open: false,
      severity: "",
    });
  };

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }} style={{ marginTop: 50 }}>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="subtitle2"
          >
            Regresar
          </Typography>
        </div>
        <div className="divider"></div>

        <PopupStateUi
          icon={CompanyFormationIconBlue}
          title="Constitución de sociedades"
          status={status || companyById.status}
          handleChange={handleChange}
        />

        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          Otros Documentos
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Pdf
            </Typography>
            <Item>
              <Button href={companyById?.urlPdf} download>
                Descargar
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <div className="divider"></div>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 50 }}>
        {/* Tipo de sociedad */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          1. Tipo de sociedad
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{companyById?.typeSociety}</Item>
          </Grid>
        </Grid>
        {companyById?.typeSociety === "S.A. De C.V." && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography
                  color={colors.black}
                  fontWeight="bold"
                  mt={1}
                  mb={1}
                >
                  Socio #1.
                </Typography>
                <Item>{companyById?.sacvpartner1}</Item>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  color={colors.black}
                  fontWeight="bold"
                  mt={1}
                  mb={1}
                >
                  Socio #2.
                </Typography>
                <Item>{companyById?.sacvpartner2}</Item>
              </Grid>
            </Grid>
          </>
        )}
        <div className="divider"></div>
        {/* Denominación o razón social */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          2. Denominación o razón social
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{companyById?.denomination1}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{companyById?.denomination2}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{companyById?.denomination3}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{companyById?.denomination4}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{companyById?.denomination5}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Denominación o razón social */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          3. Objeto social
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{companyById?.socialObject}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Domicilio social */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          4. Domicilio social
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{companyById?.registeredOffice}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Capital social */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          5. Capital social
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography color={colors.black} fontWeight="bold" mt={1} mb={1}>
              Monto en pesos
            </Typography>
            <Item>{companyById?.amountPesos}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              fontWeight="bold"
            >
              Socio o accionista
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              fontWeight="bold"
            >
              Porcentaje de participación
            </Typography>
          </Grid>
        </Grid>

        {/* Capital social participationRate */}
        {companyById?.participationRate?.map((capital, index) => (
          <Grid container spacing={2} style={{ marginBottom: 20 }} key={index}>
            <Grid item xs={6}>
              <Item>{capital.nameShareholder}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{capital.percentShareholder}%</Item>
            </Grid>
          </Grid>
        ))}

        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          Acciones
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{companyById?.action}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{companyById?.valueEachAction}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Nacionalidad */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          6. Nacionalidad
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{companyById?.nacionality}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Administración */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          7. Administración
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography color={colors.black} fontWeight="bold" mt={1} mb={1}>
              Nombre de gerente, administrador único o director
            </Typography>
            <Item>{companyById?.administration}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Facultades */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          8. Facultades
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{companyById?.administration}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Apoderados */}
        <Grid container>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Apoderados
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Para ejercer de forma individual
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Para ejercer de forma mancomunada
            </Typography>
          </Grid>
        </Grid>

        {companyById?.proxies?.length > 0 &&
          companyById.proxies.map((proxi, index) => (
            <Grid
              container
              spacing={2}
              style={{ marginBottom: 20 }}
              key={index}
            >
              <Grid item xs={2}>
                <Item>
                  <strong>{proxi.takenOver}</strong>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item>
                  <strong>
                    {proxi?.nestedArray?.map((nested) => {
                      const proxies = [];
                      if (nested.field1) proxies.push("Pleitos y cobranzas");
                      if (nested.field2)
                        proxies.push("Actos de administración");
                      if (nested.field3) proxies.push("Cuentas bancarias");
                      if (nested.field4) proxies.push("Actos de dominio");
                      if (nested.field5) proxies.push("Cambiario");
                      if (nested.field6) proxies.push("Facultad de delegación");
                      return proxies.map((item) => `${item} - `);
                    })}
                  </strong>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item>
                  <strong>
                    {proxi?.v2nestedArray?.map((nested) => {
                      const proxies = [];
                      if (nested.field1) proxies.push("Pleitos y cobranzas");
                      if (nested.field2)
                        proxies.push("Actos de administración");
                      if (nested.field3) proxies.push("Cuentas bancarias");
                      if (nested.field4) proxies.push("Actos de dominio");
                      if (nested.field5) proxies.push("Cambiario");
                      if (nested.field6) proxies.push("Facultad de delegación");
                      return proxies.map((item) => `${item} - `);
                    })}
                  </strong>
                </Item>
              </Grid>
            </Grid>
          ))}
        <div className="divider"></div>
        {/* Facultades */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          fontWeight="bold"
        >
          9. Vigilancia: comisario
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography color={colors.black} fontWeight="bold" mt={1} mb={1}>
              Nombre
            </Typography>
            <Item>{companyById?.nameCommissioner}</Item>
          </Grid>
          <Grid item xs={6}>
            <Typography color={colors.black} fontWeight="bold" mt={1} mb={1}>
              RFC
            </Typography>
            <Item>{companyById?.RFCCommissioner}</Item>
          </Grid>
          <Grid item xs={6}>
            <Typography color={colors.black} fontWeight="bold" mt={1} mb={1}>
              Comentarios y observaciones
            </Typography>
            <Item>{companyById?.commentsCommissioner}</Item>
          </Grid>
        </Grid>

        <div className="divider"></div>
        {/* accionistas */}
        {shareholdersById?.map((shareholder, index) => {
          let dateBirth = new Date(shareholder.dateBirth);
          var dateMomentObject = moment(dateBirth).locale("es").format("L");

          return (
            <Box
              key={shareholder.id}
              sx={{
                padding: 5,
                border: "1px solid #E2E2E2",
                borderRadius: 10,
                marginTop: 5,
              }}
            >
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="subtitle2"
              >
                Accionista {index + 1}
              </Typography>
              <div className="divider"></div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Nombres
                  </Typography>
                  <Item>{shareholder.name}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Apellido paterno
                  </Typography>
                  <Item>{shareholder.paternalSurname}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Apellido materno
                  </Typography>
                  <Item>{shareholder.maternalSurname}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Nacionalidad
                  </Typography>
                  <Item>{shareholder.nacionality}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Lugar de nacimiento
                  </Typography>
                  <Item>{shareholder.placeBirth}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Fecha de nacimiento
                  </Typography>
                  <Item>{dateMomentObject}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Estado civil
                  </Typography>
                  <Item>{shareholder.maritalStatus}</Item>
                </Grid>
                {shareholder.isMarried && (
                  <Grid item xs={4}>
                    <Typography
                      color={colors.black}
                      fontWeight="bold"
                      mt={1}
                      mb={1}
                    >
                      Estado civil
                    </Typography>
                    <Item>{shareholder.isMarried}</Item>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Ocupación
                  </Typography>
                  <Item>{shareholder.ocupation}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    RFC
                  </Typography>
                  <Item>{shareholder.RFC}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    CURP
                  </Typography>
                  <Item>{shareholder.CURP}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    CURP
                  </Typography>
                  <Item>{shareholder.CURP}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Identificación
                  </Typography>
                  <Item>{shareholder.identification}</Item>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Documento de identidad
                  </Typography>
                  <Item>
                    <Button href={shareholder.file} download>
                      Descargar
                    </Button>
                  </Item>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Calle y número
                  </Typography>
                  <Item>{shareholder.streetNumber}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Colonia
                  </Typography>
                  <Item>{shareholder.colony}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Estado
                  </Typography>
                  <Item>{shareholder.state}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Municipio
                  </Typography>
                  <Item>{shareholder.municipality}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    C.P
                  </Typography>
                  <Item>{shareholder.CP}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Comprobante de domicilio
                  </Typography>
                  <Item>
                    <Button href={shareholder.homeFile} download>
                      Descargar
                    </Button>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Teléfono
                  </Typography>
                  <Item>{shareholder.phone}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Correo electronico
                  </Typography>
                  <Item>{shareholder.email}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    color={colors.black}
                    fontWeight="bold"
                    mt={1}
                    mb={1}
                  >
                    Incapacidad
                  </Typography>
                  <Item>{shareholder.inability}</Item>
                </Grid>
                {shareholder?.inability === "otra" && (
                  <Grid item xs={4}>
                    <Typography
                      color={colors.black}
                      fontWeight="bold"
                      mt={1}
                      mb={1}
                    >
                      Descripción de incapacidad.
                    </Typography>
                    <Item>{shareholder?.inabilityDescription}</Item>
                  </Grid>
                )}
              </Grid>
            </Box>
          );
        })}
      </Box>

      <SnackAlert
        message={message}
        open={open}
        severity={severity}
        handleClose={handleClose}
      />
    </Container>
  );
};

export default TransactionOnlineDetail;
