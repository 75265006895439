import {
  CREATE_USER,
  LOGIN_USER,
  LOGOUT_USER,
  ALL_USERS,
  GET_USER_BY_ID,
  GET_DOCUMENT_BY_USER,
} from "./constants";

const userReducer = (state, action) => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        token: action.token,
      };
    case LOGIN_USER:
      return {
        ...state,
        token: action.token,
      };
    case LOGOUT_USER:
      return {
        ...state,
        token: action.token,
        documentsByuser: [],
        userById: null,
      };
    case ALL_USERS:
      return {
        ...state,
        all_users: action.users,
      };
    case GET_USER_BY_ID:
      return {
        ...state,
        userById: action.user,
      };
    case GET_DOCUMENT_BY_USER:
      return {
        ...state,
        documentsByuser: [...state.documentsByuser, action.document],
      };
    default:
      return state;
  }
};

export default userReducer;
