import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles((theme) => ({
  card: {
    boxSizing: "border-box",
    minWidth: "358px",
    cursor: "pointer",
    transition: ".3s all",
    marginRight: 20,
    marginBottom: 40,
    boxShadow: "0px 10px 30px #27476C29",
    borderRadius: "10px",
    //width: "80%",
    padding: "15px 20px",
    "&:hover, &:focus": {
      backgroundColor: "#ECECEC",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px auto",
      boxShadow: "0px 0px 31px -20px rgba(0,0,0,0.75) !important",
      borderRadius: "12px",
      width: "70%",
    },
  },
  container: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "initial",
    },
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    cursor: "pointer",
  },
  title: {
    // color: colors.blue,
    fontWeight: "bold",
    fontFamily: "poppinsmedium",
  },
  containerCardMedia: {
    width: 80,
    [theme.breakpoints.down("sm")]: {
      width: 80,
      height: 85,
    },
    height: 85,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // background: colors.lightskyblue,
    borderRadius: "50%",
    "& img": {
      width: 80,
      height: 40,
      objectFit: "contain",
    },
  },
  padRight: {
    paddingRight: 40,
  },
}));
