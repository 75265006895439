import { Controller } from "react-hook-form";
import { TextField } from '@mui/material';

export const InputNumber = ({ name, value, control, errors, placeholder, className }) => {

  const allowOnlyNumber=(e)=>{
     let percente = e.target.value > 100 ? 100 : e.target.value;
     return percente;
  }

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={value ?? ""}
        render={({ field: { onChange, value } }) => (
          <>
          <TextField
            placeholder={placeholder}
            type="number"
            className={className ? className : "input-text"}
            value={value}
            onChange={(text)=> onChange(allowOnlyNumber(text))}
          />
          </>
        )}
      />
    </>
  )
};
