import { createContext } from 'react';
import { useState } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "firebase/firestore";
import { useEffect } from 'react';

export const ModuleContext = createContext();

export const ModuleContextProvider = ({ children }) => {

    const [ siteConfig, setSiteConfig ] = useState();

    const getSiteConfig = async () => {
        try {
          const siteConfigRef = collection(db, "site-config");
          const siteConfigSnapshot = await getDocs(siteConfigRef);
    
          siteConfigSnapshot.forEach((doc) => {
            // console.log("ID:", doc.id, "Data:", doc.data());
            setSiteConfig(doc.data());
          });
        } catch (error) {
          console.error(
            "Error al obtener los datos de la colección 'site-config':",
            error
          );
        }
      };
    
    //   useEffect(() => {
    //     getSiteConfig();
    //   }, [siteConfig]);


    return (
        <ModuleContext.Provider value={{ 
            siteConfig,
            setSiteConfig,
            getSiteConfig,
         }}>
            { children }
        </ModuleContext.Provider>
    )
};