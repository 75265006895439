import {
    GENERATE_PDF_MARRIAGE,
    GET_MARRIAGE_CONTRACTS,
    GET_MARRIAGE_CONTRACT_BY_ID,
} from "./constants";

const marriageReducer = (state, action) => {
    switch (action.type) {
        case GET_MARRIAGE_CONTRACTS:
            return {
                ...state,
                listMarriageContract: [
                    ...state.listMarriageContract,
                    action.marriageContract,
                ],
            };

        case GET_MARRIAGE_CONTRACT_BY_ID:
            return {
                ...state,
                marriageContractById: action.marriage,
            };

        case GENERATE_PDF_MARRIAGE:
            return {
                ...state,
                pdf: action.pdf,
                marriageContractById: action.marriageContractById,
            };

        default:
            return state;
    }
};

export default marriageReducer;
