import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 100px)",
    display: "grid",
    placeItems: "center",
    justifyContent: "center",
  },
  content: {
    width: "100%",
  },
  containerSub1: {
    display: "flex",
    justifyContent: "center",
    marginBottom: 30,
  },
  subtitle1: {
    width: "66%",
    color: colors.gray,
    textAlign: "center",
    fontFamily: "Poppins, Regular",
    fontSize: 20,
  },
  title: {
    color: (props) => `${props.baseColor}`,
    textAlign: "center",
    marginBottom: 40,
    lineHeight: 1.5,
    fontSize: 35,
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px !important",
    },
  },
  btnNextStep: {
    background: (props) => `${props.baseColor}`,
    borderRadius: 20,
    fontWeight: "bold",
    width: 150,
    display: "flex",
    justifyContent: "space-between",
  },
  containerButtons: {
    display: "flex",
    justifyContent: "space-around",
  },

  buttons: {
    color: colors.darkGray,
    fontWeight: "bold",
    cursor: "pointer",
    "&:hover": {
      color: (props) => `${props.baseColor}`,
    },
  },
}));
