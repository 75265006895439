import { useCallback } from "react";

export const ValidateDataProtocolizationHook = () => {
  // Function
  const validateData = useCallback((data, dataClear) => {
    const copyData = {
      ...data,
    };

    dataClear.forEach((name) => {
      if (copyData[name]?.length && Array.isArray(copyData[name])) {
        copyData[name] = "";
      }
    });

    return copyData;
  }, []);

  return { validateData };
};
