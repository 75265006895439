import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

export default makeStyles((theme) => ({
  content: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    minHeight: 'calc(100vh - 200px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
  },
  title: {
    color: colors.blue,
    textAlign: "center",
    marginBottom: 40,
    lineHeight: 1.50,
    fontFamily: 'poppinsbold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  containerButtons: {
    display: "flex",
    justifyContent: "center",
    gap: 10,
    marginBottom: 50,
  },
  btnNextStep: {
    background: colors.blue,
    borderRadius: 20,
    fontWeight: "bold",
    width: 150,
    display: "flex",
    justifyContent: "space-between"
  },
}));
