import emailjs from "@emailjs/browser";
import { useStateValue } from "context/store";
import { enviorement } from "server/enviorement";

export const EmailHook = () => {
  const [
    {
      user: { userById },
    },
  ] = useStateValue();
  // storage
  const currentUser = JSON.parse(localStorage.getItem("current-user"));

  const typeStatus = {
    "Constitución de sociedades": "company-formation-id",
    "Donación de dinero": "donation-money-id",
    "Capitulaciones Matrimoniales": "marriage-contract-id",
    "Protocolización de Asambleas": "protocolization-assemblies-id",
  };

  const sendEmail = (typeProcedure, documentId) => {
    let notaryEmail = [];
    if (typeStatus[typeProcedure] === "company-formation-id") {
      notaryEmail.push('r.lopez@np26.com.mx') 
    } else if (typeStatus[typeProcedure] === "donation-money-id") {
      notaryEmail.push('arickescamilla@np26.com.mx')
    } else if (typeStatus[typeProcedure] === "marriage-contract-id") {
      notaryEmail.push('orosas@np26.com.mx')
    } else if (typeStatus[typeProcedure] === "protocolization-assemblies-id") {
      notaryEmail.push('atinoco@np26.com.mx')
    }

    let templateParams = {
      to_name: currentUser ? currentUser.name : userById.name,
      typeProcedure,
      document: `https://www.np26.com.mx/app/${typeStatus[typeProcedure]}/${documentId}`,
      to_email: notaryEmail.toString(),
    };

    try {
      emailjs
        .send(
          "service_1imbwpe",
          "template_36i1wfn",
          templateParams,
          "mgBVX2DhxGVF-9R11"
        )
        .then(function (response) {
          console.log("SUCCESS!", response.status, response.text);
        })
        .catch(function (error) {
          console.log("FAILED...", error);
        });
      return true;
    } catch (error) {
      console.error("error-send-email-js", error);
      return false;
    }
  };

  const sendUserEmail = (typeProcedure) => {
    let templateParams = {
      name_document: typeProcedure,
      to_email: currentUser ? currentUser.email : userById.email,
    };

    try {
      emailjs
        .send(
          "service_1imbwpe",
          "template_5hqxhnv",
          templateParams,
          "mgBVX2DhxGVF-9R11"
        )
        .then(function (response) {
          console.log("SUCCESS!", response.status, response.text);
        })
        .catch(function (error) {
          console.log("FAILED...", error);
        });
      return true;
    } catch (error) {
      console.error("error-send-email-js", error);
      return false;
    }
  };

  return { sendEmail, sendUserEmail };
};
