import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  banner: {
    background: "lightgray",
    minHeight: "70vh",
    padding: "20px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      minHeight: "auto",
      textAlign: "center",
    },
  },
  box: {
    margin: "auto",
    width: "100%",
  },
  topBanner: {
    backgroundColor: "white !important",
    // minHeight: '110px',
    // paddingTop: '20px',
  },
  hidden: {
    display: "none",
  },
  logo: {
    margin: "0 0 0 20px",
  },
  groupButtons: {
    color: "#1759A5",
    width: "100%!important",
    justifyContent: "space-between",
    display: "inline-flex",
  },
  buttons: {
    color: "#1759A5!important",
    padding: "6px 18px!important",
    margin: "0 10px!important",
    borderRadius: "30px!important",
    fontFamily: "avenirnextbold",
    height: "40px",
    "&:hover": {
      backgroundColor: "#E2E2E2!important",
    },
  },
  links: {
    color: "#1759A5!important",
    padding: "6px 19px!important",
    margin: "0 10px!important",
    borderRadius: "30px!important",
    fontFamily: "avenirnextbold",
    textDecoration: "none",
    display: "inline-block",
    height: "28px",
    lineHeight: "28px",
    "&:hover": {
      backgroundColor: "#E2E2E2!important",
    },
  },
  loginButton: {
    color: "#1759A5!important",
    border: "1px solid #1759A5!important",
    padding: "6px 35px!important",
    margin: "0 10px!important",
    borderRadius: "30px!important",
    height: "40px",
    fontFamily: "avenirnextbold",
    "&:hover": {
      backgroundColor: "#E2E2E2!important",
    },
  },

  groupButtonsResponsive: {
    color: "#1759A5",
    width: "100%",
    justifyContent: "space-between",
  },
  buttonsResponsive: {
    color: "#1759A5!important",
    padding: "0!important",
    margin: "0!important",
    width: "100%",
    height: "40px",
    borderRadius: "0px!important",
    background: "#fff",
    "&:hover": {
      backgroundColor: "#E2E2E2!important",
    },
  },
  loginButtonResponsive: {
    color: "#1759A5!important",
    border: "1px solid #1759A5!important",
    padding: "0!important",
    margin: "0!important",
    width: "100%",
    height: "40px",
    fontFamily: "avenirnextbold",
    "&:hover": {
      backgroundColor: "#E2E2E2!important",
    },
  },
}));
