import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export const InputEmail = ({
  name,
  control,
  errors,
  className,
  placeholder,
  validateError = false,
  configData,
  style,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      type="email"
      style={{ borderColor: `${configData?.baseColor} !important`, outlineColor: `${configData?.baseColor} !important` }}
      render={({ field: { onChange, value } }) => (
        <TextField
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          error={validateError && !!errors.email}
          helperText={validateError && errors.email?.message}
          type="email"
          className={className}
          style={style}
        />
      )}
    />
  );
};
