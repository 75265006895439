import { useContext, useEffect, useCallback } from "react";
import { Container, Grid } from "@mui/material";
import { FirebaseContext } from "server";
import { useStateValue } from "context/store";
import { getTestData } from "context/test/actions";
import TestForm from "components/Forms/TestForm";

export default function Hola() {
  const firebase = useContext(FirebaseContext);
  const [
    {
      test: { list },
    },
    dispatch,
  ] = useStateValue();

  const getTest = useCallback(
    async () => await getTestData(firebase, dispatch),
    [dispatch, firebase]
  );

  useEffect(() => {
    getTest();
  }, [getTest]);

  return (
    <Container maxWidth="lg">
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={2} sm={4} md={4}>
          <TestForm />
        </Grid>
      </Grid>
    </Container>
  );
}
