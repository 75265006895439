import { Grid, Typography, Button } from "@mui/material";
import { height } from "@mui/system";

const MapDonationAppearing = ({ data }) => {
  return (
    <>
      <div style={{ marginTop: 20, marginBottom: 10 }}>
        <Typography
          style={{ fontSize: 13, fontWeight: "bold", fontFamily: "Arial" }}
        >
          Datos generales (Por compareciente):
        </Typography>
      </div>

      <div
        className="divider"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
          justifyContent: "space-around",
          backgroundColor: "#F6F6F6",
          borderRadius: 5,
          padding: 10,
        }}
      >
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Nombre completo: </b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.name ? `${data?.name} ${data.lastName}` : "N/A"}
          </div>
        </div>

        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Lugar de nacimiento: </b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.country ? data?.country : "N/A"}
          </div>
        </div>

        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Ciudad: </b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.state ? data?.state : "N/A"}
          </div>
        </div>

        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Fecha de nacimiento: </b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.dateBirth ? data?.dateBirth?.split("00")[0] : "N/A"}
          </div>
        </div>
      </div>

      <div
        className="divider"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
          justifyContent: "space-around",
          backgroundColor: "#F6F6F6",
          borderRadius: 5,
          padding: 10,
        }}
      >
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Tipo de compareciente: </b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.appearing ? data?.appearing : "N/A"}
          </div>
        </div>
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Discapacidad: </b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.disability === "none"
              ? "Ninguna"
              : data?.disability === "deaf"
              ? "Sordera"
              : data?.disability === "mute"
              ? "Mudez"
              : data?.disability === "blind"
              ? "Ceguera"
              : data?.disability === "other"
              ? "Otra"
              : "N/A"}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
          justifyContent: "space-around",
          backgroundColor: "#F6F6F6",
          borderRadius: 5,
          padding: 10,
        }}
      >
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Estado civil:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.maritalStatus ? data.maritalStatus : "N/A"}
          </div>
        </div>
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Ocupación:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.occupation ? data.occupation : "N/A"}
          </div>
        </div>
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Registro federal de contribuyentes:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>{data?.rfc ? data.rfc : "N/A"}</div>
        </div>
      </div>

      <div style={{ marginTop: 10, marginBottom: 10 }}>
        <Typography
          style={{
            fontSize: 13,
            fontWeight: "bold",
            fontFamily: "Arial",
          }}
        >
          Identificación vigente:
        </Typography>
      </div>

      <div
        className="divider"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
          justifyContent: "space-around",
          backgroundColor: "#F6F6F6",
          borderRadius: 5,
          padding: 10,
        }}
      >
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>CURP:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>{data?.curp ? data.curp : "N/A"}</div>
        </div>
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Tipo de Identificación:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {
              data?.identification === 'credential' 
              ? 'Credencial de elector'
              : data?.identification === 'passport' 
              ? 'Pasaporte' 
              : 'N/A'  
            }
          </div>
        </div>
      </div>

      <div style={{ marginBottom: 10 }}>
        <Typography
          style={{
            fontSize: 13,
            fontWeight: "bold",
            fontFamily: "Arial",
          }}
        >
          Domicilio:
        </Typography>
      </div>

      <div
        className="divider"
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 10,
          justifyContent: "space-around",
          backgroundColor: "#F6F6F6",
          borderRadius: 5,
          padding: 10,
        }}
      >
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Calle y número:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.streetNumber ? data.streetNumber : "N/A"}
          </div>
        </div>
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Colonia:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.colony ? data.colony : "N/A"}
          </div>
        </div>
        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Estado:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.liveState ? data.liveState : "N/A"}
          </div>
        </div>

        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Municipio:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>
            {data?.liveMunicipality ? data.liveMunicipality : "N/A"}
          </div>
        </div>

        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>C.P:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>{data?.cp ? data.cp : "N/A"}</div>
        </div>

        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Teléfono:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>{data?.phone ? data.phone : "N/A"}</div>
        </div>

        <div>
          <Typography
            className="subtitle2"
            variant="subtitle2"
            style={{ fontFamily: "Arial" }}
            gutterBottom
          >
            <b>Correo electrónico:</b>
          </Typography>
          <div style={{ fontSize: 10 }}>{data?.email ? data.email : "N/A"}</div>
        </div>
      </div>

      <div className="divider">
        <Typography
          className="subtitle2"
          variant="subtitle2"
          gutterBottom
          style={{ fontFamily: "Arial" }}
        >
          <b>Comprobante de domicilio actual:</b>
        </Typography>
        <div style={{ fontSize: 10 }}>
          {data?.addressFile ? "Documento anexado en descripción." : "N/A"}
        </div>
      </div>

      <div className="divider">
        <Typography
          className="subtitle2"
          variant="subtitle2"
          gutterBottom
          style={{ fontFamily: "Arial" }}
        >
          <b>Representante legal:</b>
        </Typography>
        <div style={{ fontSize: 10 }}>
          {data?.identificationFile
            ? "Documento anexado en descripción."
            : "N/A"}
        </div>
      </div>
    </>
  );
};

export default MapDonationAppearing;
