import { useContext, useState, useEffect } from "react";
import { Typography, Container, Grid, Button } from "@mui/material";
import { colors } from "constants/colors";
import useStyles from "./styles";
import { FormProvider, useForm } from "react-hook-form";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "components/Loading";
import {
  createMarriage,
  editMarriage,
  getMarriageContractById,
  updatePdfMarriage,
} from "context/marriage/actions";
import { FirebaseContext } from "server";
import { EmailHook } from "hooks/send-email";
import { useStateValue } from "context/store";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaMarriageContract from "schema/MarriageContract/schema-marriage-contract";
import SpouseOne from "./spouse/spouseOne";
import SpouseTwo from "./spouse/spouse";
import CivilCeremony from "./civil-ceremony/civil-ceremony";
import ErrorForm from "components/Error-form";
import MarriageViewerPdf from "components/success-form/marriage-viewer-pdf";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import jsPDF from "jspdf";
import { getCreatedTimeValue } from "utils";

const FormMarriageContract = () => {
  // styles
  const classes = useStyles();

  // react hook form
  const methods = useForm({
    resolver: yupResolver(schemaMarriageContract),
  });

  // useState
  const [loading, setLoading] = useState(false);

  // localStorage
  const currentUser = JSON.parse(localStorage.getItem("current-user"));

  // constants
  const navigate = useNavigate();
  const tokenStorage = localStorage.getItem("auth-token");
  const [
    {
      marriage: { marriageContractById, pdf },
    },
    dispatch,
  ] = useStateValue();

  // firebase
  const firebase = useContext(FirebaseContext);

  // hooks
  const { sendEmail, sendUserEmail } = EmailHook();

  const { state } = useLocation();
  let documentId = state?.documentId;

  const typeProcedure = documentId
    ? "Capitulaciones Matrimoniales editadas con éxito"
    : "Capitulaciones Matrimoniales";

  // generate pdf
  const doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
  });

  // functions
  const onSubmit = async (data) => {
    data.createdDateTime = getCreatedTimeValue();
    if (tokenStorage) {
      setLoading(true);

      const withCurrentUser = {
        currentUser: currentUser.name,
        status: "pending",
        ...data,
      };

      if (documentId) {
        const callback = await editMarriage(
          firebase,
          withCurrentUser,
          documentId,
          dispatch
        );

        if (callback.editMarriageContract) {
          await generatePdfMarriage(documentId);
        }
      } else {
        withCurrentUser.urlPdf = "";
        const callback = await createMarriage(
          firebase,
          withCurrentUser,
          currentUser,
          dispatch
        );

        if (callback.marriageContract) {
          await generatePdfMarriage(callback.marriageContract);
        }
      }
    } else {
      navigate("/app/form-login", {
        replace: true,
        state: {
          src: "form-donation",
        },
      });
    }
  };

  const navigateSendEmail = (documentId) => {
    const emailWasSent = sendEmail(typeProcedure, documentId);
    const emailUserWasSent = sendUserEmail(typeProcedure);
    if (emailWasSent && emailUserWasSent) {
      navigate("/app/transaction-online-success", {
        replace: true,
        state: {
          src: typeProcedure,
        },
      });
    }
  };

  const generatePdfMarriage = async (marriageId) => {
    const contentPdf = document.querySelector("#contentPdfMarriage");
    const storage = getStorage();
    console.log("contentPdf====>", contentPdf);
    doc.html(contentPdf, {
      margin: [20, 0, 20, 0],
      callback: function (doc) {
        const metadata = {
          contentType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        };
        let blob = doc.output("blob");
        const storageRef = ref(
          storage,
          `Documents/${marriageId}/output${Date.now()}.pdf`
        );
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              console.log("url===>", url);
              updatePdfMarriage(firebase, marriageId, url).then((res) => {
                console.log("res====>", res);
                navigateSendEmail(marriageId);
              });
            })
            .catch((error) => {
              console.log("error generatePdf", error);
              setLoading(false);
            });
        });
      },
    });
  };

  useEffect(() => {
    const fetchMarriageById = async () => {
      if (documentId) {
        await getMarriageContractById(documentId, firebase, dispatch);
      }
    };
    fetchMarriageById();
  }, [documentId, firebase, dispatch]);

  useEffect(() => {
    methods.reset(marriageContractById);
  }, [marriageContractById, methods]);

  const countErrors = Object.entries(methods.formState.errors).length;

  return (
    <div className={classes.content}>
      {loading && <Loading />}
      <Typography
        variant="h2"
        gutterBottom
        component="div"
        className={classes.title}
      >
        Capitulaciones Matrimoniales
      </Typography>

      <Container>
        <Typography variant="subtitle1" gutterBottom component="div">
          Las capitulaciones matrimoniales son el pacto que celebran dos esposos
          para constituir la sociedad conyugal o la separación de bienes, con el
          fin de fijar las reglas económicas que regirán el patrimonio de cada
          uno. <br />
          Estas capitulaciones pueden otorgarse antes de la celebración del
          matrimonio o ya estando casados, y puede comprender la administración
          de bienes ya adquiridos o de los bienes que obtengan en el futuro.{" "}
          <br />
          En la Notaría 26 le ayudamos a agilizar trámites notariales como
          constitución de sociedades, donación de dinero, protocolización de
          asambleas y capitulaciones matrimoniales.{" "}
        </Typography>

        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            color={colors.blue}
          >
            Los campos marcados con * son obligatorios
          </Typography>
        </div>

        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 6, sm: 12, md: 12 }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.contentSpouse}>
                  <SpouseOne spouse={"One"} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className={classes.contentSpouse}>
                  <SpouseTwo spouse={"Two"} />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div className={classes.contentSpouse}>
                  <CivilCeremony />
                </div>
              </Grid>
            </Grid>

            <div className={classes.contentButton}>
              <Button
                type="submit"
                variant="contained"
                className={classes.btnNextStep}
              >
                {documentId ? "Editar" : "Enviar"}
                <RigthIcon />
              </Button>
            </div>
          </form>
          <ErrorForm countErrors={countErrors} />
        </FormProvider>
        <div style={{ display: "none" }}>
          {pdf || documentId ? <MarriageViewerPdf /> : null}
        </div>
      </Container>
    </div>
  );
};

export default FormMarriageContract;
