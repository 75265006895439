import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles((theme) => ({
  footer: {
    height: "120px",
  },
  space: {
    marginTop: "10px",
    marginBottom: "20px",
  },
  logoImage: {
    width: "200px",
  },
  rightsReserved: {
    color: "white",
    textAlign: "right",
    marginRight: "30px !important",
    fontSize: "12px !important",
  },
}));
