export const labels = [
  { id: 1, label: "Asamblea ordinaria" },
  { id: 2, label: "Asamblea extraordinaria" },
  { id: 3, label: "Resoluciones unánimes fuera de la asamblea" },
]

export const pointsAssembly = [
  { id: 1, label: "Protocolización integral" },
  { id: 2, label: "Protocolización en lo conducente" },
  { id: 3, label: "Especificar puntos" },
]