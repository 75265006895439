import AdminComponent from 'components/AdminComponent/AdminComponent'
import React, { useState } from 'react'
import {db} from '../../firebase'
import {
  doc,
  getDoc,
  updateDoc,
} from 'firebase/firestore'
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "@firebase/storage";
import firebase from "../../firebase";
import Swal from 'sweetalert2'

const initialState = {
    id: '1',
    name: '',
    baseColor: '',
    footerColor: '',
    iconsColor: '',
    highlightedColor: '',
    hoverNavColor: '',
    proceedingsColor: '',
    logo: '',
    logoSecondary: '',
    societies: null,
    donation: null,
    protocolization: null,
    capitulations: null,
}

function AdminView() {

  const [ initialConfig, setInitialConfig ] = useState(initialState)

  const submitHandler = (e) => {
    e.preventDefault();

    setInitialConfig(initialState);

    Swal.fire({
      position: 'center',
      icon: 'success',
      title: 'Los cambios han sido guardados',
      showConfirmButton: false,
      timer: 2000
    });
  };

  const generateRandomName = () => {
    let text = "";
    const possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 10; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  const handleLogoChange = (e) => {
    const randomName = generateRandomName();
    const file = e.target.files[0];
    const imageRef = ref(
        firebase.storage,
        "products/" + file.name + randomName
    );

    const uploadTask = uploadBytesResumable(imageRef, file);
    setInitialConfig({ ...initialConfig, logo: uploadTask });

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
        },
        (error) => {
            console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log("File available at", downloadURL);
                setInitialConfig({ ...initialConfig, logo: downloadURL });
            });
        }
    );
  }

  const handleLogoSecondaryChange = (e) => {
    const randomName = generateRandomName();
    const file = e.target.files[0];
    const imageRef = ref(
        firebase.storage,
        "products/" + file.name + randomName
    );

    const uploadTask = uploadBytesResumable(imageRef, file);
    setInitialConfig({ ...initialConfig, logoSecondary: uploadTask });

    uploadTask.on(
        "state_changed",
        (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
        },
        (error) => {
            console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                console.log("File available at", downloadURL);
                setInitialConfig({ ...initialConfig, logoSecondary: downloadURL });
            });
        }
    );
  }

  const asyncSubmitHandler = async () => {
    try {
      const docRef = doc(db, "site-config", "1");
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        const updateData = {};
  
        if (initialConfig.name) {
          updateData.name = initialConfig.name;
        }
        if (initialConfig.baseColor) {
          updateData.baseColor = initialConfig.baseColor;
        }
        if (initialConfig.footerColor) {
          updateData.footerColor = initialConfig.footerColor;
        }
        if (initialConfig.iconsColor) {
          updateData.iconsColor = initialConfig.iconsColor;
        }
        if (initialConfig.highlightedColor) {
          updateData.highlightedColor = initialConfig.highlightedColor;
        }
        if (initialConfig.hoverNavColor) {
          updateData.hoverNavColor = initialConfig.hoverNavColor;
        }
        if (initialConfig.proceedingsColor) {
          updateData.proceedingsColor = initialConfig.proceedingsColor;
        }
        if (initialConfig.logo) {
          updateData.logo = initialConfig.logo;
        }
        if (initialConfig.logoSecondary) {
          updateData.logoSecondary = initialConfig.logoSecondary;
        }
        if (initialConfig.societies !== null) {
          updateData.societies = initialConfig.societies;
        }
        if (initialConfig.donation !== null) {
          updateData.donation = initialConfig.donation;
        }
        if (initialConfig.protocolization !== null) {
          updateData.protocolization = initialConfig.protocolization;
        }
        if (initialConfig.capitulations !== null) {
          updateData.capitulations = initialConfig.capitulations;
        }
  
        await updateDoc(doc(db, "site-config", "1"), updateData);
      } else {
        console.error("El documento no existe.");
      }
    } catch (error) {
      console.error('Error adding document: ', error);
    }
  }

  return (
    <>
        <AdminComponent 
            initialConfig={initialConfig}
            setInitialConfig={setInitialConfig}
            submitHandler={submitHandler}
            asyncSubmitHandler={asyncSubmitHandler}
            handleLogoChange={handleLogoChange}
            handleLogoSecondaryChange={handleLogoSecondaryChange}
        />
    </>
  )
}

export default AdminView