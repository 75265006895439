import { createTheme } from "@mui/material/styles";
import { blue } from "@mui/material/colors";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#1759A5",
    },
    secondary: {
      main: blue[200],
      secondary: "#ffffff",
    },
    text: {
      primary: blue[400],
      secondary: "#828080",
    },
    action: {
      hover: "rgba(0, 0, 0, 0.08)",
      hoverOpacity: 0.05,
    },
  },
  typography: {
    fontSize: 16,
    fontFamily: ["poppinsregular"].join(","),
    button: {
      textTransform: "none",
      fontFamily: "poppinsregular",
      fontSize: "0.95rem",
    },
    h1: {
      fontSize: 96,
    },
    h2: {
      fontSize: 60,
    },
    h3: {
      fontSize: 48,
    },
    h4: {
      fontSize: 34,
    },
    h5: {
      fontSize: 24,
    },
    h6: {
      fontSize: 20,
    },
    subtitle1: {
      fontSize: 16,
    },
    subtitle2: {
      fontSize: 14,
    },
    body1: {
      fontSize: 14,
    },
    body2: {
      fontSize: 14,
    },
  },
});
