import { useContext, useEffect, useState } from "react";
import { Paper, Container, Stack } from "@mui/material";
import { FirebaseContext } from "server";
import { getCompanies } from "context/company/actions";
import { useStateValue } from "context/store";
import { DataTable } from "components/data-table/data-table";
import { CompanyFormationColumnHook } from "hooks/company-formation-columns";
import { MutatingDots } from "react-loader-spinner";

const CompanyFormationDoc = () => {
  // firebase
  const firebase = useContext(FirebaseContext);
  const [loading, setLoading] = useState(true);

  // custom hooks
  const { columns } = CompanyFormationColumnHook();

  const [
    {
      company: { companies },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    const fetchData = async () => {
      if (companies.length === 0) {
        await getCompanies(firebase, dispatch);
      }
    };

    fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, firebase]);

  useEffect(() => {
    if (companies.length > 0) {
      setLoading(false);
    }
  }, [companies]);

  return (
    <Stack minHeight={"calc(100vh - 200px)"} justifyContent="center">
      <Container>
        {loading ? (
          <MutatingDots
            height="100"
            width="100"
            color="#1759A5"
            secondaryColor="#1759A5"
            radius="15.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        ) : (
          <Paper>
            <DataTable
              data={companies.sort((a, b) =>
                parseInt(a?.createdDateTime) < parseInt(b?.createdDateTime)
                  ? 1
                  : -1
              )}
              columns={columns}
              title="Constitución de sociedades"
            />
          </Paper>
        )}
      </Container>
    </Stack>
  );
};

export default CompanyFormationDoc;
