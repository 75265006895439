import { Card, Box, CardContent, Typography, CardMedia } from "@mui/material";
import { colors } from "constants/colors";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { set } from "date-fns";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase'
import "firebase/firestore";
import { is } from "date-fns/locale";

const CardViewDocuments = ({ card, setTypeTable, clickCard, setClickCard, selected }) => {
  const [ configData, setConfigData ] = useState();

  const classes = useStyles({ isSelected: clickCard === card.id, baseColor: configData?.baseColor, hoverNavColor: configData?.hoverNavColor });

  const handleOnClick = (cardItem) => {
    setTypeTable(cardItem.url);
    setClickCard(cardItem.id);
  };

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <Card
      sx={{ display: "flex" }}
      className={classes.card}
      onClick={() => handleOnClick(card)}
    >
      <div className={classes.container}>
        <div>
          <CardMedia
            component="img"
            src={card.icon}
            color={colors.white}
            alt="Live from space album cover"
            style={{marginLeft: "5px"}}
          />
        </div>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }} style={{ padding: "17px 0" , }}>
            <Typography
              component="div"
              variant="subtitle2"
              className={classes.title}
            >
              {card.title}
            </Typography>
          </CardContent>
        </Box>
      </div>
    </Card>
  );
};

export default CardViewDocuments;
