import TransactionOnline from './components/transaction-online';
import Departments from './components/departments';
import CardTransaction from 'components/card-transaction/card-transaction';
import { Grid } from '@mui/material'
import useStyles from './styles';
// Logos
import DonatingMoney from 'assets/svg/donating-money.svg'
import Ratification from 'assets/svg/ratification.svg'
import Proceedings from 'assets/svg/proceedings.svg'
import Protocols from 'assets/svg/protocols.svg'

export default function Transaction() {

  const classes = useStyles();

  const infoTransaction = [
    {id: 1, icon: DonatingMoney, title: "Donación de Dinero", description: "Trámite legal que se realiza entre parientes de primer grado que desean realizar donaciones voluntarias, libres de impuestos, a sus hijos"},
    {id: 2, icon: Ratification, title: "Ratificación de firmas", description: "Trámite legal que se realiza entre parientes de primer grado que desean realizar donaciones voluntarias, libres de impuestos, a sus hijos"},
    {id: 3, icon: Proceedings, title: "Actas constitutivas", description: "Trámite legal que se realiza entre parientes de primer grado que desean realizar donaciones voluntarias, libres de impuestos, a sus hijos"},
    {id: 4, icon: Protocols, title: "Protocolización de asambleas", description: "Trámite legal que se realiza entre parientes de primer grado que desean realizar donaciones voluntarias, libres de impuestos, a sus hijos"},
  ]

  return (
    <div style={{ marginTop: "15vh" }}>

      <TransactionOnline />

      <Departments />

      <div className={classes.container}>
        <h1 className={classes.title}>Trámites presenciales</h1>
        <p className={classes.subtitle}>Usted recibirá la notificación del inicio de su trámite en línea a través del correo electrónico que usted nos indique.</p>
        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12, lg: 12 }}>
          {infoTransaction.map(transaction => (
            <CardTransaction key={transaction.id}
              title={transaction.title}
              description={transaction.description}
              icon={transaction.icon}/>
          ))}
        </Grid>
      </div>
    </div>
  );
}
