import { Card, Box, CardContent, Typography, CardMedia } from "@mui/material";
import useStyles from "./styles";
import { useEffect } from "react";
import { useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "firebase/firestore";

const CardAppTransaction = ({ card }) => {
  const classes = useStyles({  });

  const handleOnClick = (url) => {
    if (typeof window !== "undefined") {
      window.location.href = `/app/${url}`;
    }
  };

  const [configData, setConfigData] = useState();

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <Card
      sx={{ display: "flex" }}
      className={classes.card}
      onClick={() => handleOnClick(card.url)}
    >
      <div className={classes.container}>
        <div className={classes.containerCardMedia} style={{ background: configData?.iconsColor }}>
          <CardMedia
            component="img"
            image={card.icon}
            alt="Live from space album cover"
          />
        </div>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography
              component="div"
              variant="subtitle2"
              className={classes.title}
              style={{ color: configData?.baseColor }}
            >
              {card.title}
            </Typography>
          </CardContent>
        </Box>
      </div>
    </Card>
  );
};

export default CardAppTransaction;
