import {
  NEXT_STEP,
  BACK_STEP,
  ADD_DATA_COMPANY,
  CREATE_COMPANY,
  SHAREHOLDER_COMPANY,
  GENERATE_PDF,
  SHAREHOLDER_PDF,
  PROXIES_COMPANY,
  CLEAR_DATA,
  PARTICIPATION_RATE_COMPANY,
  GET_COMPANIES,
  GET_COMPANY_BY_ID,
  GET_SHAREHOLDERS_BY_ID,
  RESET_STEP_COMPANY,
  CLEAR_COMPANY,
  STATUS_COMPANY,
} from "./constants";

const companyReducer = (state, action) => {
  switch (action.type) {
    case NEXT_STEP:
      return {
        ...state,
        activeStep: state.activeStep + 1,
      };
    case BACK_STEP:
      return {
        ...state,
        activeStep: state.activeStep - 1,
      };
    case ADD_DATA_COMPANY:
      return {
        ...state,
        companyInput: { ...state.companyInput, ...action.data },
      };
    case SHAREHOLDER_COMPANY:
      return {
        ...state,
        shareholder: action.data,
      };
    case STATUS_COMPANY:
      return {
        ...state,
        status: action.data,
      };
    case PROXIES_COMPANY:
      return {
        ...state,
        proxies: action.data,
      };
    case PARTICIPATION_RATE_COMPANY:
      return {
        ...state,
        participationRate: action.data,
      };
    case CLEAR_DATA:
      return {
        ...state,
        activeStep: 0,
        companyInput: {},
        companyId: null,
        shareholder: { shareholder: [{}] },
        proxies: {
          proxies: [{ nestedArray: [{}], v2nestedArray: [{}] }],
          nestedArray: [{}],
          v2nestedArray: [{}],
        },
        participationRate: { participationRate: [{}] },
        pdf: null,
        shareholdersPdf: null,
        dataDocx: null,
        dataDocument: null,
        companies: [],
      };
    case CREATE_COMPANY:
      return {
        ...state,
        companyId: action.companyId,
      };
    case GENERATE_PDF:
      return {
        ...state,
        pdf: action.pdf,
      };
    case SHAREHOLDER_PDF:
      return {
        ...state,
        shareholdersPdf: action.shareholdersPdf,
      };
    case GET_COMPANIES:
      return {
        ...state,
        companies: [...state.companies, action.company],
      };
    case GET_COMPANY_BY_ID:
      return {
        ...state,
        companyById: action.company,
      };
    case GET_SHAREHOLDERS_BY_ID:
      return {
        ...state,
        shareholdersById: [...state.shareholdersById, action.shareholder],
      };
    case RESET_STEP_COMPANY:
      return {
        ...state,
        resetStepCompany: Object.assign(
          {},
          state.resetStepCompany,
          action.resetStep
        ),
      };
    case CLEAR_COMPANY:
      return {
        ...state,
        company: action.company,
      };
    default:
      return state;
  }
};

export default companyReducer;
