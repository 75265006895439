import { Fragment } from "react";
import { Grid, Typography } from "@mui/material";
import { InputDatePicker } from "components/inputs/input-date-picker";
import { InputText } from "components/inputs/input-text";
import { useFormContext } from "react-hook-form";
import useStyles from "./styles";
import { colors } from "constants/colors";

const CivilCeremony = () => {
  // styles
  const classes = useStyles();

  const {
    control,
    formState: { errors },
  } = useFormContext(); // retrieve all hook methods

  return (
    <div className="divider">
      <Grid
        container
        spacing={{ xs: 2, md: 2 }}
        columns={{ xs: 6, sm: 12, md: 12 }}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <Fragment>
          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className={classes.textInput}
            >
              Fecha de la ceremonia civil *
            </Typography>
            <InputDatePicker
              name={`dateCeremonmySpouseOne`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.dateCeremonmySpouseOne && (
              <p style={styles.errors}>
                {errors.dateCeremonmySpouseOne.message}
              </p>
            )}
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className={classes.textInput}
            >
              Nombre del juez *
            </Typography>
            <InputText
              name={`nameJudgeSpouseOne`}
              value={""}
              control={control}
              errors={errors}
            />
            {errors.nameJudgeSpouseOne && (
              <p style={styles.errors}>{errors.nameJudgeSpouseOne.message}</p>
            )}
          </Grid>
        </Fragment>
      </Grid>
    </div>
  );
};

export default CivilCeremony;

const styles = {
  errors: {
    padding: "5px 15px",
    fontSize: 14,
    borderRadius: 15,
    marginTop: -1,
    borderTopLeftRadius: 2,
    backgroundColor: colors.dangerLight,
    display: "inline-block",
    border: `1px solid ${colors.danger}`,
    color: colors.danger,
  },
};
