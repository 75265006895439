import React, { useEffect, useRef, useState, useContext } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Outlet,
  Route,
  Routes,
} from "react-router-dom";

import LayoutApp from "components/Layouts/LayoutApp";
import SuccessForm from "components/success-form/success-form";
import CompanyDetail from "pages/CompanyDetail/company-detail";
import CompanyFormation from "pages/CompanyFormation/company-formation";
import FormCompanyFormation from "pages/CompanyFormation/FormCompanyFormation/form-company-formation";
import DonationMoney from "pages/DonationMoney/donation-money";
import FormDonation from "pages/DonationMoney/form-donation/form-donation";
import NewFormDonation from "pages/DonationMoney/form-donation/new-form-donation";
import FormLogin from "pages/FormLogin/form-login";
import Landing from "pages/Landing";
import FormMarriageContract from "pages/MarriageContract/FormMarriageContract/form-marriage-contract";
import MarriageContract from "pages/MarriageContract/marriage-contract";
import PageTransactionOnline from "pages/PageTransactionOnline/page-transaction-online";
import FormProtocolization from "pages/ProtocolizationAssemblies/form-protocolization/form-protocolization";
import ProtocolizationAssemblies from "pages/ProtocolizationAssemblies/protocolization-assemblies";
import DonationMoneyDetail from "pages/ViewDetailDocuments/DonationMoneyDetail/donation-money-detail";
import MarriageContractDetail from "pages/ViewDetailDocuments/MarriageContractDetail/marriage-contract-detail";
import ProtocolizationAssembliesDetail from "pages/ViewDetailDocuments/ProtocolizationAssembliesDetail/protocolization-assemblies-detail";
import TransactionOnlineDetail from "pages/ViewDetailDocuments/TransactionOnlineDetail/transaction-online-detail";
import WelcomeCompaniesList from "pages/ViewDocuments/AllDocuments/welcome-companies-list";
import DonationMoneyDoc from "pages/ViewDocuments/DonationMoneyDoc/donation-money-doc";
import MarriageContractDoc from "pages/ViewDocuments/MarriageContractDoc/marriage-contract-doc";
import ProtocolizationAssembliesDoc from "pages/ViewDocuments/ProtocolizationAssembliesDoc/protocolization-assemblies-doc";
import CompanyFormationDoc from "pages/ViewDocuments/TransacitionOnlineDoc/company-formation-doc";
import Hola from "./Hola";
// import ProtectedRoute from "protected-route";
import { useStateValue } from "context/store";
import SimpleChat from "pages/PageChat/pageChat";
import Profile from "pages/PageChat/Profile";
import { VisitorsControllerContext } from "context/visitor/VisitorsControllerContext";
import AdminView from "pages/AdminView/AdminView";

// import SimpleChat from 'pages/SimpleChat/simple-chat';

const ProtectedRoute = ({ user, redirectPath = "/" }) => {
  if (user?.rol !== "notario") {
    return <Navigate to={redirectPath} replace />;
  } else if (user?.rol === "um-admin") {
    return <Navigate to="/um-admin" replace />;
  }

  return <Outlet />;
};

const RoutesApp = () => {
  const [user, setUser] = useState(null);

  const { current: currentUser } = useRef(
    JSON.parse(localStorage?.getItem("current-user"))
  );

  const { setIsVisitor } = useContext(VisitorsControllerContext);

  const [
    {
      user: { userById },
    },
  ] = useStateValue();

  useEffect(() => {
    setUser(userById ? userById : currentUser);
    if (currentUser?.name === "Invitado" || userById?.name === "Invitado") {
      setIsVisitor(true);
    } else {
      setIsVisitor(false);
    }
  }, [currentUser, userById, setIsVisitor]);

  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route path="app" element={<LayoutApp />}>
          <Route index element={<Hola />} />
          {/* Company */}
          <Route 
            path="company-formation" 
            element={<CompanyFormation />} 
          />
          <Route
            path="form-company-formation"
            element={<FormCompanyFormation />}
          />
          {/* Document */}
          <Route
            path="company-formation-doc"
            element={<CompanyFormationDoc />}
          />
          <Route path="donation-money-doc" element={<DonationMoneyDoc />} />
          <Route
            path="protocolization-assemblies-doc"
            element={<ProtocolizationAssembliesDoc />}
          />
          <Route
            path="marriage-contract-doc"
            element={<MarriageContractDoc />}
          />
          {/* Document Detail */}
          <Route
            path="company-formation-id/:companyId"
            element={<TransactionOnlineDetail />}
          />
          <Route
            path="donation-money-id/:donationId"
            element={<DonationMoneyDetail />}
          />
          <Route
            path="marriage-contract-id/:marriageId"
            element={<MarriageContractDetail />}
          />
          <Route
            path="protocolization-assemblies-id/:protocolizationId"
            element={<ProtocolizationAssembliesDetail />}
          />
          {/* List of Documents */}
          <Route element={<ProtectedRoute user={user} />}>
            <Route
              path="welcome-companies-list"
              element={<WelcomeCompaniesList />}
            />
          </Route>

          <Route path="company-detail" element={<CompanyDetail />} />
          {/* Marriage contract */}
          <Route path="marriage-contract" element={<MarriageContract />} />
          <Route
            path="form-marriage-contract"
            element={<FormMarriageContract />}
          />
          {/* Protocolization assemblies */}
          <Route
            path="protocolization-assemblies"
            element={<ProtocolizationAssemblies />}
          />
          <Route
            path="form-protocolization"
            element={<FormProtocolization />}
          />
          {/* Transaction online */}
          <Route
            path="transaction-online"
            element={<PageTransactionOnline />}
          />
          <Route path="transaction-online-success" element={<SuccessForm />} />
          {/* Donation money */}
          <Route path="donation-money" element={<DonationMoney />} />
          <Route path="form-donation" element={ <NewFormDonation /> } />
          {/* <Route path="form-donation" element={ <FormDonation /> } /> */}
          {/* Auth */}
          <Route path="form-login" element={<FormLogin />} />
          {/* Chat */}
          <Route path="chat" element={<SimpleChat />} />
          {/* Pages */}
          <Route path="profile" element={<Profile />} />
          {/* Admin-View */}
          <Route path="um-admin" element={<AdminView />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RoutesApp;
