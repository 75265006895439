import {
 TEST
} from './constants';

const testReducer = (state, action) => {
  switch (action.type) {
    case TEST:
      return {
        ...state,
        list: action.list[0]
      }
    default:
      return state;
  }
}

export default testReducer;
