import { useState, useContext, useEffect } from "react";
import { Grid, Typography, Divider } from "@mui/material";
import CardAppTransaction from "components/card-app-transaction/card-app-transaction";
import { cards } from "./constants";
import Login from "components/auth-user/login";
import Register from "components/auth-user/signup";
import CardUser from "components/card-user/CardUser";
import { useStateValue } from "context/store";
import useStyles from "./styles";
import SimpleChat from "pages/PageChat/pageChat";
import { ChatControllerContext } from "context/chat/ChatControllerContext";
import { VisitorsControllerContext } from "context/visitor/VisitorsControllerContext";
import { ModuleContext } from "context/modulesContext/ModuleContext";
// 
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase'
import "firebase/firestore";

const PageTransactionOnline = () => {
  const classes = useStyles();
  const [ isLogin, setIsLogin ] = useState(true);
  const { isVisitor } = useContext(VisitorsControllerContext);
  const { isChatEnabled } = useContext(ChatControllerContext);
  const [ configData, setConfigData ] = useState();

  const tokenStorage = localStorage.getItem("auth-token");
  // firebase
  const [
    {
      user: { token },
    },
  ] = useStateValue();

  const { siteConfig } = useContext(ModuleContext);

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <div className={classes.transactionContainer}>
      <div
        className={isChatEnabled ? classes.chatContainer : classes.container}
      >
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 2, sm: 8, md: 12 }}
        >
          {!isVisitor && (
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              {tokenStorage || token ? (
                <CardUser />
              ) : (
                <div className={classes.containerForm}>
                  <div className={classes.form}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                      className={classes.title}
                    >
                      Bienvenido a {configData?.name}
                    </Typography>
                    <div className={classes.containerButtons}>
                      <Typography
                        variant="body1"
                        gutterBottom
                        component="div"
                        className={classes.buttons}
                        onClick={() => setIsLogin(true)}
                        style={{ cursor: "pointer" }}
                      >
                        Ingrese
                      </Typography>
                      <Typography
                        variant="body1"
                        gutterBottom
                        component="div"
                        className={classes.buttons}
                        onClick={() => setIsLogin(false)}
                        style={{ cursor: "pointer" }}
                      >
                        Registrese
                      </Typography>
                    </div>

                    <Grid container spacing={0} className={classes.highlines}>
                      <Grid item xs={1} sm={4} md={6}>
                        {isLogin ? (
                          <div className={classes.lineUp}></div>
                        ) : (
                          <Divider className={classes.lineDown} />
                        )}
                      </Grid>
                      <Grid item xs={1} sm={4} md={6}>
                        {!isLogin ? (
                          <div className={classes.lineUp}></div>
                        ) : (
                          <Divider className={classes.lineDown} />
                        )}
                      </Grid>
                    </Grid>

                    {isLogin ? <Login /> : <Register />}
                  </div>
                </div>
              )}
            </Grid>
          )}

          {isChatEnabled ? (
            <div className={classes.chatWrapper}>
              <SimpleChat />
            </div>
          ) : isVisitor ? (
            <div className={`${isVisitor && classes.centeredVisitorContainer}`}>
              <Grid item xs={6} sm={8} md={8}>
                <Typography
                  variant="h6"
                  gutterBottom
                  component="div"
                  className={classes.titleTransaction}
                >
                  Realice sus trámites online
                </Typography>
                <div className={classes.wrapper}>
                  <Grid container>
                    {cards.map((card, index) => (
                      <Grid item xs={12} sm={6} md={5} key={index}>
                        <CardAppTransaction card={card} />
                      </Grid>
                    ))}
                  </Grid>
                </div>
              </Grid>
            </div>
          ) : (
            <Grid item xs={6} sm={8} md={8}>
              <Typography
                variant="h6"
                gutterBottom
                component="div"
                className={classes.titleTransaction}
                style={{ color: configData?.baseColor }}
              >
                Realice sus trámites online
              </Typography>
              <div className={classes.wrapper}>
                <Grid container>
                  {/* {cards.map((card, index) => (
                    <Grid item xs={12} sm={6} md={5} key={index}>
                      <CardAppTransaction card={card} />
                    </Grid>
                  ))} */}
                  {
                    configData?.societies &&
                      <Grid item xs={12} sm={6} md={5}>
                        <CardAppTransaction card={cards[0]} />
                      </Grid>
                  }
                  {
                    configData?.donation &&
                      <Grid item xs={12} sm={6} md={5}>
                        <CardAppTransaction card={cards[1]} />
                      </Grid>
                  }
                  {/* <CardAppTransaction card={cards[1]} /> */}
                  {
                    configData?.protocolization &&
                      <Grid item xs={12} sm={6} md={5}>
                        <CardAppTransaction card={cards[2]} />
                      </Grid>
                  }
                  {
                    configData?.capitulations &&
                      <Grid item xs={12} sm={6} md={5}>
                        <CardAppTransaction card={cards[3]} />
                      </Grid>
                  }
                </Grid>
              </div>
            </Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};

export default PageTransactionOnline;
