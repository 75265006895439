import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

export default makeStyles(() => ({
  title: {
    color: colors.blue,
    fontWeight: 'bold',
  },
  textInput: {
    fontWeight: 'bold',
    color: colors.black,
  }
}));
