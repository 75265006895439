import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

export default makeStyles((theme) => ({
  footer: {
    background: colors.darkblue,
    padding: "8vh 14vh 3vh",
    [theme.breakpoints.down('lg')]: {
      padding: 30
    },
  },
  title: {
    fontSize: 18,
    fontWeight: "bold"
  },
  paragraph1: {
    margin: 0,
    fontSize: 12,
    marginBottom: "-1em",
    color: colors.white
  },
  paragraph2: {
    margin: 0,
    fontSize: 12,
    paddingTop: 10,
    color: colors.white
  },
  divider: {
    background: colors.white,
    marginTop: 50,
    marginBottom: 25
  },
  linksFooter: {
    marginRight: 30,
    color: colors.white,
    fontWeight: "bold",
    width: "100%"
  },
  rightsReserved: {
    color: colors.white,
    fontWeight: "bold",
  },
  socialMedia: {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down('lg')]: {
      display: "block",
      textAlign: "center",
    },
    '& img': {
      marginRight: 40,
      [theme.breakpoints.down('lg')]: {
        marginTop: 20,
      },
    },
  },
  footerResposive: {
    [theme.breakpoints.down('lg')]: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      alignItems: "center",
      marginTop: 20
    },
  }
}));