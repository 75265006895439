import React, { createContext, useState } from "react";

const initialContex = {
  isChatActive: false,
};

export const ChatControllerContext = createContext(initialContex);

export const ChatControllerContextProvider = ({ children }) => {
  const [isChatEnabled, setIsChatEnabled] = useState(
    initialContex.isChatActive
  );
  const [selectedDocument, setSelectedDocument] = useState();

  return (
    <ChatControllerContext.Provider
      value={{
        isChatEnabled,
        setIsChatEnabled,
        selectedDocument,
        setSelectedDocument,
      }}
      displayName="Chat controller context"
    >
      {children}
    </ChatControllerContext.Provider>
  );
};
