import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Popover,
} from "@mui/material";
import { EditIcon, SmsOutlinedIcon } from "./icons";

export const PopoverWidget = ({
  id,
  open,
  anchorEl,
  handleClose,
  navigateChat,
  goToEditTramite,
}) => {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "center",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "center",
        horizontal: "left",
      }}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Opciones
          </ListSubheader>
        }
      >
        <ListItemButton onClick={navigateChat}>
          <ListItemIcon>
            <SmsOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="Chatear con notario" />
        </ListItemButton>
        <ListItemButton onClick={goToEditTramite}>
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText primary="Editar trámite" />
        </ListItemButton>
      </List>
    </Popover>
  );
};
