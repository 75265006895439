export const NEXT_STEP = "NEXT_STEP";
export const BACK_STEP = "BACK_STEP";
export const ADD_DATA_COMPANY = "ADD_DATA_COMPANY";
export const CREATE_COMPANY = "CREATE_COMPANY";
export const SHAREHOLDER_COMPANY = "SHAREHOLDER_COMPANY";
export const GENERATE_PDF = "GENERATE_PDF";
export const SHAREHOLDER_PDF = "SHAREHOLDER_PDF";
export const PROXIES_COMPANY = "PROXIES_COMPANY";
export const CLEAR_DATA = "CLEAR_DATA";
export const PARTICIPATION_RATE_COMPANY = "PARTICIPATION_RATE_COMPANY";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_COMPANY_BY_ID = "GET_COMPANY_BY_ID";
export const GET_SHAREHOLDERS_BY_ID = "GET_SHAREHOLDERS_BY_ID";
export const RESET_STEP_COMPANY = "RESET_STEP_COMPANY";
export const CLEAR_COMPANY = "CLEAR_COMPANY";
export const STATUS_COMPANY = "STATUS_COMPANY";
