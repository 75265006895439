import { GET_CHANNELS, GET_USERS } from "./constants";

const chatReducer = (state, action) => {
  switch (action.type) {
    case GET_CHANNELS:
      return {
        ...state,
        channels: [...state.channels, action.channel],
      };
    case GET_USERS:
      return {
        ...state,
        chatUsers: [...state.chatUsers, action.user],
      };
    default:
      return state;
  }
};

export default chatReducer;
