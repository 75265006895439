import { useEffect, useCallback, useContext, useState, useRef } from "react";
import {
  Container,
  Avatar,
  Divider,
  Typography,
  Stack,
  Box,
  List,
  Pagination,
} from "@mui/material";
import { colors } from "constants/colors";
import { useStateValue } from "context/store";
import useStyles from "./styles";
import { getDocumentsByUser } from "context/user/actions";
import { FirebaseContext } from "server";
import usePagination from "./pagination";
import { StatusTable } from "components/data-table/status-table";
import {
  CompanyFormationIcon,
  DonatingMoneyIcoWhite,
  MarriageContractIconWhite,
  ProtocolizationAssembliesIconWhite,
  MoreVertIcon,
} from "widgets/icons";
import { useNavigate } from "react-router-dom";
import { navigateTypeForm } from "hooks/login-forms";
import { PopoverWidget } from "widgets/popover";
import { ChatControllerContext } from "context/chat/ChatControllerContext";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "firebase/firestore";

const CardUser = () => {
  const classes = useStyles();
  // useState
  const [page, setPage] = useState(1);
  const [currentDocument, setCurrentDocument] = useState(null);
  // storage
  const { current: currentUser } = useRef(
    JSON.parse(localStorage.getItem("current-user"))
  );
  const { isChatEnabled, setIsChatEnabled, setSelectedDocument } = useContext(
    ChatControllerContext
  );

  // constants
  const [
    {
      user: { userById, documentsByuser },
    },
    dispatch,
  ] = useStateValue();
  const PER_PAGE = 2;
  const navigate = useNavigate();

  const nameDocument = {
    donation: "Donación de dinero",
    protocolization: "Protocolización de asambleas",
    companyFormation: "Constitución de sociedades",
    marriage: "Capitulaciones Matrimoniales",
  };

  const iconDocument = {
    donation: DonatingMoneyIcoWhite,
    companyFormation: CompanyFormationIcon,
    marriage: MarriageContractIconWhite,
    protocolization: ProtocolizationAssembliesIconWhite,
  };

  const [anchorEl, setAnchorEl] = useState(null);

  // Popover
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleClick = (event, document) => {
    setAnchorEl(event.currentTarget);
    setCurrentDocument(document);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentDocument(null);
  };

  // pagination
  const count = Math.ceil(documentsByuser.length / PER_PAGE);
  const _DATA = usePagination(documentsByuser, PER_PAGE);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  // firebase
  const firebase = useContext(FirebaseContext);

  const getUserDocuments = useCallback(async () => {
    const userId = currentUser ? currentUser?.userId : userById?.userId;
    if (userId) {
      await getDocumentsByUser(userId, firebase, dispatch);
    }
  }, [dispatch, firebase, currentUser, userById]);

  useEffect(() => {
    if (!documentsByuser.length) {
      getUserDocuments();
    }
  }, [documentsByuser.length, getUserDocuments]);

  const navigateChat = () => {
    setSelectedDocument(currentDocument);
    if (!isChatEnabled) {
      setIsChatEnabled(true);
    }
  };

  const goToEditTramite = () => {
    navigateTypeForm(
      currentDocument?.documentName,
      navigate,
      currentDocument?.id,
      null
    );
  };

  const infoUserStorage = localStorage.getItem("current-user");
  const infoUser = JSON.parse(infoUserStorage);

  const goProfile = () => navigate("/app/profile");

  const [configData, setConfigData] = useState();

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  // const [ prueba, setPrueba ] = useState(null);
  // console.log(prueba);

  return (
    <Container>
      <div className={classes.container}>
        {/* <TestForm /> */}
        <div 
          style={{
            backgroundColor: configData?.baseColor,
            borderRadius: 10,
            padding: 30,
            width: 300,
            minHeight: 300,
          }}
        >
          <div className={classes.flexCard}>
            <Avatar
              alt="Remy Sharp"
              src={infoUser?.avatar || require("assets/png/avatar.png").default}
              sx={{ width: 80, height: 81, cursor: "pointer" }}
              onClick={goProfile}
            />

            <div className={classes.contentText}>
              <p className={classes.greet}>Hola,</p>
              <p className={classes.textName}>
                {currentUser ? currentUser?.name : userById?.name}
              </p>
              <p className={classes.textCurp}>
                CURP #
                {currentUser
                  ? currentUser?.documentNumber
                  : userById?.documentNumber}
              </p>
            </div>
          </div>

          <div className={classes.divider}>
            <Divider style={{ background: colors.white }} />
          </div>

          <Stack mt={2}>
            <Typography variant="h6" fontWeight="bold" color="white">
              Trámites
            </Typography>
          </Stack>

          {_DATA.currentData()?.length ? (
            <>
              <List>
                {_DATA.currentData().map((document) => (
                  // setPrueba(document),
                  <Box
                    style={{
                      borderRadius: 5,
                      padding: 10,
                      backgroundColor: configData?.proceedingsColor,
                    }}
                    mt={1}
                    key={document.id}
                  >
                    <div className={classes.proceedings}>
                      {/* image */}
                      <img
                        src={iconDocument[document.documentName]}
                        alt=""
                        color="white"
                        width="45"
                        height="45"
                      />
                      {/* content */}
                      <div key={document?.id}>
                        <Stack display="block" ml={2}>
                          <Typography
                            variant="body1"
                            fontWeight="bold"
                            color="white"
                          >
                            {nameDocument[document?.documentName]}
                          </Typography>
                          <Typography fontSize={12} color="white">
                            Trámite #{document?.createdDateTime}
                          </Typography>
                        </Stack>
                        <div
                          style={{
                            display: "flex",
                            marginTop: 2,
                            marginLeft: 16,
                          }}
                        >
                          <Typography fontSize={12} color="white">
                            Estado:{" "}
                          </Typography>
                          <Box className={classes.cardStatus}>
                            <Typography fontSize={12} color="white">
                              <StatusTable
                                type={
                                  document?.status
                                    ? document?.status
                                    : "pending"
                                }
                              />
                            </Typography>
                          </Box>
                        </div>
                      </div>
                      {/* chat */}
                      <Stack
                        ml={1}
                        display="flex"
                        //
                        aria-describedby={id}
                        variant="contained"
                        onClick={(e) => handleClick(e, document)}
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          height: 30,
                          width: 30,
                          backgroundColor: configData?.iconsColor,
                          borderRadius: 50,
                          color: configData?.baseColor,
                          cursor: "pointer",
                        }}
                      >
                        <MoreVertIcon style={{ fontSize: 18 }} />
                      </Stack>

                      <PopoverWidget
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        navigateChat={navigateChat}
                        goToEditTramite={goToEditTramite}
                      />
                    </div>
                  </Box>
                ))}
              </List>

              <Pagination
                count={count}
                size="small"
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
              />
            </>
          ) : (
            <Typography color="white">
              Aun no tienes trámites creados
            </Typography>
          )}
        </div>
      </div>
    </Container>
  );
};

export default CardUser;
