import ErrorComponent from "./ErrorComponent";

function ViewAppearingElementComponent({ donationData, editingIndex }) {
  return (
    <>
      <div className="inputs__elements__style pd-bottom">
        <p>Tipo de compareciente </p>
        <input
          type="text"
          name="appearing"
          disabled={true}
          value={donationData[editingIndex]?.appearing}
        />
      </div>
      <div className="first__section">
        <div className="inputs__elements__style">
          <p>Nombres *</p>
          <input
            type="text"
            name="name"
            disabled={true}
            value={donationData[editingIndex]?.name}
          />
        </div>
        <div className="inputs__elements__style">
          <p>Apellido paterno *</p>
          <input
            type="text"
            name="lastName"
            disabled={true}
            value={donationData[editingIndex]?.lastName}
          />
        </div>
        <div className="inputs__elements__style">
          <p>Apellido materno *</p>
          <input
            type="text"
            name="lastName2"
            disabled={true}
            value={donationData[editingIndex]?.lastName2}
          />
        </div>
        <div className="inputs__elements__style">
          <p>Nacionalidad *</p>
          <input
            type="text"
            disabled
            value={donationData[editingIndex]?.country}
          />
        </div>
        <div className="inputs__elements__style">
          <p>Lugar de nacimiento *</p>
          <input
            type="text"
            disabled
            value={donationData[editingIndex]?.state}
          />
        </div>
        <div className="inputs__elements__style">
          <p>Fecha de nacimiento *</p>
          <input
            type="date"
            name="dateBirth"
            disabled={true}
            value={donationData[editingIndex]?.dateBirth}
          />
        </div>
        <div className="inputs__elements__style-check">
          <p>Estado civil *</p>
          <div className="checkbox__elements__style">
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={"soltero"}
                checked={donationData[editingIndex]?.maritalRadioValue?.soltero}
                disabled={true}
              />
              Soltero(a)
            </label>
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={"casado"}
                checked={donationData[editingIndex]?.maritalRadioValue?.casado}
                disabled={true}
              />
              Casado(a)
            </label>
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={"unionLibre"}
                checked={
                  donationData[editingIndex]?.maritalRadioValue?.unionLibre
                }
                disabled={true}
              />
              Unión libre
            </label>
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={"divorciado"}
                checked={
                  donationData[editingIndex]?.maritalRadioValue?.divorciado
                }
                disabled={true}
              />
              Divorciado
            </label>
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={"viudo"}
                checked={donationData[editingIndex]?.maritalRadioValue?.viudo}
                disabled={true}
              />
              Viudez
            </label>
          </div>
        </div>
        <div className="inputs__elements__style">
          <p>Ocupación *</p>
          <input
            type="text"
            name="occupation"
            disabled={true}
            value={donationData[editingIndex]?.occupation}
          />
        </div>
        <div className="inputs__elements__style">
          <p>RFC *</p>
          <input
            type="text"
            name="rfc"
            disabled={true}
            value={donationData[editingIndex]?.rfc}
          />
        </div>
        <div className="inputs__elements__style">
          <p>CURP *</p>
          <input
            type="text"
            name="curp"
            disabled={true}
            value={donationData[editingIndex]?.curp}
          />
        </div>
        <div className="inputs__elements__style-check">
          <p>Identificación *</p>
          <div className="checkbox__elements__style">
            <label>
              <input
                type="radio"
                name="identification"
                value={"credential"}
                checked={
                  donationData[editingIndex]?.identificationRadioValue
                    ?.credential
                }
                disabled={true}
              />
              Credencial de elector
            </label>
            <label>
              <input
                type="radio"
                name="identification"
                value={"passport"}
                checked={
                  donationData[editingIndex]?.identificationRadioValue?.passport
                }
                disabled={true}
              />
              Pasaporte
            </label>
          </div>
        </div>
      </div>
      <p className="address__title">Domicilio *</p>
      <div className="second__section">
        <div className="inputs__elements__style inputs__elements__style-second-section">
          <p>Calle y número</p>
          <input
            type="text"
            name="streetNumber"
            disabled={true}
            value={donationData[editingIndex]?.streetNumber}
          />
        </div>
        <div className="inputs__elements__style inputs__elements__style-second-section">
          <p>Colonia</p>
          <input
            type="text"
            name="colony"
            disabled={true}
            value={donationData[editingIndex]?.colony}
          />
        </div>
        <div className="inputs__elements__style inputs__elements__style-second-section">
          <p>Estado *</p>
          <input
            type="text"
            disabled={true}
            value={donationData[editingIndex]?.liveState}
          />
        </div>
        <div className="inputs__elements__style inputs__elements__style-second-section">
          <p>Municipio *</p>
          <input
            type="text"
            disabled={true}
            value={donationData[editingIndex]?.liveMunicipality}
          />
        </div>
        <div className="inputs__elements__style width__100 flex__element">
          <div className="inputs__elements__style-second-section">
            <p>C.P *</p>
            <input
              type="text"
              name="cp"
              disabled={true}
              value={donationData[editingIndex]?.cp}
            />
          </div>
          <div className="files">
            <button className="container-btn-file-view" type="button">
              Comprobante de domicilio
              <label htmlFor="proof-of-address"></label>
            </button>
            {
                donationData[editingIndex]?.addressFileName && (
                    <a target="_blank" rel="noreferrer" href={`${donationData[editingIndex]?.addressFile}`} className="files__text-label">
                    <i className="fa-solid fa-download"></i>
                    {
                      donationData[editingIndex]?.addressFileName?.length < 20
                        ? donationData[editingIndex]?.addressFileName?.substring(0, 20)
                        : donationData[editingIndex]?.addressFileName?.substring(0, 20) + '...'
                    }
                  </a>
                )
            }
          </div>
        </div>
        <div className="inputs__elements__style">
          <p>Teléfono</p>
          <input
            type="number"
            name="phone"
            disabled={true}
            value={donationData[editingIndex]?.phone}
          />
        </div>
        <div className="inputs__elements__style ">
          <p>Correo electrónico</p>
          <input
            type="email"
            name="email"
            disabled={true}
            value={donationData[editingIndex]?.email}
          />
        </div>
        <div className="inputs__elements__style-check">
          <p>¿Cuentas con algún tipo de incapacidad? *</p>
          <div className="checkbox__elements__style">
            <label>
              <input
                type="radio"
                name="disability"
                value={"none"}
                checked={donationData[editingIndex]?.disabilityRadioValue?.none}
                disabled={true}
              />
              Ninguna
            </label>
            <label>
              <input
                type="radio"
                name="disability"
                value={"deaf"}
                checked={donationData[editingIndex]?.disabilityRadioValue?.deaf}
                disabled={true}
              />
              Sordo
            </label>
            <label>
              <input
                type="radio"
                name="disability"
                value={"mute"}
                checked={donationData[editingIndex]?.disabilityRadioValue?.mute}
                disabled={true}
              />
              Mudo
            </label>
            <label>
              <input
                type="radio"
                name="disability"
                value={"blind"}
                checked={donationData[editingIndex]?.disabilityRadioValue?.blind}
                disabled={true}
              />
              Ciego
            </label>
            <label>
              <input
                type="radio"
                name="disability"
                value={"other"}
                checked={donationData[editingIndex]?.disabilityRadioValue?.other}
                disabled={true}
              />
              Otra
            </label>
          </div>
        </div>
        <div className="files">
          <button className="container-btn-file-view" type="button">
            Documento de identificación
            <label htmlFor="identification-file"></label>
          </button>
          {
            donationData[editingIndex]?.identificationFileName && (
              <a target="_blank" rel="noreferrer" href={`${donationData[editingIndex]?.identificationFile}`} className="files__text-label">
                <i className="fa-solid fa-download"></i>
                {
                  donationData[editingIndex]?.identificationFileName?.length < 20 
                    ? donationData[editingIndex]?.identificationFileName?.substring(0, 20) 
                    : donationData[editingIndex]?.identificationFileName?.substring(0, 20) + '...'
                }
              </a>
            )
          }
        </div>
      </div>
    </>
  );
}

export default ViewAppearingElementComponent;
