export const labels = [
  { id: 1, label: "Administrador único o gerente", name: "uniqueAdministrator" },
  { id: 2, label: "Gerente general, director, etc.", name: "generalManager" },
  { id: 3, label: "Consejo de administración", name: "boardOfDirectors" },
  { id: 4, label: "Consejo de gerentes", name: "CouncilManagers " },
]

export const boardDirectors = [
  { id: 1, label: "Presidente:", name: "president" },
  { id: 2, label: "Secretario:", name: "secretary" },
  { id: 3, label: "Tesorero:", name: "treasurer" },
  { id: 4, label: "Vocal:", name: "vocal" },
]

export const exercisedIndividually = [
  { id: 1, label: "A", name: "A", value: "A" },
  { id: 2, label: "B", name: "B", value: "B" },
  { id: 3, label: "C", name: "C", value: "C" },
  { id: 4, label: "D", name: "D", value: "D" },
  { id: 5, label: "E", name: "E", value: "E" },
  { id: 6, label: "F", name: "F", value: "F" },
]

function createData(name, checkboxes) {
  return { name, checkboxes };
}

export const rows = [
  createData({ name: "takenOver1" }, exercisedIndividually),
  createData({ name: "takenOver2" }, exercisedIndividually),
  createData({ name: "takenOver3" }, exercisedIndividually),
];