import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

export const InputNumberFormat = ({ name, value, control, errors, placeholder }) => {
    return (
        <Controller
            render={({ field }) => {
                return (
                    <NumberFormat
                        size="small"
                        color="secondary"
                        variant="outlined"
                        decimalScale={0}
                        allowEmptyFormatting={false}
                        control={control}
                        prefix={"$"}
                        placeholder={placeholder}
                        fixedDecimalScale={true}
                        thousandSeparator={true}
                        {...field}
                        customInput={TextField}
                        className="input-number_format"
                        onValueChange={(c) => {
                            field.onChange(c.value);
                        }}
                    />
                );
            }}
            name={name}
            variant="outlined"
            defaultValue={value}
            control={control}
        />
    );
};
