import { useForm, FormProvider } from "react-hook-form";
import { TwoNestedInput } from "./two-nested-input";
import { backStep, nextStep } from "context/company/actions";
import { Button } from "@mui/material";
import { useStateValue } from "context/store";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { ReactComponent as LeftIcon } from "assets/svg/left-icon.svg";
import useStyles from "../styles";
import {
  addDataProtocolization,
  resetStepsProtocolization,
} from "context/protocolization/actions";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaProtocolizationTwo from "schema/ProtocolizationAssemblies/protocolization-step-two";
import ErrorForm from "components/Error-form";

const defaultValues = (protocolization) => ({
  name: protocolization?.name,
  lastname: protocolization?.lastname,
  nacionality: protocolization?.nacionality,
  state: protocolization?.state,
  municipality: protocolization?.municipality,
  dateBirth: protocolization?.dateBirth,
  ocupation: protocolization?.ocupation,
  federalRegister: protocolization?.federalRegister,
  CURP: protocolization?.CURP,
  companyBackgroundFileNit: protocolization?.companyBackgroundFileNit,
  voterCard: protocolization?.voterCard,
  passport: protocolization?.passport,
  maritalStatus: protocolization?.maritalStatus,
});

const StepTwo = () => {
  // styles
  const classes = useStyles();

  // variables
  const [
    {
      protocolization: {
        protocolInput,
        resetStepProtocolization,
        protocolizationById,
      },
    },
    dispatch,
  ] = useStateValue();

  // documentId
  const { state } = useLocation();
  let documentId = state?.documentId;

  // react hook form
  const methods = useForm({
    resolver: yupResolver(schemaProtocolizationTwo),
  });

  // functions
  const onSubmit = (data) => {
    nextStep(dispatch);
    saveDataState(data);
    resetStepsProtocolization(dispatch, {
      step_two: true,
    });
  };

  const onBackSubmit = (data) => {
    backStep(dispatch);
    saveDataState(data);
    resetStepsProtocolization(dispatch, {
      step_two: true,
    });
  };

  const saveDataState = (data) => {
    addDataProtocolization(dispatch, data);
  };

  // useEffect
  useEffect(() => {
    if (!resetStepProtocolization.step_two && documentId) {
      methods.reset(defaultValues(protocolizationById));
    }
  }, [
    documentId,
    methods,
    protocolizationById,
    resetStepProtocolization.step_two,
  ]);

  const countErrors = Object.entries(methods.formState.errors).length;

  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <TwoNestedInput
          value={protocolInput}
          protocolizationById={protocolizationById}
        />
        <div className={classes.containerButtons}>
          <Button
            variant="contained"
            className={classes.btnBackStep}
            onClick={methods.handleSubmit(onBackSubmit)}
          >
            <LeftIcon />
            Volver
          </Button>

          <Button
            variant="contained"
            className={classes.btnNextStep}
            onClick={methods.handleSubmit(onSubmit)}
          >
            Siguiente
          </Button>
        </div>
      </form>
      <ErrorForm countErrors={countErrors} />
    </FormProvider>
  );
};

export default StepTwo;
