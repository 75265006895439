import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles(() => ({
    errors: {
        padding: "5px 15px",
        fontSize: 14,
        borderRadius: 15,
        backgroundColor: colors.dangerLight,
        border: `1px solid ${colors.danger}`,
        color: colors.danger,
        textAlign: "center",
        maxWidth: "300px",
        margin: "0 auto",
        fontWeight: "bold",
    },
}));
