import DonationFormComponent from "components/donationComponent/DonationFormComponent";
import React, { useContext, useEffect, useState } from "react";
import "./styles.css";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { addDoc, collection, setDoc } from "firebase/firestore";
import {db} from '../../../firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  doc,
  getDoc,
} from 'firebase/firestore';
import { EmailHook } from "hooks/send-email";
import { useLocation, useNavigate } from "react-router-dom";
import { FirebaseContext } from "server";
import {
  getDonationById,
  updateDocx,
} from "context/donation/actions";
import jsPDF from "jspdf";
import { useStateValue } from "context/store";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaDonationForm from "schema/DonationMoney/schema-donation-form";
import ValidateFormDonation from "hooks/validate-form-donation";
import { getCreatedTimeValue } from "utils";
import { ToastContainer } from "react-toastify";
import LoginFormNull from "pages/LoginFormNull/login-form-null";

function NewFormDonation() {

  const onValidate = (data) => {
    let errors = {};
    const regexText = /^[a-zA-Z\s]*$/;
    const regexNumber = /^[0-9]*$/;
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

    data.map((item, index) => {

      if(!item?.appearing) {
        errors.appearing = "Este campo es obligatorio";
      }

      if(!item?.name.trim()) {
        errors.name = "Este campo es obligatorio";
      } else if (!regexText.test(item.name)) {
        errors.name = "Solo se permiten letras";
      }

      if(!item?.lastName.trim()) {
        errors.lastName = "Este campo es obligatorio";
      }

      if(!item?.lastName2.trim()) {
        errors.lastName2 = "Este campo es obligatorio";
      }

      if(item?.country === "0") {
        errors.country = "Este campo es obligatorio";
      }

      if(item?.state === "0") {
        errors.state = "Este campo es obligatorio";
      }

      if(!item?.dateBirth.trim()) {
        errors.dateBirth = "Este campo es obligatorio";
      }

      if(!item?.occupation.trim()) {
        errors.occupation = "Este campo es obligatorio";
      } else if (!regexText.test(item.occupation)) {
        errors.occupation = "Solo se permiten letras";
      }

      if(!item?.rfc.trim()) {
        errors.rfc = "Este campo es obligatorio";
      } else if(item.rfc.trim().length < 13 || item.rfc.trim().length > 13) {
        errors.rfc = "Debe tener 13 caracteres";
      }

      if(!item?.curp.trim()) {
        errors.curp = "Este campo es obligatorio";
      } else if(item.curp.trim().length < 18 || item.curp.trim().length > 18) {
        errors.curp = "Debe tener 18 caracteres";
      }

      if(!item?.streetNumber.trim()) {
        errors.streetNumber = "Este campo es obligatorio";
      } else if (!regexText.test(item.streetNumber)) {
        errors.streetNumber = "Solo se permiten letras";
      }

      if(!item?.colony.trim()) {
        errors.colony = "Este campo es obligatorio";
      } else if (!regexText.test(item.colony)) {
        errors.colony = "Solo se permiten letras";
      }

      if(item?.liveState === "0") {
        errors.liveState = "Este campo es obligatorio";
      }

      if(item?.liveMunicipality === "0") {
        errors.liveMunicipality = "Este campo es obligatorio";
      }

      if(!item?.cp.trim()) {
        errors.cp = "Este campo es obligatorio";
      } else if(item.cp.trim().length < 5 || item.cp.trim().length > 5) {
        errors.cp = "Debe tener 5 caracteres";
      }

      if(!item?.phone.trim()) {
        errors.phone = "Este campo es obligatorio";
      } else if (!regexNumber.test(item.phone)) {
        errors.phone = "Solo se permiten números";
      }

      if(!item?.email.trim()) {
        errors.email = "Este campo es obligatorio";
      } else if (!regexEmail.test(item.email)) {
        errors.email = "Formato de correo incorrecto";
      }
      return errors
    });
    return errors
  };

  const asyncSubmitDonationHandler = async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('current-user'));
  
      const userData = {
        userId: currentUser.userId,
        currentUser: currentUser.name,
        status: 'pending', 
        createdDateTime: getCreatedTimeValue(),
        amount: amount,
      };
  
      const donationData = {
        ...dataFormAppearing,
        ...userData,
      };

      const donationRef = await addDoc(collection(db, 'donation'), donationData);

      const transactionData = {
        documentName: 'donation',
        documentId: donationRef.id,
        createdAt: new Date(),
      };
  
      const userTransactionRef = doc(db, 'users', currentUser.userId, 'transactions', donationRef.id);
  
      await setDoc(userTransactionRef, transactionData);

      console.log('Document written with ID: ', donationRef.id)

      
      if (donationRef.id) {
        handlerPdfData(dataFormAppearing, amount);
        generatePdf(donationRef.id);
        // navigateSendEmail(donationRef.id); //! Queda pendiente para no generar correos de prueba
      }
  
      // handleSubmit(); 
    } catch (error) {
      console.error('Error al enviar los datos a Firebase:', error); 
    }
  };

  // hooks
  const { sendEmail, sendUserEmail } = EmailHook();
  const { 
    dataForm,
    setDataForm,
    dataFormAppearing,
    setDataFormAppearing,
    addAppearing,
    setAddAppearing,
    amount,
    setAmount,
    handleSubmit,
    // loading,
    setLoading,
    errors,
    loginState,
    setLoginState,
   } = ValidateFormDonation( onValidate, asyncSubmitDonationHandler );

  const [ pdfData, setPdfData ] = useState([]);
  const [ pdfAmount, setPdfAmount ] = useState("");
  const [ editingIndex, setEditingIndex ] = useState(0);
  const [ editState, setEditState ] = useState(false);
  const [ dataFormEdit, setDataFormEdit ] = useState([]);

  // generate pdf
  const docPdf = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
  });

  // constants
  const navigate = useNavigate();
  const { state } = useLocation();
  let documentId = state?.documentId;
  const typeProcedure = documentId
    ? "Donación de dinero editado con éxito"
    : "Donación de dinero";
  const [
    {
      donation: { donationById },
    },
    dispatch,
  ] = useStateValue();

   // firebase
   const firebase = useContext(FirebaseContext);

  // react hook form
  const methods = useForm({
    resolver: yupResolver(schemaDonationForm),
  });

   useEffect(() => {
    methods.reset(donationById);
  }, [donationById, methods]);

  useEffect(() => {
    const fetchDonationById = async () => {
      if (documentId) {
        await getDonationById(documentId, firebase, dispatch);
      }
    };
    fetchDonationById();
  }, [documentId, firebase, dispatch]);
 
  const handlerPdfData = (data, amount) => {
    setPdfData(data);
    setPdfAmount(amount);
  };

  const navigateSendEmail = (documentId) => {
    const emailWasSent = sendEmail(typeProcedure, documentId);
    const emailUserWasSent = sendUserEmail(typeProcedure);
    if (emailWasSent && emailUserWasSent) {
      navigate("/app/transaction-online-success", {
        replace: true,
        state: {
          src: typeProcedure,
        },
      });
    }
  };
  
  const generatePdf = async (donationId) => {
    let pdfjs = document.querySelector("#new-donation");
    console.log("pdfjs====>", pdfjs);
    const storage = getStorage();
    docPdf.html(pdfjs, {
      margin: [20, 0, 20, 0],
      callback: function (doc) {
        const metadata = {
          contentType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        };
        let blob = doc.output("blob");
        const storageRef = ref(
          storage,
          `Documents/${donationId}/output${Date.now()}.pdf`
        );
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              updateDocx(firebase, donationId, url).then((res) => {
                // navigateSendEmail(donationId);
              });
            })
            .catch((error) => {
              console.log("error generatePdf", error);
              setLoading(false);
            });
        });
      },
    });
  };

  // useEffect(() => {

  // }, [dataFormAppearing, addAppearing]);

  useEffect(() => {
    if(documentId) {
      setEditState(true);
      getDonationDocument(documentId);
    }
  }, [documentId]);

  async function getDonationDocument(documentId) {
    const docRef = doc( db, 'donation', documentId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      for( let key in data ) {
        if(!isNaN(key)) {
          setDataFormEdit((prevState) => [...prevState, data[key]]);
          // setAddAppearing([ ...addAppearing, Number(key) ]);
        }
      }
      setAmount(data.amount);
    } else {
      console.log('No such document!');
    }
  };

  useEffect(() => {
    if(dataFormEdit.length > 0) {
      setDataFormAppearing(dataFormEdit);
    }
    dataFormAppearing.map((item, index) => {
      if(item?.appearing) {
        setAddAppearing((prevState) => [...prevState, index]);
      }
      return item;
    });
  }, [dataFormEdit]);

  const asyncEditDonationHandler = async () => {
    try {
      const currentUser = JSON.parse(localStorage.getItem('current-user'));
  
      const userData = {
        userId: currentUser.userId,
        currentUser: currentUser.name,
        status: 'pending', 
        createdDateTime: getCreatedTimeValue(),
        amount: amount,
      };
  
      const donationData = {
        ...dataFormAppearing,
        ...userData,
      };

      const donationRef = await setDoc(doc(db, 'donation', documentId), donationData);

      const transactionData = {
        documentName: 'donation',
        documentId: documentId,
        createdAt: new Date(),
      };
  
      const userTransactionRef = doc(db, 'users', currentUser.userId, 'transactions', documentId);
  
      await setDoc(userTransactionRef, transactionData);

      // console.log('Document written with ID: ', documentId);

      if (documentId) {
        handlerPdfData(dataFormAppearing, amount);
        generatePdf(documentId);
        // navigateSendEmail(documentId); //! Queda pendiente para no generar correos de prueba
      }

      navigate("/app/transaction-online-success", {
        replace: true,
        state: {
          src: typeProcedure,
        },
      });
  
      // handleSubmit(); 
    } catch (error) {
      console.error('Error al enviar los datos a Firebase:', error); 
    }
  };
      

  return (
    <div className="donation__container">
      <h2>Donación de Dinero</h2>
      <p className="donation__container-description">
        Trámite legal que se realiza entre parientes de primer grado que desean
        realizar donaciones voluntarias, libres de impuestos, a sus hijos.
      </p>
      <ToastContainer />
      {
        loginState &&
          <div
            style={{
              position: "fixed",
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              borderRadius: 10,
              border: '1px solid #295AA0',
              zIndex: 9,
            }}
          >
            <LoginFormNull
              setFormLoginState={setLoginState}
            />
          </div>
      }
      <form className="principal__component" onSubmit={handleSubmit}>
        <DonationFormComponent
          dataForm={dataForm}
          setDataForm={setDataForm}
          dataFormAppearing={dataFormAppearing}
          setDataFormAppearing={setDataFormAppearing}
          addAppearing={addAppearing}
          setAddAppearing={setAddAppearing}
          amount={amount}
          setAmount={setAmount}
          pdfData={pdfData}
          pdfAmount={pdfAmount}
          errors={errors}
          editingIndex={editingIndex}
          setEditingIndex={setEditingIndex}
        />

        {
          editState ?
            <button
              className="submit-button"
              type="button"
              onClick={asyncEditDonationHandler}
            >
              Editar
              <RigthIcon />
            </button>
          :
            <button 
              className="submit-button"
              type="submit"
              // onClick={asyncSubmitDonationHandler}
            >
              Enviar
              <RigthIcon />
            </button>
        }
      </form>
    </div>
  );
}

export default NewFormDonation;
