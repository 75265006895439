import { Controller } from "react-hook-form";
import { FormControlLabel, RadioGroup, Radio } from '@mui/material';

export const InputRadio = ({ name, value, control, errors, labels }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value ?? ""}
      render={({ field }) => (
      <RadioGroup {...field} row>
        {labels.map(label => (
          <FormControlLabel
            key={label.id}
            value={label.label}
            control={<Radio size="small" />}
            label={label.label}
            className="input-radio-button"
          />
        ))}
      </RadioGroup>
      )}
    />
  )
};
