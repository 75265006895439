import { useFormContext } from "react-hook-form";
import { InputEmail } from "components/inputs/input-email";
import { InputPassword } from "components/inputs/input-password";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import "firebase/firestore";
import { useState } from "react";
import { useEffect } from "react";

export const LoginNestedInput = () => {

  const [configData, setConfigData] = useState();

  const {
    control,
    formState: { errors },
  } = useFormContext(); // retrieve all hook methods

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <>
      <div style={{ marginBottom: 20, }}>
        <InputEmail
          name="email"
          control={control}
          errors={errors}
          className="input-email"
          placeholder="correo electronico"
          configData={configData}
          style={{ borderColor: `${configData?.baseColor} !important`, outlineColor: `${configData?.baseColor} !important` }}
        />
      </div>

      <InputPassword
        name="password"
        label="Contraseña"
        control={control}
        errors={errors}
        style={{ borderColor: `${configData?.baseColor} !important`, outlineColor: `${configData?.baseColor} !important` }}
      />
    </>
  );
};