import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles((theme) => ({
  container: {
    minHeight: "calc(100vh - 80px)",
    display: "grid",
    placeItems: "center",
    fontFamily: "poppinsregular",
    [theme.breakpoints.down("sm")]: {
      marginTop: 30,
    },
  },
  content: {
    width: "70%",
  },
  title: {
    color: (props) => `${props.baseColor}`,
    textAlign: "center",
    marginBottom: 40,
    lineHeight: 1.5,
    fontFamily: "poppinsmedium",
    [theme.breakpoints.down("sm")]: {
      fontSize: "30px !important",
    },
  },
  contentDocuments: {
    backgroundColor: colors.ligthGray,
    padding: 18,
    borderRadius: 10,
    marginTop: 20,
    "& h6": {
      color: (props) => `${props.baseColor}`,
      fontWeight: "bold",
    },
  },
  ul: {
    listStyle: "circle",
    color: (props) => `${props.baseColor}`,
    paddingLeft: 18,
    margin: 0,
    "& li": {
      color: colors.black,
    },
  },
  btnNextStep: {
    background: (props) => `${props.baseColor} !important`,
    borderRadius: 20,
    fontWeight: "bold",
    width: 150,
    display: "flex",
    justifyContent: "space-between",
  },
  containerButton: {
    display: "flex",
    marginTop: 20,
    justifyContent: "center",
    fontFamily: "avenirnextbold",
  },
  video: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
    },
  },
}));
