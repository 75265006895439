import commonsReducer from "./commons/reducer";
import testReducer from "./test/reducer";
import userReducer from "./user/reducer";
import companyReducer from "./company/reducer";
import chatReducer from "./chat/reducer";
import protocolizationReducer from "./protocolization/reducer";
import donationReducer from "./donation/reducers";
import marriageReducer from "./marriage/reducers";

export const indexReducer = (
  { commons, test, user, company, protocolization, chat, donation, marriage },
  action
) => {
  return {
    commons: commonsReducer(commons, action),
    test: testReducer(test, action),
    user: userReducer(user, action),
    company: companyReducer(company, action),
    protocolization: protocolizationReducer(protocolization, action),
    chat: chatReducer(chat, action),
    donation: donationReducer(donation, action),
    marriage: marriageReducer(marriage, action),
  };
};
