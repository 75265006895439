import { useStateValue } from "context/store";
import moment from "moment";
import { Typography } from "@mui/material";

const ViewerPDF = () => {
  const [
    {
      company: { companyInput, shareholder },
    },
  ] = useStateValue();

  return (
    <div style={{ width: "600px", margin: "auto" }}>
      <div id="content" style={{ width: "600px" }}>
        <div className="header-pdf">
          <h3 style={{ margin: 0 }}>
            Constitución de sociedades
            <hr />
          </h3>
        </div>
        <div className="body-pdf" style={{ padding: "0 20px" }}>
          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              Tipo de sociedad
            </Typography>
          </div>

          <div>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              style={{ fontFamily: "Arial" }}
              gutterBottom
            >
              <b>Tipo de sociedad:</b>
            </Typography>
            <div style={{ fontSize: 10 }}>
              {companyInput?.typeSociety ? companyInput.typeSociety : "N/A"}
            </div>
          </div>
          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              marginTop: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Razon social 1:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.denomination1
                  ? companyInput.denomination1
                  : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Razon social 2:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.denomination2
                  ? companyInput.denomination2
                  : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Razon social 3:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.denomination3
                  ? companyInput.denomination3
                  : "N/A"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Razon social 4:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.denomination4
                  ? companyInput.denomination4
                  : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Razon social 5:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.denomination5
                  ? companyInput.denomination5
                  : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Objeto social:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.socialObject ? companyInput.socialObject : "N/A"}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              Domicilio social:
            </Typography>
          </div>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Domicilio social:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.registeredOffice
                  ? companyInput.registeredOffice
                  : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Monto en pesos:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.amountPesos ? companyInput.amountPesos : "N/A"}
              </div>
            </div>
          </div>

          <Typography
            style={{
              fontFamily: "Arial",
              fontSize: 12,
              color: "black",
              marginLeft: 25,
            }}
          >
            <b>Socios o accionistas:</b>
          </Typography>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            {companyInput.participationRate &&
              companyInput.participationRate.map((participationRate) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: 15,
                  }}
                >
                  <div>
                    <Typography
                      className="subtitle2"
                      variant="subtitle2"
                      style={{ fontFamily: "Arial" }}
                      gutterBottom
                    >
                      <b>Nombre Accionista: </b>
                    </Typography>
                    <div style={{ fontSize: 10 }}>
                      {participationRate?.nameShareholder}
                    </div>
                  </div>
                  <div>
                    <Typography
                      className="subtitle2"
                      variant="subtitle2"
                      style={{ fontFamily: "Arial" }}
                      gutterBottom
                    >
                      <b>Porcentaje de participación: </b>
                    </Typography>
                    <div style={{ fontSize: 10 }}>
                      {participationRate?.percentShareholder}
                    </div>
                  </div>
                </div>
              ))}

            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Acciones: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.action ? companyInput.action : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Valor de cada accion: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.valueEachAction
                  ? companyInput.valueEachAction
                  : "N/A"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nacionalidad: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.nacionality ? companyInput.nacionality : "N/A"}
              </div>
            </div>
          </div>

          <Typography
            style={{
              fontFamily: "Arial",
              fontSize: 12,
              color: "black",
              marginLeft: 25,
            }}
          >
            <b>Administración:</b>
          </Typography>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Administración: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.administration
                  ? companyInput.administration
                  : "N/A"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nombre de gerente: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.nameManager ? companyInput.nameManager : "N/A"}
              </div>
            </div>
          </div>

          <Typography
            style={{
              fontFamily: "Arial",
              fontSize: 12,
              color: "black",
              marginLeft: 25,
            }}
          >
            <b>Consejo de administración o consejo de gerentes:</b>
          </Typography>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Presidente: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.president ? companyInput.president : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Secretario: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.secretary ? companyInput.secretary : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Tesorero: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.treasurer ? companyInput.treasurer : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Vocal: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.vocal ? companyInput.vocal : "N/A"}
              </div>
            </div>
          </div>

          <Typography
            style={{
              fontFamily: "Arial",
              fontSize: 12,
              color: "black",
              marginLeft: 25,
            }}
          >
            <b>Facultades:</b>
          </Typography>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            {companyInput.proxies.length > 0 &&
              companyInput.proxies.map((proxi) => (
                <>
                  <div>
                    <Typography
                      className="subtitle2"
                      variant="subtitle2"
                      style={{ fontFamily: "Arial" }}
                      gutterBottom
                    >
                      <b>Nombre apoderado: </b>
                    </Typography>
                    <div style={{ fontSize: 10 }}>{proxi?.takenOver}</div>
                  </div>
                  <div>
                    <Typography
                      className="subtitle2"
                      variant="subtitle2"
                      style={{ fontFamily: "Arial" }}
                      gutterBottom
                    >
                      <b>Para ejercer de forma individual: </b>
                    </Typography>
                    <div style={{ fontSize: 10 }}>
                      {proxi?.nestedArray?.map((nested) => {
                        const proxies = [];
                        if (nested.field1) proxies.push("Pleitos y cobranzas");
                        if (nested.field2)
                          proxies.push("Actos de administración");
                        if (nested.field3) proxies.push("Cuentas bancarias");
                        if (nested.field4) proxies.push("Actos de dominio");
                        if (nested.field5) proxies.push("Cambiario");
                        if (nested.field6)
                          proxies.push("Facultad de delegación");
                        return proxies.map((item) => `${item} - `);
                      })}
                    </div>
                  </div>
                  <div>
                    <Typography
                      className="subtitle2"
                      variant="subtitle2"
                      style={{ fontFamily: "Arial" }}
                      gutterBottom
                    >
                      <b>Para ejercer de forma mancomunada: </b>
                    </Typography>
                    <div style={{ fontSize: 10 }}>
                      {proxi?.v2nestedArray?.map((nested) => {
                        const proxies = [];
                        if (nested.field1) proxies.push("Pleitos y cobranzas");
                        if (nested.field2)
                          proxies.push("Actos de administración");
                        if (nested.field3) proxies.push("Cuentas bancarias");
                        if (nested.field4) proxies.push("Actos de dominio");
                        if (nested.field5) proxies.push("Cambiario");
                        if (nested.field6)
                          proxies.push("Facultad de delegación");
                        return proxies.map((item) => `${item} - `);
                      })}
                    </div>
                  </div>
                </>
              ))}
          </div>

          <Typography
            style={{
              fontFamily: "Arial",
              fontSize: 12,
              color: "black",
              marginLeft: 25,
            }}
          >
            <b>Vigilancia comisario:</b>
          </Typography>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nombre: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.nameCommissioner
                  ? companyInput.nameCommissioner
                  : "N/A"}{" "}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>RFC: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.RFCCommissioner
                  ? companyInput.RFCCommissioner
                  : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Comentarios y observaciones: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {companyInput?.commentsCommissioner
                  ? companyInput.commentsCommissioner
                  : "N/A"}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              Socios/Accionistas:
            </Typography>
          </div>

          {shareholder &&
            shareholder?.shareholder?.map((info) => {
              let dateBirth = new Date(info?.dateBirth);
              var dateMomentObject = moment(dateBirth).locale("es").format("L");

              return (
                <>
                  <div
                    className="divider"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 10,
                      justifyContent: "space-around",
                      backgroundColor: "#F6F6F6",
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Nombre: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.name ? info.name : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Apellido paterno: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.paternalSurname ? info.paternalSurname : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Apellido materno: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.maternalSurname ? info.maternalSurname : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Nacionalidad: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.nacionality ? info.nacionality : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Lugar de nacimiento: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.placeBirth ? info.placeBirth : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Fecha de nacimiento: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {dateMomentObject ? dateMomentObject : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Estado civil: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.maritalStatus ? info.maritalStatus : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Ocupación: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.ocupation ? info.ocupation : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>RFC: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.RFC ? info.RFC : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>CURP: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.CURP ? info.CURP : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Socio se identifica con: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.identification ? info.identification : "N/A"}
                      </div>
                    </div>
                  </div>

                  <Typography
                    style={{
                      fontFamily: "Arial",
                      fontSize: 12,
                      color: "black",
                      marginLeft: 25,
                    }}
                  >
                    <b>Domicilio:</b>
                  </Typography>
                  <div
                    className="divider"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 10,
                      justifyContent: "space-around",
                      backgroundColor: "#F6F6F6",
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Calle y número: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.street ? info.street : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Colonia: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.colony ? info.colony : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Municipio: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.municipality ? info.municipality : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Estado: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.state ? info.state : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Phone: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.phone ? info.phone : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>CP: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.CP ? info.CP : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>CP: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.CP ? info.CP : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Incapacidad: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.inability ? info.inability : "N/A"}
                      </div>
                    </div>
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Correo electrónico: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.email ? info.email : "N/A"}
                      </div>
                    </div>
                  </div>

                  <Typography
                    style={{
                      fontFamily: "Arial",
                      fontSize: 12,
                      color: "black",
                      marginLeft: 25,
                    }}
                  >
                    <b>Documentos:</b>
                  </Typography>
                  <div
                    className="divider"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: 10,
                      justifyContent: "space-around",
                      backgroundColor: "#F6F6F6",
                      borderRadius: 5,
                      padding: 10,
                    }}
                  >
                    {info?.file && (
                      <div>
                        <Typography
                          className="subtitle2"
                          variant="subtitle2"
                          style={{ fontFamily: "Arial" }}
                          gutterBottom
                        >
                          <b>Cédula: </b>
                        </Typography>
                        <div style={{ fontSize: 10 }}>
                          {info?.file && Array.isArray(info?.file)
                            ? info.file[0].name
                            : typeof info?.file === "string"
                            ? info.file
                            : "NA"}{" "}
                        </div>
                      </div>
                    )}
                    <div>
                      <Typography
                        className="subtitle2"
                        variant="subtitle2"
                        style={{ fontFamily: "Arial" }}
                        gutterBottom
                      >
                        <b>Comprobante domicilio: </b>
                      </Typography>
                      <div style={{ fontSize: 10 }}>
                        {info?.homeFile && Array.isArray(info?.homeFile)
                          ? info.homeFile[0].name
                          : typeof info?.homeFile === "string"
                          ? info.homeFile
                          : "NA"}{" "}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ViewerPDF;
