import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

export default makeStyles((theme) => ({
  container: {
    width: "100%",
    display: "grid",
    placeItems: "center"
  },
  content: {
    display: "grid",
    gridTemplateRows: "auto 1fr auto",
    minHeight: 'calc(100vh - 200px)',
    [theme.breakpoints.down('sm')]: {
      marginTop: 30,
    },
  },
  title: {
    color: colors.blue,
    textAlign: "center",
    marginBottom: 40,
    lineHeight: 1.50,
    fontFamily: 'poppinsbold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
    },
  },
  contentButton: {
    marginTop: 30,
    marginBottom: 30,
    display: 'flex',
    justifyContent: 'center',
  },
  contentDocuments: {
    backgroundColor: colors.ligthGray,
    padding: 18,
    borderRadius: 10,
    marginTop: 20,
    '& h6': {
      color: colors.blue,
      fontWeight: "bold",
    }
  },
  btnNextStep: {
    background: colors.blue,
    borderRadius: 20,
    fontWeight: "bold",
    width: 150,
    display: "flex",
    justifyContent: "space-between"
  },
  ul: {
    listStyle: "circle",
    color: colors.blue,
    paddingLeft: 18,
    margin: 0,
    '& li': {
      color: colors.black,
    }
  },
  contentSpouse: {
    padding: 15,
    border: `1px solid ${colors.lightgrey}`,
    borderRadius: 10,
  }
}));
