import { IconButton, Typography } from "@mui/material";
import { colors } from "constants/colors";
import { ContentPasteOutlinedIcon, HighlightOffIcon } from "widgets/icons";

const ShowFiles = ({
  valueStateGlobal,
  valueStateLocal,
  removeFile,
  setFileState,
  multiple,
  setValue,
  name,
}) => {
  const removeFileMultiple = (currentFile) => {
    setFileState((prevState) =>
      prevState?.filter((file) => file !== currentFile)
    );
    setValue(
      name,
      valueStateLocal?.filter((file) => file !== currentFile)
    );
  };

  const openWindowWithFile = (value) => {
    if (multiple) {
      window.open(
        URL.createObjectURL(new Blob([value[0]], { type: value[0]?.type }))
      );
    } else {
      window.open(
        URL.createObjectURL(new Blob([value], { type: value?.type }))
      );
    }
  };

  const removeExtension = (filename) => {
    return filename.substring(0, filename.lastIndexOf(".")) || filename;
  };

  if (valueStateGlobal && !valueStateLocal.length) {
    return (
      <div
        key={valueStateGlobal}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "2px 10px",
          background: colors.borderGray,
          borderRadius: 50,
          marginTop: 10,
          width: 250,
        }}
      >
        <ContentPasteOutlinedIcon
          style={{ fontSize: 20, color: colors.blue }}
        />
        <Typography
          ml={1}
          mr={1}
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            width: 200,
          }}
        >
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href={`${valueStateGlobal}`}
          >
            Ver doc
          </a>
        </Typography>
      </div>
    );
  }

  if (multiple) {
    return valueStateLocal?.map((files, index) =>
      files?.map((file, subIndex) => (
        <div
          key={file.name}
          style={{
            display: "flex",
            alignItems: "center",
            padding: "2px 10px",
            background: colors.borderGray,
            borderRadius: 50,
            marginTop: 10,
            width: 250,
          }}
        >
          <ContentPasteOutlinedIcon
            style={{ fontSize: 20, color: colors.blue }}
          />
          <Typography
            ml={1}
            mr={1}
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              cursor: "pointer",
              width: 200,
            }}
          >
            <a onClick={() => openWindowWithFile(valueStateLocal[index])}>
              {removeExtension(file.name)}
            </a>
          </Typography>
          <IconButton
            onClick={() => removeFileMultiple(valueStateLocal[index])}
            size="small"
          >
            <HighlightOffIcon style={{ fontSize: 20, color: colors.blue }} />
          </IconButton>
        </div>
      ))
    );
  } else {
    return valueStateLocal?.map((file, index) => (
      <div
        key={file.name}
        style={{
          display: "flex",
          alignItems: "center",
          padding: "2px 10px",
          background: colors.borderGray,
          borderRadius: 50,
          marginTop: 10,
          width: 250,
        }}
      >
        <ContentPasteOutlinedIcon
          style={{ fontSize: 20, color: colors.blue }}
        />
        <Typography
          ml={1}
          mr={1}
          sx={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
            cursor: "pointer",
            width: 200,
          }}
        >
          <a onClick={() => openWindowWithFile(valueStateLocal[index])}>
            {removeExtension(file.name)}
          </a>
        </Typography>
        <IconButton onClick={() => removeFile()} size="small">
          <HighlightOffIcon style={{ fontSize: 20, color: colors.blue }} />
        </IconButton>
      </div>
    ));
  }
};

export default ShowFiles;
