const initialState = {
  commons: {
    message: null,
  },
  test: {
    list: null,
    newTest: null,
  },
  user: {
    token: null,
    all_users: null,
    userById: null,
    documentsByuser: [],
  },
  company: {
    activeStep: 0,
    companyInput: {},
    companyId: null,
    shareholder: { shareholder: [{}] },
    proxies: {
      proxies: [{ nestedArray: [{}], v2nestedArray: [{}] }],
      nestedArray: [{}],
      v2nestedArray: [{}],
    },
    participationRate: { participationRate: [{}] },
    pdf: null,
    shareholdersPdf: null,
    dataDocx: null,
    dataDocument: null,
    companies: [],
    companyById: {},
    shareholdersById: [],
    resetStepCompany: {
      step_one: false,
      step_two: false,
      step_three: false,
      step_four: false,
    },
  },
  protocolization: {
    protocolInput: {},
    listProtocolization: [],
    protocolizationById: {},
    pdf: null,
    resetStepProtocolization: {
      step_one: false,
      step_two: false,
      step_three: false,
      step_four: false,
    },
  },
  chat: {
    channels: [],
    chatUsers: [],
    isChatEnabled: false,
  },
  donation: {
    pdf: null,
    listDonation: [],
    donationById: {},
  },
  marriage: {
    pdf: null,
    listMarriageContract: [],
    marriageContractById: {},
  },
};

export default initialState;
