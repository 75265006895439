import Menu from './Menu';
import Footer from './Footer';

const LayoutLanding = ({ children }) => {
  return (
    <div>
			<Menu />
      {children}
      <Footer />
    </div>
  );
}

export default LayoutLanding;