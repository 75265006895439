import { useFormContext } from "react-hook-form";
import { InputEmail } from 'components/inputs/input-email';
import { InputPassword } from "components/inputs/input-password";
import { InputText } from "components/inputs/input-text";
import Grid from '@mui/material/Grid';
import { onInputNumber } from "utils";

export const SignupNestedInput = () => {

  const { control, formState: { errors } } = useFormContext(); // retrieve all hook methods

  return (
    <>
      <InputText name="displayName" control={control} errors={errors} placeholder="Nombre completo" className="input-text_signup" />

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <InputEmail name="email" control={control} errors={errors} className="input-email" placeholder="Correo electronico" type="email" />
        </Grid>

        <Grid item xs={6}>
          <InputText name="phone" control={control} errors={errors} placeholder="Teléfono" className="input-text_signup" onInput={onInputNumber} />
        </Grid>
      </Grid>

      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <InputPassword name="password" label="Contraseña" control={control} errors={errors} />
        </Grid>

        <Grid item xs={6}>
          <InputPassword name="passwordConfirm" label="Confirmar contraseña" control={control} errors={errors} />
        </Grid>
      </Grid>
    </>
  )
}