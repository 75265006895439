import { useStateValue } from "context/store";
import moment from "moment";
import { Typography } from "@mui/material";

const MarriageViewerPdf = () => {
  const [
    {
      marriage: { marriageContractById },
    },
  ] = useStateValue();

  return (
    <div style={{ width: "600px", margin: "auto" }}>
      <div id="contentPdfMarriage" style={{ width: "600px" }}>
        <div className="header-pdf">
          <h3 style={{ margin: 0 }}>
            Capitulaciones matrimoniales
            <hr />
          </h3>
        </div>
        <div
          className="body-pdf"
          style={{ padding: "0 20px" }}
        >
          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              Contrayente 1:
            </Typography>
          </div>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nombre completo: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.nameSpouseOne
                  ? marriageContractById?.nameSpouseOne
                  : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nacionalidad:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.nacionalitySpouseOne
                  ? marriageContractById.nacionalitySpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Lugar de nacimiento:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.placeBirthSpouseOne
                  ? marriageContractById.placeBirthSpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Fecha de nacimiento:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.dateBirthSpouseOne
                  ? moment(marriageContractById.dateBirthSpouseOne)
                      .locale("es")
                      .format("L")
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Estado civil:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.maritalStatusSpouseOne
                  ? marriageContractById.maritalStatusSpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>CURP:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.curpSpouseOne
                  ? marriageContractById.curpSpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>RFC:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.rfcSpouseOne
                  ? marriageContractById.rfcSpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Domicilio:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.streetNumberSpouseOne
                  ? marriageContractById.streetNumberSpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Código Postal:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.postalcodeSpouseOne
                  ? marriageContractById.postalcodeSpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Teléfono:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.phoneSpouseOne
                  ? marriageContractById.phoneSpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Correo electrónico:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.emailSpouseOne
                  ? marriageContractById.emailSpouseOne
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Fecha de la ceremonia civil:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.dateCeremonmySpouseOne
                  ? moment(marriageContractById.dateCeremonmySpouseOne)
                      .locale("es")
                      .format("L")
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nombre del juez:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.nameJudgeSpouseOne
                  ? marriageContractById.nameJudgeSpouseOne
                  : "NA"}
              </div>
            </div>
          </div>

          <div style={{ marginTop: 20, marginBottom: 10 }}>
            <Typography
              style={{
                fontSize: 13,
                fontWeight: "bold",
                fontFamily: "Arial",
              }}
            >
              Contrayente 2:
            </Typography>
          </div>

          <div
            className="divider"
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: 10,
              justifyContent: "space-around",
              backgroundColor: "#F6F6F6",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nombre completo: </b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.nameSpouseTwo
                  ? marriageContractById?.nameSpouseTwo
                  : "N/A"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nacionalidad:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.nacionalitySpouseTwo
                  ? marriageContractById.nacionalitySpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Lugar de nacimiento:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.placeBirthSpouseTwo
                  ? marriageContractById.placeBirthSpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Fecha de nacimiento:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.dateBirthSpouseTwo
                  ? moment(marriageContractById.dateBirthSpouseTwo)
                      .locale("es")
                      .format("L")
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Estado civil:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.maritalStatusSpouseTwo
                  ? marriageContractById.maritalStatusSpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>CURP:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.curpSpouseTwo
                  ? marriageContractById.curpSpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>RFC:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.rfcSpouseTwo
                  ? marriageContractById.rfcSpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Domicilio:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.streetNumberSpouseTwo
                  ? marriageContractById.streetNumberSpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Código Postal:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.postalcodeSpouseTwo
                  ? marriageContractById.postalcodeSpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Teléfono:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.phoneSpouseTwo
                  ? marriageContractById.phoneSpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Correo electrónico:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.emailSpouseTwo
                  ? marriageContractById.emailSpouseTwo
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Fecha de la ceremonia civil:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.dateCeremonmySpouseTwo
                  ? moment(marriageContractById.dateCeremonmySpouseTwo)
                      .locale("es")
                      .format("L")
                  : "NA"}
              </div>
            </div>
            <div>
              <Typography
                className="subtitle2"
                variant="subtitle2"
                style={{ fontFamily: "Arial" }}
                gutterBottom
              >
                <b>Nombre del juez:</b>
              </Typography>
              <div style={{ fontSize: 10 }}>
                {marriageContractById?.nameJudgeSpouseTwo
                  ? marriageContractById.nameJudgeSpouseTwo
                  : "NA"}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarriageViewerPdf;
