import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import { InputRadio } from "components/inputs/input-radio";
import { Button, Grid, Typography } from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { InputTextarea } from "components/inputs/input-textarea";
import { labels, pointsAssembly } from "./constants";
import { colors } from "constants/colors";
import { InputFile } from "components/inputs/input-file";
import ShowFiles from "components/show-files/show-files";

export const OneNestedInput = ({ value, protocolizationById }) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext(); // retrieve all hook methods

  // useState Files
  const [fileBackground, setFileBackground] = useState([]);
  const [fileShareholders, setFileShareholders] = useState([]);
  const [fileDelegate, setFileDelegate] = useState([]);
  const [fileActSignedFile, setFileActSignedFile] = useState([]);

  return (
    <>
      <div className="divider">
        <Typography variant="subtitle1" gutterBottom>
          La ley exige protocolizar algunas de las asambleas ordinarias y todas
          las asambleas extraordinarias. Apóyese en Notaría 26 para su
          protocolización jurídica.
        </Typography>
      </div>

      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <Typography variant="subtitle2" gutterBottom color={colors.blue}>
          Los campos marcados con * son obligatorios
        </Typography>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              style={styles.title}
            >
              1. Nombre de la empresa *
            </Typography>
            <InputText
              name="companyName"
              value={value?.companyName}
              control={control}
              errors={errors}
            />
            {errors.companyName && (
              <p style={styles.errors}>{errors.companyName.message}</p>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              style={styles.title}
            >
              2. Tipo de acta *
            </Typography>
            <InputRadio
              name="typeReport"
              value={value?.typeReport}
              control={control}
              errors={errors}
              labels={labels}
            />
            {errors.typeReport && (
              <p style={styles.errors}>{errors.typeReport.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              style={styles.title}
            >
              3. Puntos a tratar en acta de asamblea/resoluciones: *
            </Typography>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              color={colors.darkGrayShade}
            >
              (En caso de haber aumentado de capital, señalar monto que se
              aumenta)
            </Typography>
            <InputTextarea
              name="assembleResolutions"
              value={value?.assembleResolutions}
              control={control}
            />
            {errors.assembleResolutions && (
              <p style={styles.errors}>{errors.assembleResolutions.message}</p>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              style={styles.title}
            >
              4. Puntos a protocolizar en el acta de asamblea *
            </Typography>
            <InputRadio
              name="actAssembly"
              value={value?.actAssembly}
              control={control}
              errors={errors}
              labels={pointsAssembly}
            />
            {errors.actAssembly && (
              <p style={styles.errors}>{errors.actAssembly.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          style={styles.title}
        >
          5. Favor anexar: *
        </Typography>

        {/* 1 */}
        <div className="divider">
          <Typography variant="subtitle2" gutterBottom component="div">
            <span style={styles.titleDocuments}>
              Antecedentes de la empresa
            </span>{" "}
            <span>(Constitutiva y asambleas posteriores)</span>
          </Typography>
          <Button
            variant="contained"
            component="label"
            style={styles.fileButton}
          >
            <InputFile
              name={`companyBackgroundFile`}
              setValue={setValue}
              control={control}
              hidden={true}
              currentFiles={fileBackground}
              setFiles={setFileBackground}
              multiple={true}
            />

            <span>Adjuntar</span>
            <div style={{ marginLeft: 15 }}>+</div>
          </Button>

          <ShowFiles
            value={value?.companyBackgroundFile}
            valueStateGlobal={protocolizationById?.companyBackgroundFile}
            valueStateLocal={fileBackground}
            setValue={setValue}
            name={"companyBackgroundFile"}
            setFileState={setFileBackground}
            multiple={true}
          />
        </div>

        {/* 2 */}
        <div className="divider">
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            style={styles.titleDocuments}
          >
            Cédulas fiscales de los accionistas y/o socios de la misma
          </Typography>
          <Button
            variant="contained"
            component="label"
            style={styles.fileButton}
          >
            <InputFile
              name={`ccShareholdersFile`}
              setValue={setValue}
              control={control}
              hidden={true}
              currentFiles={fileShareholders}
              setFiles={setFileShareholders}
              multiple={true}
            />

            <span>Adjuntar</span>
            <div style={{ marginLeft: 15 }}>+</div>
          </Button>

          <ShowFiles
            value={value?.ccShareholdersFile}
            valueStateGlobal={protocolizationById?.ccShareholdersFile}
            valueStateLocal={fileShareholders}
            setFileState={setFileShareholders}
            multiple={true}
            name={`ccShareholdersFile`}
            setValue={setValue}
          />
        </div>

        {/* 3 */}
        <div className="divider">
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            style={styles.titleDocuments}
          >
            Identificación oficial del delegado especial
          </Typography>
          <Button
            variant="contained"
            component="label"
            style={styles.fileButton}
          >
            <InputFile
              name={`delegateFile`}
              setValue={setValue}
              control={control}
              hidden={true}
              currentFiles={fileDelegate}
              setFiles={setFileDelegate}
              multiple={true}
            />

            <span>Adjuntar</span>
            <div style={{ marginLeft: 15 }}>+</div>
          </Button>

          <ShowFiles
            value={value?.delegateFile}
            valueStateGlobal={protocolizationById?.delegateFile}
            valueStateLocal={fileDelegate}
            setFileState={setFileDelegate}
            multiple={true}
            name={`delegateFile`}
            setValue={setValue}
          />
        </div>

        <div className="divider">
          <Typography
            variant="subtitle2"
            gutterBottom
            component="div"
            style={styles.titleDocuments}
          >
            Acta firmada a protocolizar y su archivo en Word
          </Typography>
          <Button
            variant="contained"
            component="label"
            style={styles.fileButton}
          >
            <InputFile
              name={`actSignedFile`}
              setValue={setValue}
              control={control}
              currentFiles={fileActSignedFile}
              hidden={true}
              setFiles={setFileActSignedFile}
              multiple={true}
            />
            <span>Adjuntar</span>
            <div style={{ marginLeft: 15 }}>+</div>
          </Button>
          <ShowFiles
            valueStateGlobal={protocolizationById?.actSignedFile}
            valueStateLocal={fileActSignedFile}
            setFileState={setFileActSignedFile}
            multiple={true}
            name={`actSignedFile`}
            setValue={setValue}
          />
        </div>
      </div>

      <div className="divider">
        <Typography variant="subtitle2" gutterBottom component="div">
          <span style={styles.titleDocuments}>* NOTA:</span>
          <br />
          Se requiere el acta a protocolizar elaborada y formada; si no la
          tienen y necesitan el servicio de elaboración de la misma, favor
          comunicarlo y les podemos canalizar con un externo a la notaría.
        </Typography>
      </div>
    </>
  );
};

const styles = {
  title: {
    color: colors.blue,
    fontWeight: "bold",
  },
  titleDocuments: {
    color: colors.darkblue,
    fontWeight: "bold",
  },
  fileButton: {
    background: colors.blue,
    color: colors.white,
    borderRadius: 20,
    fontWeight: "bold",
    width: 160,
    marginRight: 20,
    alignItems: "center",
  },
  errors: {
    padding: "5px 15px",
    fontSize: 14,
    borderRadius: 15,
    marginTop: -1,
    borderTopLeftRadius: 2,
    backgroundColor: colors.dangerLight,
    display: "inline-block",
    border: `1px solid ${colors.danger}`,
    color: colors.danger,
  },
};
