import React from "react";
import Attachment from "../../svg/Attachment";
import logoUpload from "./../../assets/svg/uploadLogo.svg";
import logoSend from "./../../assets/svg/sendButton.svg";
import emailjs from "@emailjs/browser";
import "./components.scss";

const MessageForm = ({
  handleSubmit,
  text,
  setText,
  setImg,
  userChat,
  userLogged,
  shortMode,
}) => {
  const sendEmail = () => {
    if (userChat.isOnline === false) {
      emailjs
        .sendForm(
          "service_ufsgnqc",
          "template_zzyfmee",
          "form",
          "YF23lBilOvmM5MuhY"
        )
        .then(
          (result) => {
            console.log("Éxito!", result.status, result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      console.log("User is Logged");
    }
  };

  return (
    <form
      className={`message_form1 ${shortMode ? "short" : ""}`}
      onSubmit={handleSubmit}
    >
      <div style={{ display: "none" }} id="form">
        <input type="text" name="user_name" value={userLogged?.name} />
        <input type="text" name="user_email" value={userLogged?.email} />
        <input type="text" name="user_message" value={text} />
        <input type="text" name="user_reply" value={userChat?.email} />
      </div>
      {shortMode ? (
        <>
          <div className="message-menu-options">
            <div className="input-options">
              <button className="btn1" onClick={sendEmail}>
                Responder
              </button>
              <label htmlFor="img">
                Adjuntar documento <Attachment />
              </label>
              <input
                type="file"
                id="img"
                accept="image/*"
                style={{ display: "none" }}
                onChange={(e) => setImg(e.target.files[0])}
              />
            </div>
          </div>
          <div className="messageInput">
            <input
              type="text"
              placeholder="Escribe tu mensaje aquí"
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="text-option"
            />
            <div className="hidden-options">
              <label htmlFor="img">
                <img src={logoUpload} alt="upload attachment" />
              </label>
              <button onClick={sendEmail}>
                <img src={logoSend} />
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="hidden-normal">
            <label htmlFor="img">
              <Attachment />
            </label>
            <input
              type="file"
              id="img"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => setImg(e.target.files[0])}
            />
            <div>
              <input
                type="text"
                placeholder="Escribe tu mensaje aquí"
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="text-option"
              />
            </div>
            <div className="answer-option">
              <button className="btn1" onClick={sendEmail}>
                Responder
              </button>
            </div>
          </div>
          <div className="hidden-responsive">
            <input
              type="text"
              placeholder="Escribe tu mensaje aquí"
              value={text}
              onChange={(e) => setText(e.target.value)}
              className="text-option"
            />
            <div className="responsive-options">
              <label htmlFor="img">
                <img src={logoUpload} alt="upload attachment" />
              </label>
              <button onClick={sendEmail}>
                <img src={logoSend} />
              </button>
            </div>
          </div>
        </>
      )}
    </form>
  );
};

export default MessageForm;
