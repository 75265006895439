import React, { useContext, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { OneNestedInput } from "./one-nested-input";
import useStyles from "../styles";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { Button } from "@mui/material";
import { useStateValue } from "context/store";
import {
  addDataCompany,
  getCompanyById,
  getShareholdersById,
  nextStep,
  resetStepsCompany,
} from "context/company/actions";
import { useLocation } from "react-router-dom";
import { FirebaseContext } from "server";
import { yupResolver } from "@hookform/resolvers/yup";
import schemaOneForm from "schema/CompanyFormation/schema-one-form";
import ErrorForm from "components/Error-form";

const defaultValues = (company) => ({
  typeSociety: company?.typeSociety,
  denomination1: company?.denomination1,
  denomination2: company?.denomination2,
  denomination3: company?.denomination3,
  denomination4: company?.denomination4,
  denomination5: company?.denomination5,
  socialObject: company?.socialObject,
});

const StepOne = () => {
  const classes = useStyles();
  const [partnersList, setPartnersList] = useState();

  const methods = useForm({
    resolver: yupResolver(schemaOneForm),
  });

  const [
    {
      company: { companyInput, companyById, resetStepCompany },
    },
    dispatch,
  ] = useStateValue();

  const { state } = useLocation();
  let documentId = state?.documentId;

  const firebase = useContext(FirebaseContext);

  const onSubmit = (data) => {
    nextStep(dispatch);
    resetStepsCompany(dispatch, {
      step_one: true,
    });
    saveDataState(data);
  };

  const saveDataState = (data) => {
    addDataCompany(dispatch, data);
  };

  useEffect(() => {
    const fetchCompanyById = async () => {
      if (documentId) {
        if (Object.keys(companyById).length === 0) {
          await getCompanyById(documentId, firebase, dispatch);
        }
      }
    };
    fetchCompanyById();
  }, [documentId, dispatch, firebase]);

  useEffect(() => {
    const fetchCompanyById = async () => {
      if (documentId) {
        if (Object.keys(companyById).length === 0) {
          await getShareholdersById(documentId, firebase, dispatch);
        }
      }
    };
    fetchCompanyById();
  }, [documentId, dispatch, firebase]);

  useEffect(() => {
    if (!resetStepCompany.step_one) {
      methods.reset(defaultValues(companyById));
    }
  }, [companyById, methods, resetStepCompany.step_one]);

  const countErrors = Object.entries(methods.formState.errors).length;
  return (
    <FormProvider {...methods}>
      <form autoComplete="off">
        <div style={{ marginBottom: 30 }}>
          <OneNestedInput value={companyInput} partners={setPartnersList} />

          <div className={classes.containerButtons}>
            <Button
              variant="contained"
              className={classes.btnNextStep}
              onClick={methods.handleSubmit(onSubmit)}
            >
              Siguiente
            </Button>
          </div>
          <ErrorForm countErrors={countErrors} />
        </div>
      </form>
    </FormProvider>
  );
};

export default StepOne;
