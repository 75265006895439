import { Fragment, useContext, useEffect, useState, useRef } from "react";
import { Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStateValue } from "context/store";
import { FirebaseContext } from "server";
import { colors } from "constants/colors";
// import moment from "moment";
import { makeStyles } from "@mui/styles";
import {
  getMarriageContractById,
  updateMarriageContractById,
} from "context/marriage/actions";
import { SnackAlert } from "widgets/snackAlert";
import { MarriageContractIconBlue } from "widgets/icons";
import PopupStateUi from "components/popup-state-ui/popup-state-ui";
import { Button } from "@mui/material";

const useStyles = makeStyles({
  title: {
    color: colors.blue,
    fontWeight: "bold",
  },
});

const MarriageContractDetail = () => {
  let { marriageId } = useParams();
  const navigate = useNavigate();

  // firebase
  const firebase = useContext(FirebaseContext);

  const typeStatus = {
    pending: "pendiente",
    cancelled: "cancelado",
    finished: "finalizado",
  };

  // styles
  const classes = useStyles();

  const [
    {
      marriage: { marriageContractById },
      user: { userById },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (currentUser?.rol !== "notario") {
      navigate("/app/form-login", {
        replace: true,
        state: {
          src: window.location.href,
        },
      });
    }
  }, [userById]);

  const { current: currentUser } = useRef(
    JSON.parse(localStorage.getItem("current-user"))
  );

  // useState
  const [status, setStatus] = useState(marriageContractById.status);
  const [snackBar, setSnackBar] = useState({
    message: "",
    open: false,
    severity: "",
  });

  const { message, open, severity } = snackBar;

  // constants
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    border: `1px solid ${colors.blue}`,
    color: colors.black,
    minHeight: '20px',
  }));

  // custom hooks
  useEffect(() => {
    getMarriageContractById(marriageId, firebase, dispatch);
  }, [marriageId, firebase, dispatch]);

  const handleChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await updateMarriageContractById(marriageId, firebase, newStatus);
    setSnackBar({
      message: `El estado ha cambiado a ${typeStatus[newStatus]}`,
      open: true,
      severity: "success",
    });
  };

  const handleClose = () => {
    setSnackBar({
      message: "",
      open: false,
      severity: "",
    });
  };

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }} style={{ marginTop: 50 }}>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          {/* <IconButton size="small"> */}
          <ArrowBackIcon />
          {/* </IconButton> */}
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="subtitle2"
          >
            Regresar
          </Typography>
        </div>
        <div className="divider"></div>

        <PopupStateUi
          icon={MarriageContractIconBlue}
          title="Capitulaciones Matrimoniales"
          status={status || marriageContractById.status}
          handleChange={handleChange}
        />

        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          Otros Documentos
        </Typography>
      </Box>
      <div className="divider">
        <Grid container spacing={2} mb={2}>
          <Grid item xs={6}>
            <Typography className="subtitle2" fontWeight="bold">
              Pdf
            </Typography>
            <Item>
              <Button href={marriageContractById?.urlPdf} download>
                Descargar
              </Button>
            </Item>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 6, sm: 12, md: 12 }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <div>
                <Typography
                  variant="body1"
                  gutterBottom
                  component="div"
                  className={classes.title}
                >
                  Contrayente
                </Typography>

                <div className="divider">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                  >
                    Nombre Completo *
                  </Typography>
                  <Item>{marriageContractById?.nameSpouseOne}</Item>
                </div>

                <div className="divider">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                  >
                    <Grid item xs={6} sm={6} md={4}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Nacionalidad *
                      </Typography>
                      <Item>{marriageContractById?.nacionalitySpouseOne}</Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Lugar de nacimiento *
                      </Typography>
                      <Item>{marriageContractById?.placeBirthSpouseOne}</Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Fecha de nacimiento *
                      </Typography>
                      {/* <Item>{marriageContractById?.dateBirthSpouseOne.toDate()}</Item> */}
                    </Grid>
                  </Grid>
                </div>

                <div className="divider">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Estado civil *
                      </Typography>
                      <Item>
                        {marriageContractById?.maritalStatusSpouseOne}
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Ocupación *
                      </Typography>
                      <Item>{marriageContractById?.ocupationSpouseOne}</Item>
                    </Grid>
                  </Grid>
                </div>

                <div className="divider">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        CURP *
                      </Typography>
                      <Item>{marriageContractById?.curpSpouseOne}</Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        RFC *
                      </Typography>
                      <Item>{marriageContractById?.rfcSpouseOne}</Item>
                    </Grid>
                  </Grid>
                </div>

                <div className="divider">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                  >
                    Domicilio *
                  </Typography>
                  <Item>{marriageContractById?.streetNumberSpouseOne}</Item>
                </div>

                <div className="divider">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Código Postal *
                      </Typography>
                      <Item>{marriageContractById?.postalcodeSpouseOne}</Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Teléfono *
                      </Typography>
                      <Item>{marriageContractById?.phoneSpouseOne}</Item>
                    </Grid>
                  </Grid>
                </div>

                <div className="divider">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                  >
                    Correo electrónico *
                  </Typography>
                  <Item>{marriageContractById?.emailSpouseOne}</Item>
                </div>
              </div>
            </div>
          </Grid>
          {/* SPOUSE TWO */}
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <div>
                <Typography
                  variant="body1"
                  gutterBottom
                  component="div"
                  className={classes.title}
                >
                  Contrayente
                </Typography>

                <div className="divider">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                  >
                    Nombre Completo *
                  </Typography>
                  <Item>{marriageContractById?.nameSpouseTwo}</Item>
                </div>

                <div className="divider">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                  >
                    <Grid item xs={6} sm={6} md={4}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Nacionalidad *
                      </Typography>
                      <Item>{marriageContractById?.nacionalitySpouseTwo}</Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Lugar de nacimiento *
                      </Typography>
                      <Item>{marriageContractById?.placeBirthSpouseTwo}</Item>
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Fecha de nacimiento *
                      </Typography>
                      {/* <Item>{marriageContractById?.dateBirthSpouseTwo.toDate()}</Item> */}
                    </Grid>
                  </Grid>
                </div>

                <div className="divider">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Estado civil *
                      </Typography>
                      <Item>
                        {marriageContractById?.maritalStatusSpouseTwo}
                      </Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Ocupación *
                      </Typography>
                      <Item>{marriageContractById?.ocupationSpouseTwo}</Item>
                    </Grid>
                  </Grid>
                </div>

                <div className="divider">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        CURP *
                      </Typography>
                      <Item>{marriageContractById?.curpSpouseTwo}</Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        RFC *
                      </Typography>
                      <Item>{marriageContractById?.rfcSpouseTwo}</Item>
                    </Grid>
                  </Grid>
                </div>

                <div className="divider">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                  >
                    Domicilio *
                  </Typography>
                  <Item>{marriageContractById?.streetNumberSpouseTwo}</Item>
                </div>

                <div className="divider">
                  <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                  >
                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Código Postal *
                      </Typography>
                      <Item>{marriageContractById?.postalcodeSpouseTwo}</Item>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        component="div"
                        className={classes.textInput}
                      >
                        Teléfono *
                      </Typography>
                      <Item>{marriageContractById?.phoneSpouseTwo}</Item>
                    </Grid>
                  </Grid>
                </div>

                <div className="divider">
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                  >
                    Correo electrónico *
                  </Typography>
                  <Item>{marriageContractById?.emailSpouseTwo}</Item>
                </div>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <div className="divider">
              <Grid
                container
                spacing={{ xs: 2, md: 2 }}
                columns={{ xs: 6, sm: 12, md: 12 }}
              >
                <Fragment>
                  {/* CEREMONY SPOUSE ONE */}
                  <Grid item xs={12} sm={6} md={3}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      className={classes.textInput}
                    >
                      Fecha de la ceremonia civil *
                    </Typography>
                    {/* <Item>{marriageContractById?.dateCeremonmySpouseOne.toDate()}</Item> */}
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      className={classes.textInput}
                    >
                      Nombre del juez *
                    </Typography>
                    <Item>{marriageContractById?.nameJudgeSpouseOne}</Item>
                  </Grid>
                </Fragment>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>
      <SnackAlert
        message={message}
        open={open}
        severity={severity}
        handleClose={handleClose}
      />
    </Container>
  );
};

export default MarriageContractDetail;
