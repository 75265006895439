import React, { useMemo, useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { useFormContext } from "react-hook-form";
import useStyles from "./styles";
import countryList from "react-select-country-list";
import { InputSelect } from "components/inputs/input-select";
import { InputDatePicker } from "components/inputs/input-date-picker";
import { labelsMaritalStatus } from "pages/CompanyFormation/FormCompanyFormation/step-four/constants";
import { InputEmail } from "components/inputs/input-email";
import { colors } from "constants/colors";
import { onInputNumber } from "utils";
import { State } from "country-state-city";

const SpouseTwo = ({ spouse }) => {
    // styles
    const classes = useStyles();
    const options = useMemo(() => countryList().getData(), []);
    const [statesByNation, setStatesByNation] = useState([]);

    const {
        control,
        watch,
        formState: { errors },
    } = useFormContext(); // retrieve all hook methods

    const nationality = watch(`nacionalitySpouse${spouse}`)

    useEffect(() => {
        if (nationality && nationality !== '') {
            setStatesByNation(State?.getStatesOfCountry(countryList()?.getValue(nationality))
                ?.map(state => {
                    return {
                        value: state?.isoCode,
                        label: state?.name
                    }
                }))
        }
    }, [nationality])

    return (
        <div>
            <Typography
                variant="body1"
                gutterBottom
                component="div"
                className={classes.title}
            >
                Contrayente
            </Typography>

            <div className="divider">
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                >
                    Nombre Completo *
                </Typography>
                <InputText
                    name={`nameSpouse${spouse}`}
                    value={""}
                    control={control}
                />
                {errors.nameSpouseTwo && (
                    <p style={styles.errors}>{errors.nameSpouseTwo.message}</p>
                )}
            </div>

            <div className="divider">
                <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                >
                    <Grid item xs={6} sm={6} md={4}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            Nacionalidad *
                        </Typography>
                        <InputSelect
                            name={`nacionalitySpouse${spouse}`}
                            value={""}
                            control={control}
                            errors={errors}
                            labels={options}
                            placeholder="Seleccione"
                        />
                        {errors.nacionalitySpouseTwo && (
                            <p style={styles.errors}>
                                {errors.nacionalitySpouseTwo.message}
                            </p>
                        )}
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            Lugar de nacimiento *
                        </Typography>
                        <InputSelect
                            name={`placeBirthSpouse${spouse}`}
                            value={""}
                            control={control}
                            errors={errors}
                            labels={statesByNation}
                        />
                        {errors.placeBirthSpouseTwo && (
                            <p style={styles.errors}>
                                {errors.placeBirthSpouseTwo.message}
                            </p>
                        )}
                    </Grid>

                    <Grid item xs={6} sm={6} md={4}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            Fecha de nacimiento *
                        </Typography>
                        <InputDatePicker
                            name={`dateBirthSpouse${spouse}`}
                            value={""}
                            control={control}
                            errors={errors}
                        />
                        {errors.dateBirthSpouseTwo && (
                            <p style={styles.errors}>
                                {errors.dateBirthSpouseTwo.message}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div className="divider">
                <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                >
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            Estado civil *
                        </Typography>
                        <InputSelect
                            name={`maritalStatusSpouse${spouse}`}
                            value={""}
                            control={control}
                            errors={errors}
                            labels={labelsMaritalStatus}
                            placeholder="Seleccione"
                        />
                        {errors.maritalStatusSpouseTwo && (
                            <p style={styles.errors}>
                                {errors.maritalStatusSpouseTwo.message}
                            </p>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            Ocupación *
                        </Typography>
                        <InputText
                            name={`ocupationSpouse${spouse}`}
                            value={""}
                            control={control}
                            errors={errors}
                        />
                        {errors.ocupationSpouseTwo && (
                            <p style={styles.errors}>
                                {errors.ocupationSpouseTwo.message}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div className="divider">
                <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                >
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            CURP *
                        </Typography>
                        <InputText
                            name={`curpSpouse${spouse}`}
                            value={""}
                            control={control}
                            errors={errors}
                        />
                        {errors.curpSpouseTwo && (
                            <p style={styles.errors}>
                                {errors.curpSpouseTwo.message}
                            </p>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            RFC *
                        </Typography>
                        <InputText
                            name={`rfcSpouse${spouse}`}
                            value={""}
                            control={control}
                            errors={errors}
                        />
                        {errors.rfcSpouseTwo && (
                            <p style={styles.errors}>
                                {errors.rfcSpouseTwo.message}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div className="divider">
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                >
                    Domicilio *
                </Typography>
                <InputText
                    name={`streetNumberSpouse${spouse}`}
                    value={""}
                    control={control}
                    errors={errors}
                />
                {errors.streetNumberSpouseTwo && (
                    <p style={styles.errors}>
                        {errors.streetNumberSpouseTwo.message}
                    </p>
                )}
            </div>

            <div className="divider">
                <Grid
                    container
                    spacing={{ xs: 2, md: 2 }}
                    columns={{ xs: 6, sm: 12, md: 12 }}
                >
                    <Grid item xs={12} sm={12} md={6}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            Código Postal *
                        </Typography>
                        <InputText
                            name={`postalcodeSpouse${spouse}`}
                            value={""}
                            control={control}
                            errors={errors}
                        />
                        {errors.postalcodeSpouseTwo && (
                            <p style={styles.errors}>
                                {errors.postalcodeSpouseTwo.message}
                            </p>
                        )}
                    </Grid>

                    <Grid item xs={12} sm={12} md={6}>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            component="div"
                            className={classes.textInput}
                        >
                            Teléfono *
                        </Typography>
                        <InputText
                            name={`phoneSpouse${spouse}`}
                            value={""}
                            control={control}
                            onInput={onInputNumber}
                            errors={errors}
                        />
                        {errors.phoneSpouseTwo && (
                            <p style={styles.errors}>
                                {errors.phoneSpouseTwo.message}
                            </p>
                        )}
                    </Grid>
                </Grid>
            </div>

            <div className="divider">
                <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className={classes.textInput}
                >
                    Correo electrónico *
                </Typography>
                <InputEmail
                    name={`emailSpouse${spouse}`}
                    value={""}
                    control={control}
                    type="email"
                    errors={errors}
                    className="input-email"
                />
                {errors.emailSpouseTwo && (
                    <p style={styles.errors}>{errors.emailSpouseTwo.message}</p>
                )}
            </div>
        </div>
    );
};

export default SpouseTwo;

const styles = {
    errors: {
        padding: "5px 15px",
        fontSize: 14,
        borderRadius: 15,
        marginTop: -1,
        borderTopLeftRadius: 2,
        backgroundColor: colors.dangerLight,
        display: "inline-block",
        border: `1px solid ${colors.danger}`,
        color: colors.danger,
    },
};
