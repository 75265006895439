import React, { createContext, useState } from "react";

export const VisitorsControllerContext = createContext();

export const VisitorsControllerContextProvider = ({ children }) => {
  const [isVisitor, setIsVisitor] = useState(false);

  return (
    <VisitorsControllerContext.Provider
      value={{
        isVisitor,
        setIsVisitor,
      }}
      displayName="Visitor Controller context"
    >
      {children}
    </VisitorsControllerContext.Provider>
  );
};
