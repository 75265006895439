import React, { useContext, useEffect, useState } from "react";
import { InputEmail } from "components/inputs/input-email";
import { Button } from "@mui/material";
import { colors } from "constants/colors";
import { useFormContext } from "react-hook-form";
import { FirebaseContext } from "server";
import { resetUserPassword } from "context/user/actions";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import "firebase/firestore";

const ForgotPasswordInput = ({ setForgotPasswordFlow }) => {
  const { control, watch } = useFormContext();
  const [emailMessage, setEmailMessage] = useState(false);
  const firebase = useContext(FirebaseContext);
  const [configData, setConfigData] = useState();

  const handleSendEmail = async (e) => {
    e.preventDefault();
    await resetUserPassword(firebase, watch("email"));
    setEmailReviewMessage();
  };

  const setEmailReviewMessage = () => {
    setEmailMessage(true);
    setTimeout(() => {
      setEmailMessage(false);
      setForgotPasswordFlow(false);
    }, 6000);
  };

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return emailMessage ? (
    <div
      style={{
        marginBottom: 20,
        width: 240,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <span
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: 14,
          textAlign: "center",
        }}
      >
        Un email ha sido enviado al correo electrónico proporcionado, por favor
        siga el proceso para reestablecer la contraseña e intente nuevamente
        ingresar a esta aplicación.{" "}
      </span>
    </div>
  ) : (
    <div
      style={{
        marginBottom: 20,
        width: 240,
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        margin: "0 auto",
      }}
    >
      <InputEmail
        name="email"
        control={control}
        className="input-email"
        placeholder="correo electronico"
      />
      <Button
        type="submit"
        variant="contained"
        style={{
          background: configData?.iconsColor,
          color: configData?.baseColor,
          borderRadius: 20,
          fontWeight: "bold",
          width: 180,
          margin: "20px auto",
        }}
        onClick={(e) => handleSendEmail(e)}
      >
        Enviar correo
      </Button>
      <span
        style={{
          color: "white",
          fontWeight: "bold",
          fontSize: 14,
          textAlign: "center",
        }}
      >
        Un correo le será enviado para reestablecer su contraseña.
      </span>
    </div>
  );
};

export default ForgotPasswordInput;
