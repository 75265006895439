import { useCallback, useEffect, useState } from "react";
import useStyles from "./styles";
import { Typography, Box, Grid, Button, Container } from "@mui/material";
import YoutubeEmbed from "components/youtube-embed/youtube-embed";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { useNavigate } from "react-router-dom";
// import { clearCompany } from "context/company/actions";
// import { useStateValue } from "context/store";
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../firebase'
import "firebase/firestore";

const CompanyFormation = () => {
  const navigate = useNavigate();
  
  const [ configData, setConfigData ] = useState();

  const classes = useStyles({ baseColor: configData?.baseColor });

  // const [{ dispatch }] = useStateValue();

  const handleOnClick = useCallback(() => {
    // clearCompany(dispatch);
    navigate("/app/form-company-formation", { replace: true });
  }, [navigate]);


  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <div className={classes.container}>
      <Container>
        <Typography
          variant="h2"
          gutterBottom
          component="div"
          className={classes.title}
        >
          Constitución de sociedades
        </Typography>

        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={{ xs: 2, md: 8 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={12} sm={12} md={6}>
              <Typography variant="subtitle1" gutterBottom>
                Cuando de iniciar una nueva empresa se trata, legislativamente
                es necesario realizar notarialmente la constitución de la
                sociedad de la misma, es decir realizar el registro legal de las
                firmas de los socios, del sentido de la empresa y así mismo una
                aclaración legal del rubro en el que ésta se desempeña.
              </Typography>

              <div className={classes.contentDocuments}>
                <Typography variant="subtitle1" gutterBottom>
                  Documentos necesarios
                </Typography>
                <ul className={classes.ul}>
                  <li>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      style={{ fontWeight: 500 }}
                    >
                      Cédulas fiscales de cada socio y su identificación, (en
                      caso de identificarse con pasaporte, adjuntar un
                      comprobante de domicilio a nombre del mismo socio)
                    </Typography>
                  </li>

                  <li>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      style={{ fontWeight: 500 }}
                    >
                      En caso de ser extranjero, presentar su forma migratoria y
                      pasaporte
                    </Typography>
                  </li>
                </ul>
              </div>

              <div className={classes.containerButton}>
                <Button
                  variant="contained"
                  className={classes.btnNextStep}
                  onClick={handleOnClick}
                >
                  Comenzar
                  <RigthIcon />
                </Button>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} className={classes.videoContainer}>
              <div className={classes.video}>
                <YoutubeEmbed embedId="rokGy0huYEA" />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </div>
  );
};

export default CompanyFormation;
