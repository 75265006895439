import { Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "./alert";

export const SnackAlert = ({ message, open, severity, handleClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      key={"top-right"}
    >
      <Alert onClose={handleClose} severity={severity || "info"}>
        <Typography variant="body1">{message}</Typography>
      </Alert>
    </Snackbar>
  );
};
