import { Typography } from '@mui/material';

export default function Menu (){

  return (
      <div style={{ display: 'none' }}>
        <Typography  style={{ display: 'none' }}>
            Aca va el menu, revisar en la libreria AppBar
        </Typography>
    </div>

  )
}
