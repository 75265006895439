import { Controller } from "react-hook-form";
import { TextareaAutosize } from "@mui/material";

export const InputTextarea = ({ name, value, control }) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={value ?? ""}
            render={({ field: { onChange, value } }) => (
                <TextareaAutosize
                    className="input-textarea"
                    minRows={5}
                    value={value}
                    onChange={onChange}
                    style={{ width: "100%" }}
                    // error={!!errors.password}
                    // helperText={errors.password?.message}
                />
            )}
        />
    );
};
