import { useState, useEffect } from "react";
import { onSnapshot, doc } from 'firebase/firestore';
import { db } from '../../src/firebase';

const useNotifications = (user, user1) => {

    const user2 = user?.uid;
    const [dataNew, setDataNew] = useState('');
    const [userNew, setUserNew] = useState(user2);

    useEffect(() => {
        const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`
        let unsub = onSnapshot(doc(db, 'lastMsg', id), doc => {
            setDataNew(doc.data([]))
        })
        return () => unsub()
    }, []);

    return { dataNew, userNew }
    
}

export default useNotifications