import useStyles from "./styles";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";

const SuccessForm = () => {
  const classes = useStyles();

  let {
    state: { src },
  } = useLocation();

  return (
    <>
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography
            variant="h2"
            gutterBottom
            component="div"
            className={classes.title}
          >
            {src}
          </Typography>

          <div className={classes.contentMail}>
            <div className={classes.circleMail}>
              <img
                src={require("assets/svg/mail-icon.svg").default}
                className={classes.iconMail}
                alt="mail-send"
              />
            </div>

            <div className={classes.widthText}>
              <Typography
                gutterBottom
                component="div"
                className={classes.titleEmail}
              >
                Recibirá una notificación con la persona que estará a cargo de
                su trámite
              </Typography>

              <Typography variant="body1" gutterBottom component="div">
                Uno de nuestros asesores legales hará la verificación del
                documento y lo mantendrá al tanto de todo el proceso, también
                puede verificar el estado <br /> de su proceso en el{" "}
                <span className={classes.span}>Dashboard.</span>
              </Typography>

              {/*<Button onClick={generatePdf}>generateDocx</Button>*/}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessForm;
