export const navigateTypeForm = (typeForm, navigate, documentId, user) => {
  switch (typeForm) {
    case "company-formation":
    case "companyFormation":
      return navigate("/app/form-company-formation", {
        replace: true,
        state: {
          documentId,
        },
      });
    case "form-protocolization":
    case "protocolization":
      return navigate("/app/form-protocolization", {
        replace: true,
        state: {
          documentId,
        },
      });
    case "marriage":
      return navigate("/app/form-marriage-contract", {
        replace: true,
        state: {
          documentId,
        },
      });
    case "form-donation":
    case "donation":
      return navigate("/app/form-donation", {
        replace: true,
        state: {
          documentId,
        },
      });

    case "form-login":
      if (user) {
        if(user.rol === "notario"){
          return navigate("/app/welcome-companies-list", { replace: true })
        } else if(user.rol === "um-admin"){
          return navigate("/app/um-admin", { replace: true })
        } else {
          return navigate("/app/transaction-online", { replace: true });
        }
        // return user?.rol === "notario"
        //   ? navigate("/app/welcome-companies-list", { replace: true })
        //   : navigate("/app/transaction-online", { replace: true });
      }
      return navigate("/app/transaction-online", { replace: true });
    default:
      return navigate("/", { replace: true });
  }
};
