import { makeStyles } from "@mui/styles";
import { colors } from "constants/colors";

export default makeStyles(theme => ({
  card: {
    marginRight: 30,
    marginBottom: 40,
    boxShadow: "0px 0px 0px",
    borderRadius: "10px",
    // width: "100%",
    padding: "15px 20px",
  },
  container: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "initial",
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: colors.blue,
    fontFamily: "poppinsmedium",
    fontWeight: "bold",
    fontSize: 30,
  },
  containerCardMedia: {
    width: 112,
    height: 112,
    [theme.breakpoints.down("sm")]: {
      width: 60,
    },
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: colors.lightskyblue,
    borderRadius: "50%",
    "& img": {
      width: 44,
      height: 40,
      objectFit: "contain",
    },
  },
}));
