export const labelsMaritalStatus = [
  { id: 1, label: "Soltero(a)", name: "single" },
  { id: 2, label: "Casado(a)", name: "married" },
  { id: 3, label: "Unión libre", name: "cohabitation" },
  // { id: 4, label: "Sociedad conyugal", name: "communityProperty" },
  { id: 5, label: "Divorciado", name: "divorced" },
  // { id: 6, label: "Separación de bienes", name: "separationOfProperty" },
  { id: 7, label: "Viudez", name: "widowhood" },
]

export const partnerIdentified = [
  { id: 1, label: "Credencial de elector", name: "votersCard" },
  { id: 2, label: "Pasaporte", name: "passport" }
]

export const labelsInability = [
  { id: 1, label: "Ninguna", name: "none" },
  { id: 2, label: "Sordo", name: "deaf" },
  { id: 3, label: "Mudo", name: "mute" },
  { id: 4, label: "Ciego", name: "blind" },
  { id: 5, label: "otra", name: "other" },
]


export const labelsIsMarried = [
  { id: 1, label: "Separación de bienes"},
  { id: 2, label: "Sociedad conyugal"},
]