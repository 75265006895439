import {
  Box,
  Button,
  CardContent,
  CardMedia,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { StatusTable } from "components/data-table/status-table";
import { colors } from "constants/colors";
import Popover from "@mui/material/Popover";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";

const PopupStateUi = ({ icon, title, status, handleChange, donationById }) => {
  return (
    <div className="divider">
      <div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: 80,
              height: 80,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: colors.lightskyblue,
              borderRadius: "50%",
            }}
          >
            <CardMedia
              component="img"
              src={icon}
              sx={{
                width: 44,
                height: 40,
                objectFit: "contain",
              }}
              alt="Live from space album cover"
            />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography
                component="div"
                variant="subtitle2"
                fontSize={20}
                fontWeight="bold"
                sx={{
                  color: colors.blue,
                  fontFamily: "poppinsmedium",
                }}
              >
                Trámite {donationById?.createdDateTime}
              </Typography>
              <Typography
                component="div"
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  color: colors.blue,
                  fontFamily: "poppinsmedium",
                }}
              >
                {title}
              </Typography>

              <PopupState variant="popover" popupId="demo-popup-popover">
                {popupState => (
                  <div>
                    <Button {...bindTrigger(popupState)}>
                      <StatusTable type={status} />
                    </Button>

                    <Popover
                      {...bindPopover(popupState)}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Typography sx={{ p: 2 }}>
                        <Select value={status} onChange={handleChange}>
                          <MenuItem value="pending">Pendiente</MenuItem>
                          <MenuItem value="finished">Finalizado</MenuItem>
                          <MenuItem value="cancelled">Cancelado</MenuItem>
                        </Select>
                      </Typography>
                    </Popover>
                  </div>
                )}
              </PopupState>
            </CardContent>
          </Box>
        </div>
      </div>
    </div>
  );
};

export default PopupStateUi;
