import { Box, Typography } from "@mui/material";

export const StatusTable = ({ type }) => {
  switch (type) {
    case "pending":
      return (
        <Box
          sx={{
            borderRadius: 50,
            padding: "0px 7px",
            backgroundColor: "#EABE46",
            width: 60,
          }}
        >
          <Typography fontSize={12} color="black" fontWeight="bold">
            Pendiente
          </Typography>
        </Box>
      );

    case "cancelled":
      return (
        <Box
          sx={{
            borderRadius: 50,
            padding: "0px 7px",
            backgroundColor: "#DDDDDD",
          }}
        >
          <Typography fontSize={12} color="black" fontWeight="bold">
            Cancelado
          </Typography>
        </Box>
      );

    case "finished":
      return (
        <Box
          sx={{
            borderRadius: 50,
            padding: "0px 7px",
            backgroundColor: "#59C43F",
            width: 60,
          }}
        >
          <Typography fontSize={12} color="white" fontWeight="bold">
            Finalizado
          </Typography>
        </Box>
      );

    default:
      return (
        <Box
          sx={{
            borderRadius: 50,
            padding: "0px 7px",
            width: 60,
          }}
        >
          <Typography color="white" fontWeight="bold">
            type
          </Typography>
        </Box>
      );
  }
};
