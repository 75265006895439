import { useCallback } from "react";

export const ValidateDataCompanyFormationHook = () => {
    const validateData = useCallback((data, dataClear) => {
        const newData = data.map((value) => {
            let obj = { ...value };

            dataClear.forEach((key) => {
                if (obj[key]?.length && Array.isArray(obj[key])) {
                    obj[key] = "";
                }
            });

            return obj;
        });

        return {
            shareholder: newData,
        };
    }, []);

    return { validateData };
};
