import CompanyFormation from "assets/svg/companyFormation.svg";
import DonatingMoney from "assets/svg/donatingMoney.svg";
import RatificationEngagement from "assets/svg/ratificationEngagement.svg";
import CertifiedCopy from "assets/svg/certifiedCopy.svg";
import Audits from "assets/svg/audits.svg";
import MarriageContract from "assets/svg/marriage-contract.svg";

export const cards = [
  {
    id: 1,
    icon: CompanyFormation,
    title: "Constitución de sociedades",
    url: "company-formation",
  },
  {
    id: 2,
    icon: DonatingMoney,
    title: "Donación de Dinero",
    url: "donation-money",
  },
  // {
  //   id: 3,
  //   icon: RatificationEngagement,
  //   title: "Ratificación de Contratos",
  //   url: "company-formation",
  // },
  // {
  //   id: 4,
  //   icon: CertifiedCopy,
  //   title: "Copia Certificada",
  //   url: "company-formation",
  // },
  {
    id: 5,
    icon: Audits,
    title: "Protocolización de Asambleas",
    url: "protocolization-assemblies",
  },
  {
    id: 6,
    icon: MarriageContract,
    title: "Capitulaciones Matrimoniales",
    url: "marriage-contract",
  },
];
