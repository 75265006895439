import { useState, useEffect } from "react";
import { Container, Grid, Typography } from "@mui/material";
import useStyles from "./styles";
import clientConfig from "constants/clientConfig";
// 
import { collection, getDocs } from "firebase/firestore";
import { db } from '../../../../firebase'
import "firebase/firestore";

export default function Footer() {

  const [ configData, setConfigData ] = useState();

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 600px)").matches
  );
  const styles = useStyles();

  useEffect(() => {
    window
      .matchMedia("(min-width: 600px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data())
      });
    } catch (error) {
      console.error("Error al obtener los datos de la colección 'site-config':", error);
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]); 

  return (
    <footer style={{ backgroundColor: configData?.footerColor }} className={styles.footer}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Container className={styles.space}>
            <img
              src={configData?.logoSecondary}
              alt="Footer"
              className={styles.logoImage}
            />
          </Container>
        </Grid>
        <Grid item xs={4} className={styles.items}>
          <Typography className={styles.rightsReserved}>
            Ⓒ {configData?.name}, Todos los Derechos Reservados
          </Typography>
        </Grid> 
      </Grid>
    </footer>
  );
}
