import { useState } from "react";
import { Controller } from "react-hook-form";
import { TextField } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const InputPassword = ({ name, label, control, errors }) => {

  // useState
  const [visibility, setVisibility] = useState(true);

  let icon = null;
  if (visibility) {
    icon = <RemoveRedEyeIcon fontSize="small" onClick={() => setVisibility(!visibility)} style={{ cursor: 'pointer' }} />;
  } else {
    icon = <VisibilityOffIcon fontSize="small" onClick={() => setVisibility(!visibility)} style={{ cursor: 'pointer' }} />;
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { onChange, value } }) => (
        <TextField
          placeholder={label}
          variant="outlined"
          value={value}
          onChange={onChange}
          error={!!errors.password}
          helperText={name === "password" ? errors.password?.message : errors.passwordConfirm?.message}
          type={visibility ? 'password' : 'text'}
          style={{ marginBottom: 20 }}
          className="input-password"
          InputProps={{
            style: { backgroundColor: '#fff' },
            endAdornment: icon
          }}
        />
      )}
    />
  )
};