import React, { useState, useRef, useEffect, useContext } from "react";
import {
  Container,
  AppBar,
  Box,
  Toolbar,
  Button,
  IconButton,
} from "@mui/material/";
import useStyles from "./styles";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import { useStateValue } from "context/store";
import { ChatControllerContext } from "context/chat/ChatControllerContext";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../../firebase";
import "firebase/firestore";

export default function Menu() {
  // useState
  const [openMenu, setOpenMenu] = useState(true);
  const [user, setUser] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [configData, setConfigData] = useState();

  // variables
  const navigate = useNavigate();

  // Styles
  const propStyles = { openMenu };
  const classes = useStyles(propStyles);
  const { setSelectedDocument } = useContext(ChatControllerContext);
  const tokenStorage = localStorage.getItem("auth-token");
  const { current: currentUser } = useRef(
    JSON.parse(localStorage.getItem("current-user"))
  );

  const [
    {
      user: { userById },
    },
  ] = useStateValue();

  useEffect(() => {
    setUser(currentUser ? currentUser : userById);
  }, [currentUser, userById]);

  const handleShowMenu = () => {
    setShowMenu((prevState) => !prevState);
  };

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <div style={{ position: "fixed", zIndex: "1" }}>
      <section>
        <Container maxWidth="xl">
          <AppBar className={classes.topBanner}>
            <Toolbar>
              <Box
                className={classes.box}
                sx={{
                  "& button": { m: 1 },
                  display: { xs: "none", sm: "none", md: "none", lg: "block" },
                }}
              >
                <div className={classes.groupButtons}>
                  <img
                    src={ configData?.logo }
                    alt="Logo"
                    width="250px"
                    style={{ marginTop: 5 }}
                  />
                  
                  <div style={{ width: "auto", margin: "auto 0" }}>
                    
                    <Link
                      className={classes.links}
                      size="small"
                      to="https://www.np26.com.mx/"
                      title="Regresar al Home"
                    >
                      Regresar al Home
                    </Link>
                   
                    {user?.rol !== "notario" && (
                      <Button
                        className={classes.buttons}
                        size="small"
                        title="Centro de Trámites"
                        onClick={() =>
                          navigate("/app/transaction-online", {
                            replace: true,
                            state: {
                              src: "",
                            },
                          })
                        }
                      >
                        Centro de Trámites
                      </Button>
                    )}

                    {user && user.rol === "notario" && (
                      <Button
                        className={classes.buttons}
                        size="small"
                        onClick={() =>
                          navigate("/app/welcome-companies-list", {
                            replace: true,
                          })
                        }
                      >
                        Documentos
                      </Button>
                    )}

                    {tokenStorage && (
                      <Button
                        className={classes.buttons}
                        size="small"
                        onClick={() => {
                          setSelectedDocument();
                          navigate("/app/chat", { replace: true });
                        }}
                      >
                        Chat
                      </Button>
                    )}

                    {!tokenStorage && (
                      <Button
                        className={classes.loginButton}
                        size="small"
                        title="Ingresar"
                        onClick={() =>
                          navigate("/app/form-login", {
                            replace: true,
                            state: {
                              src: "",
                            },
                          })
                        }
                      >
                        Ingresar
                      </Button>
                    )}
                  </div>
                </div>
              </Box>

              <Box
                className={classes.box}
                sx={{
                  "& button": { m: 1 },
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                  },
                }}
              >
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => setOpenMenu(!openMenu)}
                  style={{
                    color: "#1759A5",
                    position: "absolute",
                    right: "2%",
                  }}
                >
                  <MenuIcon onClick={() => handleShowMenu()} />
                </IconButton>

                <div className={classes.groupButtonsResponsive}>
                  <div style={{ marginTop: 20 }}>
                    <img
                      src={ configData?.logo }
                      alt="phone"
                      width="250px"
                    />
                  </div>
                  <div
                    className={!showMenu && classes.hidden}
                    style={{
                      width: "auto",
                      margin: "auto 0",
                      marginBottom: 20,
                    }}
                  >
                    <Button
                      className={classes.buttonsResponsive}
                      size="small"
                      onClick={() =>
                        navigate("/app/transaction-online", { replace: true })
                      }
                    >
                      Centro de Trámites
                    </Button>

                    <Button
                      className={classes.buttonsResponsive}
                      size="small"
                      onClick={() =>
                        navigate("/app/welcome-companies-list", {
                          replace: true,
                        })
                      }
                    >
                      Documentos
                    </Button>

                    {tokenStorage && (
                      <Button
                        className={classes.buttonsResponsive}
                        size="small"
                        onClick={() => navigate("/app/chat", { replace: true })}
                      >
                        Chat
                      </Button>
                    )}

                    {!tokenStorage && (
                      <Button
                        className={classes.loginButtonResponsive}
                        size="small"
                        onClick={() =>
                          navigate("/app/form-login", {
                            replace: true,
                            state: {
                              src: "",
                            },
                          })
                        }
                      >
                        Ingresar
                      </Button>
                    )}
                  </div>
                </div>
              </Box>
            </Toolbar>
          </AppBar>
        </Container>
      </section>
    </div>
  );
}
