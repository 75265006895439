import React, { useContext, useState, useEffect } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { FourNestedInput } from "./four-nested-input";
import useStyles from "../styles";
import { ReactComponent as RigthIcon } from "assets/svg/rigth-icon.svg";
import { ReactComponent as LeftIcon } from "assets/svg/left-icon.svg";
import { Button, Box } from "@mui/material";
import { useStateValue } from "context/store";
import {
  addShareholderCompany,
  backStep,
  clearCompany,
  createCompany,
  editCompany,
  resetStepsCompany,
  updateDocx,
} from "context/company/actions";
import { FirebaseContext } from "server";
import { colors } from "constants/colors";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "@mui/base/Tabs";
import { TabsList } from "@mui/base/TabsList";
import { Tab } from "@mui/base/Tab";
import ModeOutlinedIcon from "@mui/icons-material/ModeOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ViewerPDF from "components/success-form/viewer-pdf";
import Loading from "components/Loading";
import { yupResolver } from "@hookform/resolvers/yup";
import { EmailHook } from "hooks/send-email";
import schemaFourForm from "schema/CompanyFormation/schema-four-form";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import jsPDF from "jspdf";
import ErrorForm from "components/Error-form";
import { ValidateDataCompanyFormationHook } from "hooks/validateData/companyFormation";
import { getCreatedTimeValue } from "utils";
import { PartnersControllerContext } from "context/company/PartnersControllerContext";
import { uuidv4 } from "@firebase/util";
import LoginFormNull from "pages/LoginFormNull/login-form-null";
import { ToastContainer, toast } from "react-toastify";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const StepFour = () => {
  const [formLoginState, setFormLoginState] = useState(false);
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { partners, setPartners } = useContext(PartnersControllerContext);

  const classes = useStyles();

  const { sendEmail, sendUserEmail } = EmailHook();

  const tokenStorage = localStorage.getItem("auth-token");
  const currentUser = JSON.parse(localStorage.getItem("current-user"));
  const [
    {
      company: { companyInput, shareholder, pdf, shareholdersById },
    },
    dispatch,
  ] = useStateValue();
  const { state } = useLocation();
  let documentId = state?.documentId;

  const typeProcedure = "Constitución de sociedades";

  const methods = useForm({
    resolver: yupResolver(schemaFourForm),
    defaultValues: {
      shareholder: shareholdersById.length
        ? shareholdersById
        : shareholder.shareholder,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "shareholder",
  });

  const createField = (partner) => {
    return {
      name: partner?.nameShareholder || "",
      paternalSurname: "",
      maternalSurname: "",
      nacionality: "",
      placeBirth: "",
      dateBirth: "",
      maritalStatus: "",
      ocupation: "",
      RFC: "",
      CURP: "",
      identification: "",
      streetNumber: "",
      colony: "",
      state: "",
      municipality: "",
      CP: "",
      homeFile: "",
      phone: "",
      email: "",
      inability: "",
      file: "",
      id: partner?.id || uuidv4(),
    };
  };

  useEffect(() => {
    if (partners.length) {
      const partnersListToReplace = [];
      partners.forEach((partner) => {
        if (
          !fields.some(
            (field) =>
              field?.name === partner?.nameShareholder ||
              field?.nameShareholder === partner?.nameShareholder
          )
        ) {
          partnersListToReplace.push(createField(partner));
        }
      });
      if (partnersListToReplace.length) {
        remove();
        partnersListToReplace.forEach((partner) => append(partner));
      }
    }
  }, [partners]);

  const doc = new jsPDF({
    orientation: "p",
    unit: "pt",
    format: "a4",
  });

  const firebase = useContext(FirebaseContext);

  const { validateData } = ValidateDataCompanyFormationHook();

  const onSubmit = async (data) => {
    if (tokenStorage) {
      data.createdDateTime = getCreatedTimeValue();
      addShareholderCompany(dispatch, data);
      resetStepsCompany(dispatch, {
        step_four: true,
      });
      clearCompany(dispatch);
      await saveDataState(data);
    } else {
      toast.error("Debe iniciar sesión y enviar el formulario nuevamente para continuar");
      setFormLoginState(true);
    }
  };

  const onBackSubmit = (data) => {
    resetStepsCompany(dispatch, {
      step_four: true,
    });
    backStep(dispatch);
    addShareholderCompany(dispatch, data);
  };

  const saveDataState = async (data) => {
    setLoading(true);
    data.createdDateTime = getCreatedTimeValue();
    const dataCompany = {
      currentUser: currentUser?.name,
      urlGeneral: "",
      // urlPdf: "",
      status: "pending",
      createdDateTime: getCreatedTimeValue(),
      ...companyInput,
    };

    const namesClear = ["file", "homeFile"];

    const clearDataShareholders = validateData(data.shareholder, namesClear);

    if (documentId) {
      const callback = await editCompany(
        firebase,
        dataCompany,
        clearDataShareholders,
        data,
        documentId,
        namesClear,
        dispatch
      );

      if (callback) {
        generatePdf(documentId);
      }

      setLoading(false);
    } else {
      const callback = await createCompany(
        firebase,
        dispatch,
        dataCompany,
        data,
        currentUser,
        clearDataShareholders,
        namesClear
      );

      if (callback.companyId) {
        generatePdf(callback.companyId);
      }

      setLoading(false);
    }
  };

  const generatePdf = async (companyId) => {
    let pdfjs = document.querySelector("#content");
    const storage = getStorage();
    console.log("pdfjs====>", pdfjs);
    doc.html(pdfjs, {
      margin: [20, 0, 20, 0],
      callback: function (doc) {
        const metadata = {
          contentType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        };
        let blob = doc.output("blob");
        const storageRef = ref(
          storage,
          `Documents/${companyId}/output${Date.now()}.pdf`
        );
        uploadBytes(storageRef, blob, metadata).then((snapshot) => {
          getDownloadURL(snapshot.ref)
            .then((url) => {
              console.log("url===>", url);
              updateDocx(firebase, companyId, url, dispatch).then((res) => {
                console.log("res====>", res);
                navigateSendEmail(companyId);
              });
            })
            .catch((error) => {
              console.log("error generatePdf", error);
              setLoading(false);
            });
        });
      },
    });
  };

  const navigateSendEmail = (documentId) => {
    const emailWasSent = sendEmail(typeProcedure, documentId);
    const emailUserWasSent = sendUserEmail(typeProcedure);
    if (emailWasSent && emailUserWasSent) {
      navigate("/app/transaction-online-success", {
        replace: true,
        state: {
          src: typeProcedure,
        },
      });
    }
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };

  const countErrors = Object.entries(methods.formState.errors).length;

  return (
    <>
      <ToastContainer />
      {
        formLoginState &&
          <div
            style={{
              position: "fixed",
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              backgroundColor: 'white',
              borderRadius: 10,
              border: '1px solid #295AA0',
              zIndex: 9,
            }}
          >
            <LoginFormNull
              setFormLoginState={setFormLoginState}
            />
          </div>
      }
      <FormProvider {...methods}>
        {loading && <Loading />}
        <form>
          <Box display="flex" justifyContent="space-between" alignItems="end">
            <Tabs value={value} onChange={handleChange}>
              <TabsList className={classes.containerItemShareholder}>
                {fields.map((item, index) => (
                  <Tab
                    key={item.id}
                    className={classes.itemShareholder}
                  >
                    <b>{`Socio ${index + 1}:`} </b>
                    <small className={classes.nameItem}>
                      {methods.watch(`shareholder[${index}].name`)
                        ? methods.watch(`shareholder[${index}].name`)
                        : item?.name
                        ? item?.name
                        : item?.nameShareholder}
                    </small>
                    <span>
                      <ModeOutlinedIcon fontSize="small" />
                      <CloseOutlinedIcon
                        fontSize="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          remove(index);
                          index < value && setValue(value - 1);
                        }}
                      />
                    </span>
                  </Tab>
                ))}
              </TabsList>
            </Tabs>
            <div
              type="button"
              variant="contained"
              onClick={() => {
                append(createField());
                setValue(fields.length);
              }}
              style={{
                height: "100%",
                padding: "8px 15px",
                border: `1px solid ${colors.blue}`,
                borderRadius: 50,
                fontWeight: "bold",
                color: colors.blue,
                display: "flex",
                cursor: "pointer",
                justifyContent: "space-between",
              }}
            >
              Añadir Accionista/Socio
              <div style={{ marginLeft: 20 }}>+</div>
            </div>
          </Box>
          {fields.map((item, index) => (
            <TabPanel
              key={item.id}
              value={value}
              index={index}
              className={classes.contentFormShareholder}
            >
              <FourNestedInput
                index={index}
                item={item}
                remove={remove}
                reset={methods.reset}
              />
            </TabPanel>
          ))}
          <div className={classes.containerButtons}>
            <Button
              variant="contained"
              className={classes.btnBackStep}
              onClick={methods.handleSubmit(onBackSubmit)}
            >
              <LeftIcon />
              Volver
            </Button>

            <Button
              variant="contained"
              className={classes.btnNextStep}
              onClick={methods.handleSubmit(onSubmit)}
            >
              {documentId ? "Editar" : "Enviar"}
              <RigthIcon />
            </Button>
          </div>
          <ErrorForm countErrors={countErrors} />
        </form>
      </FormProvider>
      <div style={{ display: "none" }}>{pdf && <ViewerPDF />}</div>
    </>
  );
};

export default StepFour;
