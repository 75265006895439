import React, { useRef, useEffect } from "react";
import "./components.scss";
import profile_image from "../../img/profile_image.png";
import Moment from "react-moment";
import "moment/locale/es";

const Message = ({ msg, user1, users }) => {
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [msg]);

  return (
    <div
      className={`message_wrapper1 ${msg.from === user1 ? "own1" : ""}`}
      ref={scrollRef}
    >
      {msg.from === user1 ? (
        <div className="userNameMessage">
          <img src={profile_image} alt="avatarChat" className="avatarChat" />
          <span>Yo</span>
        </div>
      ) : (
        <div>
          <img
            src={
              "https://firebasestorage.googleapis.com/v0/b/notarias-76c68.appspot.com/o/avatar%2F1664203603938%20-%20businessman-profile-cartoon_18591-58481.jpg?alt=media&token=d1810c5a-e5ee-4c46-a5ef-a739eb580868"
            }
            className="avatarChat"
          />
        </div>
      )}
      <p className={msg.from === user1 ? "me1" : "friend1"}>
        {msg.media ? <img src={msg.media} alt={msg.text} /> : null}
        {msg.text}
        <br />
        <small>
          Enviado:{" "}
          <Moment fromNow locale="es">
            {msg.createdAt.toDate()}
          </Moment>
        </small>
      </p>
    </div>
  );
};

export default Message;
