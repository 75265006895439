import { string, object } from "yup";

const schemaDonationForm = object({
  amountPesos: string().required("Este campo es obligatorio"),
  fullName: string().required("Este campo es obligatorio"),
  stateHome: string().required("Este campo es obligatorio"),
  municipalityHome: string().required("Este campo es obligatorio"),
  dateBirth: string().required("Este campo es obligatorio"),
  maritalStatus: string().required("Este campo es obligatorio"),
  ocupation: string().required("Este campo es obligatorio"),
  economicActivity: string().required("Este campo es obligatorio"),
  federalRegister: string().required("Este campo es obligatorio"),
  CURP: string()
    .required("Este campo es obligatoriorequerido")
    .min(18, "El CURP debe contener 18 digitos")
    .max(18, "El CURP debe contener 18 digitos"),
  street: string().required("Este campo es obligatorio"),
  colony: string().required("Este campo es obligatorio"),
  state: string().required("Este campo es obligatorio"),
  municipality: string().required("Este campo es obligatorio"),
  CP: string().required("Este campo es obligatorio"),
  phone: string().required("Este campo es obligatorio"),
  email: string()
    .required("Este campo es obligatorio")
    .email("Email no válido"),
}).required();

export default schemaDonationForm;
