import { useContext, useEffect, useState, useRef } from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
// import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStateValue } from "context/store";
import { FirebaseContext } from "server";
import { colors } from "constants/colors";
import { SnackAlert } from "widgets/snackAlert";
import PopupStateUi from "components/popup-state-ui/popup-state-ui";
import { ProtocolizationAssembliesIcon } from "widgets/icons";
import {
  getProtocolizationById,
  updateProtocolizationById,
} from "context/protocolization/actions";

const ProtocolizationAssembliesDetail = () => {
  let { protocolizationId } = useParams();
  const navigate = useNavigate();

  // firebase
  const firebase = useContext(FirebaseContext);

  const [
    {
      protocolization: { protocolizationById },
      user: { userById },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (currentUser?.rol !== "notario") {
      navigate("/app/form-login", {
        replace: true,
        state: {
          src: window.location.href,
        },
      });
    }
  }, [userById]);

  const { current: currentUser } = useRef(
    JSON.parse(localStorage.getItem("current-user"))
  );

  const typeStatus = {
    pending: "pendiente",
    cancelled: "cancelado",
    finished: "finalizado",
  };

  // useState
  const [status, setStatus] = useState(protocolizationById.status);
  const [snackBar, setSnackBar] = useState({
    message: "",
    open: false,
    severity: "",
  });

  const { message, open, severity } = snackBar;

  useEffect(() => {
    getProtocolizationById(protocolizationId, firebase, dispatch);
  }, [protocolizationId, firebase, dispatch]);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    border: `1px solid ${colors.blue}`,
    color: colors.black,
    minHeight: '20px',
  }));

  const handleChange = async (event) => {
    const newStatus = event.target.value;
    setStatus(newStatus);
    await updateProtocolizationById(
      protocolizationId,
      firebase,
      newStatus
    );
    setSnackBar({
      message: `El estado ha cambiado a ${typeStatus[newStatus]}`,
      open: true,
      severity: "success",
    });
  };

  const handleClose = () => {
    setSnackBar({
      message: "",
      open: false,
      severity: "",
    });
  };

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }} style={{ marginTop: 50 }}>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          <ArrowBackIcon />
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="subtitle2"
          >
            Regresar
          </Typography>
        </div>
        <div className="divider"></div>

        <PopupStateUi
          icon={ProtocolizationAssembliesIcon}
          title="Protocolización de Asambleas"
          status={status || protocolizationById.status}
          handleChange={handleChange}
        />

        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          Otros Documentos
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography className="subtitle2" fontWeight="bold">
              Pdf
            </Typography>
            <Item>
              <Button href={protocolizationById?.urlPdf} download>
                Descargar
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <div className="divider"></div>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 50 }}>
        <div className="divider">
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={4} sm={8} md={6}>
              <Typography className="subtitle2" fontWeight="bold">
                Nombre de la empresa
              </Typography>
              <Item>{protocolizationById?.companyName}</Item>
            </Grid>

            <Grid item xs={4} sm={8} md={6}>
              <Typography className="subtitle2" fontWeight="bold">
                Tipo de acta
              </Typography>
              <Item>{protocolizationById?.typeReport}</Item>
            </Grid>
          </Grid>
        </div>

        <div className="divider">
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid item xs={4} sm={8} md={6}>
              <Typography className="subtitle2" fontWeight="bold">
                Puntos a tratar en acta de asamblea/resoluciones:
              </Typography>
              <Item>{protocolizationById?.assembleResolutions}</Item>
            </Grid>

            <Grid item xs={4} sm={8} md={6}>
              <Typography className="subtitle2" fontWeight="bold">
                Puntos a protocolizar en el acta de asamblea
              </Typography>
              <Item>{protocolizationById?.actAssembly}</Item>
            </Grid>
          </Grid>
        </div>

        <div className="divider">
          <Grid
            container
            display="block"
            flexDirection="column"
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {/* Antecedentes de la empresa */}
            <div className="divider">
              <Grid item xs={4} sm={8} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Antecedentes de la empresa
                </Typography>
                {protocolizationById?.companyBackgroundFile &&
                  protocolizationById?.companyBackgroundFile?.map(
                    (document) => (
                      <Item style={{ marginTop: "7px" }}>
                        <Button href={document} download>
                          Descargar
                        </Button>
                      </Item>
                    )
                  )}
              </Grid>
            </div>

            {/* Cédulas fiscales de los accionistas y/o socios de la misma */}
            <div className="divider">
              <Grid item xs={4} sm={8} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Cédulas fiscales de los accionistas y/o socios de la misma
                </Typography>
                {protocolizationById?.ccShareholdersFile &&
                  protocolizationById?.ccShareholdersFile?.map((document) => (
                    <Item style={{ marginTop: "7px" }}>
                      <Button href={document} download>
                        Descargar
                      </Button>
                    </Item>
                  ))}
              </Grid>
            </div>

            {/* Acta firmada a protocolizar y su archivo en Word */}
            <div className="divider">
              <Grid item xs={4} sm={8} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Identificación oficial del delegado especial
                </Typography>
                {protocolizationById?.delegateFile &&
                  protocolizationById?.delegateFile?.map((document) => (
                    <Item style={{ marginTop: "7px" }}>
                      <Button href={document} download>
                        Descargar
                      </Button>
                    </Item>
                  ))}
              </Grid>
            </div>

            <div className="divider">
              <Grid item xs={4} sm={8} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Acta firmada a protocolizar y su archivo en Word
                </Typography>
                {protocolizationById?.actSignedFile &&
                  protocolizationById?.actSignedFile.map((document) => (
                    <Item style={{ marginTop: "7px" }}>
                      <Button href={document} download>
                        Descargar
                      </Button>
                    </Item>
                  ))}
              </Grid>
            </div>
          </Grid>

          {/* Step 2 */}
          <div className="divider">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={4} sm={8} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Nombre(s)
                </Typography>
                <Item>{protocolizationById?.name}</Item>
              </Grid>

              <Grid item xs={4} sm={8} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Apellido(s)
                </Typography>
                <Item>{protocolizationById?.lastname}</Item>
              </Grid>

              <Grid item xs={4} sm={8} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Nacionalidad *
                </Typography>
                <Item>{protocolizationById?.nacionality}</Item>
              </Grid>
            </Grid>
          </div>
          {/* divider */}
          <div className="divider">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={4} sm={8} md={6}>
                <Typography className="subtitle2" fontWeight="bold">
                  Lugar de nacimiento
                </Typography>
                <Grid
                  container
                  spacing={{ xs: 2, md: 3 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={4} sm={8} md={6}>
                    <Item>{protocolizationById?.state}</Item>
                  </Grid>
                  <Grid item xs={4} sm={8} md={6}>
                    <Item>{protocolizationById?.municipality}</Item>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <Typography className="subtitle2" fontWeight="bold">
                  Estado civil *
                </Typography>
                <Item>{protocolizationById?.maritalStatus}</Item>
              </Grid>
            </Grid>
          </div>
          {/* divider */}
          <div className="divider">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              {/* <Grid item xs={4} sm={8} md={3}>
                <Typography
                  variant="subtitle2"
                  gutterBottom
                  component="div"
                  className="subtitle2"
                >
                  Fecha de nacimiento
                </Typography>
                <Item>{protocolizationById?.dateBirth}</Item>
              </Grid> */}
              <Grid item xs={4} sm={8} md={3}>
                <Typography className="subtitle2" fontWeight="bold">
                  Ocupación *
                </Typography>
                <Item>{protocolizationById?.ocupation}</Item>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <Typography className="subtitle2" fontWeight="bold">
                  Registro Federal de Contribuyentes
                </Typography>
                <Item>{protocolizationById?.federalRegister}</Item>
              </Grid>
              <Grid item xs={4} sm={8} md={3}>
                <Typography className="subtitle2" fontWeight="bold">
                  CURP
                </Typography>
                <Item>{protocolizationById?.CURP}</Item>
              </Grid>
            </Grid>
          </div>
          {/* divider */}
          <div className="divider">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={4} sm={8} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Identificación *
                </Typography>
                <Item>
                  {protocolizationById?.companyBackgroundFile && (
                    <Button
                      href={protocolizationById?.companyBackgroundFile}
                      download
                    >
                      Descargar
                    </Button>
                  )}
                </Item>
              </Grid>
            </Grid>
          </div>
          {/* Step 3 */}
          <div className="divider">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={3}>
                <Typography className="subtitle2" fontWeight="bold">
                  Calle y número
                </Typography>
                <Item>{protocolizationById?.street}</Item>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Typography className="subtitle2" fontWeight="bold">
                  Colonia *
                </Typography>

                <Item>{protocolizationById?.colony}</Item>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Typography className="subtitle2" fontWeight="bold">
                  CP *
                </Typography>
                <Item>{protocolizationById?.cp}</Item>
              </Grid>
              <Grid item xs={12} sm={6} md={2}>
                <Typography className="subtitle2" fontWeight="bold">
                  Estado *
                </Typography>
                <Item>{protocolizationById?.stateHome}</Item>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Typography className="subtitle2" fontWeight="bold">
                  Municipio *
                </Typography>
                <Item>{protocolizationById?.municipalityHome}</Item>
              </Grid>
            </Grid>
          </div>
          {/* divider */}
          <div className="divider">
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Teléfono *
                </Typography>
                <Item>{protocolizationById?.phone}</Item>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography className="subtitle2" fontWeight="bold">
                  Correo electrónico
                </Typography>
                <Item>{protocolizationById?.email}</Item>
              </Grid>
            </Grid>
          </div>
          {/* divider */}
          <div className="divider">
            <Typography className="subtitle2" fontWeight="bold">
              Incapacidad
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={12} sm={6} md={6}>
                <Item>{protocolizationById?.inability}</Item>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Item>{protocolizationById?.otherInability}</Item>
              </Grid>
            </Grid>
          </div>
        </div>
      </Box>

      <SnackAlert
        message={message}
        open={open}
        severity={severity}
        handleClose={handleClose}
      />
    </Container>
  );
};

export default ProtocolizationAssembliesDetail;
