import React from "react";
import useStyles from "./styles";

const ErrorForm = ({ countErrors }) => {
    const classes = useStyles();
    return countErrors ? (
        <p className={classes.errors}>
            No podemos continuar, hay errores en el formulario
        </p>
    ) : null;
};

export default ErrorForm;
