import { Controller } from "react-hook-form";
import { TextField } from "@mui/material";

export const InputText = ({
  name,
  value,
  control,
  errors,
  placeholder,
  className,
  subType,
  ...properties
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={value ?? ""}
        render={({ field: { onChange, value } }) => (
          <TextField
            {...properties}
            placeholder={placeholder}
            className={className ? className : "input-text"}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  );
};
