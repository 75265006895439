import { string, object } from "yup";

const schemaMarriageContract = object({
  nameSpouseOne: string().required("Este campo es obligatorio"),
  nameSpouseTwo: string().required("Este campo es obligatorio"),
  nacionalitySpouseOne: string().required("Este campo es obligatorio"),
  nacionalitySpouseTwo: string().required("Este campo es obligatorio"),
  placeBirthSpouseOne: string().required("Este campo es obligatorio"),
  placeBirthSpouseTwo: string().required("Este campo es obligatorio"),
  dateBirthSpouseOne: string().required("Este campo es obligatorio"),
  dateBirthSpouseTwo: string().required("Este campo es obligatorio"),
  maritalStatusSpouseOne: string().required("Este campo es obligatorio"),
  maritalStatusSpouseTwo: string().required("Este campo es obligatorio"),
  ocupationSpouseOne: string().required("Este campo es obligatorio"),
  ocupationSpouseTwo: string().required("Este campo es obligatorio"),
  curpSpouseOne: string()
    .required("Este campo es obligatoriorequerido")
    .min(18, "El CURP debe contener 18 digitos")
    .max(18, "El CURP debe contener 18 digitos"),
  curpSpouseTwo: string()
    .required("Este campo es obligatoriorequerido")
    .min(18, "El CURP debe contener 18 digitos")
    .max(18, "El CURP debe contener 18 digitos"),
  rfcSpouseOne: string()
    .required("Este campo es obligatorio")
    .min(13, "El RFC debe contener 13 digitos")
    .max(13, "El RFC debe contener 13 digitos"),
  rfcSpouseTwo: string()
    .required("Este campo es obligatorio")
    .min(13, "El RFC debe contener 13 digitos")
    .max(13, "El RFC debe contener 13 digitos"),
  streetNumberSpouseOne: string().required("Este campo es obligatorio"),
  streetNumberSpouseTwo: string().required("Este campo es obligatorio"),
  postalcodeSpouseOne: string().required("Este campo es obligatorio"),
  postalcodeSpouseTwo: string().required("Este campo es obligatorio"),
  phoneSpouseOne: string().required("Este campo es obligatorio"),
  phoneSpouseTwo: string().required("Este campo es obligatorio"),
  emailSpouseOne: string()
    .required("Este campo es obligatorio")
    .email("Email no válido"),
  emailSpouseTwo: string()
    .required("Este campo es obligatorio")
    .email("Email no válido"),
  nameJudgeSpouseOne: string().required("Este campo es obligatorio"),
  dateCeremonmySpouseOne: string().required("Este campo es obligatorio"),
}).required();

export default schemaMarriageContract;
