import { Timestamp } from "firebase/firestore";
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
} from "firebase/storage";

import {
    GET_DONATIONS,
    GET_DONATION_BY_ID,
    CLEAR_DONATION,
    GENERATE_PDF,
} from "./constants";

export const createDonation = async (
    firebase,
    copyData,
    data,
    currentUser,
    dispatch
) => {
    try {
        let response = await firebase.db.collection("donation").add(copyData);

        await firebase.db
            .collection("users")
            .doc(currentUser.userId)
            .collection("transactions")
            .add({
                documentName: "donation",
                documentId: response.id,
                createdAt: Timestamp.fromDate(new Date()),
            });

        if (data?.conjugalPartnership?.length > 0)
            await updateConjugalPartnership(
                firebase,
                data.conjugalPartnership,
                response.id
            );

        if (data?.separateProperty?.length > 0)
            await updateSeparateProperty(
                firebase,
                data.separateProperty,
                response.id
            );

        if (data.passportFile.length > 0)
            await updatePassportFile(firebase, data.passportFile, response.id);

        if (data.passportFileMigrate.length > 0)
            await updatePassportFileMigrate(
                firebase,
                data.passportFileMigrate,
                response.id
            );

        if (data.homeFile.length > 0)
            await updateHomeFile(firebase, data.homeFile, response.id);

        if (data.legalRepresentative.length > 0)
            await updateLegalRepresentative(
                firebase,
                data.legalRepresentative,
                response.id
            );

        (await response?.id) &&
            (await getDataPdf(firebase, dispatch, response.id));
        return { donationId: response.id };
    } catch (error) {
        console.error("Error-Donations===>", error);
    }
};

export const editDonation = async (
    firebase,
    copyData,
    documentId,
    dispatch,
    data
) => {
    try {
        await firebase.db
            .collection("donation")
            .doc(documentId)
            .update(copyData);

        if (copyData?.conjugalPartnership === "" && data?.conjugalPartnership)
            await updateConjugalPartnership(
                firebase,
                data?.conjugalPartnership,
                documentId
            );

        if (copyData?.separateProperty === "" && data?.separateProperty)
            await updateSeparateProperty(
                firebase,
                data?.separateProperty,
                documentId
            );

        if (copyData.passportFile === "" && data.passportFile)
            await updatePassportFile(firebase, data.passportFile, documentId);

        if (copyData.passportFileMigrate === "" && data.passportFileMigrate)
            await updatePassportFileMigrate(
                firebase,
                data.passportFileMigrate,
                documentId
            );

        if (copyData.homeFile === "" && data.homeFile)
            await updateHomeFile(firebase, data.homeFile, documentId);

        if (copyData.legalRepresentative === "" && data.legalRepresentative)
            await updateLegalRepresentative(
                firebase,
                data.legalRepresentative,
                documentId
            );

        await getDataPdf(firebase, dispatch, documentId);

        return true;
    } catch (error) {
        console.error("Error-Donations===>", error);
        return false;
    }
};

export const updateDocx = async (firebase, donationId, url) => {
    firebase.db
        .collection("donation")
        .doc(donationId)
        .update({
            urlPdf: url,
        })
        .then((docs) => {
            console.log("Success Update docx");
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const updateConjugalPartnership = async (firebase, file, idDonation) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Documentos/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                reject();
                console.error("Error Storage Firebase::", error);
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("donation")
                        .doc(idDonation)
                        .update({
                            conjugalPartnership: downloadURL,
                        })
                        .then((docs) => {
                            console.log("Success Update");
                            resolve();
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const updateSeparateProperty = async (firebase, file, idDonation) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Documentos/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                console.error("Error Storage Firebase::", error);
                reject();
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("donation")
                        .doc(idDonation)
                        .update({
                            separateProperty: downloadURL,
                        })
                        .then((docs) => {
                            console.log("Success Update");
                            resolve();
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const updatePassportFile = async (firebase, file, idDonation) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Documentos/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                console.error("Error Storage Firebase::", error);
                reject();
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("donation")
                        .doc(idDonation)
                        .update({
                            passportFile: downloadURL,
                        })
                        .then((docs) => {
                            console.log("Success Update");
                            resolve();
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const updatePassportFileMigrate = async (firebase, file, idDonation) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Documentos/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                reject();
                console.error("Error Storage Firebase::", error);
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("donation")
                        .doc(idDonation)
                        .update({
                            passportFileMigrate: downloadURL,
                        })
                        .then((docs) => {
                            resolve();
                            console.log("Success Update");
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const updateHomeFile = async (firebase, file, idDonation) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Documentos/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                console.error("Error Storage Firebase::", error);
                reject();
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("donation")
                        .doc(idDonation)
                        .update({
                            homeFile: downloadURL,
                        })
                        .then((docs) => {
                            console.log("Success Update");
                            resolve();
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const updateLegalRepresentative = async (firebase, file, idDonation) => {
    const storage = getStorage();

    const metadata = {
        contentType: "image/*",
    };
    const storageRef = ref(storage, "Documentos/" + file[0].name);
    const uploadTask = uploadBytesResumable(storageRef, file[0], metadata);

    await new Promise((resolve, reject) => {
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log("Upload is " + progress + "% done");
            },
            (error) => {
                // A full list of error codes is available at
                reject();
                console.error("Error Storage Firebase::", error);
            },
            () => {
                // Upload completed successfully, now we can get the download URL
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    firebase.db
                        .collection("donation")
                        .doc(idDonation)
                        .update({
                            legalRepresentative: downloadURL,
                        })
                        .then((docs) => {
                            resolve();
                            console.log("Success Update");
                        })
                        .catch(function (error) {
                            console.error("Error getting documents: ", error);
                        });
                    console.log("File available at", downloadURL);
                });
            }
        );
    });
};

export const getDonations = async (firebase, dispatch) => {
    try {
        firebase.db
            .collection("donation")
            .get()
            .then((docs) => {
                docs.forEach((doc) => {
                    dispatch({
                        type: GET_DONATIONS,
                        donation: { id: doc.id, ...doc.data() },
                    });
                });
            });
    } catch (error) {
        console.error("Error-getDonations===>", error);
    }
};

export const getDonationById = async (donationId, firebase, dispatch) => {
    try {
        firebase.db
            .collection("donation")
            .doc(donationId)
            .get()
            .then((docs) => {
                dispatch({
                    type: GET_DONATION_BY_ID,
                    donation: docs.data(),
                });
            });
    } catch (error) {
        console.error("Error-getCompanyById===>", error);
    }
};

export const getDataPdf = async (firebase, dispatch, donationId) => {
    await firebase.db
        .collection("donation")
        .doc(donationId)
        .get()
        .then((docs) => {
            dispatch({
                type: GENERATE_PDF,
                pdf: true,
                donationById: docs.data(),
            });
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const updateDonationById = async (donationId, firebase, status) => {
    firebase.db
        .collection("donation")
        .doc(donationId)
        .update({
            status: status,
        })
        .then(() => {
            console.log("Success Update docx");
        })
        .catch(function (error) {
            console.error("Error getting documents: ", error);
        });
};

export const clearDonation = async (dispatch) => {
    dispatch({
        type: CLEAR_DONATION,
        donation: {
            listDonation: [],
            donationById: {},
        },
    });
};
