import { string, object } from "yup";

const schemaOneForm = object({
  typeSociety: string().required("Este campo es obligatorio"),
  denomination1: string().required("Este campo es obligatorio"),
  denomination2: string().required("Este campo es obligatorio"),
  denomination3: string().required("Este campo es obligatorio"),
  denomination4: string().required("Este campo es obligatorio"),
  denomination5: string().required("Este campo es obligatorio"),
  socialObject: string().required("Este campo es obligatorio"),
}).required();

export default schemaOneForm;
