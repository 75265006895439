import React, { useEffect, useRef } from "react";
import { Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Grid, Container } from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import moment from "moment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useStateValue } from "context/store";

const CompanyDetail = () => {
  let {
    state: { company, shareholders },
  } = useLocation();

  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  const [
    {
      user: { userById },
    },
    dispatch,
  ] = useStateValue();

  useEffect(() => {
    if (currentUser?.rol !== "notario") {
      navigate("/app/form-login", {
        replace: true,
        state: {
          src: window.location.pathname,
        },
      });
    }
  }, [userById])

  const { current: currentUser } = useRef(
    JSON.parse(localStorage.getItem("current-user"))
  );

  return (
    <Container>
      <Box sx={{ flexGrow: 1 }} style={{ marginTop: 50 }}>
        <div
          style={{ display: "flex", cursor: "pointer" }}
          onClick={() => navigate(-1)}
        >
          {/* <IconButton size="small"> */}
          <ArrowBackIcon />
          {/* </IconButton> */}
          <Typography
            variant="h6"
            gutterBottom
            component="div"
            className="subtitle2"
          >
            Regresar
          </Typography>
        </div>
        <div className="divider"></div>

        <Typography
          variant="h5"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          Otros Documentos
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Pdf
            </Typography>
            <Item>
              <Button href={company?.urlPdf} download>
                Descargar
              </Button>
            </Item>
          </Grid>
        </Grid>
      </Box>
      <div className="divider"></div>
      <Box sx={{ flexGrow: 1 }} style={{ marginBottom: 50 }}>
        {/* Tipo de sociedad */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          1. Tipo de sociedad
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.typeSociety}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Denominación o razón social */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          2. Denominación o razón social
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.denomination1}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{company?.denomination2}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{company?.denomination3}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{company?.denomination4}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{company?.denomination5}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Denominación o razón social */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          3. Objeto social
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.socialObject}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Domicilio social */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          4. Domicilio social
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.registeredOffice}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Capital social */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          5. Capital social
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.amountPesos}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Socio o accionista
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Porcentaje de participación
            </Typography>
          </Grid>
        </Grid>

        {/* Capital social participationRate */}
        {company?.participationRate.map(capital => (
          <Grid container spacing={2} style={{ marginBottom: 20 }}>
            <Grid item xs={6}>
              <Item>{capital.nameShareholder}</Item>
            </Grid>
            <Grid item xs={6}>
              <Item>{capital.percentShareholder}%</Item>
            </Grid>
          </Grid>
        ))}

        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          Acciones
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.action}</Item>
          </Grid>
          <Grid item xs={6}>
            <Item>{company?.valueEachAction}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Nacionalidad */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          6. Nacionalidad
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.nacionality}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Administración */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          7. Administración
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.administration}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Facultades */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          8. Facultades
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Item>{company?.administration}</Item>
          </Grid>
        </Grid>
        <div className="divider"></div>
        {/* Apoderados */}
        <Grid container>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Apoderados
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Para ejercer de forma individual
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Para ejercer de forma mancomunada
            </Typography>
          </Grid>
        </Grid>

        {company?.proxies.length > 0 &&
          company.proxies.map(proxi => (
            <Grid container spacing={2} style={{ marginBottom: 20 }}>
              <Grid item xs={2}>
                <Item>
                  <strong>{proxi.takenOver}</strong>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item>
                  <strong>
                    {proxi.nestedArray.map(nested => {
                      const proxies = [];
                      if (nested.field1) proxies.push("Pleitos y cobranzas");
                      if (nested.field2)
                        proxies.push("Actos de administración");
                      if (nested.field3) proxies.push("Cuentas bancarias");
                      if (nested.field4) proxies.push("Actos de dominio");
                      if (nested.field5) proxies.push("Cambiario");
                      if (nested.field6) proxies.push("Facultad de delegación");
                      return proxies.map(item => `${item} - `);
                    })}
                  </strong>
                </Item>
              </Grid>
              <Grid item xs={5}>
                <Item>
                  <strong>
                    {proxi.v2nestedArray.map(nested => {
                      const proxies = [];
                      if (nested.field1) proxies.push("Pleitos y cobranzas");
                      if (nested.field2)
                        proxies.push("Actos de administración");
                      if (nested.field3) proxies.push("Cuentas bancarias");
                      if (nested.field4) proxies.push("Actos de dominio");
                      if (nested.field5) proxies.push("Cambiario");
                      if (nested.field6) proxies.push("Facultad de delegación");
                      return proxies.map(item => `${item} - `);
                    })}
                  </strong>
                </Item>
              </Grid>
            </Grid>
          ))}
        <div className="divider"></div>
        {/* Facultades */}
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
        >
          9. Vigilancia: comisario
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Nombre
            </Typography>
            <Item>{company?.nameCommissioner}</Item>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              RFC
            </Typography>
            <Item>{company?.RFCCommissioner}</Item>
          </Grid>
          <Grid item xs={6}>
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
            >
              Comentarios y observaciones
            </Typography>
            <Item>{company?.commentsCommissioner}</Item>
          </Grid>
        </Grid>

        <div className="divider"></div>
        {/* accionistas */}
        {shareholders.map((shareholder, index) => {
          let dateBirth = new Date(shareholder.dateBirth);
          var dateMomentObject = moment(dateBirth).locale("es").format("L");

          return (
            <Box key={shareholder.id}>
              <Typography
                variant="h5"
                gutterBottom
                component="div"
                className="subtitle2"
              >
                Accionista {index + 1}
              </Typography>
              <div className="divider"></div>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Nombres
                  </Typography>
                  <Item>{shareholder.name}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Apellido paterno
                  </Typography>
                  <Item>{shareholder.paternalSurname}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Apellido materno
                  </Typography>
                  <Item>{shareholder.maternalSurname}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Nacionalidad
                  </Typography>
                  <Item>{shareholder.nacionality}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Lugar de nacimiento
                  </Typography>
                  <Item>{shareholder.placeBirth}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Fecha de nacimiento
                  </Typography>
                  <Item>{dateMomentObject}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Estado civil
                  </Typography>
                  <Item>{shareholder.maritalStatus}</Item>
                </Grid>
                {shareholder.isMarried && (
                  <Grid item xs={4}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      component="div"
                      className="subtitle2"
                    >
                      Estado civil
                    </Typography>
                    <Item>{shareholder.isMarried}</Item>
                  </Grid>
                )}
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Ocupación
                  </Typography>
                  <Item>{shareholder.ocupation}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    RFC
                  </Typography>
                  <Item>{shareholder.RFC}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    CURP
                  </Typography>
                  <Item>{shareholder.CURP}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    CURP
                  </Typography>
                  <Item>{shareholder.CURP}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    identificación
                  </Typography>
                  <Item>{shareholder.identification}</Item>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Documento de identificación
                  </Typography>
                  <Item>
                    <Button href={shareholder.file} download>
                      Descargar
                    </Button>
                  </Item>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Calle y número
                  </Typography>
                  <Item>{shareholder.streetNumber}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Colonia
                  </Typography>
                  <Item>{shareholder.colony}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Estado
                  </Typography>
                  <Item>{shareholder.state}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Municipio
                  </Typography>
                  <Item>{shareholder.municipality}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    C.P
                  </Typography>
                  <Item>{shareholder.CP}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Comprobante de domicilio
                  </Typography>
                  <Item>
                    <Button href={shareholder.homeFile} download>
                      Descargar
                    </Button>
                  </Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Teléfono
                  </Typography>
                  <Item>{shareholder.phone}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Correo electronico
                  </Typography>
                  <Item>{shareholder.email}</Item>
                </Grid>
                <Grid item xs={4}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    component="div"
                    className="subtitle2"
                  >
                    Incapacidad
                  </Typography>
                  <Item>{shareholder.inability}</Item>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </Box>
    </Container>
  );
};

export default CompanyDetail;
