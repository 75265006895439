import {
    GET_DONATIONS,
    GET_DONATION_BY_ID,
    CLEAR_DONATION,
    GENERATE_PDF,
} from "./constants";

const donationReducer = (state, action) => {
    switch (action.type) {
        case GET_DONATIONS:
            return {
                ...state,
                listDonation: [...state.listDonation, action.donation],
            };
        case GET_DONATION_BY_ID:
            return {
                ...state,
                donationById: action.donation,
            };
        case CLEAR_DONATION:
            return {
                ...state,
                donation: action.donation,
            };
        case GENERATE_PDF:
            return {
                ...state,
                pdf: action.pdf,
                donationById: action.donationById,
            };
        default:
            return state;
    }
};

export default donationReducer;
