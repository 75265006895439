import ErrorComponent from "./ErrorComponent";

function AppearingElement({
  handleChange,
  countryOptions,
  stateOptions,
  dataFormAppearing,
  editingIndex,
  mexicanStates,
  citiesOptions,
  handleAddressFileChange,
  handleIdentificationFileChange,
  handleMaritalStatusChange,
  handleDisabilityChange,
  handleIdentificationChange,
  errors,
}) {
  
  const handleLocalChange = (e) => {
    handleChange(e);
  };

  const handleLocalFileChange = (e) => {
    handleAddressFileChange(e);
  };

  const handleLocalIdentificationFileChange = (e) => {
    handleIdentificationFileChange(e);
  };

  const handleLocalMaritalStatusChange = (e) => {
    handleMaritalStatusChange(e);
  };

  const handleLocalDisabilityChange = (e) => {
    handleDisabilityChange(e);
  };

  const handleLocalIdentificationChange = (e) => {
    handleIdentificationChange(e);
  };

  return (
    <>
      <div className="inputs__elements__style pd-bottom">
        <p>Tipo de compareciente </p>
        <select
          name="appearing"
          onChange={handleLocalChange}
          value={dataFormAppearing[editingIndex]?.appearing}
        >
          <option value="">- Compareciente -</option>
          <option value="donante">Donante</option>
          <option value="donatario">Donatario</option>
        </select>
        { errors.appearing && <ErrorComponent error={ errors?.appearing } /> }
      </div>
      <div className="first__section">
        <div className="inputs__elements__style">
          <p>Nombres *</p>
          <input
            type="text"
            name="name"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.name}
          />
          { errors.name && <ErrorComponent error={ errors?.name } /> }
        </div>
        <div className="inputs__elements__style">
          <p>Apellido paterno *</p>
          <input
            type="text"
            name="lastName"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.lastName}
          />
          { errors.lastName && <ErrorComponent error={ errors?.lastName } />}
        </div>
        <div className="inputs__elements__style">
          <p>Apellido materno *</p>
          <input
            type="text"
            name="lastName2"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.lastName2}
          />
          { errors.lastName2 && <ErrorComponent error={ errors?.lastName2 } />}
        </div>
        <div className="inputs__elements__style">
          <p>Nacionalidad *</p>
          <select
            name="nacionality"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.countryId}
          >
            <option value="0" disabled>
              - Nacionalidad -
            </option>
            {countryOptions.map((country) => (
              <option value={country.value} key={country.label}>
                {country.label}
              </option>
            ))}
          </select>
          { errors.country && <ErrorComponent error={ errors?.country } />}
        </div>
        <div className="inputs__elements__style">
          <p>Lugar de nacimiento *</p>
          <select
            name="placeBirth"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.stateId}
          >
            <option value="0" disabled>
              - Lugar de nacimiento -
            </option>
            {stateOptions.map((state) => (
              <option value={state.value} key={state.label}>
                {state.label}
              </option>
            ))}
          </select>
          { errors.state && <ErrorComponent error={errors?.state} /> }
        </div>
        <div className="inputs__elements__style">
          <p>Fecha de nacimiento *</p>
          <input
            type="date"
            name="dateBirth"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.dateBirth}
          />
          { errors.dateBirth && <ErrorComponent error={ errors?.dateBirth } /> }
        </div>
        <div className="inputs__elements__style-check">
          <p>Estado civil *</p>
          <div className="checkbox__elements__style">
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={'soltero'}
                checked={dataFormAppearing[editingIndex]?.maritalRadioValue?.soltero}
                onChange={handleLocalMaritalStatusChange}
              />
              Soltero(a)
            </label>
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={'casado'}
                checked={dataFormAppearing[editingIndex]?.maritalRadioValue?.casado}
                onChange={handleLocalMaritalStatusChange}
              />
              Casado(a)
            </label>
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={'unionLibre'}
                checked={dataFormAppearing[editingIndex]?.maritalRadioValue?.unionLibre}
                onChange={handleLocalMaritalStatusChange}
              />
              Unión libre
            </label>
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={'divorciado'}
                checked={dataFormAppearing[editingIndex]?.maritalRadioValue?.divorciado}
                onChange={handleLocalMaritalStatusChange}
              />
              Divorciado
            </label>
            <label>
              <input
                type="radio"
                name="maritalStatus"
                value={'viudo'}
                checked={dataFormAppearing[editingIndex]?.maritalRadioValue?.viudo}
                onChange={handleLocalMaritalStatusChange}
              />
              Viudez
            </label>
          </div>
        </div>
        <div className="inputs__elements__style">
          <p>Ocupación *</p>
          <input
            type="text"
            name="occupation"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.occupation}
          />
          { errors.occupation && <ErrorComponent error={errors?.occupation} /> }
        </div>
        <div className="inputs__elements__style">
          <p>RFC *</p>
          <input
            type="text"
            name="rfc"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.rfc}
          />
          { errors.rfc && <ErrorComponent error={errors?.rfc} /> }
        </div>
        <div className="inputs__elements__style">
          <p>CURP *</p>
          <input
            type="text"
            name="curp"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.curp}
          />
          { errors.curp && <ErrorComponent error={errors?.curp} /> }
        </div>
        <div className="inputs__elements__style-check">
          <p>Identificación *</p>
          <div className="checkbox__elements__style">
            <label>
              <input
                type="radio"
                name="identification"
                value={'credential'}
                checked={dataFormAppearing[editingIndex]?.identificationRadioValue?.credential}
                onChange={handleLocalIdentificationChange}
              />
              Credencial de elector
            </label>
            <label>
              <input
                type="radio"
                name="identification"
                value={'passport'}
                checked={dataFormAppearing[editingIndex]?.identificationRadioValue?.passport}
                onChange={handleLocalIdentificationChange}
              />
              Pasaporte
            </label>
          </div>
        </div>
      </div>
      <p className="address__title">Domicilio *</p>
      <div className="second__section">
        <div className="inputs__elements__style inputs__elements__style-second-section">
          <p>Calle y número</p>
          <input
            type="text"
            name="streetNumber"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.streetNumber}
          />
          { errors.streetNumber && <ErrorComponent error={ errors?.streetNumber } /> }
        </div>
        <div className="inputs__elements__style inputs__elements__style-second-section">
          <p>Colonia</p>
          <input
            type="text"
            name="colony"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.colony}
          />
          { errors.colony && <ErrorComponent error={ errors?.colony } /> }
        </div>
        <div className="inputs__elements__style inputs__elements__style-second-section">
          <p>Estado *</p>
          <select
            name="liveState"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.liveStateId}
          >
            <option value="0">- Estado -</option>
            {mexicanStates.map((state) => (
              <option value={state.value} key={state.label}>
                {state.label}
              </option>
            ))}
          </select>
          { errors.liveState && <ErrorComponent error={ errors?.liveState } /> }
        </div>
        <div className="inputs__elements__style inputs__elements__style-second-section">
          <p>Municipio *</p>
          <select
            name="liveMunicipality"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.liveMunicipalityId}
          >
            <option value="0">- Municipio -</option>
            {citiesOptions.map((city) => (
              <option value={city.value} key={city.label}>
                {city.label}
              </option>
            ))}
          </select>
          { errors.liveMunicipality && <ErrorComponent error={ errors?.liveMunicipality } /> }
        </div>
        <div className="inputs__elements__style width__100 flex__element">
          <div className="inputs__elements__style-second-section">
            <p>C.P *</p>
            <input
              type="text"
              name="cp"
              onChange={handleLocalChange}
              value={dataFormAppearing[editingIndex]?.cp}
            />
            { errors.cp && <ErrorComponent error={ errors?.cp } /> }
          </div>
          <div className="files">
            <button className="container-btn-file" type="button">
              Comprobante de domicilio
              <i className="fa-solid fa-plus"></i>
              <label htmlFor="proof-of-address"></label>
              <input
                type="file"
                id="proof-of-address"
                onChange={handleLocalFileChange}
              />
            </button>
            {
              dataFormAppearing[editingIndex]?.addressFileName && (
                <label className="files__text-label">
                  <i className="fa-regular fa-clipboard"></i>
                  {
                    dataFormAppearing[editingIndex]?.addressFileName?.length < 20 
                      ? dataFormAppearing[editingIndex]?.addressFileName?.substring(0, 20) 
                      : dataFormAppearing[editingIndex]?.addressFileName?.substring(0, 20) + '...'
                  }
                </label>
              )
            }
          </div>
        </div>
        <div className="inputs__elements__style">
          <p>Teléfono</p>
          <input
            type="number"
            name="phone"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.phone}
          />
          { errors.phone && <ErrorComponent error={ errors?.phone } />}
        </div>
        <div className="inputs__elements__style ">
          <p>Correo electrónico</p>
          <input
            type="email"
            name="email"
            onChange={handleLocalChange}
            value={dataFormAppearing[editingIndex]?.email}
          />
          { errors.email && <ErrorComponent error={ errors?.email } />}
        </div>
        <div className="inputs__elements__style-check">
          <p>¿Cuentas con algún tipo de incapacidad? *</p>
          <div className="checkbox__elements__style">
            <label>
              <input
                type="radio"
                name="disability"
                value={'none'}
                checked={dataFormAppearing[editingIndex]?.disabilityRadioValue?.none}
                onChange={handleLocalDisabilityChange}
              />
              Ninguna
            </label>
            <label>
              <input
                type="radio"
                name="disability"
                value={'deaf'}
                checked={dataFormAppearing[editingIndex]?.disabilityRadioValue?.deaf}
                onChange={handleLocalDisabilityChange}
              />
              Sordo
            </label>
            <label>
              <input
                type="radio"
                name="disability"
                value={'mute'}
                checked={dataFormAppearing[editingIndex]?.disabilityRadioValue?.mute}
                onChange={handleLocalDisabilityChange}
              />
              Mudo
            </label>
            <label>
              <input
                type="radio"
                name="disability"
                value={'blind'}
                checked={dataFormAppearing[editingIndex]?.disabilityRadioValue?.blind}
                onChange={handleLocalDisabilityChange}
              />
              Ciego
            </label>
            <label>
              <input
                type="radio"
                name="disability"
                value={'other'}
                checked={dataFormAppearing[editingIndex]?.disabilityRadioValue?.other}
                onChange={handleLocalDisabilityChange}
              />
              Otra
            </label>
          </div>
        </div>
        <div className="files">
          <button className="container-btn-file" type="button">
            Documento de identificación
            <i className="fa-solid fa-plus"></i>
            <label htmlFor="identification-file"></label>
            <input
              type="file"
              id="identification-file"
              onChange={handleLocalIdentificationFileChange}
            />
          </button>
          {
            dataFormAppearing[editingIndex]?.identificationFileName && (
              <label className="files__text-label">
                <i className="fa-regular fa-clipboard"></i>
                {
                  dataFormAppearing[editingIndex]?.identificationFileName?.length < 20 
                    ? dataFormAppearing[editingIndex]?.identificationFileName?.substring(0, 20) 
                    : dataFormAppearing[editingIndex]?.identificationFileName?.substring(0, 20) + '...'
                }
              </label>
            )
          }
        </div>
      </div>
    </>
  );
}

export default AppearingElement;
