import Dropzone, { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

export default function DropzoneComponent({
  multiple,
  maxSize,
  acceptFiles,
  setValue,
  name,
  currentFiles,
  setFiles,
}) {
  const { t } = useTranslation("app");
  // const [files, setFiles] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        if (multiple) {
          if (acceptedFiles?.length) {
            setFiles((prevValue) => [...prevValue, acceptedFiles]);
            setValue(name, [...currentFiles, acceptedFiles]);
          }
        } else {
          setFiles(acceptedFiles);
          setValue(name, acceptedFiles);
        }
      }}
      accept={acceptFiles || ""}
      maxSize={maxSize || 20971520}
      multiple={multiple || false}
    >
      {({ getRootProps, getInputProps, isDragReject }) => {
        const fileNotAllowed = isDragReject && "commons.fileTypeNotAllowed";
        return (
          <>
            <div
              {...getRootProps({ className: "dropzone" })}
              style={{ display: "none" }}
            >
              <input {...getInputProps()} />
              <div>
                <p>{t("commons.dragAndDropImage")}</p>
                {/* <Button primary type="button">{t('commons.clickHere')}</Button> */}
                {/* <button type="button">Obtener imagen</button> */}
              </div>
            </div>
            {fileNotAllowed && <p className="red">{t(fileNotAllowed)}</p>}
          </>
        );
      }}
    </Dropzone>
  );
}
