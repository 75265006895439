import React, { useState, useEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Typography, IconButton, Button } from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { InputRadio } from "components/inputs/input-radio";
import { labels, nacionalityLabels } from "./constants";
import { InputNumberFormat } from "components/inputs/input-number-format";
import QuestionMarket from "components/question-market/question-market";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { marketRegisteredOffice } from "components/question-market/questions";
import { colors } from "constants/colors";
import { InputNumber } from "components/inputs/input-number";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { PartnersControllerContext } from "context/company/PartnersControllerContext";

export const TwoNestedInput = ({
  value,
  fields,
  append,
  remove,
  handleActionPercentShare,
}) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useFormContext();

  const { setPartners } = useContext(PartnersControllerContext);

  useEffect(() => {
    if (value?.sacvpartner1) {
      setValue(`participationRate[0].nameShareholder`, value?.sacvpartner1);
    }
  }, [value]);

  let isNominalValue = watch("action") === "Con expresión de valor nominal";
  let sumPercentShare = 0;

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  const onChange = (e) => {
    if (e.target.value > 100) {
      return 100;
    }
    return e.target.value;
  };

  watch().participationRate.forEach((element) => {
    sumPercentShare += Number(element?.percentShareholder);
    handleActionPercentShare(sumPercentShare);
  });

  useEffect(() => {
    setPartners(getValues("participationRate"));
  }, [getValues()]);

  return (
    <>
      <div className="divider">
        <div style={{ marginTop: 30, marginBottom: 30 }}>
          <Typography variant="subtitle2" color={colors.blue}>
            Los campos marcados con * son obligatorios
          </Typography>
        </div>

        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <div style={{ display: "flex", alignItems: "flex-end" }}>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                className="subtitle2"
                style={styles.title}
              >
                4. Domicilio social *
              </Typography>

              <IconButton onClick={() => setOpen(true)} size="small">
                <HelpOutlineIcon style={{ fontSize: 20 }} />
              </IconButton>
            </div>

            <InputText
              name="registeredOffice"
              value={value.registeredOffice}
              control={control}
              errors={errors}
            />
            {errors.registeredOffice && (
              <p style={styles.errors}>{errors.registeredOffice.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <Typography
        variant="subtitle2"
        gutterBottom
        component="div"
        className="subtitle2"
        style={styles.title}
      >
        5. Capital social *<span> (monto y distribución del capital)</span>
      </Typography>

      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        <Grid item xs={4} sm={8} md={4}>
          <div className="divider">
            <Typography variant="body1" gutterBottom>
              Monto en pesos
            </Typography>
            <InputNumberFormat
              name="amountPesos"
              value={value.amountPesos || ""}
              placeholder="$0"
              control={control}
              errors={errors}
            />
            {errors.amountPesos && (
              <p style={styles.errors}>{errors.amountPesos.message}</p>
            )}
          </div>

          <div className="divider">
            <Typography
              variant="subtitle2"
              gutterBottom
              component="div"
              className="subtitle2"
              style={styles.title}
            >
              Acciones *
            </Typography>
            <InputRadio
              name="action"
              value={value.action}
              control={control}
              errors={errors}
              labels={labels}
            />
            {errors.action && (
              <p style={styles.errors}>{errors.action.message}</p>
            )}
          </div>

          {isNominalValue && (
            <div className="divider">
              <Typography variant="body1" gutterBottom>
                Valor de cada accion
              </Typography>
              <InputNumberFormat
                name="valueEachAction"
                value={value.valueEachAction}
                placeholder="$0"
                control={control}
                errors={errors}
              />
            </div>
          )}
        </Grid>

        <Grid item xs={10} sm={10} md={8}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 8, sm: 8, md: 12 }}
          >
            {fields.map((item, index) => (
              <React.Fragment key={index}>
                <Grid item xs={4} sm={3} md={5}>
                  <div className="divider" key={index}>
                    <Typography variant="body1" gutterBottom>
                      Socio o accionista
                    </Typography>
                    <InputText
                      name={`participationRate[${index}].nameShareholder`}
                      control={control}
                      errors={errors}
                      defaultValue={item.nameShareholder}
                    />
                  </div>
                </Grid>
                <Grid item xs={3} sm={3} md={5}>
                  <div className="divider" key={index}>
                    <Typography variant="body1" gutterBottom>
                      Porcentaje
                    </Typography>
                    <InputNumber
                      name={`participationRate[${index}].percentShareholder`}
                      control={control}
                      errors={errors}
                      defaultValue={item.percentShareholder}
                      onChange={onChange}
                    />
                  </div>
                </Grid>
                <Grid item xs={1} sm={2} md={2}>
                  <br />
                  <IconButton onClick={() => remove(index)} size="small">
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>

          {sumPercentShare > 100 ? (
            <p style={styles.errors}>
              La suma de los porcentajes no puede ser mayor a{" "}
              <strong>100%</strong>
              <br />
              <strong> suma = {sumPercentShare}%</strong>
            </p>
          ) : null}
          <Button
            type="button"
            variant="contained"
            onClick={() => sumPercentShare <= 100 && append()}
            style={{
              background: colors.white,
              color: colors.blue,
              border: `1px solid ${colors.blue}`,
              borderRadius: 20,
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
          >
            Añadir Accionista/Socio
            <div style={{ marginLeft: 30 }}>+</div>
          </Button>
        </Grid>
      </Grid>

      <div className="divider">
        <Typography
          variant="body1"
          gutterBottom
          component="div"
          className="note"
        >
          <strong>Nota:</strong> en caso de que los socios o accionistas sean
          personas morales extranjeras, favor de adjuntar a la presente el poder
          traducido y apostillado, además de identificación y generales del
          representante que firmará la escritura.
        </Typography>
      </div>

      <div className="divider">
        <Typography
          variant="subtitle2"
          gutterBottom
          component="div"
          className="subtitle2"
          style={styles.title}
        >
          6. Nacionalidad *
        </Typography>
        <InputRadio
          name="nacionality"
          value={value.nacionality}
          control={control}
          errors={errors}
          labels={nacionalityLabels}
        />
        {errors.nacionality && (
          <p style={styles.errors}>{errors.nacionality.message}</p>
        )}
      </div>

      {/* QuestionMarket */}
      <QuestionMarket
        open={open}
        handleClose={handleClose}
        question={marketRegisteredOffice.question}
        definition={marketRegisteredOffice.definition}
      />
    </>
  );
};

const styles = {
  title: {
    color: colors.blue,
    fontWeight: "bold",
  },
  errors: {
    padding: "5px 15px",
    fontSize: 14,
    borderRadius: 15,
    marginTop: -1,
    borderTopLeftRadius: 2,
    backgroundColor: colors.dangerLight,
    display: "inline-block",
    border: `1px solid ${colors.danger}`,
    color: colors.danger,
  },
};
