import { makeStyles } from '@mui/styles';
import { colors } from 'constants/colors';

export default makeStyles(() => ({
  container: {
    minHeight: "83vh",
    display: "flex",
    justifyContent: 'center',
  },
  content: {
    height: "fit-content",
    marginTop: 120,
    alignItems: 'center',
  },
  title: {
    color: colors.blue,
    textAlign: "center",
    marginBottom: 40,
    lineHeight: 1.50,
    fontFamily: 'poppinsregular'
  },
  contentMail: {
    display: 'flex',
    justifyContent: 'center'
  },
  titleEmail: {
    fontSize: 20,
    color: colors.blue,
    fontWeight: 'bold',
    fontFamily: 'avenirnextbold'
  },
  span: {
    color: colors.blue,
  },
  circleMail: {
    width: 110,
    height: 110,
    borderRadius: "50%",
    backgroundColor: colors.blue,
    display: "flex",
    justifyContent: "center",
    alignItems: 'center',
    marginRight: 30,
  },
  iconMail: {
    width: 38,
    height: 40,
    objectFit: 'contain'
  },
  widthText: {
    width: '46%'
  }
}));
