import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { colors } from 'constants/colors';

export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: colors.blue,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: colors.blue,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : colors.white,
  zIndex: 1,
  color: colors.blue,
  fontWeight: "bold",
  border: `1px solid ${colors.blue}`,
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: colors.blue,
    color: colors.white,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    fontWeight: "bold",
  }),
  ...(ownerState.completed && {
    backgroundColor: colors.lightskyblue,
    border: "none",
    color: colors.blue,
    fontWeight: "bold",
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
}));


export const ColorlibStepIcon = (props) => {

  const { active, completed, className } = props;

  const icons = {
    1: "1.",
    2: "2.",
    3: "3.",
    4: "4.",
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}