import React from "react";
import "./adminComponent.css";
import { useEffect } from "react";
import { useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import "firebase/firestore";
import { useContext } from "react";
import { ModuleContext } from "context/modulesContext/ModuleContext";

function AdminComponent({
  initialConfig,
  setInitialConfig,
  submitHandler,
  asyncSubmitHandler,
  handleLogoChange,
  handleLogoSecondaryChange,
}) {
  const [configData, setConfigData] = useState();

  const { setSiteConfig } = useContext(ModuleContext);

  const handleTextChange = (e) => {
    setInitialConfig({ ...initialConfig, name: e.target.value });
  };
  const handleBaseColorChange = (e) => {
    setInitialConfig({ ...initialConfig, baseColor: e.target.value });
  };
  const handleFooterColorChange = (e) => {
    setInitialConfig({ ...initialConfig, footerColor: e.target.value });
  };
  const handleIconsColorChange = (e) => {
    setInitialConfig({ ...initialConfig, iconsColor: e.target.value });
  };
  const handleHighlightedColorChange = (e) => {
    setInitialConfig({ ...initialConfig, highlightedColor: e.target.value });
  };
  const handleHoverNavColorChange = (e) => {
    setInitialConfig({ ...initialConfig, hoverNavColor: e.target.value });
  };
  const handleLocalLogoChange = (e) => {
    setInitialConfig({ ...initialConfig, logo: e.target.value });
    handleLogoChange(e);
  };
  const handleLocalLogoSecondaryChange = (e) => {
    setInitialConfig({ ...initialConfig, logoSecondary: e.target.value });
    handleLogoSecondaryChange(e);
  };
  const handleCheckboxSocieties = (e) => {
    setInitialConfig({ ...initialConfig, societies: e.target.checked });
  };
  const handleCheckboxDonation = (e) => {
    setInitialConfig({ ...initialConfig, donation: e.target.checked });
  };
  const handleCheckboxProtocolization = (e) => {
    setInitialConfig({ ...initialConfig, protocolization: e.target.checked });
  };
  const handleCheckboxCapitulations = (e) => {
    setInitialConfig({ ...initialConfig, capitulations: e.target.checked });
  };
  const handleProceedingsColorChange = (e) => {
    setInitialConfig({ ...initialConfig, proceedingsColor: e.target.value });
  };

  const getSiteConfig = async () => {
    try {
      const siteConfigRef = collection(db, "site-config");
      const siteConfigSnapshot = await getDocs(siteConfigRef);

      siteConfigSnapshot.forEach((doc) => {
        // console.log("ID:", doc.id, "Data:", doc.data());
        setConfigData(doc.data());
        setSiteConfig(doc.data());
      });
    } catch (error) {
      console.error(
        "Error al obtener los datos de la colección 'site-config':",
        error
      );
    }
  };

  useEffect(() => {
    getSiteConfig();
  }, [configData]);

  return (
    <section className="admin__container">
      <h2>Configuración del sitio</h2>
      <form onSubmit={submitHandler}>
        <div className="form__container">
          <div className="text__container">
            <p>Nombre de la notaría:</p>
            <input
              type="text"
              name="text-name"
              id="text-name"
              value={initialConfig.name}
              onChange={handleTextChange}
            />
          </div>
          <div className="colors__container">
            <div className="admin__container-element">
              <p>Color de base o principal:</p>
              <input
                type="color"
                name="base-color"
                id="base-color"
                onChange={handleBaseColorChange}
              />
            </div>
            <div className="admin__container-element">
              <p>Color de énfasis y footer:</p>
              <input
                type="color"
                name="footer-color"
                id="footer-color"
                onChange={handleFooterColorChange}
              />
            </div>
            <div className="admin__container-element">
              <p>Color de fondo para iconos:</p>
              <input
                type="color"
                name="icons-color"
                id="icons-color"
                onChange={handleIconsColorChange}
              />
            </div>
            <div className="admin__container-element">
              <p>Color de formulario activo:</p>
              <input
                type="color"
                name="highlighted-color"
                id="highlighted-color"
                onChange={handleHighlightedColorChange}
              />
            </div>
            <div className="admin__container-element">
              <p>Color de hover nav y tramites:</p>
              <input
                type="color"
                name="hover-nav-color"
                id="hover-nav-color"
                onChange={handleHoverNavColorChange}
              />
            </div>
            <div className="admin__container-element">
              <p>Background tramites:</p>
              <input
                type="color"
                name="proceedings-color"
                id="proceedings-color"
                onChange={handleProceedingsColorChange}
              />
            </div>
          </div>
          <div className="image__upload__container">
            <div className="admin__container-element-upload">
              <p>Cargar logo principal:</p>
              <input
                type="file"
                name="logo"
                id="logo"
                onChange={handleLocalLogoChange}
              />
            </div>
            <div className="admin__container-element-upload">
              <p>Cargar logo secundario:</p>
              <input
                type="file"
                name="logoSecondary"
                id="logoSecondary"
                onChange={handleLocalLogoSecondaryChange}
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="modules__section">
          <h2>Selección de módulos</h2>
          <div className="modules__section-elements">
            <div className="modules__section-checkbox">
              <label htmlFor="societies">Constitución de sociedades:</label>
              <input
                type="checkbox"
                name="societies"
                id="societies"
                onChange={handleCheckboxSocieties}
                checked={
                  initialConfig.societies !== null
                    ? initialConfig.societies
                    : configData?.societies
                }
              />
            </div>
            <div className="modules__section-checkbox">
              <label htmlFor="donation">Donación de dinero:</label>
              <input
                type="checkbox"
                name="donation"
                id="donation"
                onChange={handleCheckboxDonation}
                checked={
                  initialConfig.donation !== null
                    ? initialConfig.donation
                    : configData?.donation
                }
              />
            </div>
            <div className="modules__section-checkbox">
              <label htmlFor="protocolization">
                Protocolización de asambleas:
              </label>
              <input
                type="checkbox"
                name="protocolization"
                id="protocolization"
                onChange={handleCheckboxProtocolization}
                checked={
                  initialConfig.protocolization !== null
                    ? initialConfig.protocolization
                    : configData?.protocolization
                }
              />
            </div>
            <div className="modules__section-checkbox">
              <label htmlFor="capitulations">
                Capitulaciones matrimoniales:
              </label>
              <input
                type="checkbox"
                name="capitulations"
                id="capitulations"
                onChange={handleCheckboxCapitulations}
                checked={
                  initialConfig.capitulations !== null
                    ? initialConfig.capitulations
                    : configData?.capitulations
                }
              />
            </div>
          </div>
        </div>
        <hr />
        <div className="button__container">
          <button onClick={asyncSubmitHandler}>Guardar cambios</button>
        </div>
      </form>
    </section>
  );
}

export default AdminComponent;
