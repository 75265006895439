import { useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Grid, Typography, Button } from "@mui/material";
import { InputText } from "components/inputs/input-text";
import { InputRadio } from "components/inputs/input-radio";
import { colors } from "constants/colors";
import { InputSelect } from "components/inputs/input-select";
import countryList from "react-select-country-list";
import { SimpleInputSelect } from "components/inputs/simple-input-select";
import StatesMunicipalities from "constants/bd";
import { labelsMaritalStatus } from "./constants";
import { InputDatePicker } from "components/inputs/input-date-picker";
import { InputFile } from "components/inputs/input-file";
import ShowFiles from "components/show-files/show-files";

export const TwoNestedInput = ({ value, protocolizationById }) => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext(); // retrieve all hook methods

  const options = useMemo(() => countryList().getData(), []);

  const watchState = watch(`state`);

  const states = StatesMunicipalities.map((state) => state[0]);
  let municipalities = [];
  if (watchState) {
    municipalities = StatesMunicipalities.filter(
      (item) => watchState === item[0]
    )[0][1];
  }

  const [fileCompanyBackgroundNit, setFileCompanyBackgroundNit] = useState([]);

  const removefileCompanyBackgroundNit = () => {
    setFileCompanyBackgroundNit([]);
  };
  return (
    <>
      <div className="divider">
        <Typography
          gutterBottom
          color={colors.blue}
          style={{ fontSize: 40, fontWeight: "bold" }}
        >
          Datos generales del delegado
        </Typography>
      </div>

      <div style={{ marginTop: 30, marginBottom: 30 }}>
        <Typography variant="subtitle2" gutterBottom color={colors.blue}>
          Los campos marcados con * son obligatorios
        </Typography>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Nombre(s) *
            </Typography>
            <InputText
              name="name"
              value={value.name}
              control={control}
              errors={errors}
            />
            {errors.name && <p style={styles.errors}>{errors.name.message}</p>}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Apellido(s) *
            </Typography>
            <InputText
              name="lastname"
              value={value.lastname}
              control={control}
              errors={errors}
            />
            {errors.lastname && (
              <p style={styles.errors}>{errors.lastname.message}</p>
            )}
          </Grid>

          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Nacionalidad *
            </Typography>
            <InputSelect
              name={`nacionality`}
              value={value.nacionality}
              control={control}
              errors={errors}
              labels={options}
              placeholder="--Pais--"
            />
            {errors.nacionality && (
              <p style={styles.errors}>{errors.nacionality.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={6}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Lugar de nacimiento *
            </Typography>
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
            >
              <Grid item xs={4} sm={8} md={6}>
                <SimpleInputSelect
                  name={`state`}
                  value={value.state}
                  control={control}
                  errors={errors}
                  labels={states}
                  placeholder="--Estado--"
                />
                {errors.state && (
                  <p style={styles.errors}>{errors.state.message}</p>
                )}
              </Grid>
              <Grid item xs={4} sm={8} md={6}>
                <SimpleInputSelect
                  name={`municipality`}
                  value={value.municipality}
                  control={control}
                  errors={errors}
                  labels={municipalities}
                  disabled={!municipalities.length}
                  placeholder="--Ciudad--"
                />
                {errors.municipality && (
                  <p style={styles.errors}>{errors.municipality.message}</p>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} sm={8} md={6}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Estado civil *
            </Typography>
            <InputRadio
              name="maritalStatus"
              value={value.maritalStatus}
              control={control}
              errors={errors}
              labels={labelsMaritalStatus}
            />
            {errors.maritalStatus && (
              <p style={styles.errors}>{errors.maritalStatus.message}</p>
            )}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Fecha de nacimiento *
            </Typography>
            <InputDatePicker
              name={`dateBirth`}
              value={value.dateBirth}
              control={control}
              errors={errors}
            />
            {errors.dateBirth && (
              <p style={styles.errors}>{errors.dateBirth.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Ocupación *
            </Typography>
            <InputText
              name="ocupation"
              value={value.ocupation}
              control={control}
              errors={errors}
            />
            {errors.ocupation && (
              <p style={styles.errors}>{errors.ocupation.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Registro Federal de Contribuyentes *
            </Typography>
            <InputText
              name="federalRegister"
              value={value.federalRegister}
              control={control}
              errors={errors}
            />
            {errors.federalRegister && (
              <p style={styles.errors}>{errors.federalRegister.message}</p>
            )}
          </Grid>
          <Grid item xs={4} sm={8} md={3}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              CURP *
            </Typography>
            <InputText
              name="CURP"
              value={value.CURP}
              control={control}
              errors={errors}
            />
            {errors.CURP && <p style={styles.errors}>{errors.CURP.message}</p>}
          </Grid>
        </Grid>
      </div>

      <div className="divider">
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          <Grid item xs={4} sm={8} md={4}>
            <Typography
              className="subtitle2"
              variant="subtitle2"
              gutterBottom
              style={styles.title}
            >
              Identificación *
            </Typography>
            <Button
              variant="contained"
              component="label"
              style={styles.fileButton}
            >
              <InputFile
                name={`companyBackgroundFileNit`}
                setValue={setValue}
                control={control}
                hidden={true}
                setFiles={setFileCompanyBackgroundNit}
              />
              <span>Adjuntar</span>
              <div style={{ marginLeft: 15 }}>+</div>
            </Button>

            <ShowFiles
              valueStateGlobal={protocolizationById.companyBackgroundFile}
              valueStateLocal={fileCompanyBackgroundNit}
              removeFile={removefileCompanyBackgroundNit}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

const styles = {
  fileButton: {
    background: colors.blue,
    color: colors.white,
    borderRadius: 20,
    fontWeight: "bold",
    width: 160,
    marginRight: 20,
    alignItems: "center",
  },
  title: {
    color: colors.blue,
    fontWeight: "bold",
  },
  errors: {
    padding: "5px 15px",
    fontSize: 14,
    borderRadius: 15,
    marginTop: -1,
    borderTopLeftRadius: 2,
    backgroundColor: colors.dangerLight,
    display: "inline-block",
    border: `1px solid ${colors.danger}`,
    color: colors.danger,
  },
};
